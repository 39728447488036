import Grid from '@mui/material/Grid'
import { Button, IconButton } from '@mui/material'
import styles from './BottomProduct.module.css'
import { Add, Remove } from '@mui/icons-material'
import { useSelector, useDispatch } from 'react-redux'
import { decrement, increment, resetValue } from './BottomProduct.slice'
import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { colorShade } from '../../../utils/colorShade'
import { theme } from '../../../index'
import { canModifyDeliveryOrder } from '../../../utils/order_state_extension'
import TextField from '@mui/material/TextField'

const BottomProduct = ({ submitClick, setCounter, mobileView, subTotalAmount, isEdit, userOrder }) => {
  const count = useSelector((store) => store.counter_product.value)
  const dispatch = useDispatch()
  const intl = useIntl()

  useEffect(() => {
    if (isEdit === undefined || isEdit === false) {
      dispatch(resetValue())
    }
  }, [])

  const editProductQuantity = (event) => {
    if (event.target.value && !isNaN(Number(event.target.value))) {
      setCounter(event.target.value)
      dispatch(increment(event.target.value))
    }
  }

  return (
    <Grid container spacing={2} alignItems="center" justifyContent={'center'} className={styles.card} zIndex={10}>
      <Grid item xs={6}>
        <div className={styles.row} style={{ marginLeft: mobileView ? 0 : '12px' }}>
          <IconButton
            onClick={() => {
              if (count > 1) {
                dispatch(decrement())
                setCounter(count - 1)
              }
            }}
          >
            <Remove fontSize="large"/>
          </IconButton>
          <TextField
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', style: { textAlign: 'center', width: '40px' } }}
            value={count}
            variant={'standard'}
            onChange={(event) => editProductQuantity(event)}
          />
          <IconButton
            onClick={() => {
              dispatch(increment())
              setCounter(count + 1)
            }}
          >
            <Add fontSize="large"/>
          </IconButton>
        </div>
      </Grid>
      <Grid item xs={6}>
        <Button
          variant="contained"
          fullWidth
          color="primary"
          className={styles.button}
          onClick={submitClick}
          disabled={!canModifyDeliveryOrder(userOrder?.state)}
          sx={{
            '&:hover': {
              backgroundColor: colorShade(theme.palette.primary.main, -60),
            }
          }}
        >
          {intl.formatMessage({ id: (isEdit === undefined || isEdit === false) ? 'add' : 'update' })} {subTotalAmount}
        </Button>
      </Grid>
    </Grid>
  )
}

export default BottomProduct
