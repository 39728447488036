import { makeStyles } from '@mui/styles'
import { getColorForButton, getFontColorForButton } from '../../../utils/color_util'

export const homeUseStyles = makeStyles(() => ({
  button: {
    backgroundColor: ({ buttonAction }) => `#${getColorForButton(buttonAction)}`,
    color:  ({ buttonAction }) => `#${getFontColorForButton(buttonAction)}`,
    width: '300px',
    borderRadius: '50rem',
    paddingTop: '8px',
    paddingBottom: '8px',
    marginTop: '12px',
    marginBottom: '12px',
  }
}))
