import {
  TextField,
  ListItemText,
  ListSubheader,
  Typography,
} from '@mui/material'
import React from 'react'
import styles from './CommentProduct.module.css'
import getLocalKeyWords from '../../../utils/local_keywords'
import { useIntl } from 'react-intl'

const ProductComment = ({ setObservations, observations }) => {
  const intl = useIntl()

  const handleChange = (event) => {
    setObservations(event.target.value)
  }

  return (
    <div id={'comments'}>
      <ListSubheader className={styles.header}>
        <ListItemText
          className={styles.headerText}
          primary={
            <Typography variant="body1" color="black">
              {getLocalKeyWords('comment_title')}
            </Typography>
          }
        />
      </ListSubheader>
      <div className={styles.boxTextField}>
        <TextField
          id="outlined-multiline-comment"
          fullWidth={true}
          value={observations}
          variant="standard"
          multiline
          color="success"
          rows={5}
          onChange={handleChange}
          placeholder={intl.formatMessage({ id: 'writeHere' })}
        />
      </div>
    </div>
  )
}

export default ProductComment
