import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import { useEffect, useState } from 'react'
import List from '@mui/material/List'
//import ProductHeader from '../../../components/product/DetailProduct/ProductHeader'
import ToppingsProduct from '../../../components/product/DetailProduct/ToppingsProduct'
import BottomProduct from '../../../components/product/DetailProduct/BottomProduct'
import { productActions } from '../product.slice'
import { useDispatch, useSelector } from 'react-redux'
import { DialogTitle, Grid, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import styles from './ProductDialog.module.css'
import Loading from '../../../components/Loading'
import { BootstrapDialogStyle } from '../../../components/dialogs/BootstrapDialog.style'
import { formatCost } from '../../../utils'
import { getStorage } from '../../../utils/storage'
import { ConfirmDialog } from '../../../components/dialogs/ConfirmDialog'
import getLocalKeyWords from '../../../utils/local_keywords'
import { useIntl } from 'react-intl'
import ProductComment from '../../../components/product/DetailProduct/ProductComment'
import { revivalBack } from '../../../utils/navigation_util'
import ProductCarousel from '../../../components/product/DetailProduct/ProductCarousel'

const ProductDialog = ({ open, setOpen, mobileView, product, userOrder }) => {
  const dispatch = useDispatch()
  const [selectedToppings, setSelectedToppings] = useState([])
  const [observations, setObservations] = useState('')
  const [counter, setCounter] = useState(1)
  const loading = useSelector((store) => store.product.loading)
  const [toppingGroupRequired, setToppingGroupRequired] = useState(null)
  const [subTotalAmount, setSubTotalAmount] = useState('')
  const [commentRequired, setCommentRequired] = useState(false)
  const restaurantData = JSON.parse(localStorage.getItem('restaurant_data'))
  const intl = useIntl()

  useEffect(() => {
    if (commentRequired === true) {
      scrollToComments()
    }
  }, [commentRequired])

  useEffect(() => {
    calculateSubTotalAmount()
  }, [counter, selectedToppings])

  const handleClose = () => {
    setOpen(false)
    revivalBack()
  }

  const dialogDismiss = (event, reason) => {
    if (reason && reason === 'backdropClick')
      return
    setOpen(false)
    revivalBack()
  }

  const validateMinToppingQuantity = (group) => {
    if (selectedToppings) {
      let quantity = 0
      selectedToppings.forEach(topping => {
        if (topping.topping_group_id === group._id) {
          quantity += topping.quantity
        }
      })
      return group.min_toppings > quantity
    }
  }

  const calculateSubTotalAmount = () => {
    let toppingsAmount = 0
    if (product.topping_groups) {
      for (const group of product.topping_groups) {
        if (selectedToppings) {
          for (const selectedTopping of selectedToppings) {
            if (group.toppings) {
              for (const topping of group.toppings) {
                if (topping._id === selectedTopping._id) {
                  toppingsAmount += (topping.price?.cents || 0) * selectedTopping.quantity
                }
              }
            }
          }
        }
      }
    }
    const subTotal = {
      'subunits': (product.price?.cents || 0) * counter + (toppingsAmount * counter),
      'currency_iso': getStorage('currency')
    }
    setSubTotalAmount(formatCost(subTotal))
  }

  const validateAddProduct = () => {
    const toppings = []
    for (const group of product.topping_groups) {
      if (validateMinToppingQuantity(group)) {
        if (group.is_required) {
          setToppingGroupRequired(group)
          return
        }
      } else {
        if (selectedToppings) {
          for (const selectedTopping of selectedToppings) {
            for (const topping of group.toppings) {
              if (topping._id === selectedTopping._id) {
                const orderTopping = {
                  id: selectedTopping._id, quantity: selectedTopping.quantity,
                }
                toppings.push(orderTopping)
              }
            }
          }
        }
      }
    }
    if (observations === '' && restaurantData?.restaurant?.show_warning_comment === true) {
      ConfirmDialog({
        text: getLocalKeyWords('comment_advise'),
        confirmButtonText: intl.formatMessage({ id: 'confirmProduct' }, { product: getLocalKeyWords('product') }),
        cancelButtonText: intl.formatMessage({ id: 'back' }),
        onConfirm: () => {
          submitProduct(toppings)
        },
        onCancel: () => {
          setTimeout(() => {
            prepareScrollToComments()
          }, 400)
        }
      })
    } else {
      submitProduct(toppings)
    }
  }

  const prepareScrollToComments = () => {
    setToppingGroupRequired(undefined)
    setCommentRequired(true)
  }

  const submitProduct = (toppings) => {
    const params = {
      order_item: {
        amount: product.price,
        product_id: product._id,
        product_image: product.image,
        product_name: product.name,
        quantity: counter,
        client_observations: observations,
        toppings: toppings
      }
    }
    dispatch(productActions.submitProductAsync(params)).unwrap().then(() => handleClose())
  }

  const scrollToComments = () => {
    const element = document.getElementById('comments')
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      })
      setTimeout(() => {
        setCommentRequired(false)
      }, 500)
    }
  }

  return (
    <div>
      <BootstrapDialogStyle
        onClose={dialogDismiss}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: mobileView ? '100%' : '95%',
              maxWidth: '1250px',
            },
          },
          zIndex: 100
        }}
        fullScreen={mobileView}
      >
        <DialogTitle sx={{ m: 0, p: 2, marginBottom: '8px' }}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 10,
              top: 8,
              marginBottom: 24,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon/>
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ overflow: mobileView === false && product.image !== null ? 'hidden' : 'auto' }}>
          <div style={{ justifyContent: 'center', display: 'flex' }}>
            <div style={{ width: '100%' }}>
              {loading && <Loading/>}
              <Grid container direction={'row'} maxHeight={'500px'}>
                <Grid
                  item
                  xs={12}
                  md={product.image === null ? 12 : 6}
                  lg={product.image === null ? 12 : 6}
                  sx={{
                    marginLeft: mobileView === false && product.image !== null ? '22px' : '12px',
                    paddingRight: mobileView === false && product.image !== null ? '8px' : '12px',
                  }}
                  className={mobileView === false && product.image !== null ? styles.scrollHidden : styles.verticalView}>
                  <ProductCarousel {...product} mobileView={mobileView}/>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={product.image === null ? 12 : 6}
                  lg={product.image === null ? 12 : 6}
                  sx={{
                    marginLeft: mobileView === false && product.image !== null ? 0 : '12px',
                    paddingRight: mobileView === false && product.image !== null ? '12px' : '12px',
                    backgroundColor: 'white'
                  }}
                  className={mobileView === false && product.image !== null ? styles.rowView : styles.verticalView}>
                  <List>
                    <ToppingsProduct
                      {...product}
                      selectedToppings={selectedToppings}
                      setSelectedToppings={(selectedToppings) => {
                        setSelectedToppings(selectedToppings)
                      }}
                      product={product}
                      toppingGroupRequired={toppingGroupRequired}
                      setToppingGroupRequired={setToppingGroupRequired}
                    />
                    <ProductComment setObservations={setObservations}/>
                  </List>
                </Grid>
              </Grid>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <BottomProduct
            setCounter={setCounter}
            submitClick={validateAddProduct}
            mobileView={mobileView}
            subTotalAmount={subTotalAmount}
            userOrder={userOrder}
          />
        </DialogActions>
      </BootstrapDialogStyle>
    </div>
  )
}
export default ProductDialog
