import * as React from 'react'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import { useIntl } from 'react-intl'
import getLocalKeyWords from '../../../utils/local_keywords'
import { maxButtonWebWidth, maxButtonWidth } from '../../../utils/screen_utils'
import { colorShade } from '../../../utils/colorShade'
import { theme } from '../../../index'

const BottomUserOrderPay = ({ resumeClick, mobileView }) => {
  const intl = useIntl()
  return (<Card>
    <Grid container
          alignItems="center"
          justifyContent="center"
    >
      <Button variant={'contained'}
              size={'large'}
              color={'info'}
              onClick={resumeClick}
              sx={{
                borderRadius: '.4rem',
                paddingY: '8px',
                position: 'fixed',
                bottom: 0,
                zIndex: 10,
                marginBottom: '8px',
                marginLeft: '8px',
                marginRight: '8px',
                width: mobileView ? maxButtonWidth : maxButtonWebWidth,
                '&:hover': {
                  backgroundColor: colorShade(theme.palette.info.main, -60),
                }
              }}
      >
        {intl.formatMessage({ id: 'sendOrder' }, { order: getLocalKeyWords('order') })}
      </Button>
    </Grid>
  </Card>)
}

export default BottomUserOrderPay
