import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Axios from 'axios'
import { urlAddProduct, urlDetailProduct, urlEditProduct } from '../../apis/DetailProduct'
import LanguageDialog from '../../components/dialogs/LanguageDialog'
import { getStorage } from '../../utils/storage'

export const PRODUCT_NAME = 'product'

const initialState = {
  value: null,
  status: 'idle',
  loading: false
}

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const getProductAsync = createAsyncThunk(
  'getProduct',
  async (productId) => {
    try {
      const resp = await Axios({
        headers: {
          Authorization: `Bearer ${getStorage('st')}`,
          'X-PI-TABLE-TOKEN': getStorage('tt'),
          'X-PI-RESTAURANT-ID': getStorage('restaurant_id'),
          lang: navigator.language,
          'If-None-Match': ''
        },
        url: `${urlDetailProduct}/${productId}`,
        method: 'GET'
        // params: DATA,
      })
      return resp.data.response
    } catch (error) {
      return error
    }
  }
)

export const submitProductAsync = createAsyncThunk(
  'submitProduct',
  async (request, {rejectWithValue}) => {
    try {
      const resp = await Axios({
        headers: {
          Authorization: `Bearer ${getStorage('st')}`,
          'X-PI-TABLE-TOKEN': getStorage('tt'),
          'X-PI-RESTAURANT-ID': getStorage('restaurant_id'),
          lang: navigator.language,
          'If-None-Match': ''
        },
        url: urlAddProduct,
        method: 'POST',
        data: request
      })
      return resp.data.response
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const editOrderAsync = createAsyncThunk(
  'editOrder',
  async (request, {rejectWithValue}) => {
    try {
      const resp = await Axios({
        headers: {
          Authorization: `Bearer ${getStorage('st')}`,
          'X-PI-TABLE-TOKEN': getStorage('tt'),
          'X-PI-RESTAURANT-ID': getStorage('restaurant_id'),
          lang: navigator.language,
          'If-None-Match': ''
        },
        url: urlEditProduct,
        method: 'PUT',
        data: request
      })
      return resp.data.response
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)


export const productSlice = createSlice({
  name: PRODUCT_NAME,
  initialState,

  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getProductAsync.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getProductAsync.fulfilled, (state, action) => {
      state.value = action.payload
      state.loading = false
    })
    builder.addCase(getProductAsync.rejected, (state) => {
      state.status = 'error'
      state.loading = false
    })
    builder.addCase(submitProductAsync.pending, (state) => {
      state.loading = true
    })
    builder.addCase(submitProductAsync.fulfilled, (state) => {
      LanguageDialog({ from: submitProductAsync})
      state.status = 'idle'
      state.loading = false
    })
    builder.addCase(submitProductAsync.rejected, (state) => {
      state.loading = false
    })
    builder.addCase(editOrderAsync.pending, (state) => {
      state.loading = true
    })
    builder.addCase(editOrderAsync.fulfilled, (state) => {
      state.status = 'idle'
      state.loading = false
    })
    builder.addCase(editOrderAsync.rejected, (state) => {
      state.loading = false
    })
  }
})

export const productActions = { getProductAsync, submitProductAsync, editOrderAsync }

export default productSlice.reducer
