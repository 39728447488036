import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Axios from 'axios'
import {
  urlGetUserClient,
  urlLogin,
  urlUpdateDeviceData,
  urlUpdatePhoneNumber,
  urlUpdateUserName
} from '../../apis/login'
import LanguageDialog from '../../components/dialogs/LanguageDialog'
import { getStorage, saveStorage } from '../../utils/storage'

const initialState = {
  value: null, status: 'idle', loading: false, user: null
}

export const login = createAsyncThunk('login', async (request, { rejectWithValue }) => {
  try {
    const resp = await Axios({
      headers: {
        lang: navigator.language,
        'If-None-Match': ''
      },
      url: urlLogin,
      method: 'POST',
      data: request
    })
    return resp.data.response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }

})

export const updateUserName = createAsyncThunk('updateUserName', async (request) => {
  const resp = await Axios({
    headers: {
      Authorization: `Bearer ${getStorage('st')}`,
      'X-PI-TABLE-TOKEN': getStorage('tt'),
      'X-PI-RESTAURANT-ID': getStorage('restaurant_id'),
      lang: navigator.language,
      'If-None-Match': ''
    },
    url: urlUpdateUserName,
    method: 'PUT',
    data: request
  })
  return resp.data.response
})

export const updatedPhoneNumber = createAsyncThunk(
  'updatedPhoneNumber',
  async (request) => {
    const resp = await Axios({
      headers: {
        Authorization: `Bearer ${getStorage('st')}`,
        'X-PI-TABLE-TOKEN': getStorage('tt'),
        'X-PI-RESTAURANT-ID': getStorage('restaurant_id'),
        lang: navigator.language,
        'If-None-Match': ''
      },
      url: urlUpdatePhoneNumber,
      method: 'PUT',
      data: request
    })
    return resp.data
  }
)

export const updatedDeviceData = createAsyncThunk(
  'updatedDeviceData',
  async (request, { rejectWithValue }) => {
    try {
      const resp = await Axios({
        headers: {
          Authorization: `Bearer ${getStorage('st')}`,
          'X-PI-TABLE-TOKEN': getStorage('tt'),
          'X-PI-RESTAURANT-ID': getStorage('restaurant_id'),
          lang: navigator.language,
          'If-None-Match': ''
        },
        url: urlUpdateDeviceData,
        method: 'PUT',
        data: request
      })
      return resp.data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const checkForSession = createAsyncThunk(
  'checkForSession',
  async (request, { rejectWithValue }) => {
    try {
      const resp = await Axios({
        headers: {
          Authorization: `Bearer ${getStorage('st')}`,
          'X-PI-TABLE-TOKEN': getStorage('tt'),
          'X-PI-RESTAURANT-ID': getStorage('restaurant_id'),
          lang: navigator.language,
          'If-None-Match': ''
        },
        url: urlGetUserClient,
        method: 'GET',
        params: request
      })
      return resp.data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const USER_NAME = 'user'

export const userSlice = createSlice({
  name: USER_NAME,
  initialState,

  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.loading = true
    })
    builder.addCase(login.fulfilled, (state, action) => {
      state.value = action.payload
      state.loading = false
      if (state.value != null) {
        saveStorage('st', state.value.auth_token)
        saveStorage('user_id', state.value.user._id)
      }
    })
    builder.addCase(login.rejected, (state) => {
      state.status = 'error'
      //state.loading = false
      saveStorage('st', null)
      saveStorage('user_id', null)
      LanguageDialog({ from: login })
    })
    builder.addCase(updateUserName.pending, (state) => {
      state.loading = true
    })
    builder.addCase(updateUserName.fulfilled, (state, action) => {
      state.value = action.payload
      state.loading = false
    })
    builder.addCase(updateUserName.rejected, (state) => {
      state.status = 'error'
      state.loading = false
      LanguageDialog({ from: updateUserName })
    })
    builder.addCase(updatedPhoneNumber.pending, (state) => {
      state.loading = true
    })
    builder.addCase(updatedPhoneNumber.fulfilled, (state) => {
      state.loading = false
    })
    builder.addCase(updatedPhoneNumber.rejected, (state) => {
      state.status = 'error'
      state.loading = false
      LanguageDialog({ from: updatedPhoneNumber })
    })
    builder.addCase(updatedDeviceData.pending, (state) => {
      state.loading = true
    })
    builder.addCase(updatedDeviceData.fulfilled, (state) => {
      state.loading = false
    })
    builder.addCase(updatedDeviceData.rejected, (state) => {
      state.status = 'error'
      state.loading = false
      LanguageDialog({ from: updatedDeviceData })
    })
    builder.addCase(checkForSession.pending, () => {

    })
    builder.addCase(checkForSession.fulfilled, (state, action) => {
      state.user = action.payload
    })
    builder.addCase(checkForSession.rejected, (state) => {
      state.status = 'error'
    })
  }
})

export const userActions = { login, updateUserName, updatedPhoneNumber, updatedDeviceData, checkForSession }

export default userSlice.reducer
