import { localhost, domain, cookieNotice } from './util'
import Cookies from 'js-cookie'

export const saveStorage = (key, value) => {
  const currentDomain = window.location.hostname === 'localhost' ? localhost : domain
  Cookies.set(key, value, { domain: currentDomain })
}

export const getStorage = (key) => {
  return Cookies.get(key) || null
}

export const removeStorageByKey = (key) => {
  const currentDomain = window.location.hostname === 'localhost' ? localhost : domain
  Cookies.remove(key, { domain: currentDomain })
}

export const removeStorage = () => {
  const currentDomain = window.location.hostname === 'localhost' ? localhost : domain
  Object.keys(Cookies.get()).forEach(function (cookieName) {
    const neededAttributes = {
      domain: currentDomain
    }
    Cookies.remove(cookieName, neededAttributes)
  })
}

export const removeThemeStorage = () => {
  const currentDomain = window.location.hostname === 'localhost' ? localhost : domain
  Cookies.remove('title_color', { domain: currentDomain })
  Cookies.remove('primary_color', { domain: currentDomain })
  Cookies.remove('secondary_color', { domain: currentDomain })
  Cookies.remove('tertiary_color', { domain: currentDomain })
  Cookies.remove('restaurant_logo', { domain: currentDomain })
  Cookies.remove('wallpaper', { domain: currentDomain })
}

export const checkCookiePermission = () => {
  const cookiePermission = getStorage(cookieNotice)
  if (cookiePermission === 'false') {
    removeStorage()
  }
}
