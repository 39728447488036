import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { deliveryOrderStyles } from '../../../pages/deliveryOrder/DeliveryOrder/DeliveryOrderPage.style'
import React from 'react'
import {
  ListSubheader
} from '@mui/material'
import { formatCost } from '../../../utils'
import { useIntl } from 'react-intl'
import getLocalKeyWords from '../../../utils/local_keywords'
import { urlIfy } from '../../../utils/util'
import { getStorage } from '../../../utils/storage'

const SummaryDeliveryOrder = ({ myOrder, table }) => {
  const intl = useIntl()
  const styles = deliveryOrderStyles()

  const total = {
    'subunits': ((myOrder.tip_amount !== null && myOrder.tip_amount !== undefined ? (myOrder.tip_amount?.subunits || 0) : 0) + (myOrder.delivery_amount !== null && myOrder.delivery_amount !== undefined && myOrder.pickup_at_restaurant === false ? (myOrder.delivery_amount?.subunits || 0) : 0) + ((myOrder.taxes_amount.percentage * (myOrder.total_amount?.subunits || 0)) / 100) + (myOrder.total_amount?.subunits || 0)),
    'currency_iso': getStorage('currency')
  }

  const productsTotal = {
    'subunits': (myOrder.total_amount?.subunits || 0), 'currency_iso': getStorage('currency')
  }

  return (
    <div>
      {myOrder?.payment_method.method.includes('wire_transfer') && myOrder.payment_method.data !== null && myOrder.payment_method.data !== '' && myOrder.payment_method.data !== undefined &&
        <div>
          <ListSubheader className={styles.headerSection}>
            <Grid container alignItems="center">
              <Typography variant="body1" color="black" fontWeight={'bold'}>
                {intl.formatMessage({ id: 'paymentInformation' })}
              </Typography>
            </Grid>
          </ListSubheader>
          <div style={{ padding: 10 }}>
            {myOrder.pickup_at_restaurant === false && (myOrder.fixed_delivery_amount !== null && myOrder.fixed_delivery_amount !== undefined && !myOrder.fixed_delivery_amount) && (myOrder.delivery_amount === null || myOrder.delivery_amount === undefined) &&
              <Typography fontWeight={'bold'} color={'red'}>
                {intl.formatMessage({ id: 'youNeedToWaitForDeliveryAmount' }, {order: getLocalKeyWords('order'), restaurant: getStorage('restaurant_name')})}
              </Typography>}
            {(myOrder.wire_transfer_receipt_url === null || myOrder.wire_transfer_receipt_url === undefined) &&
              <Typography variant="body1" color="red" fontWeight={'bold'} sx={{ marginY: '8px' }}>
                {intl.formatMessage({ id: 'yoMustAttachAPaymentProof' })}
              </Typography>
            }
            <div>
              <div dangerouslySetInnerHTML={{ __html: urlIfy(myOrder.payment_method.data) }}/>
            </div>
          </div>
        </div>}
      <ListSubheader className={styles.headerSection}>
        <Grid container alignItems="center">
          <Typography variant="body1" color="black" fontWeight={'bold'}>
            {intl.formatMessage({ id: 'orderSummary' }, { order: getLocalKeyWords('order') })}
          </Typography>
        </Grid>
      </ListSubheader>
      <Card className={styles.summaryCard} sx={{ backgroundColor: 'primary.main', marginX: '8px' }}>
        <CardContent>
          <Grid container>
            <Grid item xs={7}>
              <Typography gutterBottom variant="body1" className={styles.titleLeft}>
                {intl.formatMessage({ id: 'paymentMethod' })}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Grid>
                <Typography gutterBottom variant="h6" className={styles.titleRight}>
                  {myOrder.payment_method.label}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={7}>
              <Typography gutterBottom variant="body1" className={styles.titleLeft}>
                {intl.formatMessage({ id: 'products' }, { products: getLocalKeyWords('products') })}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Grid>
                <Typography gutterBottom variant="h6" className={styles.titleRight}>
                  {formatCost(productsTotal)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {table?.delivery_activated === true && myOrder.pickup_at_restaurant === false ? <Grid container>
            <Grid item xs={7}>
              <Typography gutterBottom variant="body1" className={styles.titleLeft}>
                {intl.formatMessage({ id: 'deliveryAmount' })}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Grid>
                <Typography gutterBottom variant="h6" className={styles.titleRight}>
                  {myOrder.delivery_amount !== null && myOrder.delivery_amount !== undefined ? formatCost(myOrder.delivery_amount) : myOrder.fixed_delivery_amount !== null && myOrder.fixed_delivery_amount !== undefined && myOrder.fixed_delivery_amount === true ? formatCost({
                    'subunits': 0, 'currency_iso': getStorage('currency')
                  }) : `** ${intl.formatMessage({ id: 'toDefine' })}`}
                </Typography>
              </Grid>
            </Grid>
          </Grid> : <div/>}
          {myOrder.pickup_at_restaurant === false && (myOrder.fixed_delivery_amount !== null && myOrder.fixed_delivery_amount !== undefined && !myOrder.fixed_delivery_amount) && (myOrder.delivery_amount === null || myOrder.delivery_amount === undefined) &&
            <Typography color={'white'} fontWeight={'bold'} paddingBottom={1} paddingLeft={1} fontSize={12}>
              {intl.formatMessage({ id: 'deliveryAmountMessage' }, { order: getLocalKeyWords('order') })}
            </Typography>}
          {myOrder.tip_amount !== null && myOrder.tip_amount !== undefined && <Grid container>
            <Grid item xs={7}>
              <Typography gutterBottom variant="body1" className={styles.titleLeft}>
                {intl.formatMessage({ id: 'tip' })}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Grid>
                <Typography gutterBottom variant="h6" className={styles.titleRight}>
                  {myOrder.tip_amount !== null && myOrder.tip_amount !== undefined ? formatCost(myOrder.tip_amount) : formatCost({
                    'subunits': 0, 'currency_iso': getStorage('currency')
                  })}

                </Typography>
              </Grid>
            </Grid>
          </Grid>}
          <Grid container>
            <Grid item xs={7}>
              <Typography gutterBottom variant="body1" className={styles.titleLeft}>
                {intl.formatMessage({ id: 'taxes' })}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Grid>
                <Typography gutterBottom variant="h6" className={styles.titleRight}>
                  {formatCost({
                    'subunits': ((myOrder.taxes_amount.percentage * (myOrder.total_amount?.subunits || 0)) / 100),
                    'currency_iso': getStorage('currency')
                  })}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={7}>
              <Typography gutterBottom variant="body1" className={styles.titleLeft}>
                {intl.formatMessage({ id: 'total' })}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Grid>
                <Typography gutterBottom variant="h6" className={styles.titleRight}>
                  {formatCost(total)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <div style={{ height: 90 }}></div>
    </div>
  )
}

export default SummaryDeliveryOrder
