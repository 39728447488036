import { backgroundForHome, container2 } from '../../../components/Background.style'
import styles from '../Home.module.css'
import HomeImage from '../../../components/home/HomeImage'
import HomeInformation from '../../../components/home/HomeInformation'
import CookieNotice from '../../../components/CookieNotice'
import FooterMenu from '../../../features/menu/components/FooterMenu'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import BranchButton from '../../../components/home/BranchButton'
import { updateTheme } from '../../../features/theme/Theme.slice'
import Loading from '../../../components/Loading'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { homeActions } from '../../../features/home/Home.slice'
import { paramIdFromHash } from '../../../utils/paramIdFromHash'
import { getStorage, saveStorage } from '../../../utils/storage'
import { restaurantActions } from '../../../features/menu/restaurant.slice'
import getKeywordsFromFirestore from '../../../utils/keyword'
import orderStatesFromFirestore from '../../../utils/order_states_from_firestore'
import ActiveOrder from '../../../components/ActiveOrder'
import BackButton from '../../../components/buttons/BackButton'

const BranchesPage = () => {
  const [mobileView, setMobileView] = useState(false)
  const textColor = getStorage('title_color') || 'black'
  const homeInfo = useSelector((store) => store.home.homeInfo)
  const loading = useSelector((store) => store.restaurant.loading)
  const homeLoading = useSelector((store) => store.home.loading)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const { hash } = useLocation()

  const setResponsiveness = () => {
    return window.innerWidth < 900
      ? setMobileView(true)
      : setMobileView(false)
  }

  useEffect(() => {
    setResponsiveness()
    window.addEventListener('resize', () => setResponsiveness())
    return () => {
      window.removeEventListener('resize', () => setResponsiveness())
    }
  }, [])

  useEffect(() => {
    checkHomeData()
  }, [])

  useEffect(() => {
    if (homeInfo !== null) {
      initVisualData()
    }
  }, [homeInfo])

  const checkHomeData = () => {
    if (homeInfo === null || homeInfo === undefined) {
      let value
      const tableId = params.id ? params.id : paramIdFromHash(hash)
      if (tableId !== null) {
        value = {
          'table_uid': tableId
        }
      } else {
        value = {
          'host': window.location.hostname
        }
      }
      dispatch(homeActions.checkForHome(value)).catch(() => {
        navigate('/exit')
      })
    } else {
      initVisualData()
    }
  }

  const initVisualData = () => {
    dispatch(updateTheme())
  }

  const openBranchMenu = (menu) => {
    saveStorage('table_uid', menu.table_uid)
    dispatch(restaurantActions.restaurantData()).unwrap().then(() => {
      initConfigData()
      navigate(`/products/${menu.table_uid}`)
    }).catch(() => {
      navigate('/exit')
    })
  }

  const initConfigData = async () => {
    await getKeywordsFromFirestore()
    await orderStatesFromFirestore()
  }

  return (
    loading || homeLoading ? <Loading/> :
      <div style={backgroundForHome(mobileView, homeInfo?.wallpaper_url)}>
        <div style={container2(mobileView)}>
          <div className={styles.container2}>
            <BackButton/>
            <ActiveOrder/>
            <HomeImage homeInfo={homeInfo}/>
            <HomeInformation/>
            {homeInfo !== null && homeInfo?.restaurant_locations.map((restaurant_location) => (
              <BranchButton
                key={restaurant_location.id}
                onClick={openBranchMenu}
                buttonName={restaurant_location.name}
                restaurant_location={restaurant_location}
                mobileView={mobileView}
              />
            ))}
          </div>
          <CookieNotice/>
          <FooterMenu categories={0} textColor={textColor}/>
        </div>
      </div>
  )
}

export default BranchesPage
