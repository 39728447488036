import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import styles from './OrderSomethingElseButton.module.css'
import { useIntl } from 'react-intl'
import { tableConfirmed, tablePending, tablePreparing } from '../../../utils/order_state_extension'
import getLocalKeyWords from '../../../utils/local_keywords'
import { getStorage } from '../../../utils/storage'
import { OrderSomethingElseButtonStyle } from './OrderSomethingElseButton.style'

const OrderSomethingElseButton = ({ myOrder, mobileView }) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const classes = OrderSomethingElseButtonStyle(mobileView)

  const orderSomethingElse = () => {
    navigate(`/products/${getStorage('table_uid')}`, { state: { fromActiveOrder: true } })
  }

  return ((myOrder !== null && myOrder !== undefined && (myOrder.state === tablePending || myOrder.state === tableConfirmed || myOrder.state === tablePreparing)) ?
    <Grid container direction="column" alignItems={'center'} item xs={12} className={styles.grid}>
      <Button variant={'outlined'} onClick={orderSomethingElse} style={classes.button}>
        {intl.formatMessage({ id: 'backToMenu' }, { menu: getLocalKeyWords('menu') })}
      </Button>
    </Grid> : <div/>)
}

export default OrderSomethingElseButton
