import { makeStyles } from '@mui/styles'
import { maxButtonWidth, maxMobileWidth } from '../../../utils/screen_utils'

export const AddressesDialogStyle = makeStyles(theme => ({
  iconButton: {
    position: 'absolute',
    right: 10,
    top: 8,
    marginBottom: 24,
    color: theme.palette.grey[500],
  },
  dialog: {
    '& .MuiDialog-container': {
      '& .MuiPaper-root': {
        width: '100%',
        maxWidth: '600px',
      },
    },
    zIndex: 100
  }
}))

export const AddressesDialogStyles = (mobileView) => ({
  container3: {
    justifyContent: 'center',
    display: mobileView ? 'inherit' : 'flex'
  },
  container4: {
    width: maxMobileWidth
  },
  button: {
    width: maxButtonWidth,
    marginRight: '10px',
    borderRadius: '.4rem'
  },
  mapContainer: {
    justifyContent: 'center',
    width: maxMobileWidth,
  }
})