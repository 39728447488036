export const ProductItemStyle = (mobileView) => ({
  button: {
    borderRadius: '.4rem',
    paddingTop: '8px',
    width: mobileView ? '90px' : '110px',
    height: '25px',
    fontSize: 10,
    marginTop: '8px',
  },
  image: {
    width: mobileView ? 90 : 110,
    height: mobileView ? 90 : 110,
    objectFit: 'cover',
    borderRadius: '.4rem',
    inset: '0px'
  }
})