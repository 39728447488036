import * as React from 'react'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { MyQuantity, tableOrderStyles } from '../../../pages/table/TableOrder/TableOrderPage.style'
import Divider from '@mui/material/Divider'
import { stateMessage } from '../../../utils/order_state_extension'
import { useIntl } from 'react-intl'
import { theme } from '../../../index'
import { colorShade } from '../../../utils/colorShade'
import getLocalKeyWords from '../../../utils/local_keywords'

const HeaderTableOrder = ({ table, myOrder, otherUsersOrders }) => {
  const intl = useIntl()
  const styles = tableOrderStyles()
  let keys = Object.keys(otherUsersOrders)
  return (<Card className={styles.headerCard} sx={{ backgroundColor: 'primary.main', marginX: '8px' }}>
    <CardContent>
      <Grid container>
        <Grid item xs={10}>
          <Typography gutterBottom variant="h6" className={styles.titleLeft}>
            {intl.formatMessage({ id: 'hi' })}, {myOrder.user_name}
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={8}>
          <Typography gutterBottom variant="h6" className={styles.titleLeft}>
            {intl.formatMessage({ id: 'table' }, { table: getLocalKeyWords('table') })} {table.index}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Grid>
            <Typography gutterBottom variant="body1" className={styles.titleRight}>
              # {intl.formatMessage({ id: 'order' }, { order: getLocalKeyWords('order') })} {table.consecutive}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container alignItems={'center'}>
        <Grid item xs={2} container alignItems="center">
          <Grid className={styles.circleMe}>
            <MyQuantity sx={{
              width: 35,
              height: 35,
              backgroundColor: 'info.main'
            }}>{intl.formatMessage({ id: 'you' })}</MyQuantity>
          </Grid>
          <Divider orientation="vertical" flexItem color="white" className={styles.divider}/>
        </Grid>
        <Grid item xs={10} container>
          <Grid item container="true">
            {keys.slice(0, 7).map((key) => (
              <Grid key={key} item className={styles.circleMe}>
                <MyQuantity sx={{
                  width: 35,
                  height: 35,
                  backgroundColor: colorShade(theme.palette.primary.main, -60)
                }}>{otherUsersOrders !== null && otherUsersOrders[key]?.user_name !== null ? otherUsersOrders[key]?.user_name.charAt(0) : 'A'}</MyQuantity>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      {table.state !== 'pending' && <Grid container>
        <Typography variant="body1" color="white" paddingTop={2} textAlign={'center'}>
          {stateMessage(table.state)}
        </Typography>
      </Grid>}
      {/*<Grid container paddingTop={1}>
        <Typography variant="caption" color="white">
          {intl.formatMessage({ id: 'afterLunchPayOrder' })}
        </Typography>
      </Grid>*/}
    </CardContent>
  </Card>)
}

export default HeaderTableOrder
