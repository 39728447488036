import React from 'react'
import { Tab, Tabs, tabsClasses } from '@mui/material'
import { makeStyles, withStyles } from '@mui/styles'
import throttle from 'lodash/throttle'

const tabHeight = 69
const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '2px',
    borderRadius: '8rem',
    zIndex: 1,
    top: 55,
    backgroundColor: "#1fad69",
  },
})(props => <Tabs
  {...props}
  variant="scrollable"
  scrollButtons={false}
  TabIndicatorProps={{
    children: <div/>, sx: {
      top: 22,
    },
  }}
  sx={{
    [`& .${tabsClasses.scrollButtons}`]: {
      '&.Mui-disabled': { opacity: 0.3 },
    },
  }}
/>)

const StyledTab = withStyles( ({
  root: {
    height: tabHeight,
    paddingLeft: '7px',
    paddingRight: '7px',
    '&:focus': {
      opacity: 1,
    },
    fontSize: '11px',
    textTransform: 'capitalize',
    zIndex: 2,
    '&.MuiTab-root.Mui-selected': {
      color: '#1fad69'
    },
    maxWidth: '200px'
  },
}))(props => <Tab {...props}/>)

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  }, indicator: {
    padding: theme.spacing(1),
    zIndex: 1,
    transformOrigin: '0%',
    position: 'absolute',
  }, demo2: {
    backgroundColor: '#fff',
    position: 'sticky',
    top: 55,
    left: 0,
    right: 0,
    width: '100%',
    zIndex: 1,
  }
}))

/******* This is the scroll spy magic */

const noop = () => {}

const useThrottledOnScroll = (callback, delay) => {
  const throttledCallback = React.useMemo(() => (callback ? throttle(callback, delay) : noop), [callback, delay])

  React.useEffect(() => {
    if (throttledCallback === noop) return undefined

    window.addEventListener('scroll', throttledCallback)
    return () => {
      window.removeEventListener('scroll', throttledCallback)
      throttledCallback.cancel()
    }
  }, [throttledCallback])
}

const ScrollSpyTabs = (props) => {
  const [activeState, setActiveState] = React.useState(null)
  const { tabsInScroll } = props

  let itemsServer = tabsInScroll.map(tab => {
    //const hash = textToHash(tab.text);
    return {
      icon: tab.icon || '',
      text: tab.text,
      component: tab.component,
      hash: tab.hash,
      node: document.getElementById(tab.hash),
      index: tab.index
    }
  })

  const itemsClientRef = React.useRef([])
  React.useEffect(() => {
    itemsClientRef.current = itemsServer
  }, [itemsServer])

  const clickedRef = React.useRef(false)
  const unsetClickedRef = React.useRef(null)
  const findActiveIndex = React.useCallback(() => {
    // set default if activeState is null
    if (activeState === null) setActiveState(itemsServer[0].hash)

    // Don't set the active index based on scroll if a link was just clicked
    if (clickedRef.current) return

    let active
    for (let i = itemsClientRef.current.length - 1; i >= 0; i -= 1) {
      // No hash if we're near the top of the page
      if (document.documentElement.scrollTop < 0) {
        active = { hash: null }
        break
      }

      const item = itemsClientRef.current[i]

      if (item.node && item.node.offsetTop < document.documentElement.scrollTop + document.documentElement.clientHeight / 8 + tabHeight) {
        active = item
        break
      }
    }

    if (active && activeState !== active.hash) {
      setActiveState(active.hash)
    }
  }, [activeState, itemsServer])

  // Corresponds to 10 frames at 60 Hz
  useThrottledOnScroll(itemsServer.length > 0 ? findActiveIndex : null, 166)

  const handleClick = hash => () => {
    // Used to disable findActiveIndex if the page scrolls due to a click
    clickedRef.current = true
    unsetClickedRef.current = setTimeout(() => {
      clickedRef.current = false
    }, 1000)

    if (activeState !== hash) {
      setActiveState(hash)

      if (window) window.scrollTo({
        top: document.getElementById(hash).getBoundingClientRect().top + window.pageYOffset - 120, behavior: 'smooth'
      })
    }
  }

  React.useEffect(() => () => {
    clearTimeout(unsetClickedRef.current)
  }, [])

  const classes = useStyles()

  return (<div>
    <nav className={classes.demo2}>
      <StyledTabs value={activeState ? activeState : itemsServer[0].hash}>
        {itemsServer.map(item2 => (<StyledTab
          key={item2.hash}
          label={item2.text}
          onClick={handleClick(item2.hash)}
          value={item2.hash}
        />))}
      </StyledTabs>
    </nav>

    <div className="container">
      {itemsServer.map(item1 => (<article id={item1.hash} key={item1.index}>
        {item1.component}
      </article>))}
    </div>
  </div>)
}

export default ScrollSpyTabs
