import { makeStyles } from '@mui/styles'

export const LocationBranchDialogStyle = makeStyles(theme => ({
  iconButton: {
    position: 'absolute',
    right: 10,
    top: 8,
    marginBottom: 24,
    color: theme.palette.grey[500],
  },
  dialog: {
    '& .MuiDialog-container': {
      '& .MuiPaper-root': {
        width: '100%',
        maxWidth: '1200px',
      },
    },
    zIndex: 100
  },
  price: {
    margin: '16px'
  },
  button: {
    width: '40%',
    marginRight: '10px',
    borderRadius: '.4rem',
    marginTop: '8px',
    marginBottom: '8px'
  },
  container: {
    padding: '8px'
  }
}))
