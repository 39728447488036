import * as React from 'react'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { MyQuantity, userOrderStyles } from '../../../pages/deliveryOrder/UserOrderDeliveryPayBill/UserOrderDeliveryPayBillPage.style'
import {
  Avatar, IconButton, List, ListItem, ListItemAvatar, ListSubheader
} from '@mui/material'
import { formatCost } from '../../../utils'
import { useState } from 'react'
import { Edit, ExpandLess, ExpandMore } from '@mui/icons-material'
import Collapse from '@mui/material/Collapse'
import { useIntl } from 'react-intl'
import getLocalKeyWords from '../../../utils/local_keywords'
import Divider from '@mui/material/Divider'
import { getStorage } from '../../../utils/storage'

const HeaderUserOrderPay = ({ myOrder, table, mobileView, setOrderItemToEdit }) => {
  const intl = useIntl()
  const styles = userOrderStyles()
  const [open, setOpen] = useState(false)

  const direction = JSON.parse(getStorage('direction'))

  const deliveryIndications = getStorage('delivery_indications')

  return (<div>
    <Card className={styles.headerCard}>
      <CardContent>
        <Grid container>
          <Grid item xs={8}>
            <Typography gutterBottom variant="h6" className={styles.titleLeft}>
              {intl.formatMessage({ id: 'hi' })}, {myOrder.user_name}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Grid>
              <Typography gutterBottom variant="body1" className={styles.titleRight}>
                # {intl.formatMessage({ id: 'order' }, { order: getLocalKeyWords('order') })} {myOrder.consecutive}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {table?.delivery_activated === true && <Grid container>
          <Grid item xs={10}>
            <Typography gutterBottom variant="body1" className={styles.titleLeft}>
              {intl.formatMessage({ id: 'weWillSendYourOrderTo' }, { order: getLocalKeyWords('order') })}:
            </Typography>

          </Grid>
          <Grid item xs={10}>
            <Typography gutterBottom variant="body1" className={styles.titleLeft}>
              {direction !== null && direction !== undefined ? `${direction.name} ${direction.address}` : intl.formatMessage({ id: 'pickupMessage' }, { restaurant: getLocalKeyWords('restaurant') })}
            </Typography>

          </Grid>
        </Grid>}
        {table?.delivery_activated === false && deliveryIndications && <Grid container>
          <Grid item xs={10}>
            <Typography gutterBottom variant="body1" className={styles.titleLeft}>
              {intl.formatMessage({ id: 'deliveryIndications' }, { delivery_indications: getLocalKeyWords('delivery_indications') })}:
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography gutterBottom variant="body1" className={styles.titleLeft}>
              {deliveryIndications}
            </Typography>
          </Grid>
        </Grid>}
      </CardContent>
    </Card>
    <ListSubheader className={styles.headerSection}>
      <Grid container alignItems="center">
        <Typography variant="body1" color="black" fontWeight={'bold'}>
          {intl.formatMessage({ id: 'myOrder' }, { order: getLocalKeyWords('order') })}
        </Typography>
      </Grid>
    </ListSubheader>
    <ListItem onClick={() => setOpen(prev => !prev)}>
      <Grid container padding={1} alignItems={'center'}>
        <Grid item xs={7}>
          <Typography gutterBottom variant="body1">
            {intl.formatMessage({ id: 'yourOrder' }, { order: getLocalKeyWords('order') })}
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Grid>
            <Typography gutterBottom variant="h6" align={'right'} fontSize={'14px'}>
              {formatCost(myOrder.total_amount)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {open ? <ExpandLess/> : <ExpandMore/>}
    </ListItem>
    <Collapse
      in={open}
      timeout="auto"
      unmountOnExit>
      <List>
        {myOrder.order_items.map((item) => (
          <div key={`${item.id}`}>
            <Divider/>
            <ListItem>
              {item.product_image && (<ListItemAvatar style={{ paddingRight: 15 }}>
                <Avatar
                  alt={item.product_name}
                  sx={{ width: 110, height: 110, borderRadius: '.4rem' }}
                  variant="square"
                  src={item.product_image}
                />
              </ListItemAvatar>)}
              <Grid container sx={6}>
                <div>
                  <Typography gutterBottom variant="body1" fontWeight={'bold'}>
                    {item.product_name}
                  </Typography>
                  <List>
                    {item.topping_groups_array !== undefined && item.topping_groups_array !== null && item.topping_groups_array.map((group) => (
                      <div key={group?.group_name}>
                        <Typography variant="body2" color={'black'} fontWeight={'bold'} fontSize={11}>
                          {group?.group_name}
                        </Typography>
                        {group.toppings !== undefined && group.toppings !== null && group.toppings.map((topping) => (
                          <Typography key={topping._id} variant="body1" color={'black'}  fontSize={11}>
                            {topping?.quantity} • {topping?.name} {topping.price !== null && topping.price !== undefined ? topping.price.cents > 0 && `+ ${formatCost(topping?.price)}` : ''}
                          </Typography>
                        ))}
                      </div>
                    ))}
                  </List>
                  {item.client_observations !== '' && item.client_observations !== null &&
                    <div>
                      <Typography variant="body2" fontSize={10} color={'black'} fontWeight={'bold'}>
                        {getLocalKeyWords('comment_title')}
                      </Typography>
                      <Typography  variant="body2" fontSize={11} color={'black'}>
                        {item?.client_observations}
                      </Typography>
                    </div>
                  }
                  <Typography gutterBottom variant="h6">
                    {formatCost(item.amount)}
                  </Typography>
                </div>
              </Grid>
              <MyQuantity sx={{ width: mobileView ? '38px' : '28px', height: '21px', backgroundColor: 'primary.main' }}>{item.quantity}</MyQuantity>
              {setOrderItemToEdit !== undefined && <Grid container item xs={1} sm={1} justifyContent="flex-end">
                <IconButton
                  size={'small'}
                  onClick={() => {
                    setOrderItemToEdit(item)
                  }}
                >
                  <Edit fontSize={mobileView === false ? 'large' : 'medium'}/>
                </IconButton>
              </Grid>}
            </ListItem>
          </div>
        ))}
      </List>

    </Collapse>
  </div>)
}

export default HeaderUserOrderPay
