import { useMemo, useState } from 'react'

export const useResponsive = () => {
  const [mobileView, setMobileView] = useState({ mobileView: false })

  useMemo(() => {

    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setMobileView({ mobileView: true })
        : setMobileView({ mobileView: false })
    }

    setResponsiveness()
    window.addEventListener('resize', setResponsiveness)
    return () => {
      window.removeEventListener('resize',  setResponsiveness)
    }
  }, [])

  return mobileView
}
