import { useEffect, useState } from 'react'
import { collection, getDocs, query, where } from 'firebase/firestore'
import { db } from '../firebase'
import { tablesOrdersCollection, usersOrdersCollection } from './collections_names_firestore'
import { getStorage } from './storage'
import { isPayedDelivery, isTableOrderActive } from './order_state_extension'

export const useActiveOrder = () => {
  const [hasActiveOrder, setActiveOrder] = useState({ hasActiveOrder: false })
  const [isDelivery, setIsDelivery] = useState({ isDelivery: false })

  useEffect(() => {
    checkForActiveOrder()
  }, [])

  const checkForActiveOrder = () => {
    const ordersQuery = query(collection(db, usersOrdersCollection), where('user_id', '==', getStorage('user_id')))
    getDocs(ordersQuery).then((querySnapshot) => {
      if (querySnapshot.docs.length > 0) {
        querySnapshot.forEach((doc) => {
          if (doc.data() != null) {
            if (doc.data()['is_delivery'] === true) {
              setIsDelivery({ isDelivery: true })
              if (isPayedDelivery(doc.data()['state'])) {
                setActiveOrder({ hasActiveOrder: true })
              }
            } else if (doc.data()['is_delivery'] === false) {
              setIsDelivery({ isDelivery: false })
              if (isTableOrderActive(doc.data()['state'])) {
                const tableQuery = query(collection(db, tablesOrdersCollection), where('table_id', '==', doc.data()['table_id']))
                getDocs(tableQuery).then((tableQuerySnapshot) => {
                  if (tableQuerySnapshot.docs.length > 0) {
                    setActiveOrder({ hasActiveOrder: true })
                  }
                })
              }
            }
          }
        })
      }
    })
  }

  return {
    'hasActiveOrder': hasActiveOrder.hasActiveOrder,
    'isDelivery': isDelivery.isDelivery
  };
}
