import { makeStyles } from '@mui/styles'

export const PaymentSummaryStyle = makeStyles(theme => ({
  iconButton: {
    position: 'absolute',
    right: 10,
    top: 8,
    marginBottom: 24,
    color: theme.palette.grey[500],
  },
  dialog: {
    '& .MuiDialog-container': {
      '& .MuiPaper-root': {
        maxWidth: '600px',
        minWidth: '200px',
        minHeight: '450px'
      },
    },
    zIndex: 100
  },
  container1: {
    marginLeft: '8px',
    marginTop: '4px'
  },
  container2: {
    marginLeft: '8px',
    marginTop: '2px',
    marginBottom: '12px'
  },
  container3: {
    paddingTop: 5,
    paddingRight: 0,
    marginTop: '50px'
  }
}));