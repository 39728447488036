import { maxMobileWidth, maxWebWidth } from '../../utils/screen_utils'

export const bookTableStyles = (mobileView) => ({
  div: {
    justifyContent: 'center', display: mobileView ? 'inherit' : 'flex',
  },
  container: {
    padding: '12px', width: mobileView ? maxMobileWidth : maxWebWidth
  }
})
