import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import header from '../../assets/images/header.png'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import { resetDeliveryResponse } from '../../features/home/Home.slice'
import { useIntl } from 'react-intl'
import getLocalKeyWords from '../../utils/local_keywords'
import { container1, container2 } from '../../components/Background.style'
import { checkCookiePermission, getStorage, removeStorageByKey } from '../../utils/storage'
import FooterMenu from '../../features/menu/components/FooterMenu'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'
import { neutralizeBack } from '../../utils/navigation_util'

const ExitPage = () => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const [mobileView, setMobileView] = useState(false)
  const [tempTableUid, setTempTableUid] = useState(null)
  const navigate = useNavigate()

  const setResponsiveness = () => {
    return window.innerWidth < 900
      ? setMobileView(true)
      : setMobileView(false)
  }

  useEffect(() => {
    neutralizeBack()
    setResponsiveness()
    window.addEventListener('resize', () => setResponsiveness())
    return () => {
      window.removeEventListener('resize', () => setResponsiveness())
    }
  }, [])

  const cleanRestaurantSession = () => {
    removeStorageByKey('table_id')
    removeStorageByKey('tt')
    removeStorageByKey('table_index')
    removeStorageByKey('table_uid')
    removeStorageByKey('direction')
    removeStorageByKey('pickup')
    removeStorageByKey('currency')
    removeStorageByKey('restaurant_id')
    localStorage.removeItem('restaurant_data')
    removeStorageByKey('wallpaper')
    dispatch(resetDeliveryResponse())
    checkCookiePermission()
  }

  useEffect(() => {
    dispatch(cleanRestaurantSession)
    initData()
  }, [])

  const initData = () => {
    setTempTableUid(getStorage('temp_table_uid'))
  }

  const backToHome = () => {
    navigate(`/${tempTableUid}`)
  }

  return (
    <div style={container1(mobileView)}>
      <div style={container2(mobileView)}>
        <Grid
          container
          justifyContent={'center'}
          padding={5}
          direction={'column'}
          alignContent={'center'}
          alignItems={'center'}
          marginTop={'100px'}
        >
          <img src={header} alt={'logo'} width={200} height={100}/>
          <Typography variant="h6" fontWeight="bold" align={'center'} paddingTop={2}>
            {intl.formatMessage({ id: 'successfulMessage' }, {
              restaurant_name: getStorage('restaurant_name'),
            })}
          </Typography>
          <Typography variant="h6" fontWeight="bold" align={'center'} paddingTop={2} marginBottom={8}>
            {intl.formatMessage({ id: 'scanAnotherQR' }, { order_plural: getLocalKeyWords('order_plural') })}
          </Typography>
          {tempTableUid !== null &&
            <Button variant="contained" onClick={() => {backToHome()}} style={{ backgroundColor: '#553524' }}>
              {intl.formatMessage({ id: 'orderAgain' })}
            </Button>
          }
        </Grid>
        <FooterMenu categories={0}/>
      </div>
    </div>
  )
}

export default ExitPage
