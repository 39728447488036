import { Typography } from '@mui/material'
import { useIntl } from 'react-intl'
import styles from './MenuCategory.module.css'
import Grid from '@mui/material/Grid'
import { getStorage } from '../../../utils/storage'

const FooterMenu = ({ categories }) => {
  const intl = useIntl()
  const textColor = getStorage('title_color') || 'black'

  return (
    <Grid container justifyContent={'center'} position={categories > 0 ? 'inherit' : 'fixed'}>
      <footer className={styles.footer}>
        <Grid item xs={12} container direction={'row'} padding={'8px'} justifyContent={'center'}>
          <Typography variant="body1" color={`#${textColor}`} className={styles.font}>
            {intl.formatMessage({ id: 'version' }, { version: process.env.REACT_APP_VERSION },)}
          </Typography>
        </Grid>
      </footer>
    </Grid>
  )
}
export default FooterMenu
