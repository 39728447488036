import * as React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { empty, stateColor, stateName } from '../../../utils/order_state_extension'
import {
  tableOrderStyles, MyQuantity, OrderQuantity,
} from '../../../pages/table/TableOrder/TableOrderPage.style'
import { ListItemText, ListSubheader, Button } from '@mui/material'
import { formatCost } from '../../../utils'
import EmptyImage from '../../../assets/images/image_empty.svg'
import Image from '../../Image'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import getLocalKeyWords from '../../../utils/local_keywords'
import { getStorage } from '../../../utils/storage'

const OtherTableOrder = ({ otherUsersOrders }) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const styles = tableOrderStyles()
  let keys = Object.keys(otherUsersOrders)

  const navigateToTableDetail = () => {
    navigate(`/tableDetail/${getStorage('table_uid')}`)
  }

  return (<div>
    <ListSubheader className={styles.headerSection}>
      <Grid container alignItems="center">
        <Grid item xs={7}>
          <ListItemText
            color="black"
            primary={<Typography variant="body1" color="black">
              {intl.formatMessage({ id: 'orderFromYourTable' }, {
                order_plural: getLocalKeyWords('order_plural'),
                table: getLocalKeyWords('table')
              })}
            </Typography>}
          />
        </Grid>
        <Grid item xs={5} container justifyContent={'flex-end'}>
          {otherUsersOrders && keys && keys.length > 0 &&
            <Button variant="text" onClick={navigateToTableDetail}>
            {intl.formatMessage({ id: 'seeUserOrder' }, { order: getLocalKeyWords('order') })}
          </Button>}
        </Grid>
      </Grid>
    </ListSubheader>
    {keys.map((key) => (otherUsersOrders !== null && otherUsersOrders !== undefined && otherUsersOrders[key]?.state !== null && otherUsersOrders[key]?.state !== empty ?
      <Grid container key={key}>
        <Grid container alignItems="center" padding={1} direction={'row'} justifyContent={'space-between'}>
          <Grid item xs={1}>
            <Grid container className={styles.circleMe}>
              <MyQuantity sx={{
                width: 30, height: 30, backgroundColor: 'primary.main'
              }}>{otherUsersOrders[key]?.user_name.charAt(0)}</MyQuantity>
            </Grid>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="body1">
              {otherUsersOrders[key]?.user_name}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            {otherUsersOrders[key] !== null && otherUsersOrders[key].state !== null && otherUsersOrders[key].state !== undefined &&
              <Grid container bgcolor={stateColor(otherUsersOrders[key].state)} borderRadius={5}
                    justifyContent={'center'}>
                <Typography variant="body1" color={'white'} fontWeight={'bold'} textAlign={'center'}>
                  {stateName(otherUsersOrders[key]?.state)}
                </Typography>
              </Grid>}
          </Grid>
        </Grid>
        <Grid container alignItems="center" paddingRight={2} direction={'row'} justifyContent={'space-between'}>
          <Grid item xs={1}>
            <Grid container>
              <ListItemText
                color="black"
                primary={
                  <OrderQuantity sx={{ width: 30, height: 30, backgroundColor: 'primary.main' }}>
                    {otherUsersOrders[key]?.order_items.length}
                  </OrderQuantity>
                }
              />
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container>
              {otherUsersOrders[key]?.order_items.slice(0, 3).map((product) => (
                <Image key={product._id} className={styles.image2} src={product.product_image} placeholderImg={EmptyImage}/>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={4} sx={{ paddingTop: '8px' }} container justifyContent={'flex-end'} alignItems={'center'}>
            <Typography variant="h6">
              {formatCost(otherUsersOrders[key]?.total_amount)}
            </Typography>
          </Grid>
        </Grid>
      </Grid> : <div/>))}
  </div>)
}

export default OtherTableOrder
