import { saveStorage } from './storage'

export const saveRestaurantData = (restaurantData) => {
  localStorage.setItem('restaurant_data', JSON.stringify(restaurantData))
  saveStorage('subscription', restaurantData.restaurant?.active_plan?.subscription_plan?.type)
  saveStorage('read_only', restaurantData.restaurant.read_only)
  saveStorage('tt', restaurantData.user_table_token)
  saveStorage('table_index', restaurantData.table.index)
  saveStorage('table_id', restaurantData.table._id)
  saveStorage('restaurant_name', restaurantData.restaurant.name)
  saveStorage('restaurant_logo', restaurantData.restaurant.logo)
  saveStorage('currency', restaurantData.restaurant.default_money_currency)
  saveStorage('primary_color', restaurantData.restaurant.primary_color.substring(4))
  saveStorage('secondary_color', restaurantData.restaurant.secondary_color.substring(4))
  saveStorage('tertiary_color', restaurantData.restaurant.tertiary_color.substring(4))
  saveStorage('restaurant_id', restaurantData.restaurant._id)
  saveStorage('wallpaper', restaurantData.restaurant.wallpaper)
}
