import { div } from '../../../components/Background.style'
import ScrollSpyTabs from './ScrollSpyTabs'
import CategoryTab from './CategoryTab'
import React from 'react'
import Header from './Header'
import SubHeaderMenu from './SubHeaderMenu'

const StandardMenu = ({
  mobileView,
  userOrder,
  setOpen,
  filteredCategories,
  restaurantData,
  count,
  navigateToShoppingCart,
  scrollToSearchBar,
  setOpenScheduleDialog,
  onBackPressed,
  filterText,
  setFilterText,
  readOnly,
  setOpenBusinessDialog
}) => {
  return (
    <div>
      <Header
        restaurant={restaurantData?.restaurant}
        isDelivery={restaurantData?.delivery}
        mobileView={mobileView}
        count={count}
        navigateToShoppingCart={readOnly() ? undefined : navigateToShoppingCart}
        scrollToSearchBar={scrollToSearchBar}
        setOpenScheduleDialog={setOpenScheduleDialog}
        backAction={onBackPressed}
      />
      <SubHeaderMenu
        navigateToShoppingCart={readOnly() ? undefined : navigateToShoppingCart}
        mobileView={mobileView}
        count={count}
        filterText={filterText}
        setFilterText={setFilterText}
        restaurant={restaurantData?.restaurant}
      />
      <div style={div()}>
        <ScrollSpyTabs
          tabsInScroll={filteredCategories.map((category, index) => ({
            text: category.name,
            component:
              <CategoryTab
                category={category}
                mobileView={mobileView}
                userOrder={userOrder}
                setOpen={setOpen}
                readOnly={readOnly}
                setOpenBusinessDialog={setOpenBusinessDialog}
              />,
            hash: category._id,
            index: index
          }))}
        />
      </div>
    </div>
  )
}

export default StandardMenu
