import { combineReducers } from '@reduxjs/toolkit'
import userReducer, { USER_NAME } from '../features/userdata/user.slice'
import productReducer, { PRODUCT_NAME } from '../features/product/product.slice'
import counterReducer, { COUNTER_PRODUCT_NAME } from '../components/product/DetailProduct/BottomProduct.slice'
import restaurantReducer, { RESTAURANT_NAME } from '../features/menu/restaurant.slice'
import tableOrderReducer, { TABLE_ORDER_NAME } from '../features/table/table.slice'
import shoppingCartReducer, { SHOPPING_CART_NAME } from '../features/shoppingCart/ShoppingCart.slice'
import deliveryOrPickupReducer, { DELIVERY_OR_PICKUP_NAME } from '../features/delivery/DeliveryOrPickup.slice'
import directionsReducer, { DIRECTIONS_NAME } from '../features/directions/Directions.slice'
import suggestionsReducer, { SUGGESTIONS_NAME } from '../features/suggestions/Suggestions.slice'
import directionReducer, { DIRECTION_NAME } from '../features/direction/Direction.slice'
import payDeliveryBillReducer, { PAY_DELIVERY_BILL_NAME } from '../features/payDeliveryBill/PayDeliveryBill.slice'
import deliveryOrderReducer, { DELIVERY_ORDER_NAME } from '../features/deliveryOrder/deliveryOrder.slice'
import callWaiterReducer, { CALL_WAITER_NAME } from '../features/callWaiter/CallWaiter.slice'
import themeReducer, { THEME_NAME } from '../features/theme/Theme.slice'
import homeReducer, { HOME_NAME } from '../features/home/Home.slice'
import ordersHistoryReducer, { ORDERS_HISTORY_NAME } from '../features/ordersHistory/OrdersHistory.slice'

export const rootReducer = combineReducers({
  [PRODUCT_NAME]: productReducer,
  [USER_NAME]: userReducer,
  [COUNTER_PRODUCT_NAME]: counterReducer,
  [RESTAURANT_NAME]: restaurantReducer,
  [SHOPPING_CART_NAME]: shoppingCartReducer,
  [TABLE_ORDER_NAME]: tableOrderReducer,
  [DELIVERY_OR_PICKUP_NAME]: deliveryOrPickupReducer,
  [DIRECTIONS_NAME]: directionsReducer,
  [DIRECTION_NAME]: directionReducer,
  [SUGGESTIONS_NAME]: suggestionsReducer,
  [PAY_DELIVERY_BILL_NAME]: payDeliveryBillReducer,
  [DELIVERY_ORDER_NAME]: deliveryOrderReducer,
  [CALL_WAITER_NAME]: callWaiterReducer,
  [THEME_NAME]: themeReducer,
  [HOME_NAME]: homeReducer,
  [ORDERS_HISTORY_NAME]: ordersHistoryReducer,
})
