import TextField from '@mui/material/TextField'
import { useIntl } from 'react-intl'
import { Grid } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'
import CloseIcon from '@mui/icons-material/Close'
import React, { useState } from 'react'
import SearchIcon from '@mui/icons-material/Search'
import getLocalKeyWords from '../../../utils/local_keywords'
import styles from './ProductSearch.module.css'

const ProductSearch = ({ text, setText }) => {
  const intl = useIntl()
  const [isInputFocus, setInputFocus] = useState(false)

  return (
    <Grid container className={styles.grid1}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          size={'small'}
          id="outlined-basic"
          variant="outlined"
          color="secondary"
          value={text}
          placeholder={intl.formatMessage({ id: 'searchCategoryOrProduct' }, { product: getLocalKeyWords('product') })}
          onChange={(event) => {
            setText(event.target.value)
            event.target.value.length === 0 ? setInputFocus(false) : setInputFocus(true)
          }}
          onFocus={() => setInputFocus(true)}
          InputProps={{
            className: styles.input,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon/>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {isInputFocus ? (
                  <CloseIcon onClick={() => {
                    setInputFocus(false)
                    setText('')
                  }}/>
                ) : <div/>}
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  )
}

export default ProductSearch
