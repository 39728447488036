import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export const DELIVERY_OR_PICKUP_NAME = 'deliveryOrPickup'

const initialState = {
  pickup: false,
  status: 'idle',
  loading: false
}

export const setPickup = (pickup = false) => {
  return new Promise((resolve) =>
    resolve({ data: pickup })
  )
}

export const pickup = createAsyncThunk(
  'pickup',
  async (pickup) => {
    const response = await setPickup(pickup)
    // The value we return becomes the `fulfilled` action payload
    return response.data
  }
)

export const deliveryOrPickupSlice = createSlice({
  name: DELIVERY_OR_PICKUP_NAME,
  initialState,

  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(pickup.pending, (state) => {
      state.loading = true
    })
    builder.addCase(pickup.fulfilled, (state, action) => {
      state.value = action.payload
      state.loading = false
    })
    builder.addCase(pickup.rejected, (state) => {
      state.status = 'error'
      state.loading = false
    })
  }
})

export default deliveryOrPickupSlice.reducer