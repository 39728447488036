import { useIntl } from 'react-intl'

export const convertTime24to12 = (time24h) => {
  if (time24h === 12) {
    return `12 PM`
  } else if (time24h === 0 || time24h === 24) {
    return `12 AM`
  } else if (time24h > 12) {
    return `${time24h - 12} PM`
  } else {
    return `${time24h} AM`
  }
}

export const convertTime = (time) => {
  if (Object.keys(time).length > 0) {
    const min = time?.min === 0 ? '00' : time?.min
    if (time?.hour === 12) {
      return `12:${min} PM`
    } else if (time?.hour === 0 || time?.hour === 24) {
      return `12:${min} AM`
    } else if (time?.hour > 12) {
      return `${time?.hour - 12}:${min} PM`
    } else {
      return `${time?.hour}:${min} AM`
    }
  } else {
    return ''
  }
}

export const getDayName = (numberOfDay) => {
  const intl = useIntl()
  switch (numberOfDay) {
    case 0:
      return intl.formatMessage({ id: 'sunday' })
    case 1:
      return intl.formatMessage({ id: 'monday' })
    case 2:
      return intl.formatMessage({ id: 'tuesday' })
    case 3:
      return intl.formatMessage({ id: 'wednesday' })
    case 4:
      return intl.formatMessage({ id: 'thursday' })
    case 5:
      return intl.formatMessage({ id: 'friday' })
    case 6:
      return intl.formatMessage({ id: 'saturday' })
  }
}

export const formatDate = (date) => {
  return new Date(date).toLocaleString('es-CO')
}
