const Money = class Money {
  CURRENCIES = {
    COP: { iso: 'COP', subUnitToUnit: 100, symbol: '$' },
    PEN: { iso: 'PEN', subUnitToUnit: 100, symbol: 'S/' },
    MXN: { iso: 'MXN', subUnitToUnit: 100, symbol: '$' },
    ARS: { iso: 'ARS', subUnitToUnit: 100, symbol: '$' },
    CLP: { iso: 'CLP', subUnitToUnit: 1, symbol: '$' },
    BRL: { iso: 'BRL', subUnitToUnit: 100, symbol: 'R$' },
    GTQ: { iso: 'GTQ', subUnitToUnit: 100, symbol: 'Q' },
    PYG: { iso: 'PYG', subUnitToUnit: 1, symbol: '₲' },
    USD: { iso: 'USD', subUnitToUnit: 100, symbol: '$' },
    GBP: { iso: 'GBP', subUnitToUnit: 100, symbol: '£' },
    AUD: { iso: 'AUD', subUnitToUnit: 100, symbol: 'A$' },
    CAD: { iso: 'CAD', subUnitToUnit: 100, symbol: '$' },
    EUR: { iso: 'EUR', subUnitToUnit: 100, symbol: '€' },
    XBD: { iso: 'XBD', subUnitToUnit: 1, symbol: '' },
    XCD: { iso: 'XCD', subUnitToUnit: 100, symbol: '$' },
    XDR: { iso: 'XDR', subUnitToUnit: 1, symbol: 'SDR' },
    XOF: { iso: 'XOF', subUnitToUnit: 1, symbol: 'Fr' },
    XPD: { iso: 'XPD', subUnitToUnit: 1, symbol: 'oz t' },
    XPF: { iso: 'XPF', subUnitToUnit: 1, symbol: 'Fr' },
    XPT: { iso: 'XPT', subUnitToUnit: 1, symbol: 'oz t' },
    XTS: { iso: 'XTS', subUnitToUnit: 1, symbol: '' },
    YER: { iso: 'YER', subUnitToUnit: 100, symbol: '﷼' },
    ZAR: { iso: 'ZAR', subUnitToUnit: 100, symbol: 'R' },
    ZMK: { iso: 'ZMK', subUnitToUnit: 100, symbol: 'ZK' },
    ZMW: { iso: 'ZMW', subUnitToUnit: 100, symbol: 'ZK' },
    BTC: { iso: 'BTC', subUnitToUnit: 100000000, symbol: 'BTC' },
    BCH: { iso: 'BCH', subUnitToUnit: 100000000, symbol: 'BCH' },
    JEP: { iso: 'JEP', subUnitToUnit: 100, symbol: '£' },
    GGP: { iso: 'GGP', subUnitToUnit: 100, symbol: '' },
    XFU: { iso: 'XFU', subUnitToUnit: 100, symbol: '' },
    GBX: { iso: 'GBX', subUnitToUnit: 1, symbol: '' },
    CNH: { iso: 'CNH', subUnitToUnit: 100, symbol: '¥' },
    EEK: { iso: 'EEK', subUnitToUnit: 100, symbol: 'kr' },
    GHS: { iso: 'GHS', subUnitToUnit: 100, symbol: '¢' },
    LTL: { iso: 'LTL', subUnitToUnit: 100, symbol: 'Lt' },
    LVL: { iso: 'LVL', subUnitToUnit: 100, symbol: 'Ls' },
    MRO: { iso: 'MRO', subUnitToUnit: 5, symbol: 'UM' },
    MTL: { iso: 'MTL', subUnitToUnit: 100, symbol: 'Lm' },
    TMM: { iso: 'TMM', subUnitToUnit: 100, symbol: 'T' },
    JPY: { iso: 'JPY', subUnitToUnit: 100, symbol: '¥' },
    ZWD: { iso: 'ZWD', subUnitToUnit: 100, symbol: 'Z$' },
    ZWL: { iso: 'ZWL', subUnitToUnit: 100, symbol: 'Z$' },
    ZWN: { iso: 'ZWN', subUnitToUnit: 100, symbol: 'Z$' },
    ZWR: { iso: 'ZWR', subUnitToUnit: 100, symbol: 'Z$' },
    AED: { iso: 'AED', subUnitToUnit: 100, symbol: 'د.إ' },
    VEF: { iso: 'VEF', subUnitToUnit: 100, symbol: 'Bs' },
    AFN: { iso: 'AFN', subUnitToUnit: 100, symbol: '؋' },
    ALL: { iso: 'ALL', subUnitToUnit: 100, symbol: 'L' },
    AMD: { iso: 'AMD', subUnitToUnit: 100, symbol: 'դր' },
    ANG: { iso: 'ANG', subUnitToUnit: 100, symbol: 'ƒ' },
    AOA: { iso: 'AOA', subUnitToUnit: 100, symbol: 'Kz' },
    UYU: { iso: 'UYU', subUnitToUnit: 100, symbol: '$' },
    BOB: { iso: 'BOB', subUnitToUnit: 100, symbol: 'Bs' },
    NIO: { iso: 'NIO', subUnitToUnit: 100, symbol: 'C$' },
  }

  constructor (hash) {
    this.subunits = hash.subunits
    this.iso = hash.iso.toUpperCase()
    this.defaultSubunits = 100
  }

  toHash () {
    return { subunits: this.subunits, iso: this.iso }
  }

  currency () {
    return this.CURRENCIES[this.iso]
  }

  format () {
    const currency = this.currency()
    const amount = this.subunits / this.defaultSubunits
    const intl_options = { style: 'currency', currency: currency.iso, minimumFractionDigits: 2 }
    const numberFormat = new Intl.NumberFormat('en-US', intl_options)
    if (amount > 0) {
      return numberFormat.format(amount)
    }
  }

  flatFormat () {
    return this.subunits / this.defaultSubunits
  }

  formatPrice () {
    return this.subunits * this.defaultSubunits
  }
}

export default Money
