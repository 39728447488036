import * as React from 'react'
import {
  Checkbox, ListItem, ListItemButton, ListItemText, ListSubheader, Typography
} from '@mui/material'
import Grid from '@mui/material/Grid'
import { formatCost } from '../../../utils'
import styles from '../UserOrderDeliveryPayBill/PaymentMethodUserOrder.module.css'
import { grey } from '@mui/material/colors'
import { useIntl } from 'react-intl'
import CurrencyInput from 'react-currency-input-field'
import { useRef } from 'react'
import { getStorage } from '../../../utils/storage'

const TipsUserOrder = ({ myOrder, table, tip, setTip, setTipAmount }) => {
  const intl = useIntl()

  const inputRef = useRef(null);

  return (<div>
    <ListSubheader className={styles.headerSection}>
      <Grid container alignItems="center">
        <Typography variant="body1" color="black" fontWeight={'bold'}>
          {intl.formatMessage({ id: 'tip' })}
        </Typography>
      </Grid>
    </ListSubheader>
    <div>
      {table.tip_options.map((tipItem) => (<div key={tipItem.percentage} className={styles.item}>
          <ListItem
            className={styles.item}
            key={`${tipItem.percentage}`}
            secondaryAction={<Checkbox
              edge="end"
              sx={{
                color: grey, '&.Mui-checked': {
                  color: '#57a496'
                }
              }}
              onChange={() => setTip(tipItem.percentage)}
              checked={tip === tipItem.percentage}
            />}>
            <ListItemButton className={styles.itemText}>
              <ListItemText
                id={tipItem.percentage}
                primary={`${tipItem.percentage}% (${tipItem.value !== null ? formatCost({
                  'subunits': (tipItem.percentage * (myOrder.total_amount?.subunits || 0)) / 100,
                  'currency_iso':getStorage('currency')
                }) : '-'})`}
              />
            </ListItemButton>
          </ListItem>
        </div>))}
      <ListItem
        divider>
      </ListItem>
      <ListItem
        className={styles.item}
        key={'-1'}
        secondaryAction={<Checkbox
          edge="end"
          sx={{
            color: grey, '&.Mui-checked': {
              color: '#57a496'
            }
          }}
          onChange={() => {
            setTip(-1)
            inputRef.current.focus();
          }}
          checked={tip === -1}
        />}>
        <ListItemButton className={styles.itemText}>
          <CurrencyInput
            id="input-tip"
            name="input-tip"
            placeholder={intl.formatMessage({ id: 'another' })}
            width={'90px'}
            prefix={getStorage('currency')}
            decimalsLimit={2}
            ref={inputRef}
            onFocus={() => {
              setTip(-1)
            }}
            onValueChange={(value) => setTipAmount(value * 100)}
            style={{ border: 'none', padding: '8px', width: '200px' }}
          />
        </ListItemButton>
      </ListItem>
    </div>
  </div>)
}

export default TipsUserOrder