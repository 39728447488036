import { createIntl } from 'react-intl'
import English from '../../lang/en.json'
import Spanish from '../../lang/es.json'
import { getCategories, removeUserOrder, restaurantData } from '../../features/menu/restaurant.slice'
import { InfoDialog } from './InfoDialog'
import { submitProductAsync } from '../../features/product/product.slice'
import {
  confirmUserOrder,
  decrementQuantity,
  deleteAllItems,
  incrementQuantity,
  removeOrderItem
} from '../../features/shoppingCart/ShoppingCart.slice'
import { callWaiter, stopCallWaiter } from '../../features/callWaiter/CallWaiter.slice'
import {
  deleteDirection,
  registerDirection,
  reverseGeocode, selectDirection,
} from '../../features/direction/Direction.slice'
import { getDirections } from '../../features/directions/Directions.slice'
import { login, updatedDeviceData, updatedPhoneNumber, updateUserName } from '../../features/userdata/user.slice'
import { pay } from '../../features/payDeliveryBill/PayDeliveryBill.slice'
import { getSuggestions } from '../../features/suggestions/Suggestions.slice'
import { askPayBill, cancelUserOrder, confirmTableOrder } from '../../features/table/table.slice'
import getLocalKeyWords from '../../utils/local_keywords'
import { closeOrder, updateDeliveryInfo, uploadReceipt } from '../../features/deliveryOrder/deliveryOrder.slice'

const LanguageDialog = ({ text, from }) => {

  let local = navigator.language

  let lang
  local = local.split('-')[0]
  if (local === 'en' || local === 'en-US') {
    lang = English
  } else if (local === 'es') {
    lang = Spanish
  } else {
    lang = English
  }

  const intl = createIntl({ locale: local, messages: lang })

  let message
  switch (from) {
    case restaurantData:
      message = intl.formatMessage({ id: 'tryOtherQR' }, { message: text })
      break
    case removeUserOrder:
      message = intl.formatMessage({ id: 'errorLogout' }, { order: getLocalKeyWords('order') })
      break
    case getCategories:
      message = intl.formatMessage({ id: 'errorGetCategories' }, { restaurant: getLocalKeyWords('restaurant') })
      break
    case submitProductAsync:
      message = intl.formatMessage({ id: 'productAdded' }, { product: getLocalKeyWords('product') })
      break
    case confirmUserOrder:
      message = intl.formatMessage({ id: 'errorConfirmUserOrder' }, { order: getLocalKeyWords('order') })
      break
    case removeOrderItem:
      message = intl.formatMessage({ id: 'errorDeleteProduct' }, { product: getLocalKeyWords('product') })
      break
    case incrementQuantity:
      message = intl.formatMessage({ id: 'errorAddProduct' }, { product: getLocalKeyWords('product') })
      break
    case decrementQuantity:
      message = intl.formatMessage({ id: 'errorDecrementProduct' }, { product: getLocalKeyWords('product') })
      break
    case deleteAllItems:
      message = intl.formatMessage({ id: 'errorDeleteProduct' }, { product: getLocalKeyWords('product') })
      break
    case callWaiter:
      message = intl.formatMessage({ id: 'errorToCallWaiter' }, { waiter: getLocalKeyWords('waiter') })
      break
    case stopCallWaiter:
      message = intl.formatMessage({ id: 'errorToStopCallWaiter' }, { waiter: getLocalKeyWords('waiter') })
      break
    case reverseGeocode:
      message = intl.formatMessage({ id: 'errorGetDirection' })
      break
    case registerDirection:
      message = intl.formatMessage({ id: 'errorPostDirection' })
      break
    case deleteDirection:
      message = intl.formatMessage({ id: 'errorDeleteDirection' })
      break
    case getDirections:
      message = intl.formatMessage({ id: 'errorGetMyDirections' })
      break
    case login:
      message = intl.formatMessage({ id: 'errorLogin' })
      break
    case updateUserName:
      message = intl.formatMessage({ id: 'errorAddName' })
      break
    case updatedPhoneNumber:
      message = intl.formatMessage({ id: 'errorAddPhone' })
      break
    case pay:
      message = intl.formatMessage({ id: 'errorSendOrder' }, { order: getLocalKeyWords('order') })
      break
    case getSuggestions:
      message = intl.formatMessage({ id: 'errorGetDirections' })
      break
    case confirmTableOrder:
      message = intl.formatMessage({ id: 'errorConfirmTable' }, {
        order: getLocalKeyWords('order'),
        table: getLocalKeyWords('table')
      })
      break
    case askPayBill:
      message = intl.formatMessage({ id: 'errorPayingBill' })
      break
    case cancelUserOrder:
      message = intl.formatMessage({ id: 'errorCancelOrder' }, { order: getLocalKeyWords('order') })
      break
    case updatedDeviceData:
      message = intl.formatMessage({ id: 'errorUpdateData' })
      break
    case selectDirection:
      message = intl.formatMessage({ id: 'errorSelectDirection' })
      break
    case closeOrder:
      message = intl.formatMessage({ id: 'errorCloseOrder' })
      break
    case uploadReceipt:
      message = intl.formatMessage({ id: 'errorUploadReceipt' })
      break
    case updateDeliveryInfo:
      message = intl.formatMessage({ id: 'errorUpdateDeliveryInfo' })
      break
  }

  InfoDialog({ text: message })
}

export default LanguageDialog
