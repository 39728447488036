import { cookieNotice, domain, localhost } from '../utils/util'
import { CookieConsent } from 'react-cookie-consent'
import { useIntl } from 'react-intl'

const CookieNotice = () => {
  const intl = useIntl()

  return (
    <CookieConsent
      location="bottom"
      buttonText={intl.formatMessage({ id: 'cookieButtonAccept' })}
      cookieName={cookieNotice}
      enableDeclineButton
      style={{ background: '#2B373B' }}
      buttonStyle={{ color: '#4e503b', fontSize: '13px', borderRadius: '.4rem', padding: '8px' }}
      declineButtonStyle={{ fontSize: '13px',  borderRadius: '.4rem', padding: '8px' }}
      declineButtonText={intl.formatMessage({ id: 'cookieButtonReject' })}
      extraCookieOptions={{ domain: window.location.hostname === 'localhost' ? localhost : domain }}
    >
      {intl.formatMessage({ id: 'cookiesAdvice' })}.
      <span style={{ fontSize: "10px" }}> {intl.formatMessage({ id: 'allowCookies' })}</span>
    </CookieConsent>
  )
}

export default CookieNotice