import * as React from 'react'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { useState } from 'react'
import MenuIcon from '@mui/icons-material/Menu'
import { Fab, Typography } from '@mui/material'
import { useActiveOrder } from '../../utils/useActiveOrder'
import { getStorage } from '../../utils/storage'
import History from '../../assets/icons/ic_delivery_history.png'
import { useIntl } from 'react-intl'
import Grid from '@mui/material/Grid'
import getLocalKeyWords from '../../utils/local_keywords'
import OrdersHistoryDialog from '../../features/ordersHistory/components/OrdersHistoryDialog'
import Header from '../../assets/images/header.png'
import { neutralizeBack, revivalBack } from '../../utils/navigation_util'
import OrdersHistoryDetailDialog from '../../features/ordersHistory/components/OrdersHistoryDetailDialog'
import { useSubscription } from '../../utils/useSubscription'
import styles from './MenuDrawer.module.css'
import { useSelector } from 'react-redux'

export default function MenuDrawer ({ showAtBottom, mobileView }) {
  const restaurantData = useSelector((store) => store.restaurant.restaurantData)
  const [open, setOpen] = useState(false)
  const { hasActiveOrder } = useActiveOrder()
  const userId = getStorage('user_id')
  const userName = getStorage('user_name')
  const intl = useIntl()
  const [openOrderDialog, setOpenOrderDialog] = useState(false)
  const [openOrderDetailDialog, setOpenOrderDetailDialog] = useState(false)
  const [orderHistoryDetail, setOrderHistoryDetail] = useState(null)
  const isPremium = useSubscription()

  const readOnly = () => {
    return !!(restaurantData !== null && restaurantData.restaurant?.read_only)
  }

  const openOrderHistoryDialog = () => {
    setOpenOrderDialog(true)
    neutralizeBack(() => handleCloseOrderHistoryDialog())
  }

  const handleCloseOrderHistoryDialog = () => {
    setOpenOrderDialog(false)
    revivalBack()
  }

  const handleOpenOrderHistoryDetailDialog = () => {
    setOpenOrderDetailDialog(true)
    neutralizeBack(() => {
      handleCloseOrderHistoryDialog()
      handleCloseOrderHistoryDetailDialog()
    })
  }

  const handleCloseOrderHistoryDetailDialog = () => {
    setOpenOrderDetailDialog(false)
  }

  const canShowMenuDrawer = () => {
    return userId !== undefined && userId !== null && userId !== '' && userName !== undefined && userName !== null && userName !== '' && isPremium === true && readOnly() === false
  }

  const onDone = () => {
    handleCloseOrderHistoryDialog()
    handleCloseOrderHistoryDetailDialog()
  }

  const list = () => (
    <Box role="presentation" onClick={() => setOpen(false)} onKeyDown={() => setOpen(false)}>
      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={openOrderHistoryDialog}>
            <ListItemIcon>
              <img src={History} alt="history" className={styles.image}/>
            </ListItemIcon>
            <ListItemText primary={
              intl.formatMessage({ id: 'ordersHistory' }, { orders: getLocalKeyWords('order_plural') },)
            }/>
          </ListItemButton>
        </ListItem>
        <Divider/>
      </List>
    </Box>
  )

  return (
    <div>
      <React.Fragment>
        {canShowMenuDrawer() === true &&
          <Fab
            size="small"
            color="primary"
            aria-label="add"
            sx={{ position: 'fixed', bottom: hasActiveOrder && showAtBottom === false ? 50 : 8, left: 8, zIndex: 2 }}
            onClick={() => setOpen(true)}
          >
            <MenuIcon/>
          </Fab>}
        <Drawer anchor={'left'} open={open} onClose={() => setOpen(false)}>
          <img src={Header} alt="header" className={styles.logo}/>
          <Divider/>
          {list()}
          <List className={styles.list}>
            <Grid container item direction="column" justifyContent="flex-end" alignItems="center">
              <Typography variant="body1">
                {userName}
              </Typography>
              <Typography variant="body1">
                Cocoa - {intl.formatMessage({ id: 'version' }, { version: process.env.REACT_APP_VERSION },)}
              </Typography>
            </Grid>
          </List>
        </Drawer>
      </React.Fragment>
      {openOrderDialog === true &&
        <OrdersHistoryDialog
          setOpen={handleCloseOrderHistoryDialog}
          open={openOrderDialog}
          mobileView={true}
          setOpenOrderDetailDialog={handleOpenOrderHistoryDetailDialog}
          setOrderHistoryDetail={setOrderHistoryDetail}
        />}
      {openOrderDetailDialog === true &&
        <OrdersHistoryDetailDialog
          setOpen={handleCloseOrderHistoryDetailDialog}
          mobileView={mobileView}
          open={openOrderDetailDialog}
          orderHistoryDetail={orderHistoryDetail}
          onDone={onDone}
        />
      }
    </div>
  )
}
