import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import React, { useEffect } from 'react'
import { auth, logInWithAnonymously } from '../../../firebase'
import { browserName, browserVersion } from 'react-device-detect'
import packageJson from '../../../../package.json'
import { userActions } from '../../../features/userdata/user.slice'
import { restaurantActions } from '../../../features/menu/restaurant.slice'
import getKeywordsFromFirestore from '../../../utils/keyword'
import orderStatesFromFirestore from '../../../utils/order_states_from_firestore'
import Loading from '../../../components/Loading'
import { updateTheme } from '../../../features/theme/Theme.slice'
import { homeActions } from '../../../features/home/Home.slice'
import HomeButtons from '../../../components/home/HomeButtons'
import FooterMenu from '../../../features/menu/components/FooterMenu'
import { getStorage, saveStorage } from '../../../utils/storage'
import { backgroundForHome, container2 } from '../../../components/Background.style'
import styles from '../Home.module.css'
import HomeImage from '../../../components/home/HomeImage'
import CookieNotice from '../../../components/CookieNotice'
import SocialButtons from '../../../components/home/SocialButtons'
import HomeInformation from '../../../components/home/HomeInformation'
import { useResponsive } from '../../../utils/useResponsive'
import LocationBranchDialog from '../../../features/home/components/LocationBranchDialog'
import { paramIdFromHash } from '../../../utils/paramIdFromHash'
import Background from '../../../assets/images/background.jpg'
import { neutralizeBack, revivalBack } from '../../../utils/navigation_util'
import ActiveOrder from '../../../components/ActiveOrder'

const HomePage = () => {
  const homeInfo = useSelector((store) => store.home.homeInfo)
  const [background, setBackground] = useState('')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { mobileView } = useResponsive()
  const [loading, setLoading] = useState(true)
  const params = useParams()
  const { hash } = useLocation()
  const [open, setOpen] = useState(false)

  useEffect(() => {
    checkUserData()
  }, [])

  useEffect(() => {
    if (homeInfo !== null) {
      setLoading(false)
      if (Object.keys(homeInfo).length === 0) {
        navigate(`/products/${getStorage('table_uid')}`)
      }
      checkForBackgroundImage()
    }
  }, [homeInfo])

  const initConfigData = async () => {
    orderStatesFromFirestore()
    getKeywordsFromFirestore()
  }

  const checkForBackgroundImage = () => {
    if (homeInfo?.wallpaper_url === undefined || homeInfo?.wallpaper_url === null) {
      setBackground(Background)
    } else {
      setBackground(homeInfo?.wallpaper_url)
    }
  }

  const checkUserData = () => {
    if (getStorage('user_id') !== null && getStorage('st') !== null) {
      checkForHomeData()
    } else {
      checkForUserSession()
    }
  }

  const checkForUserSession = () => {
    setLoading(true)
    const userToken = getStorage('st')
    if (userToken === null) {
      logInWithAnonymously().then(() => auth.currentUser?.getIdTokenResult().then((value) => {
        const params = {
          firebase_info: {
            user_name: '',
            id_token: value.token,
            provider: value.signInProvider,
            device: {
              'model': browserName,
              'brand': 'Web',
              'os': 'Web',
              'os_version': browserVersion,
              'app_version': packageJson.version,
              'lang': navigator.language
            }
          }
        }
        dispatch(userActions.login(params)).unwrap().then(() => {
          checkForHomeData()
        })
      })).catch(() => {
        navigate('/open-browser')
      })
    } else {
      checkForHomeData()
    }
  }

  const checkForHomeData = (tableUid) => {
    setLoading(true)
    let value
    let tableId
    if (tableUid === undefined) {
      tableId = params.id ? params.id : paramIdFromHash(hash)
    } else {
      tableId = tableUid
    }
    if (tableId != null) {
      value = {
        'table_uid': tableId
      }
      saveStorage('table_uid', tableId)
    } else {
      value = {
        'host': window.location.hostname
      }
    }
    dispatch(homeActions.checkForHome(value)).unwrap().then((value) => {
      if (value !== null && Object.keys(value).length > 0) {
        const key = Object.keys(value)
        if (key[0] === 'restaurant_info') {
          initConfigData()
          openMenu()
        }
      }
      dispatch(updateTheme())
      setLoading(false)
    }).catch(() => {
      navigate('/exit')
      setLoading(false)
    })
  }

  const openMenu = () => {
    navigate(`/products/${getStorage('table_uid')}`)
  }

  const openBranches = () => {
    if (homeInfo !== null && homeInfo?.restaurant_locations.length > 1) {
      navigate(`/branches/${getStorage('table_uid')}`)
    } else {
      setLoading(true)
      dispatch(restaurantActions.restaurantData()).unwrap().then(() => {
        setLoading(true)
        initConfigData()
        openMenu()
      }).catch(() => {
        setLoading(true)
        navigate('/exit')
      })
    }
  }

  const openAddressDialog = () => {
    setOpen(true)
    neutralizeBack(() => handleCloseAddressDialog())
  }

  const handleCloseAddressDialog = () => {
    setOpen(false)
    revivalBack()
  }

  return (
    loading ? <Loading/> :
      <div style={backgroundForHome(mobileView, background)}>
        <div style={container2(mobileView)}>
          <div className={styles.container}>
            <ActiveOrder/>
            <HomeImage homeInfo={homeInfo}/>
            <HomeInformation homeInfo={homeInfo}/>
            <HomeButtons
              openBranches={openBranches}
              homeInfo={homeInfo}
              mobileView={mobileView}
              openAddressDialog={openAddressDialog}
            />
            <SocialButtons homeInfo={homeInfo} backgroundImage={background}/>
            {open === true &&
              <LocationBranchDialog
                branches={homeInfo?.restaurant_locations}
                mobileView={mobileView}
                setOpen={handleCloseAddressDialog}
                open={open}
              />}
          </div>
          <CookieNotice/>
          <FooterMenu categories={1}/>
        </div>
      </div>
  )
}

export default HomePage
