import React from 'react'
import { Box, Tab, Tabs, tabsClasses } from '@mui/material'
import { makeStyles, withStyles } from '@mui/styles'
import throttle from 'lodash/throttle'
import Grid from '@mui/material/Grid'

const tabHeight = 50
const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '2px',
    borderRadius: '8rem',
    zIndex: 1,
    top: 45,
    backgroundColor: "#1fad69",
  },
})(props => <Tabs
  {...props}
  variant="scrollable"
  scrollButtons={false}
  TabIndicatorProps={{
    children: <div/>, sx: {
      top: 22,
    },
  }}
  sx={{
    [`& .${tabsClasses.scrollButtons}`]: {
      '&.Mui-disabled': { opacity: 0.3 },
    },
  }}
/>)

const StyledTab = withStyles(({
  root: {
    height: tabHeight,
    paddingLeft: '7px',
    paddingRight: '7px',
    '&:focus': {
      opacity: 1,
    },
    fontSize: '11px',
    textTransform: 'capitalize',
    zIndex: 2,
    margin: 'auto',
    '&.MuiTab-root.Mui-selected': {
      color: '#1fad69'
    },
    maxWidth: '200px',
  },
}))(props => <Tab {...props}/>)

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  }, indicator: {
    padding: theme.spacing(1),
    zIndex: 1,
    transformOrigin: '0%',
    position: 'absolute',
  }, demo2: {
    backgroundColor: '#fff',
    position: 'sticky',
    boxShadow: '1px 5px 5px #D1D1D1',
    top: '180px',
    left: 0,
    right: 0,
    width: '100%',
    zIndex: 10,
  }
}))

/******* This is the scroll spy magic */

const noop = () => {}

const useThrottledOnScroll = (callback, delay) => {
  const throttledCallback = React.useMemo(() => (callback ? throttle(callback, delay) : noop), [callback, delay])

  React.useEffect(() => {
    if (throttledCallback === noop) return undefined

    window.addEventListener('scroll', throttledCallback)
    return () => {
      window.removeEventListener('scroll', throttledCallback)
      throttledCallback.cancel()
    }
  }, [throttledCallback])
}

const PremiumScrollSpyTabs = ({ mobileView, tabsInScroll }) => {
  const [activeState, setActiveState] = React.useState(null)

  let itemsServer = tabsInScroll.map(tab => {
    return {
      icon: tab.icon || '',
      text: tab.text,
      component: tab.component,
      hash: tab.hash,
      node: document.getElementById(tab.hash),
      index: tab.index
    }
  })

  const itemsClientRef = React.useRef([])
  React.useEffect(() => {
    itemsClientRef.current = itemsServer
  }, [itemsServer])

  const clickedRef = React.useRef(false)
  const unsetClickedRef = React.useRef(null)
  const findActiveIndex = React.useCallback(() => {
    // set default if activeState is null
    if (activeState === null) setActiveState(itemsServer[0].hash)

    // Don't set the active index based on scroll if a link was just clicked
    if (clickedRef.current) return

    let active
    for (let i = itemsClientRef.current.length - 1; i >= 0; i -= 1) {
      // No hash if we're near the top of the page
      if (document.documentElement.scrollTop < 0) {
        active = { hash: null }
        break
      }

      const item = itemsClientRef.current[i]

      if (item.node && item.node.offsetTop < document.documentElement.scrollTop + document.documentElement.clientHeight / 8 + tabHeight) {
        active = item
        break
      }
    }

    if (active && activeState !== active.hash) {
      setActiveState(active.hash)
    }
  }, [activeState, itemsServer])

  // Corresponds to 10 frames at 60 Hz
  useThrottledOnScroll(itemsServer.length > 0 ? findActiveIndex : null, 166)

  const handleClick = hash => () => {
    // Used to disable findActiveIndex if the page scrolls due to a click
    clickedRef.current = true
    unsetClickedRef.current = setTimeout(() => {
      clickedRef.current = false
    }, 1000)

    if (activeState !== hash) {
      setActiveState(hash)

      if (window) window.scrollTo({
        top: document.getElementById(hash).getBoundingClientRect().top + window.pageYOffset - 120, behavior: 'smooth'
      })
    }
  }

  React.useEffect(() => () => {
    clearTimeout(unsetClickedRef.current)
  }, [])

  const classes = useStyles()

  return (
    <div>
      {mobileView === true &&
        <div>
          <nav className={classes.demo2} style={{ top: mobileView === true ?  55 : '180px'}}>
            <StyledTabs value={activeState ? activeState : itemsServer[0].hash} orientation={mobileView === true ? 'horizontal' : 'vertical'}>
              {itemsServer.map(item2 => (<StyledTab
                key={item2.hash}
                label={item2.text}
                onClick={handleClick(item2.hash)}
                value={item2.hash}
              />))}
            </StyledTabs>
          </nav>
          <div className="container">
            {itemsServer.map(item1 => (<article id={item1.hash} key={item1.index}>
              {item1.component}
            </article>))}
          </div>
        </div>
      }

      {mobileView === false &&
        <Box sx={{ flexGrow: 1 }}>
          <Grid container>
            <Grid item xs={12} sm={3} md={3} lg={3}>
              <nav className={classes.demo2}>
                <StyledTabs value={activeState ? activeState : itemsServer[0].hash} orientation={mobileView === true ? 'horizontal' : 'vertical'}>
                  {itemsServer.map(item2 => (<StyledTab
                    key={item2.hash}
                    label={item2.text}
                    onClick={handleClick(item2.hash)}
                    value={item2.hash}
                  />))}
                </StyledTabs>
              </nav>
            </Grid>
            <Grid item xs={12} sm={9} md={9} lg={7.5} xl={6}>
              <div className="container">
                {itemsServer.map(item1 => (<article id={item1.hash} key={item1.index}>
                  {item1.component}
                </article>))}
              </div>
            </Grid>
          </Grid>
        </Box>
      }
    </div>
  )
}

export default PremiumScrollSpyTabs
