import { maxButtonWebWidth, maxButtonWidth } from '../../../utils/screen_utils'

export const BottomDeliveryOrderStyle = (mobileView) => ({
  button: {
    borderRadius: '.4rem',
    marginTop: '64px',
    width: mobileView ? maxButtonWidth : maxButtonWebWidth,
    position: 'fixed',
    bottom: '8px'
  }
})