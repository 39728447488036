import Swal from 'sweetalert2'

export const ConfirmDialog = ({text, onConfirm, onCancel, confirmButtonText, cancelButtonText}) => {
  Swal.fire({
    text: text,
    showCancelButton: true,
    confirmButtonText: confirmButtonText,
    cancelButtonText: cancelButtonText,
    position: 'center'
  }).then((result) => {
    if (result.isConfirmed) {
      onConfirm()
    } else if (result.isDismissed) {
      onCancel()
    }
  })
}