import { BootstrapDialogStyle } from '../../../components/dialogs/BootstrapDialog.style'
import { DialogTitle, IconButton } from '@mui/material'
import styles from '../../menu/components/ScheduleDialog.module.css'
import CloseIcon from '@mui/icons-material/Close'
import DialogContent from '@mui/material/DialogContent'
import Grid from '@mui/material/Grid'
import { LocationBranchDialogStyle } from './LocationBranchDialog.style'
import GetLocationByMap from '../../../components/maps/GetLocationByMap'
import { useEffect, useState } from 'react'
import { sleep } from '../../../utils/timerUtil'
import BranchItem from './BranchItem'
import DialogActions from '@mui/material/DialogActions'

const LocationBranchDialog = ({ open, setOpen, mobileView, branches }) => {
  const classes = LocationBranchDialogStyle()
  const [direction, setDirection] = useState(null)
  const [refreshMap, setRefreshMap] = useState(false)

  useEffect(() => {
    if (branches !== undefined && branches !== null && branches.length > 0) {
      setDirection({
        address: branches[0]?.address,
        lat: branches[0]?.lat,
        lon: branches[0]?.lon
      })
      setRefreshMap(true)
    }
  }, [])

  const handleClose = () => {
    setOpen(false)
  }

  const dialogDismiss = () => {
    setOpen(false)
  }

  const selectBranchAddress = (branch) => {
    setRefreshMap(false)
    setDirection({
      address: branch.address,
      lat: branch.lat,
      lon: branch.lon
    })
    sleep(200).then(() => {
      setRefreshMap(true)
    })
  }

  return (
    <div>
      <BootstrapDialogStyle
        onClose={dialogDismiss}
        aria-labelledby="customized-dialog-title"
        open={open}
        className={classes.dialog}
        fullScreen={mobileView}
      >
        <DialogTitle className={styles.title}>
          <IconButton aria-label="close" onClick={handleClose} className={classes.iconButton}>
            <CloseIcon/>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div style={{ justifyContent: 'center', display: 'flex' }}>
            <div style={{ width: '100%', height: '550px' }}>
              <Grid container>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  className={mobileView === true ? styles.verticalViewAddresses : styles.rowViewAddresses}
                >
                  {branches?.map((branch) => (
                    <BranchItem key={branch?.id} branch={branch} selectBranchAddress={selectBranchAddress}/>
                  ))}
                </Grid>
                {direction !== null && refreshMap === true &&
                  <Grid
                    item
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    className={mobileView === true ? styles.verticalViewMap : styles.rowViewMap}
                  >
                    <GetLocationByMap
                      direction={direction}
                      index={0}
                      style={{ zIndex: 0 }}
                      showCurrentLocation={false}
                      showDragablePin={false}
                    />
                  </Grid>
                }
              </Grid>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Grid sx={{ height: '25px' }}/>
        </DialogActions>
      </BootstrapDialogStyle>
    </div>
  )
}

export default LocationBranchDialog
