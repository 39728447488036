import { Alert, Box } from '@mui/material'
import styles from '../pages/home/Home.module.css'
import Grid from '@mui/material/Grid'
import React from 'react'
import Button from '@mui/material/Button'
import { getStorage } from '../utils/storage'
import { useNavigate } from 'react-router-dom'
import Collapse from '@mui/material/Collapse'
import { maxMobileWidth } from '../utils/screen_utils'
import { useActiveOrder } from '../utils/useActiveOrder'
import { useIntl } from 'react-intl'

const ActiveOrder = () => {
  const navigate = useNavigate()
  const values = useActiveOrder()
  const intl = useIntl()

  const openActiveOrder = () => {
    if (values.isDelivery) {
      navigate(`/deliveryOrder/${getStorage('table_uid')}`, { state: { fromOrder: true } })
    } else {
      navigate(`/tableOrder/${getStorage('table_uid')}`, { state: { fromOrder: true } })
    }
  }

  return (
    values.hasActiveOrder && <Grid container justifyContent={'flex-end'}>
      <Box sx={{ width: maxMobileWidth, position: 'fixed', bottom: -18, left: 0, zIndex: 1 }}>
        <Collapse in={true}>
          <Alert severity="info" sx={{ mb: 2 }}>
            {intl.formatMessage({ id: 'activeOrderMessage' })}
          </Alert>
        </Collapse>
      </Box>
      <Button
        variant={'contained'}
        size={'large'}
        color={'info'}
        onClick={() => openActiveOrder()}
        className={styles.active}
      >
        {intl.formatMessage({ id: 'goToActiveOrder' })}
      </Button>
    </Grid>

  )
}

export default ActiveOrder
