import { envConfig } from '../../envConfig'

const webUrlBase = envConfig.url.API_URL

export const urlGetDirections = `${webUrlBase}api/mobile/directions`
export const urlReverseGeocode = `${webUrlBase}api/mobile/addresses/reverse_geocode`
export const urlRegisterDirection = `${webUrlBase}api/mobile/directions`
export const urlDeleteDirection = (direction_id) => `${webUrlBase}api/mobile/directions/${direction_id}`;
export const urlValidateDirection = (direction_id) => `${webUrlBase}api/mobile/user_orders/validate_locations/${direction_id}`;
export const urlSelectDirection = (order_id, direction_id) => `${webUrlBase}api/mobile/user_orders/${order_id}/set_direction/${direction_id}`;
