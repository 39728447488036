import { makeStyles } from '@mui/styles'

export const ScheduleDialogStyle = makeStyles(theme => ({
  iconButton: {
    position: 'absolute',
    right: 10,
    top: 8,
    marginBottom: 24,
    color: theme.palette.grey[500],
  },
  dialog: {
    '& .MuiDialog-container': {
      '& .MuiPaper-root': {
        width: '100%',
        maxWidth: '600px',
      },
    },
    zIndex: 100
  },
}));