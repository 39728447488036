import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, onMessage, isSupported } from 'firebase/messaging'
import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  signInAnonymously
} from 'firebase/auth'
import {
  getFirestore
} from 'firebase/firestore'
import { InfoDialog } from './components/dialogs/InfoDialog'
import { saveStorage } from './utils/storage'

const firebaseConfig = {
  apiKey: 'AIzaSyANS4oHiUdz3HNuOD-Rl3iGP4UdWNMML84',
  authDomain: 'cocoa-1281e.firebaseapp.com',
  projectId: 'cocoa-1281e',
  storageBucket: 'cocoa-1281e.appspot.com',
  messagingSenderId: '388429595189',
  appId: '1:388429595189:web:d13c93a2def624477de77c',
  measurementId: 'G-E9GEG1F9YK'
}

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const db = getFirestore(app)

const messaging = (async () => {
  try {
    const isSupportedBrowser = await isSupported()
    if (isSupportedBrowser) {
      return getMessaging(app)
    }
    console.log('Firebase not supported this browser')
    return null
  } catch (err) {
    console.log(err)
    return null
  }
})()

const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password)
  } catch (err) {
    InfoDialog({ text: err.message })
  }
}

const logInWithAnonymously = async () => {
  try {
    await signInAnonymously(auth)
  } catch (err) {
    console.error(err)
    InfoDialog({ text: err.message })
  }
}

const logout = () => {
  signOut(auth)
}

const fetchToken = async () => {
  const currentMessaging = await messaging
  return await getToken(currentMessaging, { vapidKey: 'BIQ7ax9w0Y4F_qAarWS_vywD38yjdWe9dL2Ex_EdCfD2OUjDOcbQMGQeMBhy4ExJTSQIBEUuzQd8RDhfK6MeyME' }).then((currentToken) => {
    if (currentToken) {
      saveStorage('fcm_token', currentToken)
    } else {
      console.log('No registration token available. Request permission to generate one.')
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err)
  })
}

const onMessageListener = async ({ audioPlayer }) => {
  const currentMessaging = await messaging
  return await new Promise( (resolve) => {
    if (currentMessaging !== null) {
      onMessage(currentMessaging, (payload) => {
        InfoDialog({ text: payload.notification.body })
        if (audioPlayer !== undefined && audioPlayer !== null && audioPlayer.current !== undefined && audioPlayer.current !== null) {
          audioPlayer.current?.play()
        }
        resolve(payload)
      })
    } else {
      resolve()
    }
  })
}

export {
  auth,
  db,
  logInWithEmailAndPassword,
  logInWithAnonymously,
  logout,
  fetchToken,
  onMessageListener
}
