import Button from '@mui/material/Button'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'
import DialogActions from '@mui/material/DialogActions'
import styles from '../../features/callWaiter/components/CallWaiter.module.css'
import Grid from '@mui/material/Grid'
import { useIntl } from 'react-intl'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { useState } from 'react'
import { urlIfy } from '../../utils/util'
import { BootstrapDialogStyle } from './BootstrapDialog.style'

const ImagePaymentMethodDialog = ({ paymentData }) => {
  const [open, setOpen] = useState(false)
  const intl = useIntl()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const dialogDismiss = () => {
    setOpen(false)
  }

  return (<div>
    <Grid container direction={'row'} alignItems={'center'} onClick={handleClickOpen} paddingTop={'8px'}>
      <Typography
        fontWeight={'bold'}>
        {intl.formatMessage({ id: 'paymentDataDetail' })}
      </Typography>
      <ArrowForwardIosIcon color="black" fontSize="small"/>
    </Grid>
    <BootstrapDialogStyle
      onClose={dialogDismiss}
      aria-labelledby="customized-dialog-title"
      open={open}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            padding: '16px',
          },
        },
      }}
    >
      <DialogContent>
        <Grid
          container
          justifyContent={'center'}
          direction={'column'}
        >
          {paymentData.image !== null && paymentData.image !== undefined && paymentData.image !== '' &&
            <img
              src={paymentData.image}
              alt="payment"
              style={{ width: 300, height: 300, }}
            />}
          {paymentData.data !== null && paymentData.data !== '' && paymentData.data !== undefined ? <Typography
            fontWeight={'bold'}>
            {intl.formatMessage({ id: 'paymentInformation' })}
          </Typography> : <div/>}
          {paymentData.data !== null && paymentData.data !== '' && paymentData.data !== undefined ? <div>
            <div dangerouslySetInnerHTML={{ __html: urlIfy(paymentData.data) }}/>
          </div> : <div/>}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          className={styles.button}
          fullWidth={true}
          color="secondary"
          variant="contained"
          sx={{ borderRadius: '8rem' }}
          onClick={handleClose}
        >
          {intl.formatMessage({ id: 'buttonClose' })}
        </Button>
      </DialogActions>
    </BootstrapDialogStyle>
  </div>)
}

export default ImagePaymentMethodDialog