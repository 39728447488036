import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { userOrderStyles } from '../../../pages/deliveryOrder/UserOrderDeliveryPayBill/UserOrderDeliveryPayBillPage.style'
import {
  ListSubheader
} from '@mui/material'
import { formatCost } from '../../../utils'
import { useIntl } from 'react-intl'
import getLocalKeyWords from '../../../utils/local_keywords'
import { getStorage } from '../../../utils/storage'

const SummaryUserOrderPay = ({ myOrder, tip, tipAmount, table }) => {
  const intl = useIntl()
  const styles = userOrderStyles()

  const pickup = getStorage('pickup')
  let newTip = tip !== null && tip > -1 ? {
    'subunits': ((tip * (myOrder.total_amount?.subunits || 0)) / 100), 'currency_iso': getStorage('currency')
  } : { 'subunits': tipAmount || 0, 'currency_iso': getStorage('currency') }
  let total = {
    'subunits': ((tip !== null && tip > -1 ? ((tip * (myOrder.total_amount?.subunits || 0)) / 100) : tipAmount || 0) + (myOrder.delivery_amount !== null && myOrder.delivery_amount !== undefined && pickup === 'false' ? myOrder.delivery_amount.subunits : 0) + ((myOrder.taxes_amount.percentage * (myOrder.total_amount?.subunits || 0)) / 100) + (myOrder.total_amount?.subunits || 0)),
    'currency_iso': getStorage('currency')
  }

  const productsTotal = {
    'subunits': (myOrder.total_amount?.subunits || 0), 'currency_iso': getStorage('currency')
  }

  return (<div>
    <ListSubheader className={styles.headerSection}>
      <Grid container alignItems="center">
        <Typography variant="body1" color="black" fontWeight={'bold'}>
          {intl.formatMessage({ id: 'orderSummary' })}
        </Typography>
      </Grid>
    </ListSubheader>
    <Card className={styles.summaryCard}>
      <CardContent>
        <Grid container>
          <Grid item xs={7}>
            <Typography gutterBottom variant="body1" className={styles.titleLeft}>
              {intl.formatMessage({ id: 'products' }, { products: getLocalKeyWords('products') })}
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Grid>
              <Typography gutterBottom variant="h6" className={styles.titleRight}>
                {formatCost(productsTotal)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {pickup === 'false' ? <Grid container>
          <Grid item xs={7}>
            <Typography gutterBottom variant="body1" className={styles.titleLeft}>
              {intl.formatMessage({ id: 'deliveryAmount' })}
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Grid>
              <Typography gutterBottom variant="h6" className={styles.titleRight}>
                {myOrder.delivery_amount !== null && myOrder.delivery_amount !== undefined ? formatCost(myOrder.delivery_amount) : myOrder.fixed_delivery_amount !== null && myOrder.fixed_delivery_amount !== undefined && myOrder.fixed_delivery_amount === true ? formatCost({
                  'subunits': 0, 'currency_iso': getStorage('currency')
                }) : `** ${intl.formatMessage({ id: 'toDefine' })}`}
              </Typography>
            </Grid>
          </Grid>
        </Grid> : <div/>}
        {table?.delivery_activated === true && myOrder.fixed_delivery_amount !== null && myOrder.fixed_delivery_amount !== undefined && !myOrder.fixed_delivery_amount &&
          <Typography color={'white'} fontWeight={'bold'} paddingBottom={1} paddingLeft={1} fontSize={12}>
            {intl.formatMessage({ id: 'deliveryAmountMessage' }, { order: getLocalKeyWords('order') })}
          </Typography>}
        {((tip !== null && tip !== undefined && tip > 0) || (tipAmount !== null && tipAmount !== undefined && tipAmount > 0)) &&
          <Grid container>
            <Grid item xs={7}>
              <Typography gutterBottom variant="body1" className={styles.titleLeft}>
                {intl.formatMessage({ id: 'tip' })}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Grid>
                <Typography gutterBottom variant="h6" className={styles.titleRight}>
                  {formatCost(newTip)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>}
        <Grid container>
          <Grid item xs={7}>
            <Typography gutterBottom variant="body1" className={styles.titleLeft}>
              {intl.formatMessage({ id: 'taxes' })}
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Grid>
              <Typography gutterBottom variant="h6" className={styles.titleRight}>
                {formatCost({
                  'subunits': ((myOrder.taxes_amount.percentage * (myOrder.total_amount?.subunits || 0) / 100)),
                  'currency_iso': getStorage('currency')
                })}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={7}>
            <Typography gutterBottom variant="body1" className={styles.titleLeft}>
              {intl.formatMessage({ id: 'total' })}
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Grid>
              <Typography gutterBottom variant="h6" className={styles.titleRight}>
                {formatCost(total)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
    <div style={{ height: 90 }}></div>
  </div>)
}

export default SummaryUserOrderPay
