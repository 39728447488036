import React, { useState } from 'react'
import { IntlProvider } from 'react-intl'
import Spanish from './lang/es.json'
import English from './lang/en.json'

export const Context = React.createContext()

let local = navigator.language

let lang
if (local) {
  local = local.split('-')[0]
  if (local === 'en' || local === 'en-US') {
    lang = English
  } else if (local === 'es') {
    lang = Spanish
  } else {
    lang = English
  }
}

const Wrapper = (props) => {
  const [locale, setLocale] = useState(local)

  const [messages, setMessages] = useState(lang)

  const selectLanguage = (e) => {
    const newLocale = e.target.value
    setLocale(newLocale)
    if (newLocale === 'en' || newLocale === 'en-US') {
      setMessages(English)
    } else if (newLocale === 'es') {
      setMessages(Spanish)
    } else {
      setMessages(English)
    }
  }

  return (
    <Context.Provider value={{ locale, selectLanguage }}>
      <IntlProvider messages={messages} locale={locale}>
        {props.children}
      </IntlProvider>
    </Context.Provider>

  )
}

export default Wrapper