import { useState } from 'react'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import { SocialIcon } from 'react-social-icons'
import Divider from '@mui/material/Divider'
import { ListItemText } from '@mui/material'
import { useIntl } from 'react-intl'
import { LocationBranchDialogStyle } from './LocationBranchDialog.style'
import { handleWhatsappUrl } from '../../../utils/whatsappUtil'

const BranchItem = ({ branch, selectBranchAddress }) => {
  const classes = LocationBranchDialogStyle()
  const [background, setBackground] = useState('white')
  const intl = useIntl()

  const onMouseOver = () => {
    setBackground('#F7F7F7')
  }

  const onMouseOut = () => {
    setBackground('white')
  }

  const openMap = (branch) => {
    window.open('https://maps.google.com?q=' + branch?.lat + ',' + branch?.lon)
  }

  const hasWhatsapp = (branch) => {
    return branch?.phone_code !== undefined && branch?.phone_number !== undefined
  }

  return (
    <div
      style={{ backgroundColor: background, marginRight: '16px' }}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      onClick={() => selectBranchAddress(branch)}
    >
      <ListItemText
        primary={
          <Typography variant="body1" color="black" fontSize={'20px'} paddingLeft={'8px'} paddingTop={'8px'}>
            {branch.name}
          </Typography>
        }
      />
      <div className={classes.container}>
        <Typography>
          {branch?.address}
        </Typography>
        <Grid container direction={'row'} sx={{ marginTop: '12px', marginBottom: '12px', alignItems: 'center' }}>
          <Button color="info" variant="contained" className={classes.button} onClick={() => openMap(branch)}>
            {intl.formatMessage({ id: 'howToGetThere' })}
          </Button>
          {hasWhatsapp(branch) &&
            <SocialIcon
              network={'whatsapp'}
              target={'_blank'}
              style={{ height: 35, width: 35, marginLeft: '8px' }}
              url={handleWhatsappUrl(`${branch?.phone_code}${branch?.phone_number}`)}
            />}
        </Grid>
        <Divider variant="middle"/>
      </div>
    </div>
  )
}

export default BranchItem
