import { BootstrapDialogStyle } from '../../../components/dialogs/BootstrapDialog.style'
import { Avatar, DialogTitle, IconButton, List, ListItem, ListItemAvatar, Typography, } from '@mui/material'
import styles from './OrdersHistory.module.css'
import CloseIcon from '@mui/icons-material/Close'
import DialogContent from '@mui/material/DialogContent'
import Loading from '../../../components/Loading'
import Grid from '@mui/material/Grid'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import { colorShade } from '../../../utils/colorShade'
import { theme } from '../../../index'
import * as React from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { OrdersHistoryDetailDialogStyle } from './OrdersHistoryDialog.style'
import Divider from '@mui/material/Divider'
import { MyQuantity } from '../../../pages/deliveryOrder/UserOrderDeliveryPayBill/UserOrderDeliveryPayBillPage.style'
import { formatDate } from '../../../utils/date_utils'
import { formatCost } from '../../../utils'
import getLocalKeyWords from '../../../utils/local_keywords'
import { stateColorDelivery, stateNameDelivery } from '../../../utils/order_state_extension'
import { ordersHistoryActions } from '../OrdersHistory.slice'
import { useShoppingCartItem } from '../../../utils/useShoppingCartItem'
import { ConfirmDialog } from '../../../components/dialogs/ConfirmDialog'
import { useActiveOrder } from '../../../utils/useActiveOrder'

const OrdersHistoryDetailDialog = ({ open, setOpen, mobileView, orderHistoryDetail, onDone }) => {
  const intl = useIntl()
  const loading = useSelector((store) => store.ordersHistory.loading)
  const classes = OrdersHistoryDetailDialogStyle()
  const dispatch = useDispatch()
  const { hasItemInCart } = useShoppingCartItem()
  const { hasActiveOrder } = useActiveOrder()

  const handleClose = () => {
    setOpen(false)
  }

  const dialogDismiss = (event, reason) => {
    if (reason && reason === 'backdropClick')
      return
    setOpen(false)
  }

  const getStateFromOrder = () => {
    if (orderHistoryDetail?.is_delivery === true) {
      return orderHistoryDetail?.delivery_state
    }
    if (orderHistoryDetail?.is_delivery === false) {
      return orderHistoryDetail?.state
    }
  }

  const cloneOrder = () => {
    if (hasItemInCart === true) {
      ConfirmDialog({
        text: intl.formatMessage({ id: 'cloneOrderHelper' }),
        confirmButtonText: intl.formatMessage({ id: 'buttonAccept' }),
        cancelButtonText: intl.formatMessage({ id: 'buttonCancel' }),
        onConfirm: () => {
          requestCloneOrder()
        },
        onCancel: () => {}
      })
    } else {
      requestCloneOrder()
    }
  }

  const requestCloneOrder = () => {
    const params = {
      user_order: {
        clone_order_id: orderHistoryDetail?._id
      }
    }
    dispatch(ordersHistoryActions.cloneOrder(params)).unwrap().then(() => {
      onDone()
    })
  }

  const canCloneOrder = () => {
    return !hasActiveOrder
  }

  return (
    <div>
      <BootstrapDialogStyle
        onClose={dialogDismiss}
        aria-labelledby="customized-dialog-title"
        open={open}
        className={classes.dialog}
        fullScreen={mobileView}
      >
        <DialogTitle className={styles.title}>
          <IconButton aria-label="close" onClick={handleClose} className={classes.iconButton}>
            <CloseIcon/>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div className={styles.container}>
            {loading && <Loading/>}
            <Divider/>
            <Grid container sx={{ padding: '10px' }} justifyContent={'center'} justifyItems={'center'}
                  alignItems={'center'}>
              <Grid item xs={8}>
                <Typography variant="body1" fontWeight={'bold'}>
                  {intl.formatMessage({ id: 'date' })}
                </Typography>
                <Typography variant="body1">
                  {formatDate(orderHistoryDetail.updated_at)}
                </Typography>
              </Grid>
              <Grid
                item
                container
                bgcolor={stateColorDelivery(getStateFromOrder())}
                borderRadius={4}
                justifyContent={'center'}
                padding={'5px'}
                xs={4}
              >
                <Typography
                  variant="body1"
                  color={'white'}
                  fontWeight={'bold'}
                  alignItems={'center'}
                  fontSize={'12px'}
                  align={'center'}
                >
                  {stateNameDelivery(getStateFromOrder())}
                </Typography>
              </Grid>
            </Grid>
            <Divider/>
            <Grid container direction={'column'} className={styles.grid1}>
              <Typography variant="body1" fontWeight={'bold'}>
                {intl.formatMessage({ id: 'clientName' })}
              </Typography>
              <Typography variant="body1">
                {orderHistoryDetail?.user?.first_name}
              </Typography>
            </Grid>
            <Divider/>
            <Grid container direction={'column'} className={styles.grid1}>
              <Typography variant="body1" fontWeight={'bold'}>
                {intl.formatMessage({ id: 'contactPhone' })}
              </Typography>
              <Typography variant="body1">
                {orderHistoryDetail?.user?.phone_code} {orderHistoryDetail?.user?.phone_number}
              </Typography>
            </Grid>
            <Divider/>
            <Grid container direction={'column'} className={styles.grid1}>
              <Typography variant="body1" fontWeight={'bold'}>
                {intl.formatMessage({ id: 'paymentMethodHistory' })}
              </Typography>
              <Typography variant="body1">
                {orderHistoryDetail?.payment_method}
              </Typography>
            </Grid>
            <Divider/>
            <List>
              {orderHistoryDetail?.order_items.map((item) => (
                <ListItem key={`${item._id}`}>
                  <Grid container direction={'row'}>
                    {item.product.import_image_url && (
                      <Grid item container xs={mobileView === true ? 4.5 : 3}>
                        <ListItemAvatar>
                          <Avatar
                            alt={item.product_name}
                            sx={{ width: 110, height: 110, borderRadius: '.4rem' }}
                            variant="square"
                            src={item.product.import_image_url}
                          />
                        </ListItemAvatar>
                      </Grid>
                    )}
                    <Grid item container xs={item.product.import_image_url  ? 5.5 : mobileView === true ? 10 : 8.5}>
                      <div>
                        <Typography gutterBottom variant="body1" fontWeight={'bold'}>
                          {item.product_name}
                        </Typography>
                        <List>
                          {item.topping_groups_array !== undefined && item.topping_groups_array !== null && item.topping_groups_array.map((group) => (
                            <div key={group?.group_name}>
                              <Typography variant="body2" color={'black'} fontWeight={'bold'} fontSize={11}>
                                {group?.group_name}
                              </Typography>
                              {group.toppings !== undefined && group.toppings !== null && group.toppings.map((topping) => (
                                <Typography key={topping._id} variant="body1" color={'black'} fontSize={11}>
                                  {topping?.quantity} • {topping?.name} {topping.price !== null && topping.price !== undefined ? topping.price.cents > 0 && `+ ${formatCost(topping?.price)}` : ''}
                                </Typography>
                              ))}
                            </div>
                          ))}
                        </List>
                        {item.client_observations !== '' && item.client_observations !== null &&
                          <div>
                            <Typography variant="body2" fontSize={10} color={'black'} fontWeight={'bold'}>
                              {getLocalKeyWords('comment_title')}
                            </Typography>
                            <Typography variant="body2" fontSize={11} color={'black'}>
                              {item?.client_observations}
                            </Typography>
                          </div>
                        }
                        <Typography gutterBottom variant="h6">
                          {formatCost(orderHistoryDetail?.total_amount)}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item container xs={2} justifyItems={'center'} justifyContent={'center'} alignItems={'center'}>
                      <MyQuantity sx={{
                        width: '25px',
                        height: '21px',
                        backgroundColor: 'primary.main'
                      }}>{item.quantity}</MyQuantity>
                    </Grid>
                  </Grid>
                </ListItem>
              ))}
            </List>
          </div>
        </DialogContent>
        <DialogActions>
          <Grid container direction={'column'}>
            <Grid item container direction={'column'} className={styles.grid}>
              <Typography variant="body1" fontWeight={'bold'}>
                Total
              </Typography>
              <Typography variant="body1" fontWeight={'bold'}>
                {formatCost(orderHistoryDetail?.total_amount)}
              </Typography>
            </Grid>
            {canCloneOrder() &&
              <Grid item xs={12} justifyContent={'center'} justifyItems={'center'} container>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={cloneOrder}
                  className={styles.button}
                  sx={{
                    '&:hover': {
                      backgroundColor: colorShade(theme.palette.primary.main, -60),
                    }
                  }}
                >
                  {intl.formatMessage({ id: 'orderAgain' })}
                </Button>
              </Grid>}
          </Grid>
        </DialogActions>
      </BootstrapDialogStyle>
    </div>
  )
}

export default OrdersHistoryDetailDialog
