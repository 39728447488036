import React, { useEffect, useState } from 'react'
import HeaderTableOrder from '../../../components/table/TableOrder/HeaderTableOrder'
import MyTableOrder from '../../../components/table/TableOrder/MyTableOrder'
import { collection, doc, onSnapshot, where, query } from 'firebase/firestore'
import { db } from '../../../firebase'
import Loading from '../../../components/Loading'
import OtherTableOrder from '../../../components/table/TableOrder/OthersTableOrder'
import AmountButtonTableOrder from '../../../components/table/TableOrder/AmountButtonTableOrder'
import { tablesOrdersCollection, usersOrdersCollection } from '../../../utils/collections_names_firestore'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { tableOrderActions } from '../../../features/table/table.slice'
import { updateTheme } from '../../../features/theme/Theme.slice'
import OrderSomethingElseButton from '../../../components/table/TableOrder/OrderSomethingElseButton'
import PaymentSummary from '../../../features/paymentSummary/components/PaymentSummary'
import { ConfirmDialog } from '../../../components/dialogs/ConfirmDialog'
import { useIntl } from 'react-intl'
import getLocalKeyWords from '../../../utils/local_keywords'
import {
  tableCanceled,
  tableConfirmed,
  tablePayed, tablePaying,
  tablePending,
  tablePreparing
} from '../../../utils/order_state_extension'
import { restaurantActions } from '../../../features/menu/restaurant.slice'
import Header from '../../../features/menu/components/Header'
import { background, container } from '../../../components/Background.style'
import { getStorage } from '../../../utils/storage'
import styles from './TableOrderPage.module.css'

const TableOrderPage = () => {
  const [table, setTable] = useState(null)
  const [myOrder, setMyOrder] = useState(null)
  const [otherUsersOrders, setOtherUsersOrders] = useState({})
  const [otherUsersReady, setOtherUsersReady] = useState(false)
  const [myOrderReady, setMyOrderReady] = useState(false)
  const [loading, setLoading] = useState(false)
  const sliceLoading = useSelector((store) => store.table_order.loading)
  const restaurant = useSelector((store) => store.restaurant.restaurantData)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { state } = useLocation()
  let { fromOrder } = state || {}
  const intl = useIntl()
  const [mobileView, setMobileView] = useState(false)
  const restaurantData = JSON.parse(localStorage.getItem('restaurant_data'))

  const setResponsiveness = () => {
    return window.innerWidth < 900
      ? setMobileView(true)
      : setMobileView(false)
  }

  useEffect(() => {
    setResponsiveness()
    window.addEventListener('resize', () => setResponsiveness())
    return () => {
      window.removeEventListener('resize', () => setResponsiveness())
    }
  }, [])

  useEffect(() => {
    const getTable = async () => {
      try {
        setLoading(true)
        const document = await doc(collection(db, tablesOrdersCollection), getStorage('table_id'))
        await onSnapshot(document, (snapshot) => {
          setTable(snapshot.data())
          setLoading(false)
        })
      } catch (error) {
        setLoading(false)
      }
    }

    const getMyOrder = async () => {
      try {
        setLoading(true)
        const document = await query(collection(doc(collection(db, tablesOrdersCollection), getStorage('table_id')), usersOrdersCollection), where('user_id', '==', getStorage('user_id')))
        await onSnapshot(document, (snapshot) => {
          snapshot.docChanges().forEach((change) => {
            if (change.type === 'added' || change.type === 'modified') {
              if (change.doc.data()['state'] === 'empty') {
                navigate(`/${getStorage('table_uid')}`)
              } else {
                setMyOrder(change.doc.data())
                setMyOrderReady(change.doc.data().state === tableConfirmed || change.doc.data().state === tablePreparing)
              }
            }
            if (change.type === 'removed') {
              setMyOrder(null)
              setMyOrderReady(false)
              navigate('/exit')
            }
          })
          setLoading(false)
        })
      } catch (error) {
        setLoading(false)
      }
    }

    const getOtherUsersOrders = async () => {
      try {
        setLoading(true)
        const document = await query(collection(doc(collection(db, tablesOrdersCollection), getStorage('table_id')), usersOrdersCollection), where('user_id', '!=', getStorage('user_id')))
        await onSnapshot(document, (snapshot) => {
          snapshot.docChanges().forEach((change) => {
            if (change.type === 'added' || change.type === 'modified') {
              otherUsersOrders[change.doc.id] = change.doc.data()
            }
            if (change.type === 'removed') {
              Object.keys(otherUsersOrders).forEach(function (key) {
                if (key.match('^' + change.doc.id)) delete otherUsersOrders[key]
              })
            }
          })

          let isConfirmed = true
          let keys = Object.keys(otherUsersOrders)
          for (let i = 0; i < keys.length; i++) {
            if (otherUsersOrders[keys] !== null && otherUsersOrders[keys] !== undefined) {
              if (!(otherUsersOrders[keys[i]].state === tableConfirmed || otherUsersOrders[keys[i]].state === tablePreparing)) {
                isConfirmed = false
                break
              }
            }
          }

          setOtherUsersReady(isConfirmed)
          setOtherUsersOrders(otherUsersOrders)
          setLoading(false)
        })
      } catch (error) {
        setLoading(false)
      }
    }

    if (fromOrder === undefined) {
      navigate(`/${getStorage('table_uid')}`)
    }

    dispatch(updateTheme())
    getOtherUsersOrders()
    getMyOrder()
    getTable()
    if (restaurant === null || restaurant === undefined) {
      dispatch(restaurantActions.restaurantData()).unwrap().catch(() => {
        navigate('/exit')
      })
    }
  }, [])

  const changeClick = () => {
    if (table != null) {
      if (table.state === tablePending) {
        dispatch(tableOrderActions.confirmTableOrder())
      } else if (table.state === tablePreparing) {
        dispatch(tableOrderActions.askPayBill())
      }
    }
  }

  const cancelOrder = () => {
    ConfirmDialog({
      text: intl.formatMessage({ id: 'wantToCancelQuestion' }, { order: getLocalKeyWords('order') }),
      confirmButtonText: intl.formatMessage({ id: 'buttonCancelOrder' }),
      cancelButtonText: intl.formatMessage({ id: 'buttonNo' }), //
      onConfirm: () => {
        dispatch(tableOrderActions.cancelUserOrder())
      },
      onCancel: () => {}
    })
  }

  return (loading || sliceLoading ? <Loading/> :
      <div style={background(mobileView)}>
        <div style={container(mobileView)}>
          <div className={styles.container}>
            <Header restaurant={restaurantData?.restaurant} isDelivery={restaurantData?.delivery} mobileView={mobileView}/>
            {table && myOrder && otherUsersOrders &&
              <HeaderTableOrder table={table} myOrder={myOrder} otherUsersOrders={otherUsersOrders}/>}
            {myOrder && myOrder.state !== tableCanceled &&
              <MyTableOrder table={table} myOrder={myOrder} onCancelClick={cancelOrder} mobileView={mobileView}/>
            }
            {otherUsersOrders && Object.keys(otherUsersOrders).length > 0 &&
              <OtherTableOrder otherUsersOrders={otherUsersOrders}/>}
            <OrderSomethingElseButton myOrder={myOrder} mobileView={mobileView}/>
            {otherUsersReady && myOrderReady && table && (table.state === tablePending || table.state === tablePreparing) &&
              <AmountButtonTableOrder table={table} myOrder={myOrder} changeClick={changeClick} mobileView={mobileView}/>}
            {table && (table.state === tablePaying || table.state === tablePayed) &&
              <PaymentSummary
                isDelivery={false}
                tableOrder={table}
                userOrders={[myOrder, otherUsersOrders]}
                mobileView={mobileView}/>
            }
          </div>
        </div>
      </div>
  )
}

export default TableOrderPage
