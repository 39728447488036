const environments = {
  prod: {
    environment: 'prod',
    url: {
      API_URL: 'https://api.cocoa.app/',
    },
    tablesCollection: 'tables',
    userOrdersCollection: 'user_orders',
    deliveriesCollection: 'deliveries',
    keywordsCollection: 'keywords',
    restaurantsCollection: 'restaurants'
  },
  dev: {
    environment: 'dev',
    url: {
      API_URL: 'https://cocoa-st-g27w3.ondigitalocean.app/'
    },
    tablesCollection: 'tables_dev',
    userOrdersCollection: 'user_orders_dev',
    deliveriesCollection: 'deliveries_dev',
    keywordsCollection: 'keywords_dev',
    restaurantsCollection: 'restaurants_dev'
  },
  local: {
    environment: 'local',
    url: {
      API_URL: 'http://locahost:3002/'
    },
    tablesCollection: 'tables_dev',
    userOrdersCollection: 'user_orders_dev',
    deliveriesCollection: 'deliveries_dev',
    keywordsCollection: 'keywords_dev',
    restaurantsCollection: 'restaurants_dev'
  }
}

export const envConfig = environments[process.env.REACT_APP_ENV]
