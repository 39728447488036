import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Axios from 'axios'
import { urlAddressSuggestions } from '../../apis/suggestions'
import LanguageDialog from '../../components/dialogs/LanguageDialog'
import { getStorage } from '../../utils/storage'

export const SUGGESTIONS_NAME = 'suggestions'

const initialState = {
  suggestions: null,
  status: 'idle',
  loading: false,
}

export const getSuggestions = createAsyncThunk('getSuggestions', async (params) => {
  const resp = await Axios({
    headers: {
      Authorization: `Bearer ${getStorage('st')}`,
      'X-PI-TABLE-TOKEN': getStorage('tt'),
      'X-PI-RESTAURANT-ID': getStorage('restaurant_id'),
      lang: navigator.language,
      'If-None-Match': ''
    },
    url: urlAddressSuggestions,
    method: 'GET',
    params: params
  })
  return resp.data.response
})

export const suggestionsSlice = createSlice({
  name: SUGGESTIONS_NAME,
  initialState,

  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getSuggestions.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getSuggestions.fulfilled, (state, action) => {
      state.suggestions = action.payload
      state.loading = false
    })
    builder.addCase(getSuggestions.rejected, (state) => {
      state.status = 'error'
      state.loading = false
      state.suggestions = null
      LanguageDialog({ from: getSuggestions })
    })
  }
})

export const suggestionsActions = { getSuggestions }

export default suggestionsSlice.reducer
