import { Avatar } from '@mui/material'
import Grid from '@mui/material/Grid'
import styles from './HomeImage.module.css'

const HomeImage = ({ homeInfo }) => {
  return (
    <Grid container direction={'column'} className={styles.grid}>
      <Avatar className={styles.avatar} src={homeInfo?.logo_url}/>
    </Grid>
  )
}

export default HomeImage
