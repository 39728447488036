import { Card, Grid, Typography } from '@mui/material'

export const SuggestionsItem = ({ suggestion, onPressed }) => {
  return (<Grid item>
    <Card sx={{ p: 1, boxShadow: 'none' }}>
      <Grid
        container
        alignItems={'center'}
        justifyContent="space-between"
        sx={{ color: 'black' }}>
        <Grid item onClick={onPressed}>
          <Typography variant="subtitle2">
            {suggestion.name} {suggestion.address}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  </Grid>)
}