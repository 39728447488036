import { ScheduleMeeting } from 'react-schedule-meeting'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import styles from './BookTable.module.css'
import PhoneInput from 'react-phone-input-2'
import { Grid } from '@mui/material'
import { useIntl } from 'react-intl'
import { useEffect, useState } from 'react'
import { getStorage } from '../../utils/storage'
import { maxMobileWidth, maxWebWidth } from '../../utils/screen_utils'
import Button from '@mui/material/Button'
import { InfoDialog } from '../../components/dialogs/InfoDialog'
import { bookTableStyles } from './BookTable.style'
import { theme } from '../../index'
import { updateTheme } from '../../features/theme/Theme.slice'
import { useDispatch } from 'react-redux'

const BookTablePage = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const [name, setName] = useState(getStorage('user_name'))
  const [notes, setNotes] = useState(null)
  const [email, setEmail] = useState('')
  const [errorValidation, setErrorValidation] = useState(null)
  const validRegex = /^(([^<>()[\],;:\s@]+(\.[^<>()[\],;:\s@]+)*)|(.+))@(([^<>()[\],;:\s@]+\.)+[^<>()[\],;:\s@]{2,})$/i
  const [state, setstate] = useState({
    phoneNumber: JSON.parse(getStorage('phone_number'))?.user?.phone_number,
    phoneCode: JSON.parse(getStorage('phone_number'))?.user?.phone_code,
  })
  const [mobileView, setMobileView] = useState(false)
  const [selectedDate, setSelectedDate] = useState(null)
  const classes = bookTableStyles(mobileView)

  useEffect(() => {
    dispatch(updateTheme())
    setErrorValidation(false)
  }, [])

  useEffect(() => {
    setResponsiveness()
    window.addEventListener('resize', () => setResponsiveness())
    return () => {
      window.removeEventListener('resize', () => setResponsiveness())
    }
  }, [])

  const setResponsiveness = () => {
    return window.innerWidth < 900
      ? setMobileView(true)
      : setMobileView(false)
  }

  const handleOnChangePhoneNumber = (value, data) => {
    setstate({ ...state, phoneNumber: value.slice(data.dialCode.length), phoneCode: `+${data.dialCode}` })
    setErrorValidation(false)
  }

  const availableTimeslots = [0, 1, 2, 3, 4, 5].map((id) => {
    return {
      id,
      startTime: new Date(new Date(new Date().setDate(new Date().getDate() + id)).setHours(9, 0, 0, 0)),
      endTime: new Date(new Date(new Date().setDate(new Date().getDate() + id)).setHours(17, 0, 0, 0)),
    }
  })

  const bookATable = () => {
    setErrorValidation(false)

    if (name === null || name === '' || name === undefined || name.trim() === '') {
      setErrorValidation(true)
      return
    }

    if (email !== '' && !email.match(validRegex)) {
      setErrorValidation(true)
      return
    }

    if (state.phoneNumber === null || state.phoneNumber === '' || state.phoneNumber === undefined) {
      setErrorValidation(true)
      return
    }

    if (selectedDate === null || selectedDate === '' || selectedDate === undefined) {
      InfoDialog({text: intl.formatMessage({ id: 'youMustSelectADate' })})
      return
    }

    InfoDialog({text: intl.formatMessage({ id: 'gotoMenu' })})
  }

  const getColor = () => {
    if (theme.palette.info.main) {
      return theme.palette.info.main
    } else {
      return null
    }
  }

  return (
    <div style={classes.div}>
      <Grid container justifyContent="center" sx={{ padding: '12px', width: mobileView ? maxMobileWidth : maxWebWidth }}>
        <Grid item xl={10.5} xs={12} md={12} lg={10.5} sm={12}>
          <Typography gutterBottom variant="h6" className={styles.titles2}>
            {intl.formatMessage({ id: 'selectADate' })}
          </Typography>
          <ScheduleMeeting
            borderRadius={10}
            eventDurationInMinutes={30}
            availableTimeslots={availableTimeslots}
            onStartTimeSelect={setSelectedDate}
            primaryColor={getColor()}
          />
        </Grid>
        <Grid item xl={10} xs={12} md={11.5} lg={10} sm={12} container>
          <Typography gutterBottom variant="h6" className={styles.titles}>
            {intl.formatMessage({ id: 'whatsYourName' })}
          </Typography>
          <TextField
            fullWidth
            id="name"
            label={intl.formatMessage({ id: 'writeHere' })}
            variant="outlined"
            color="secondary"
            value={name}
            onChange={(event) => setName(event.target.value)}
            error={errorValidation && (name === '' || name === null || name.trim() === '')}
            helperText={errorValidation && (name === '' || name === null) ? intl.formatMessage({ id: 'required' }) : ' '}
            InputProps={{
              style: {
                borderRadius: '.4rem'
              },
            }}
          />
        </Grid>
        <Grid item xl={10} xs={12} md={11.5} lg={10} sm={12} container>
          <Typography gutterBottom variant="h6" className={styles.titles}>
            {intl.formatMessage({ id: 'addEmail' })}
          </Typography>
          <TextField
            fullWidth
            id="name"
            label={intl.formatMessage({ id: 'writeHere' })}
            variant="outlined"
            color="secondary"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            error={errorValidation && (email === '' || email === null || email.match(validRegex))}
            helperText={errorValidation && (email === '' || email === null || email.match(validRegex)) ? intl.formatMessage({ id: 'required' }) : ' '}
            InputProps={{
              style: {
                borderRadius: '.4rem'
              },
            }}
          />
        </Grid>
        <Grid item xl={10} xs={12} md={11.5} lg={10} sm={12} container>
          <Typography gutterBottom variant="h6" className={styles.titles}>
            {intl.formatMessage({ id: 'addPhoneNumber' })}
          </Typography>
          <PhoneInput
            inputStyle={{ width: '100%', borderRadius: '.4rem' }}
            fullWidth
            preferredCountries={['co', 'mx', 'us']}
            country={'co'}
            countryCodeEditable={false}
            value={`${state.phoneCode} ${state.phoneNumber}`}
            placeholder={intl.formatMessage({ id: 'writeHere' })}
            onChange={handleOnChangePhoneNumber}
            defaultErrorMessage={intl.formatMessage({ id: 'required' })}
            isValid={() => !(errorValidation && (state.phoneNumber === null || state.phoneNumber === '' || state.phoneNumber === undefined))}
            inputProps={{
              required: true,
            }}
          />
        </Grid>
        <Grid item xl={10} xs={12} md={11.5} lg={10} sm={12} container>
          <Typography gutterBottom variant="h6" className={styles.titles1}>
            {intl.formatMessage({ id: 'additionalNotes' })}
          </Typography>
          <TextField
            fullWidth
            id="outlined-basic"
            label={intl.formatMessage({ id: 'writeHere' })}
            variant="outlined"
            color="secondary"
            value={notes}
            rows={5}
            multiline
            onChange={(event) => setNotes(event.target.value)}
            InputProps={{
              style: {
                borderRadius: '.4rem'
              },
            }}
          />
        </Grid>

        <Grid item container xl={4} xs={12} md={4} lg={4} sm={12} className={styles.grid} justifyContent="center">
          <Button variant={'contained'} size={'large'} color={'info'} onClick={bookATable}>
            {intl.formatMessage({ id: 'bookTable' })}
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}

export default BookTablePage
