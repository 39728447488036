import * as React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { formatCost } from '../../../utils'
import { useIntl } from 'react-intl'
import getLocalKeyWords from '../../../utils/local_keywords'
import { tablePending, tablePreparing } from '../../../utils/order_state_extension'
import { getStorage } from '../../../utils/storage'
import styles from './AmountButtonTableOrder.module.css'
import { AmountButtonTableOrderStyle } from './AmountButtonTableOrder.style'
import { colorShade } from '../../../utils/colorShade'
import { theme } from '../../../index'

const AmountButtonTableOrder = ({ table, myOrder, changeClick, mobileView }) => {
  const intl = useIntl()
  const classes = AmountButtonTableOrderStyle(mobileView)

  const productsTotal = {
    'subunits': (myOrder.total_amount?.subunits || 0), 'currency_iso': getStorage('currency')
  }

  return (
    <div className={styles.container}>
      <Grid container direction="column" alignItems="center" justifyContent="center">
        <Button
          variant={'contained'}
          size={'large'}
          color={'primary'}
          onClick={changeClick}
          style={classes.button}
          sx={{
            '&:hover': {
              backgroundColor: colorShade(theme.palette.primary.main, -60),
            }
          }}
        >
          {table.state === tablePending ? intl.formatMessage({ id: 'confirmTableOrder' }, {
            order: getLocalKeyWords('order'),
            table: getLocalKeyWords('table')
          }) : table.state === tablePreparing ? intl.formatMessage({ id: 'requestPayOrder' }) : intl.formatMessage({ id: 'tableEmpty' })}
        </Button>
      </Grid>
      <Typography variant="body1" color={'black'} paddingTop={1} fontWeight={'bold'} textAlign={'center'}>
        {intl.formatMessage({ id: 'totalInOrder' }, { order: getLocalKeyWords('order') })} {formatCost(productsTotal)}
      </Typography>
    </div>)
}

export default AmountButtonTableOrder
