import styles from './SocialButtons.module.css'
import Grid from '@mui/material/Grid'
import { handleUrl } from '../../utils/urlUtils'
import { handleWhatsappUrl } from '../../utils/whatsappUtil'
import { Button } from '@mui/material'
import * as React from 'react'
import { IconFacebook, IconInstagram, IconWhatsapp } from './icons'
import {getColorForButton} from '../../utils/color_util'

const SocialButtons = ({ homeInfo }) => {

  return (
    <Grid direction={'row'} container className={styles.container}>
      {homeInfo?.button_actions?.map((buttonAction) => (
        <div key={buttonAction.label}>
          {buttonAction.label === 'Whatsapp' && buttonAction.whatsapp_phone !== '' &&
            <Grid className={styles.container1}>
              <Button onClick={() => open(handleWhatsappUrl(buttonAction.whatsapp_phone))}>
                <IconWhatsapp
                  fill={`#${getColorForButton(buttonAction)}`}/>
              </Button>
            </Grid>
          }
          {buttonAction.label === 'Instagram' && buttonAction.external_url !== '' &&
            <Grid className={styles.container1}>
              <Button onClick={() => open(handleUrl(buttonAction.external_url))}>
                <IconInstagram
                  fill={`#${getColorForButton(buttonAction)}`}/>
              </Button>
            </Grid>
          }
          {buttonAction.label === 'Facebook' && buttonAction.external_url !== '' &&
            <Grid className={styles.container1}>
              <Button onClick={() => open(handleUrl(buttonAction.external_url))}>
                <IconFacebook
                  fill={`#${getColorForButton(buttonAction)}`}/>
              </Button>
            </Grid>
          }
        </div>
      ))}
    </Grid>
  )
}

export default SocialButtons
