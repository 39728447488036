import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { updateTheme } from '../../features/theme/Theme.slice'
import { restaurantActions } from '../../features/menu/restaurant.slice'
import { collection, onSnapshot, query, where } from 'firebase/firestore'
import { db } from '../../firebase'
import { usersOrdersCollection } from '../../utils/collections_names_firestore'
import { shoppingCartActions } from '../../features/shoppingCart/ShoppingCart.slice'
import { ConfirmDialog } from '../../components/dialogs/ConfirmDialog'
import Loading from '../../components/Loading'
import Typography from '@mui/material/Typography'
import CallWaiter from '../../features/callWaiter/components/CallWaiter'
import { Box, List } from '@mui/material'
import ShoppingListItem from '../../features/shoppingCart/components/ShoppingListItem'
import Button from '@mui/material/Button'
import { canModifyDeliveryOrder, canModifyTableOrder } from '../../utils/order_state_extension'
import getLocalKeyWords from '../../utils/local_keywords'
import Grid from '@mui/material/Grid'
import { getStorage, saveStorage } from '../../utils/storage'
import styles from './OrderDetailPage.module.css'
import { OrderDetailPageStyle } from './OrderDetailPage.style'
import { colorShade } from '../../utils/colorShade'
import { theme } from '../../index'

const OrderDetailPage = () => {
  const [orderItems, setOrderItems] = useState(null)
  const loading = useSelector((store) => store.shoppingCart.loading)
  const restaurant = useSelector((store) => store.restaurant.restaurantData)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const intl = useIntl()
  const [userOrder, setUserOrder] = useState(null)
  const [mobileView, setMobileView] = useState(false)
  const classes = OrderDetailPageStyle(mobileView)

  const setResponsiveness = () => {
    return window.innerWidth < 900
      ? setMobileView(true)
      : setMobileView(false)
  }

  useEffect(() => {
    setResponsiveness()
    window.addEventListener('resize', () => setResponsiveness())
    return () => {
      window.removeEventListener('resize', () => setResponsiveness())
    }
  }, [])

  useMemo(() => {
    if (orderItems) {
      if (orderItems.length === 0) {
        navigate(`/${getStorage('table_uid')}`)
      }
    }
  }, [orderItems])

  useEffect(() => {
    dispatch(getOrder)
    dispatch(updateTheme())
    if (restaurant == null) {
      dispatch(restaurantActions.restaurantData()).unwrap().catch(() => {
        navigate(`/${getStorage('table_uid')}`)
      })
    }
  }, [])

  const getOrder = () => {
    const orderQuery = query(collection(db, usersOrdersCollection), where('user_id', '==', getStorage('user_id')), where('table_id', '==', getStorage('table_id')))
    onSnapshot(orderQuery, (querySnapshot) => {
      querySnapshot.docChanges().forEach((change) => {
        if (change.type === 'added' || change.type === 'modified') {
          setOrderItems(change.doc.data().order_items)
          setUserOrder(change.doc.data())
          saveStorage('user_order_id', change.doc.data()['user_order_id'])
        }
        if (change.type === 'removed') {
          setOrderItems(null)
          setUserOrder(null)
          navigate('/exit')
        }
      })
    })
  }

  const deleteAllItems = () => {
    ConfirmDialog({
      text: intl.formatMessage({ id: 'wantToDeleteAllItems' }, {
        order: getLocalKeyWords('order'),
        products: getLocalKeyWords('products')
      }),
      confirmButtonText: intl.formatMessage({ id: 'buttonDeleteItems' }),
      cancelButtonText: intl.formatMessage({ id: 'buttonNo' }),
      onConfirm: () => {
        dispatch(shoppingCartActions.deleteAllItems()).unwrap().then(() => navigate(`/${getStorage('table_uid')}`))
      },
      onCancel: () => {}
    })
  }

  const navigateBack = () => {
    navigate(-1)
  }

  return (loading ? <Loading/> : orderItems ?
      <div style={classes.container}>
        <div style={classes.container2}>
          {restaurant?.delivery ? <div/> :
            <div className={styles.container3}>
              <Typography variant="h5" className={styles.font}>
                {intl.formatMessage({ id: 'myOrder' }, { order: getLocalKeyWords('order') })}
              </Typography>
              <CallWaiter/>
            </div>
          }
          <List>
            {orderItems.map((orderItem) => (
              <div key={orderItem._id} className={styles.div}>
                <ShoppingListItem
                  orderItem={orderItem}
                  canEdit={(userOrder?.is_delivery === true ? canModifyDeliveryOrder(userOrder.state) : canModifyTableOrder(userOrder.state))}
                  mobileView={mobileView}
                />
              </div>
            ))}
            {(userOrder?.is_delivery === true ? canModifyDeliveryOrder(userOrder.state) : canModifyTableOrder(userOrder.state)) &&
              <Box textAlign="center" className={styles.box}>
                <Button variant="outlined" color="error" size="large" onClick={deleteAllItems}>
                  {intl.formatMessage({ id: 'removeProduct' }, { products: getLocalKeyWords('products') })}
                </Button>
              </Box>}
          </List>
          {(userOrder?.is_delivery === true ? !canModifyDeliveryOrder(userOrder.state) : !canModifyTableOrder(userOrder.state)) &&
            <Box textAlign="center" className={styles.box}/>}
          <Grid container alignItems="center" justifyContent="center">
            <Button
              color="primary"
              variant="contained"
              onClick={navigateBack}
              style={classes.button}
              sx={{
                '&:hover': {
                  backgroundColor: colorShade(theme.palette.primary.main, -60),
                }
              }}
            >
              {intl.formatMessage({ id: 'buttonBack' })}
            </Button>
          </Grid>
        </div>
      </div> : <div/>
  )
}

export default OrderDetailPage
