import React from 'react'
import { Backdrop } from '@mui/material'
import Lottie from 'lottie-react'
import loadingAnimation from '../assets/animations/loading.json'

const style = {
  height: 200, width: 200
}

const Loading = () => (
  <Backdrop open style={{ zIndex: 9000, background: 'rgba(0, 0, 0, 0.2)' }}>
    <Lottie animationData={loadingAnimation} loop={true} style={style}/>
  </Backdrop>
)

export default Loading
