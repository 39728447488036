import * as React from 'react'
import { ListItemText, ListSubheader, Stack, Step, StepLabel, Stepper, Typography } from '@mui/material'
import { OrderQuantity, tableOrderStyles } from '../../../pages/table/TableOrder/TableOrderPage.style'
import Grid from '@mui/material/Grid'
import { formatCost } from '../../../utils'
import Button from '@mui/material/Button'
import {
  getAllTablesStatesName, getAllTableStates,
  stateColor,
  stateName,
  tableConfirmed,
  tablePreparing
} from '../../../utils/order_state_extension'
import EmptyImage from '../../../assets/images/image_empty.svg'
import Image from '../../Image'
import { useIntl } from 'react-intl'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { useNavigate } from 'react-router-dom'
import getLocalKeyWords from '../../../utils/local_keywords'
import { getStorage } from '../../../utils/storage'
import { QontoConnector, QontoStepIcon } from '../../Stepper'
import { useState } from 'react'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import { HoverOrder } from '../../../pages/deliveryOrder/DeliveryOrder/DeliveryOrderPage.style'
import Collapse from '@mui/material/Collapse'

const MyTableOrder = ({ table, myOrder, onCancelClick, mobileView }) => {
  const intl = useIntl()
  const styles = tableOrderStyles()
  const navigate = useNavigate()
  const steps = getAllTablesStatesName()
  const [isHovering, setIsHovering] = useState(false)
  const [isOpenCollapse, setOpenCollapse] = useState(false)

  const openOrderDetail = () => {
    navigate(`/orderDetail/${getStorage('table_uid')}`)
  }

  const getCurrentState = () => {
    const tableStates = getAllTableStates()
    if (tableStates !== undefined && tableStates !== null && tableStates.length > 0) {
      return tableStates.indexOf(table.state)
    }
  }

  const handleMouseOver = () => {
    setIsHovering(true)
  }

  const handleMouseOut = () => {
    setIsHovering(false)
  }

  return (
    <div>
      <ListSubheader className={styles.headerSection}>
        <Grid container alignItems="center">
          <Grid item xs={mobileView === true ? 8 : 2}>
            <ListItemText color="black" primary={<Typography variant="body1" color="black">
              {intl.formatMessage({ id: 'myOrder' }, { order: getLocalKeyWords('order') })}
            </Typography>}
            />
          </Grid>
          {mobileView === true &&
            <Grid
              item xs={4}
              container
              direction={'row'}
              justifyItems={'flex-end'}
              justifyContent={'flex-end'}
              onMouseOver={handleMouseOver}
              onMouseOut={handleMouseOut}
              style={HoverOrder(isHovering).hover}
              onClick={() => setOpenCollapse(prev => !prev)}
            >
              {myOrder !== null && myOrder !== undefined && myOrder?.state !== null &&
                <Grid
                  container
                  item xs={10}
                  bgcolor={stateColor(myOrder.state)}
                  borderRadius={5}
                  justifyContent={'center'}
                  padding={'7px'}
                >
                  <Typography variant="body1" color={'white'} fontWeight={'bold'} fontSize={'12px'} alignItems={'center'}>
                    {stateName(myOrder?.state)}
                  </Typography>
                </Grid>
              }
              <Grid container item xs={2}>
                <InfoIcon fontSize={'large'} sx={{ color: '#666666' }}/>
              </Grid>
            </Grid>
          }
          {mobileView === false &&
            <Grid container item xs={10} justifyItems={'flex-end'} justifyContent={'flex-end'}>
              <Stack sx={{ width: '100%' }} spacing={4}>
                <Stepper alternativeLabel activeStep={getCurrentState()} connector={<QontoConnector/>}>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Stack>
            </Grid>}
        </Grid>
      </ListSubheader>
      {mobileView === true &&
        <Collapse
          in={isOpenCollapse}
          timeout="auto"
          unmountOnExit>
          <Grid container direction={'column'}>
            <Grid item container xs={12} direction={'row'} justifyContent={'center'} className={styles.container2}>
              <Typography variant="h6" color={'black'}>
                {intl.formatMessage({ id: 'orderStatus' })}
              </Typography>
            </Grid>
            <Grid container item={12} justifyContent={'center'} alignItems={'center'} marginTop={'32px'}>
              <Stepper activeStep={getCurrentState()} orientation="vertical">
                {steps.map((step) => (
                  <Step key={step} >
                    <StepLabel StepIconComponent={QontoStepIcon}>{step}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Grid>
          </Grid>
        </Collapse>
      }
      <Grid container alignItems="center" direction={'row'} justifyContent={'space-between'} paddingY={'20px'}>
        <Grid item xs={1} lg={0.4}>
          <ListItemText
            color="black"
            primary={
              <OrderQuantity sx={{ width: 30, height: 30, backgroundColor: 'primary.main' }}>
                {myOrder?.order_items.length}
              </OrderQuantity>
            }
          />
        </Grid>
        <Grid item xs={5} lg={5.6}>
          <Grid item container="true">
            {myOrder?.order_items.slice(0, 3).map((product) => (
              product.product_image && !product.product_image.toString().includes('missing') &&
              <Image key={product._id} className={styles.image} src={product.product_image}
                     placeholderImg={EmptyImage}/>))}
          </Grid>
        </Grid>
        <Grid item xs={4} container className={styles.grid1}>
          <Typography gutterBottom variant="h6" align="center">
            {formatCost(myOrder?.total_amount)}
          </Typography>
        </Grid>
        <Grid item xs={1} container className={styles.grid}>
          <ArrowForwardIosIcon color="black" fontSize="small" onClick={openOrderDetail}/>
        </Grid>
      </Grid>
      {myOrder?.state === tableConfirmed && table.state !== tablePreparing &&
        <div className={styles.container}>
          <Button variant="text" color="error" onClick={onCancelClick}>
            {intl.formatMessage({ id: 'cancelOrder' }, { order: getLocalKeyWords('order') })}
          </Button></div>}
    </div>
  )
}

export default MyTableOrder
