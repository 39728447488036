export const isValidHttpUrl = (s) => {
  let url
  try {
    url = new URL(s)
  } catch (e) { return false }
  return /https?/.test(url.protocol)
}

export const urlIfy = (text) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  text = text.replace(/\n/g," <br/> ")
  return text.replace(urlRegex, function(url) {
    return '<a href="' + url + ' " target="_blank">' + url + '</a>';
  })
}

export function clean(obj) {
  for (const propName in obj) {
    if ((obj[propName] === null || obj[propName] === undefined)) {
      delete obj[propName]
    } else {
      if (obj[propName] instanceof Object && Object.keys(obj[propName]).length > 1) {
        for (const innerPropName in obj[propName]) {
          if (kind(obj[propName][innerPropName]) === 'String' && obj[propName][innerPropName].trim().length === 0) {
            delete obj[propName][innerPropName]
          }
        }
      }
    }
  }
  return obj
}

const kind = function(item) {
  const getPrototype = function(item) {
    return Object.prototype.toString.call(item).slice(8, -1);
  };
  let kind, Undefined;
  if (item === null ) {
    kind = 'null';
  } else {
    if ( item === Undefined ) {
      kind = 'undefined';
    } else {
      const prototype = getPrototype(item);
      if ( ( prototype === 'Number' ) && isNaN(item) ) {
        kind = 'NaN';
      } else {
        kind = prototype;
      }
    }
  }
  return kind;
};


export const domain =  'cocoa.app'//'192.168.3.109'
export const localhost = 'localhost'
export const cookieNotice = 'cookie_notice'
