import Image from '../../Image'
import EmptyImage from '../../../assets/images/image_empty.png'
import * as React from 'react'

const ProductImage = ({ image, mobileView }) => {
  return (
    image && <Image
      style={{
        height: mobileView === true ? 260 : 460,
        borderRadius: '.4rem',
        width: '100%',
        objectFit: 'cover',
      }}
      src={image}
      placeholderImg={EmptyImage}
    />
  )
}

export default ProductImage
