import { Navigate, useParams } from 'react-router-dom'
import { getStorage } from '../utils/storage'

const ConditionalRoute = ({ children }) => {
  let params = useParams()


  if (getStorage('st') === null || getStorage('st') === undefined || getStorage('st') === '' || getStorage === null || getStorage('tt') === undefined || getStorage('tt') === '') {
    if (params.id !== undefined && params.id !== null && params.id !== '') {
      return <Navigate to={`/${params.id}`}/>
    }
  }

  return children
}

export default ConditionalRoute