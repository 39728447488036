import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'
import { useEffect, useState } from 'react'
import PayButtonTableOrder from '../../../components/table/TableOrder/PayButtonTableOrder'
import Grid from '@mui/material/Grid'
import { Avatar, List } from '@mui/material'
import { empty, orderArrived } from '../../../utils/order_state_extension'
import { formatCost } from '../../../utils'
import CloseIcon from '@mui/icons-material/Close'
import { useIntl } from 'react-intl'
import getLocalKeyWords from '../../../utils/local_keywords'
import { getStorage } from '../../../utils/storage'
import { BootstrapDialogStyle } from '../../../components/dialogs/BootstrapDialog.style'
import { PaymentSummaryStyle } from './PaymentSummary.style'
import { neutralizeBack, revivalBack } from '../../../utils/navigation_util'

const PaymentSummary = ({ isDelivery, tableOrder, userOrders, mobileView }) => {
  const intl = useIntl()

  const [open, setOpen] = useState(false)
  const [tip, setTip] = useState(0)
  const [tax, setTax] = useState(0)
  const [subTotal, setSubTotal] = useState(0)
  const [total, setTotal] = useState(0)
  const [delivery, setDelivery] = useState(0)
  const classes = PaymentSummaryStyle()

  const users = Object.keys(userOrders)

  const handleClose = () => {
    setOpen(false)
    revivalBack()
  }

  const openDialog = () => {
    setOpen(true)
    neutralizeBack(() => handleClose())
  }

  useEffect(() => {

    if (isDelivery === true && userOrders !== null && userOrders !== undefined) {

      const total = {
        'subunits': ((userOrders[0].total_amount !== null && userOrders[0].total_amount !== undefined ? userOrders[0].total_amount.subunits : 0) + (userOrders[0].taxes_amount !== null && userOrders[0].taxes_amount !== undefined && userOrders[0].taxes_amount.value !== null && userOrders[0].taxes_amount.value !== undefined ? userOrders[0].taxes_amount.value.subunits : 0) + (userOrders[0].tip_amount !== null && userOrders[0].tip_amount !== undefined ? userOrders[0].tip_amount.subunits : 0) + (userOrders[0].delivery_amount !== null && userOrders[0].delivery_amount !== undefined && userOrders[0].pickup_at_restaurant === false ? userOrders[0].delivery_amount.subunits : 0)),
        'currency_iso': getStorage('currency')
      }

      const tipCalculated = {
        'subunits': ((userOrders[0].tip_amount !== null && userOrders[0].tip_amount !== undefined ? userOrders[0].tip_amount.subunits : 0)),
        'currency_iso': getStorage('currency')
      }

      const productsTotal = {
        'subunits': (userOrders[0].total_amount?.subunits || 0), 'currency_iso': getStorage('currency')
      }

      const taxesTotal = {
        'subunits': (userOrders[0].taxes_amount.value?.subunits || 0), 'currency_iso': getStorage('currency')
      }

      setTotal(formatCost(total))
      setSubTotal(formatCost(productsTotal))
      setTax(formatCost(taxesTotal))
      setTip(formatCost(tipCalculated))
      setDelivery(formatCost())
      if (userOrders[0].delivery_amount !== null && userOrders[0].delivery_amount !== undefined) {
        setDelivery(formatCost(userOrders[0].delivery_amount))
      }
    }

    if (isDelivery === false && tableOrder !== null && tableOrder !== undefined) {

      const total = {
        'subunits': ((tableOrder.total_amount !== null && tableOrder.total_amount !== undefined ? tableOrder.total_amount.subunits : 0) + (tableOrder.taxes_amount !== null && tableOrder.taxes_amount !== undefined && tableOrder.taxes_amount.value !== null && tableOrder.taxes_amount.value !== undefined ? tableOrder.taxes_amount.value.subunits : 0) + (tableOrder.tip_amount !== null && tableOrder.tip_amount !== undefined ? tableOrder.tip_amount.subunits : 0)),
        'currency_iso': getStorage('currency')
      }

      const tipCalculated = {
        'subunits': ((tableOrder.tip_amount !== null && tableOrder.tip_amount !== undefined ? tableOrder.tip_amount.subunits : 0)),
        'currency_iso': getStorage('currency')
      }

      const productsTotal = {
        'subunits': (tableOrder.total_amount?.subunits || 0), 'currency_iso': getStorage('currency')
      }

      const taxesTotal = {
        'subunits': (tableOrder.taxes_amount.value?.subunits || 0), 'currency_iso': getStorage('currency')
      }

      setTotal(formatCost(total))
      setSubTotal(formatCost(productsTotal))
      setTax(formatCost(taxesTotal))
      setTip(formatCost(tipCalculated))
    }
  }, [tableOrder, userOrders])

  return (<div>
    <PayButtonTableOrder resumeClick={openDialog} mobileView={mobileView} orderDelivered={userOrders[0].state === orderArrived}/>
    <BootstrapDialogStyle
      onClose={handleClose}
      open={open}
      fullScreen={mobileView}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: mobileView === true ? '100%' : '500px',
            maxWidth: '600px',
            minHeight: '450px'
          },
        },
        zIndex: 100
      }}
    >
      <DialogContent>
        <Grid direction="column" container spacing={1} padding="8px">
          <Grid direction="row" container spacing={1} padding="8px" paddingBottom={4} alignItems="center">
            <Grid
              container
              alignItems="center"
              direction={'row'}
              rows={{ xs: 12, md: 12, xl: 12, lg: 12, sm: 12 }}
              item xs={11} sm={11} lg={11}
            >
              <Avatar alt={getStorage('restaurant_name')} src={getStorage('restaurant_logo')}/>
              {<Typography variant="h6" color="black" fontWeight="Bold" paddingLeft={2}>
                {getStorage('restaurant_name')}
              </Typography>}
            </Grid>
            <Grid item xs={1} lg={1} sm={1} justifyContent="flex-end" container alignItems="center">
              <CloseIcon onClick={handleClose}/>
            </Grid>
          </Grid>
          {users.map((key, index) => userOrders[key].state !== empty ? <div key={index} style={{ width: '95%'}}>
            <Typography key={index} variant="body1" fontWeight="Bold">
              {userOrders[key].user_name}
            </Typography>
            {userOrders !== null && userOrders !== undefined && userOrders[key].order_items !== null && userOrders[key].order_items !== undefined && userOrders[key].order_items.map((orderItem, index) =>
              <Grid direction="row" container alignItems="center" key={index} style={{ marginTop: '8px'}}>
                <Grid item xs={9}>
                  <Typography variant="body1" fontWeight={'bold'}>
                    {orderItem.quantity} • {orderItem.product_name}
                  </Typography>
                  <List className={classes.container1}>
                    {orderItem.topping_groups_array !== undefined && orderItem.topping_groups_array !== null && orderItem.topping_groups_array.map((group, index) => (
                      <div key={index}>
                        <Typography variant="body2" color={'black'} fontWeight={'bold'} fontSize={11}>
                          {group?.group_name}
                        </Typography>
                        {group.toppings !== undefined && group.toppings !== null && group.toppings.map((topping, index) => (
                          <Typography key={index} variant="body1" color={'black'} fontSize={11}>
                            {topping?.quantity} • {topping?.name} {topping.price !== null && topping.price !== undefined ? topping.price.cents > 0 && `+ ${formatCost(topping?.price)}` : ''}
                          </Typography>
                        ))}
                      </div>
                    ))}
                  </List>
                  {orderItem.client_observations !== '' && orderItem.client_observations !== null &&
                    <div className={classes.container2}>
                      <Typography variant="body2" fontSize={10} color={'black'} fontWeight={'bold'}>
                        {getLocalKeyWords('comment_title')}
                      </Typography>
                      <Typography variant="body2" fontSize={11} color={'black'}>
                        {orderItem?.client_observations}
                      </Typography>
                    </div>
                  }
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body1" fontWeight={'bold'}>
                    {formatCost(orderItem.amount)}
                  </Typography>
                </Grid>
              </Grid>)}

          </div> : <div/>)}
          <Grid className={classes.container3} container justify="flex-end" direction={'column'}>
            <Grid container direction={'row'} justifyContent={'flex-end'} alignSelf={'flex-end'} alignItems={'flex-end'}>
              <Grid container direction={'row'} justifyContent={'flex-end'} alignSelf={'flex-end'} alignItems={'flex-end'}>
                <Grid item xs={4}>
                  <Typography sx={{ paddingRight: 0 }} variant="body1">
                    {intl.formatMessage({ id: 'products' }, { products: getLocalKeyWords('products') })}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography sx={{ paddingRight: 0 }} variant="body1" fontWeight={'bold'}>
                    {subTotal}
                  </Typography>
                </Grid>
              </Grid>
              {delivery !== null && delivery !== undefined && delivery !== 0 && delivery !== '' && userOrders[0].pickup_at_restaurant === false ?
                <Grid container direction={'row'} justifyContent={'flex-end'} alignSelf={'flex-end'} alignItems={'flex-end'}>
                  <Grid item xs={4}>
                    <Typography sx={{ paddingRight: 0 }} variant="body1">
                      {intl.formatMessage({ id: 'deliveryAmount' })}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography sx={{ paddingRight: 0 }} variant="body1" fontWeight={'bold'}>
                      {delivery}
                    </Typography>
                  </Grid>
                </Grid> : <div/>}
              <Grid item xs={4}>
                <Typography sx={{ paddingRight: 0 }} variant="body1">
                  {intl.formatMessage({ id: 'tip' })}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography sx={{ paddingRight: 0 }} variant="body1" fontWeight={'bold'}>
                  {tip}
                </Typography>
              </Grid>
            </Grid>
            <Grid container direction={'row'} justifyContent={'flex-end'} alignSelf={'flex-end'} alignItems={'flex-end'}>
              <Grid item xs={4}>
                <Typography sx={{ paddingRight: 0 }} variant="body1">
                  {intl.formatMessage({ id: 'taxes' })}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography sx={{ paddingRight: 0 }} variant="body1" fontWeight={'bold'}>
                  {tax}
                </Typography>
              </Grid>
            </Grid>
            <Grid container direction={'row'} justifyContent={'flex-end'} alignSelf={'flex-end'} alignItems={'flex-end'}>
              <Grid item xs={4}>
                <Typography sx={{ paddingRight: 0 }} variant="body1" fontWeight={'bold'}>
                  {intl.formatMessage({ id: 'total' })}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography sx={{ paddingRight: 0 }} variant="body1" fontWeight={'bold'}>
                  {total}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </BootstrapDialogStyle>
  </div>)
}

export default PaymentSummary
