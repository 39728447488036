import { Fab } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React from 'react'
import { useNavigate } from 'react-router-dom'

const BackButton = ({ backAction }) => {
  const navigate = useNavigate()

  const onBackPressed = () => {
    if (backAction !== undefined) {
      backAction()
    } else {
      navigate(-1)
    }
  }

  return (
    <Fab
      size="small"
      color="primary"
      aria-label="add"
      sx={{ position: 'fixed', top: 8, left: 8, zIndex: 0 }}
      onClick={onBackPressed}
    >
      <ArrowBackIcon/>
    </Fab>
  )
}

export default BackButton
