import React, { useState, useEffect } from 'react';
import MapPinSolid from '../../assets/icons/map-pin-solid.svg';
import GoogleMap from './GoogleMap';

let newMarkers = [];

let options = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0,
};

const GetLocationByMap = ({
  direction,
  onDragEnd,
  showCurrentLocation = false,
  showDragablePin = false,
}) => {
  const [maps, setmaps] = useState(null);
  const [map, setmap] = useState(null);
  const [mapLoaded, setmapLoaded] = useState(false);
  const [currentLocation, setcurrentLocation] = useState(null);

  const getCurrentLocation = () =>
    navigator.geolocation.getCurrentPosition(onSuccess, onError, options);

  useEffect(() => {
    showCurrentLocation && mapLoaded === true && getCurrentLocation();
    if (mapLoaded === true && !showCurrentLocation) {
      setMarker({
        newMarker: direction,
        isDragable: showDragablePin,
        zIndex: 1,
        icon: MapPinSolid,
      });
    }
  }, [mapLoaded]);

  useEffect(() => {
    if (currentLocation !== null) {
      setMarker({
        newMarker: currentLocation,
        isDragable: true,
        icon: MapPinSolid,
      });

    }

  }, [currentLocation]);

  let bounds;

  const onSuccess = ({ coords }) =>
    setcurrentLocation({ lat: coords.latitude, lon: coords.longitude });

  const onError = (err) => {
    console.warn('ERROR(' + err.code + '): ' + err.message);
    setcurrentLocation({ lat: 37.421757, lon: -122.083910 })
  };

  const setMarker = ({ newMarker, isDragable, zIndex = 0, icon }) => {
    if (!bounds) {
      bounds = new google.maps.LatLngBounds();
    }
    const tempNewMarker = { lat: newMarker.lat, lng: newMarker.lon };
    const stopLatLng = new google.maps.LatLng(
      tempNewMarker.lat,
      tempNewMarker.lng
    );

    const marker = new maps.Marker({
      position: stopLatLng,
      map,
      animation: google.maps.Animation.DROP,
      icon: icon ? icon : null,
      draggable: !!isDragable,
      zIndex,
    });

    maps.event.addListener(marker, 'dragend', function (event) {
      onDragEnd({ lat: event.latLng.lat(), lng: event.latLng.lng() });
    });

    newMarkers.push(marker);

    bounds.extend(stopLatLng);
    map.fitBounds(bounds);
    map.setZoom(18);
  };

  return (
    <GoogleMap
      onLoadedMap={({ maps, map }) => {
        setmaps(maps);
        setmap(map);
        setmapLoaded(true);
      }}
    />
  );
};

export default GetLocationByMap;
