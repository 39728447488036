import { colorShade } from '../../utils/colorShade'
import { makeStyles } from '@mui/styles'

export const homeStyle = makeStyles(theme => ({
  button1: {
    width: '300px',
    borderRadius: '50rem',
    paddingTop: '8px',
    paddingBottom: '8px',
    marginTop: '12px',
    marginBottom: '12px',
    '&:hover': {
      backgroundColor: colorShade(theme.palette.info.main, -60),
    }
  },
  button2: {
    width: '300px',
    borderRadius: '50rem',
    paddingTop: '8px',
    paddingBottom: '8px',
    marginTop: '10px',
    marginBottom: '10px',
    '&:hover': {
      backgroundColor: colorShade(theme.palette.info.main, -60),
    }
  },
}))
