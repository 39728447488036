import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import styles from './HomeButtons.module.css'
import { homeStyle } from './Home.style'
import { useIntl } from 'react-intl'
import { handleUrl } from '../../utils/urlUtils'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import React from 'react'
import { homeUseStyles } from '../../pages/home/Home/HomePage.style'

const HomeButtons = ({ openBranches, homeInfo, openAddressDialog }) => {
  const classes = homeStyle()
  const intl = useIntl()

  const isLocationsAvailable = () => {
    return homeInfo !== null && Object.keys(homeInfo).length > 0 && homeInfo?.restaurant_locations !== null
  }

  const isExternalButtonsAvailable = homeInfo !== null && Object.keys(homeInfo).length > 0 && homeInfo?.button_actions !== null

  const openExternalUrl = (url) => {
    const win = window.open(handleUrl(url ?? 'www.google.com'), '_blank')
    win.focus()
  }

  /* const openBookATablePage = () => {
     navigate(`/book-table`)
   }*/

  const handleOnClickButton = (buttonAction) => {
    if (buttonAction.type === 'menu') {
      openBranches()
    } else if (buttonAction.type === 'external_url') {
      openExternalUrl(buttonAction.external_url)
    }
  }

  const buttonActionValidation = (buttonAction) => {
    return buttonAction.type !== 'social' && buttonAction.type !== 'whatsapp' &&
      (buttonAction.type !== 'menu' ? buttonAction.external_url : buttonAction.label)
  }

  return (
    <Grid container direction={'column'} className={styles.grid}>
      {isExternalButtonsAvailable && [].concat(homeInfo?.button_actions)
        .sort((a, b) => a.position > b.position ? 1 : -1)
        .map((buttonAction) => (
            buttonActionValidation(buttonAction) &&
            <Button
              key={buttonAction.label}
              variant={'contained'}
              size={'large'}
              color={'info'}
              onClick={() => handleOnClickButton(buttonAction)}
              className={homeUseStyles({ buttonAction: buttonAction }).button}
            >
              {buttonAction.label}
            </Button>
          )
        )
      }

      {isLocationsAvailable() &&
        <Button
          variant={'contained'}
          size={'large'}
          color={'info'}
          onClick={() => {openAddressDialog()}}
          className={classes.button1}
          endIcon={<LocationOnIcon color={'white'}/>}
        >
          {intl.formatMessage({ id: 'location' })}
        </Button>}

      {/*<Button variant={'contained'} size={'large'} color={'info'} onClick={openBookATablePage} className={classes.button1}>
        {intl.formatMessage({ id: 'bookTable' })}
      </Button>*/}
    </Grid>
  )
}
export default HomeButtons
