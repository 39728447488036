import * as React from 'react'
import {
  ListItem, ListItemButton, Checkbox, ListSubheader, ListItemAvatar, Avatar, ListItemText, Typography
} from '@mui/material'
import styles from './ToppingsProduct.module.css'
import { grey } from '@mui/material/colors'
import Grid from '@mui/material/Grid'
import { formatCost } from '../../../utils'
import { useIntl } from 'react-intl'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import CardContent from '@mui/material/CardContent'

const useStyles = makeStyles(() => ({
  shake: {
    animation: '$description 1s',
    animationIterationCount: '1'
  },
  '@keyframes description': {
    '0%': { transform: 'translate(2px, 1px)' },
    '10%': { transform: 'translate(-1px, -2px)' },
    '20%': { transform: 'translate(-3px, 0px)' },
    '30%': { transform: 'translate(0px, 2px)' },
    '40%': { transform: 'translate(1px, -1px)' },
    '50%': { transform: 'translate(-1px, 2px)' },
    '60%': { transform: 'translate(-3px, 1px)' },
    '70%': { transform: 'translate(2px, 1px)' },
    '80%': { transform: 'translate(-1px, -1px)' },
    '90%': { transform: 'translate(2px, 2px)' },
    '100%': { transform: ' translate(1px, -2px)' },
  }
  /*'@keyframes description': {
    '0%': { opacity: 0, transform: 'translateY(0)' },
    '15%': { transform: 'translateY(-4px, 0)' },
    '30%': { transform: 'translateY(6px, 0)' },
    '45%': { transform: 'translateY(-4px, 0)' },
    '60%': { transform: 'translateY(6px, 0)' },
    '100%': { opacity: 1, transform: 'translateY(0)' }
  },*/
  /*'@keyframes description': {
    '0%': { transform: 'translate(2px, 1px)   rotate(0deg);' },
    '10%': { transform: 'translate(-1px, -2px) rotate(-2deg);' },
    '20%': { transform: 'translate(-3px, 0px)  rotate(3deg);' },
    '30%': { transform: 'translate(0px, 2px)   rotate(0deg);' },
    '40%': { transform: 'translate(1px, -1px)  rotate(1deg);' },
    '50%': { transform: 'translate(-1px, 2px)  rotate(-1deg);' },
    '60%': { transform: 'translate(-3px, 1px)  rotate(0deg);' },
    '70%': { transform: 'translate(2px, 1px)   rotate(-2deg);' },
    '80%': { transform: 'translate(-1px, -1px) rotate(4deg);' },
    '90%': { transform: 'translate(2px, 2px)   rotate(0deg);' },
    '100%': { transform: ' translate(1px, -2px)  rotate(-1deg);' },
  }*/
}))

const ToppingsProduct = ({
  topping_groups,
  selectedToppings,
  setSelectedToppings,
  product,
  toppingGroupRequired,
  setToppingGroupRequired,
}) => {
  const intl = useIntl()
  const [isShake, setShake] = useState(false)
  const classes = useStyles()

  useEffect(() => {
    if (toppingGroupRequired) {
      scrollToToppingGroup()
    }
  }, [toppingGroupRequired])

  const scrollToToppingGroup = () => {
    const element = document.getElementById(toppingGroupRequired._id)
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'center'
      }, 500)
      setTimeout(activeShake, 500)
      setTimeout(reset, 2000)
    }
  }

  const activeShake = () => {
    setShake(true)
  }

  const reset = () => {
    setToppingGroupRequired(undefined)
    setShake(false)
  }

  const addToppingQuantity = (topping, group) => {
    const toppingIndex = (group.toppings.findIndex((t) => topping._id === t._id))
    const groupIndex = (topping_groups.findIndex((topping_group) => topping_group._id === group._id))
    const selectedToppingIndex = (selectedToppings.findIndex((t) => topping._id === t._id))
    if (selectedToppingIndex === -1) {
      topping = { ...topping_groups[groupIndex].toppings[toppingIndex], quantity: 1 }
      setSelectedToppings([...selectedToppings, topping])
    } else {
      const updatedToppings = selectedToppings
      const updatedTopping = selectedToppings[selectedToppingIndex]
      updatedTopping.quantity += 1
      updatedToppings.splice(selectedToppingIndex, 1, updatedTopping)
      setSelectedToppings([...updatedToppings])
    }
  }

  const subToppingQuantity = (topping) => {
    const selectedToppingIndex = (selectedToppings.findIndex((t) => topping._id === t._id))
    const updatedToppings = selectedToppings
    const updatedTopping = selectedToppings[selectedToppingIndex]
    if (updatedTopping?.quantity > 1) {
      updatedTopping.quantity -= 1
      if (selectedToppingIndex !== -1) {
        updatedToppings.splice(selectedToppingIndex, 1, updatedTopping)
      }
    } else {
      if (selectedToppingIndex !== -1) {
        updatedToppings.splice(selectedToppingIndex, 1)
      }
    }
    setSelectedToppings([...updatedToppings])
  }

  const getQuantity = (topping) => {
    const toppingIndex = (selectedToppings.findIndex((t) => topping._id === t._id))
    if (selectedToppings[toppingIndex] !== undefined) {
      return selectedToppings[toppingIndex].quantity
    }
    return 0
  }

  const getTotalQuantitySelectedForGroup = (group) => {
    let quantity = 0
    if (selectedToppings) {
      selectedToppings.forEach(topping => {
        if (topping.topping_group_id === group._id) {
          quantity += topping.quantity
        }
      })
    }
    return quantity
  }

  const isToppingChecked = (topping) => {
    const toppingIndex = (selectedToppings.findIndex((t) => topping._id === t._id))
    return toppingIndex !== -1
  }

  const uncheckToppingAndCheckAnother = (topping, group) => {
    const selectedToppingIndex = (selectedToppings.findIndex((t) => t.topping_group_id === group._id))
    const updatedToppings = selectedToppings
    updatedToppings.splice(selectedToppingIndex, 1)
    setSelectedToppings([...updatedToppings])
    addToppingQuantity(topping, group)
  }

  return (<div>
    <CardContent className={styles.infoSection}>
      <Grid container>
        <Grid item xs={7}>
          <Typography gutterBottom variant="h6" className={styles.titles}>
            {product.name}
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography gutterBottom variant="h6" className={styles.titles} align="right" sx={{ marginRight: '8px' }}>
            {formatCost(product.price)}
          </Typography>
        </Grid>
      </Grid>
      <Typography
        variant="body2"
        dangerouslySetInnerHTML={{
          __html: `${product.description}`
        }}
      >
      </Typography>
    </CardContent>
    {topping_groups && topping_groups.map((group) => (
      <div id={group._id} key={`section-${group._id}`} className={styles.item}>
        <ListSubheader className={styles.header}>
          <Grid container alignItems="center">
            <Grid item xs={9}>
              <ListItemText
                className={
                  isShake && group._id === toppingGroupRequired._id
                    ? classes.shake
                    : styles.headerText
                }
                primary={<Typography variant="body1" color="black">
                  {group.title}
                </Typography>}
                secondary={group.help_text}
              />
            </Grid>
            {group.is_required && (<Grid item xs={3}>
              <Typography
                gutterBottom
                variant="body2"
                className={styles.mandatory}
                align="center"
                sx={{ backgroundColor: 'primary.main' }}
                fontWeight={'bold'}
                fontSize={'10px'}
              >
                {intl.formatMessage({ id: 'mandatory' })}
              </Typography>
            </Grid>)}
          </Grid>
        </ListSubheader>
        {group.toppings.map((topping) => (<ListItem
          className={styles.item}
          key={`${group._id}-${topping._id}`}
          secondaryAction={topping.max_limit > 1 ? getQuantity(topping) > 0 ? <Grid
            direction={'row'}
            container
            justifyContent="space-between"
            alignItems="center"
          >
            <RemoveIcon onClick={() => subToppingQuantity(topping, group)} sx={{ paddingRight: 2 }}/>
            <Typography variant="body1" color="black" sx={{ paddingRight: 2 }}>
              {getQuantity(topping)}
            </Typography>
            {getQuantity(topping) < topping.max_limit && getTotalQuantitySelectedForGroup(group) < group.max_toppings ? (
              <AddIcon onClick={() => addToppingQuantity(topping, group)}/>) : (<AddIcon color="disabled"/>)}
          </Grid> : getQuantity(topping) < topping.max_limit && getTotalQuantitySelectedForGroup(group) < group.max_toppings ? (
            <AddIcon onClick={() => addToppingQuantity(topping, group)}/>) : (<AddIcon color="disabled"/>) : <Checkbox
            edge="end"
            sx={{
              color: grey, '&.Mui-checked': {
                color: '#57a496'
              }
            }}
            onChange={(event) => {
              if (event.target.checked === true) {
                if (getTotalQuantitySelectedForGroup(group) < group.max_toppings) {
                  addToppingQuantity(topping, group)
                } else if (getTotalQuantitySelectedForGroup(group) >= group.max_toppings && group.is_multiple === false) {
                  uncheckToppingAndCheckAnother(topping, group)
                }
              } else {
                subToppingQuantity(topping, group)
              }
            }}
            checked={isToppingChecked(topping)}
          />}
        >
          <ListItemButton className={styles.itemText}>
            {topping.image && (<ListItemAvatar className={styles.itemPhoto}>
              <Avatar
                alt={topping.name}
                sx={{ width: 56, height: 56 }}
                variant="square"
                src={topping.image}
              />
            </ListItemAvatar>)}
            <div>
              <ListItemText
                id={topping._id}
                primary={topping.name}
                secondary={topping.price !== null && topping.price !== undefined ? topping.price.cents > 0 && `+ ${formatCost(topping.price)}` : ''}
              />
            </div>
          </ListItemButton>
        </ListItem>))}
      </div>))}
  </div>)
}

export default ToppingsProduct
