import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Axios from 'axios'
import { urlCallWaiter, urlStopCallWaiter } from '../../apis/callWaiter'
import LanguageDialog from '../../components/dialogs/LanguageDialog'
import { getStorage } from '../../utils/storage'

export const CALL_WAITER_NAME = 'callWaiter'

const initialState = {
  value: null,
  status: 'idle',
  loading: false,
}

export const callWaiter = createAsyncThunk('callWaiter', async () => {
  const resp = await Axios({
    headers: {
      Authorization: `Bearer ${getStorage('st')}`,
      'X-PI-TABLE-TOKEN': getStorage('tt'),
      'X-PI-RESTAURANT-ID': getStorage('restaurant_id'),
      lang: navigator.language,
      'If-None-Match': ''
    },
    url: urlCallWaiter,
    method: 'PUT'
  })
  return resp.data.response
})

export const stopCallWaiter = createAsyncThunk('stopCallWaiter', async () => {
  const resp = await Axios({
    headers: {
      Authorization: `Bearer ${getStorage('st')}`,
      'X-PI-TABLE-TOKEN': getStorage('tt'),
      'X-PI-RESTAURANT-ID': getStorage('restaurant_id'),
      lang: navigator.language,
      'If-None-Match': ''
    },
    url: urlStopCallWaiter,
    method: 'PUT'
  })
  return resp.data.response
})

export const callWaiterSlice = createSlice({
  name: CALL_WAITER_NAME,
  initialState,

  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(callWaiter.pending, (state) => {
      state.loading = true
    })
    builder.addCase(callWaiter.fulfilled, (state, action) => {
      state.value = action.payload
      state.loading = false
    })
    builder.addCase(callWaiter.rejected, (state) => {
      state.status = 'error'
      state.loading = false
      state.value = null
      LanguageDialog({ from: callWaiter })

    })
    builder.addCase(stopCallWaiter.pending, (state) => {
      state.loading = true
    })
    builder.addCase(stopCallWaiter.fulfilled, (state, action) => {
      state.value = action.payload
      state.loading = false
    })
    builder.addCase(stopCallWaiter.rejected, (state) => {
      state.status = 'error'
      state.loading = false
      state.value = null
      LanguageDialog({ from: stopCallWaiter })
    })
  }
})

export const callWaiterActions = { callWaiter, stopCallWaiter }

export default callWaiterSlice.reducer
