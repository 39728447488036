import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Axios from 'axios'
import { urlConfirmUserOrder, urlDeleteAllItems, urlDeleteOrderItem, urlRemoveQuantity } from '../../apis/shoppingCart'
import { urlAddProduct } from '../../apis/DetailProduct'
import LanguageDialog from '../../components/dialogs/LanguageDialog'
import { getStorage } from '../../utils/storage'

export const SHOPPING_CART_NAME = 'shoppingCart'

const initialState = {
  response: null,
  status: 'idle',
  loading: false,
  error: false
}

export const confirmUserOrder = createAsyncThunk('confirmUserOrder', async () => {
  const resp = await Axios({
    headers: {
      Authorization: `Bearer ${getStorage('st')}`,
      'X-PI-TABLE-TOKEN': getStorage('tt'),
      'X-PI-RESTAURANT-ID': getStorage('restaurant_id'),
      lang: navigator.language,
      'If-None-Match': ''
    },
    url: urlConfirmUserOrder,
    method: 'PUT',
  })
  return resp.data.response
})

export const incrementQuantity = createAsyncThunk('incrementQuantity', async (request) => {
  const resp = await Axios({
    headers: {
      Authorization: `Bearer ${getStorage('st')}`,
      'X-PI-TABLE-TOKEN': getStorage('tt'),
      'X-PI-RESTAURANT-ID': getStorage('restaurant_id'),
      lang: navigator.language,
      'If-None-Match': ''
    },
    url: urlAddProduct,
    method: 'POST',
    data: request
  })
  return resp.data.response
})

export const decrementQuantity = createAsyncThunk('decrementQuantity', async (request) => {
  const resp = await Axios({
    headers: {
      Authorization: `Bearer ${getStorage('st')}`,
      'X-PI-TABLE-TOKEN': getStorage('tt'),
      'X-PI-RESTAURANT-ID': getStorage('restaurant_id'),
      lang: navigator.language,
      'If-None-Match': ''
    },
    url: urlRemoveQuantity,
    method: 'POST',
    data: request
  })
  return resp.data.response
})

export const removeOrderItem = createAsyncThunk('removeOrderItem', async (request) => {
  const resp = await Axios({
    headers: {
      Authorization: `Bearer ${getStorage('st')}`,
      'X-PI-TABLE-TOKEN': getStorage('tt'),
      'X-PI-RESTAURANT-ID': getStorage('restaurant_id'),
      lang: navigator.language,
      'If-None-Match': ''
    },
    url: urlDeleteOrderItem,
    method: 'POST',
    data: request
  })
  return resp.data.response
})

export const deleteAllItems = createAsyncThunk('deleteAllItems', async () => {
  const resp = await Axios({
    headers: {
      Authorization: `Bearer ${getStorage('st')}`,
      'X-PI-TABLE-TOKEN': getStorage('tt'),
      'X-PI-RESTAURANT-ID': getStorage('restaurant_id'),
      lang: navigator.language,
      'If-None-Match': ''
    },
    url: urlDeleteAllItems,
    method: 'POST',
  })
  return resp.data.response
})

export const shoppingCartSlice = createSlice({
  name: SHOPPING_CART_NAME,
  initialState,

  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(confirmUserOrder.pending, (state) => {
      state.loading = true
    })
    builder.addCase(confirmUserOrder.fulfilled, (state, action) => {
      state.response = action.payload
      state.loading = false
    })
    builder.addCase(confirmUserOrder.rejected, (state) => {
      state.status = 'error'
      state.loading = false
      state.error = true
      state.response = null
      LanguageDialog({ from: confirmUserOrder })
      window.location.reload()
    })
    builder.addCase(removeOrderItem.pending, (state) => {
      state.loading = true
    })
    builder.addCase(removeOrderItem.fulfilled, (state, action) => {
      state.response = action.payload
      state.loading = false
    })
    builder.addCase(removeOrderItem.rejected, (state) => {
      state.status = 'error'
      state.loading = false
      state.error = true
      state.response = null
      LanguageDialog({ from: removeOrderItem })
      window.location.reload()
    })
    builder.addCase(incrementQuantity.pending, (state) => {
      state.loading = true
    })
    builder.addCase(incrementQuantity.fulfilled, (state, action) => {
      state.response = action.payload
      state.loading = false
    })
    builder.addCase(incrementQuantity.rejected, (state) => {
      state.status = 'error'
      state.loading = false
      state.error = true
      state.response = null
      LanguageDialog({ from: incrementQuantity })
    })
    builder.addCase(decrementQuantity.pending, (state) => {
      state.loading = true
    })
    builder.addCase(decrementQuantity.fulfilled, (state, action) => {
      state.response = action.payload
      state.loading = false
    })
    builder.addCase(decrementQuantity.rejected, (state) => {
      state.status = 'error'
      state.loading = false
      state.response = null
      state.error = true
      LanguageDialog({ from: decrementQuantity })
      window.location.reload()
    })
    builder.addCase(deleteAllItems.pending, (state) => {
      state.loading = true
    })
    builder.addCase(deleteAllItems.fulfilled, (state, action) => {
      state.response = action.payload
      state.loading = false
    })
    builder.addCase(deleteAllItems.rejected, (state) => {
      state.status = 'error'
      state.loading = false
      state.response = null
      state.error = true
      LanguageDialog({ from: deleteAllItems })
      window.location.reload()
    })
  }
})

export const shoppingCartActions = {
  confirmUserOrder,
  decrementQuantity,
  removeOrderItem,
  incrementQuantity,
  deleteAllItems
}

export default shoppingCartSlice.reducer
