import Button from '@mui/material/Button'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Typography from '@mui/material/Typography'
import styles from './CallWaiter.module.css'
import { useDispatch } from 'react-redux'
import { collection, doc, onSnapshot } from 'firebase/firestore'
import { db } from '../../../firebase'
import { tablesOrdersCollection } from '../../../utils/collections_names_firestore'
import { callWaiterActions } from '../CallWaiter.slice'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import getLocalKeyWords from '../../../utils/local_keywords'
import { getStorage } from '../../../utils/storage'
import { BootstrapDialogStyle } from '../../../components/dialogs/BootstrapDialog.style'

const CallWaiter = () => {
  const [open, setOpen] = useState(false)
  const [isWaiterCalled, setCallWaiter] = useState(false)
  const dispatch = useDispatch()
  const intl = useIntl()

  const isCalledWaiter = () => {
    const document = doc(collection(db, tablesOrdersCollection), getStorage('table_id'))
    onSnapshot(document, (snapshot) => {
      if (snapshot.data() !== null && snapshot.data() !== undefined && snapshot.data().call_waiter !== null && snapshot.data().call_waiter !== undefined) {
        setCallWaiter(snapshot.data().call_waiter)
      }
    })
  }

  useEffect(() => {isCalledWaiter()}, [])

  const callWaiter = () => {
    dispatch(callWaiterActions.callWaiter())
  }

  const stopCallWaiter = () => {
    dispatch(callWaiterActions.stopCallWaiter())
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    if (isWaiterCalled === false) {
      callWaiter()
    }
    if (isWaiterCalled === true) {
      stopCallWaiter()
    }
  }

  const dialogDismiss = () => {
    setOpen(false)
  }

  return (<div>
    <Button
      variant="contained"
      sx={{
        fontSize: 8,
        paddingX: '2px',
        paddingY: '4px',
        borderRadius: '8rem',
        backgroundColor: (isWaiterCalled === false ? '#757575' : 'primary')
      }} size="small"
      onClick={handleClickOpen}>
      {intl.formatMessage({ id: 'help' })}
    </Button>
    <BootstrapDialogStyle
      onClose={dialogDismiss}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogContent>
        {/*<img src={Waiter} alt="CallWaiter"
             style={{ alignContent: 'center', alignSelf: 'center', alignItems: 'center', paddingLeft: 50 }}/>*/}
        <Typography gutterBottom fontWeight={'bold'}>
          {isWaiterCalled ? intl.formatMessage({ id: 'stopCallWaiterMessage' }, { waiter: getLocalKeyWords('waiter') }) : intl.formatMessage({ id: 'callWaiterInfo' }, { waiter: getLocalKeyWords('waiter') })}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          className={styles.button}
          fullWidth={true}
          color="secondary"
          variant="contained"
          sx={{ borderRadius: '.4rem' }}
          onClick={handleClose}
        >
          {isWaiterCalled ? intl.formatMessage({ id: 'buttonStopCallWaiter' }, { waiter: getLocalKeyWords('waiter') }) : intl.formatMessage({ id: 'callWaiter' }, { waiter: getLocalKeyWords('waiter') })}
        </Button>
      </DialogActions>
    </BootstrapDialogStyle>
  </div>)
}

export default CallWaiter