import * as React from 'react'
import Slider from 'react-slick'
import styles from './HeaderProduct.module.css'
import Card from '@mui/material/Card'
//import videoFile from '../../../assets/video/Screen Recording 2023-08-22 at 7.45.46 PM.mov'
//import VideoPlayer from '../../VideoPlayer'
import ProductImage from './ProductImage'

const ProductCarousel = ({ image, mobileView }) => {

  const settings = {
    dots: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    adaptiveHeight: true
  }

  return (
    <Slider {...settings} >
      <div>
        <Card className={styles.card}>
          <ProductImage image={image} mobileView={mobileView}/>
        </Card>
      </div>
      {/*<div>
        <Card className={styles.card}>
          <ProductImage image={image} mobileView={mobileView}/>
        </Card>
      </div>
      <div>
        <Card className={styles.card}>
          <VideoPlayer videoFile={videoFile} mobileView={mobileView}/>
        </Card>
      </div>*/}
    </Slider>
  )
}

export default ProductCarousel
