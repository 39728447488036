import * as React from 'react'
import Card from '@mui/material/Card'
import styles from './HeaderProduct.module.css'
import EmptyImage from '../../../assets/images/image_empty.png'
import Image from '../../Image'

const ProductHeader = ({ image, mobileView }) => {
  return (
    <Card
      className={styles.card}
    >
      {image && <Image
        style={{
          height: mobileView === true ? 260 : 500,
          borderRadius: '.4rem',
          width: '100%',
          objectFit: 'cover',
        }}
        src={image}
        placeholderImg={EmptyImage}
      />}
      {mobileView === false && <p style={{ height: '32px' }}/>}
    </Card>
  )
}

export default ProductHeader
