import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Axios from 'axios'
import { urlGetDirections } from '../../apis/directions'
import LanguageDialog from '../../components/dialogs/LanguageDialog'
import { getStorage } from '../../utils/storage'

export const DIRECTIONS_NAME = 'directions'

const initialState = {
  directions: null,
  status: 'idle',
  loading: false,
}

export const getDirections = createAsyncThunk('getDirections', async () => {
  const resp = await Axios({
    headers: {
      Authorization: `Bearer ${getStorage('st')}`,
      'X-PI-TABLE-TOKEN': getStorage('tt'),
      'X-PI-RESTAURANT-ID': getStorage('restaurant_id'),
      lang: navigator.language,
      'If-None-Match': ''
    },
    url: urlGetDirections,
    method: 'GET'
  })
  return resp.data.response
})

export const directionsSlice = createSlice({
  name: DIRECTIONS_NAME,
  initialState,

  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getDirections.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getDirections.fulfilled, (state, action) => {
      state.directions = action.payload
      state.loading = false
    })
    builder.addCase(getDirections.rejected, (state) => {
      state.status = 'error'
      state.loading = false
      state.directions = null
      LanguageDialog({ from: getDirections })
    })
  }
})

export const directionsActions = { getDirections }

export default directionsSlice.reducer
