import header from '../../assets/images/header.png'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import { useIntl } from 'react-intl'

const OpenBrowserPage = () => {
  const intl = useIntl()

  return (
    <Grid
      container
      justifyContent={'center'}
      padding={5}
      direction={'column'}
      alignContent={'center'}
      alignItems={'center'}
      marginTop={'100px'}
    >
      <img src={header} alt={'logo'} width={200} height={100}/>
      <Typography variant="h6" fontWeight="bold" align={'center'} paddingTop={10}>
        {intl.formatMessage({ id: 'browserError' })}
      </Typography>
    </Grid>
  )
}

export default OpenBrowserPage
