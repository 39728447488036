import { CardMedia, DialogTitle, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import DialogContent from '@mui/material/DialogContent'
import Loading from '../../../components/Loading'
import { useIntl } from 'react-intl'
import { useDropzone } from 'react-dropzone'
import { deliveryOrderActions } from '../../deliveryOrder/deliveryOrder.slice'
import Grid from '@mui/material/Grid'
import { useDispatch } from 'react-redux'
import { InfoDialog } from '../../../components/dialogs/InfoDialog'
import { deliveryOrderStyles } from '../../../pages/deliveryOrder/DeliveryOrder/DeliveryOrderPage.style'
import Button from '@mui/material/Button'
import Upload from '../../../assets/images/upoload.png'
import { useEffect } from 'react'
import { useState } from 'react'
import { BootstrapDialogStyle } from '../../../components/dialogs/BootstrapDialog.style'
import { PaymentProofDialogStyle, PaymentProofDialogStyles } from './PaymentProofDialog.style'
import { colorShade } from '../../../utils/colorShade'
import { theme } from '../../../index'
import DialogActions from '@mui/material/DialogActions'

const PaymentProofDialog = ({ myOrder, openDialog, setOpenDialog, mobileView }) => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [file, setFile] = useState(null)
  const [preview, setPreview] = useState(null)
  const dispatch = useDispatch()
  const styles = deliveryOrderStyles()
  const classes = PaymentProofDialogStyles()
  const { getRootProps, getInputProps, open } = useDropzone({
    accept: {
      'image/png': ['.png', '.jpg', '.jpeg'],
    }, onDropAccepted: (file) => {
      setFile(file)
      setPreview(URL.createObjectURL(file[0]))
    }, noClick: true
  })

  const uploadFile = (file) => {
    if (file !== null) {
      setLoading(true)
      dispatch(deliveryOrderActions.uploadReceipt({
        'id': myOrder.user_order_id,
        'payload': file[0]
      })).unwrap().then(() => {
        setFile(null)
        setPreview(null)
        InfoDialog({text: intl.formatMessage({ id: 'paymentProofUploaded' })})
      })
    } else {
      InfoDialog({ text: intl.formatMessage({ id: 'paymentProofRequired' }) })
    }
  }

  useEffect(() => {
    setLoading(false)
  }, [myOrder?.wire_transfer_receipt_url])

  const handleClose = () => {
    setOpenDialog(false)
  }

  const dialogDismiss = (event, reason) => {
    if (reason && reason === 'backdropClick')
      return
    setOpenDialog(false)
  }

  const openFile = () => {
    if (myOrder.pickup_at_restaurant === false && myOrder.fixed_delivery_amount === false && (myOrder.delivery_amount === null || myOrder.delivery_amount === undefined)) {
      InfoDialog({ text: intl.formatMessage({ id: 'paymentProofValidate' }) })
    } else {
      open()
    }
  }

  return (
    <div>
      <Grid container alignItems="center" justifyContent="center">
        <Button
          variant={'contained'}
          size={'large'}
          onClick={setOpenDialog}
          fullWidth
          style={PaymentProofDialogStyle(mobileView).container}
          sx={{
            '&:hover': {
              backgroundColor: colorShade(theme.palette.info.main, -60),
            }
          }}
        >
          {myOrder?.wire_transfer_receipt_url ? intl.formatMessage({ id: 'updatePaymentProof' }) : intl.formatMessage({ id: 'paymentProof' })}
        </Button>
      </Grid>
      <BootstrapDialogStyle
        onClose={dialogDismiss}
        aria-labelledby="customized-dialog-title"
        open={openDialog}
        style={PaymentProofDialogStyle(mobileView).dialog}
        fullScreen={mobileView}
      >
        <DialogTitle className={styles.container}>
          <IconButton aria-label="close" onClick={handleClose} className={classes.iconButton}>
            <CloseIcon/>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div style={PaymentProofDialogStyle(mobileView).container2}>
            {loading && <Loading/>}
            <CardMedia
              className={styles.photo}
              component="img"
              image={preview !== null ? preview : myOrder.wire_transfer_receipt_url ? myOrder.wire_transfer_receipt_url : Upload}
              alt="receipt"
            />

          </div>
        </DialogContent>
        <DialogActions>
          <Grid container direction={'row'} alignItems={'center'} >
            <Grid item xs={6}>
              <section className="container">
                <div {...getRootProps({ className: 'dropzone' })}>
                  <input {...getInputProps()} />
                  <Grid container direction={'column'}>
                    <Grid container item xs={12} onClick={openFile}>
                      <Button
                        variant={'contained'}
                        size={'large'}
                        fullWidth
                        sx={{
                          '&:hover': {
                            backgroundColor: colorShade(theme.palette.primary.main, -60),
                          },
                          marginRight: '8px'
                        }}
                      >
                        {intl.formatMessage({ id: 'attach' })}
                      </Button>
                    </Grid>
                    {/*<Grid container direction="row" justifyContent="flex-start" alignItems="center">
                    <Grid item xs={5}>
                      <p style={{ fontSize: 13 }}>{intl.formatMessage({ id: 'uploadImage' })}</p>
                    </Grid>
                    <Grid item xs={3}>
                      {myOrder?.wire_transfer_receipt_url ? <img src={Check} alt="check" className={styles.check}/> :
                        <div/>}
                    </Grid>
                  </Grid>*/}
                  </Grid>
                </div>
              </section>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant={'contained'}
                size={'large'}
                onClick={() => {uploadFile(file)}}
                fullWidth
                disabled={file === null}
                sx={{
                  '&:hover': {
                    backgroundColor: colorShade(theme.palette.primary.main, -60),
                  },
                }}
              >
                {intl.formatMessage({ id: 'upload' })}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </BootstrapDialogStyle>
    </div>
  )
}

export default PaymentProofDialog
