import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Axios from 'axios'
import {
  urlDeleteDirection,
  urlRegisterDirection,
  urlReverseGeocode, urlSelectDirection,
  urlValidateDirection
} from '../../apis/directions'
import LanguageDialog from '../../components/dialogs/LanguageDialog'
import { getStorage } from '../../utils/storage'

export const DIRECTION_NAME = 'direction'

const initialState = {
  aproxAddress: null,
  status: 'idle',
  loading: false,
  direction: null,
  validateDirection: null,
  selectDirection: null
}

export const reverseGeocode = createAsyncThunk('reverseGeocode', async (params) => {
  const resp = await Axios({
    headers: {
      Authorization: `Bearer ${getStorage('st')}`,
      'X-PI-TABLE-TOKEN': getStorage('tt'),
      'X-PI-RESTAURANT-ID': getStorage('restaurant_id'),
      lang: navigator.language,
      'If-None-Match': ''
    },
    url: urlReverseGeocode,
    method: 'GET',
    params: params
  })
  return resp.data.response
})

export const registerDirection = createAsyncThunk('registerDirection', async (params) => {
  const resp = await Axios({
    headers: {
      Authorization: `Bearer ${getStorage('st')}`,
      'X-PI-TABLE-TOKEN': getStorage('tt'),
      'X-PI-RESTAURANT-ID': getStorage('restaurant_id'),
      lang: navigator.language,
      'If-None-Match': ''
    },
    url: urlRegisterDirection,
    method: 'POST',
    data: params
  })
  return resp.data.response
})

export const deleteDirection = createAsyncThunk('deleteDirection', async (params) => {
  const resp = await Axios({
    headers: {
      Authorization: `Bearer ${getStorage('st')}`,
      'X-PI-TABLE-TOKEN': getStorage('tt'),
      'X-PI-RESTAURANT-ID': getStorage('restaurant_id'),
      lang: navigator.language,
      'If-None-Match': ''
    },
    url: urlDeleteDirection(params.id),
    method: 'DELETE',
    data: params
  })
  return resp.data.response
})

export const validateDirection = createAsyncThunk('validateDirection', async (params, rejectWithValue) => {
  try {
    const resp = await Axios({
      headers: {
        Authorization: `Bearer ${getStorage('st')}`,
        'X-PI-TABLE-TOKEN': getStorage('tt'),
        'X-PI-RESTAURANT-ID': getStorage('restaurant_id'),
        lang: 'es',
        'If-None-Match': ''
      },
      url: urlValidateDirection(params.id),
      method: 'GET',
      data: params
    })
    return resp.data.response
  } catch (e) {
    return rejectWithValue(e.response.data)
  }
})

export const selectDirection = createAsyncThunk('selectDirection', async (params, rejectWithValue) => {
  try {
    const resp = await Axios({
      headers: {
        Authorization: `Bearer ${getStorage('st')}`,
        'X-PI-TABLE-TOKEN': getStorage('tt'),
        'X-PI-RESTAURANT-ID': getStorage('restaurant_id'),
        lang: 'es',
        'If-None-Match': ''
      },
      url: urlSelectDirection(params.orderId, params.id),
      method: 'PUT',
      data: params
    })
    return resp.data.response
  } catch (e) {
    return rejectWithValue(e.response.data)
  }
})

export const directionSlice = createSlice({
  name: DIRECTION_NAME,
  initialState,

  reducers: {
    resetAproxAddress: (state) => {
      state.aproxAddress = null
    },
  },

  extraReducers: (builder) => {
    builder.addCase(reverseGeocode.pending, (state) => {
      state.loading = true
    })
    builder.addCase(reverseGeocode.fulfilled, (state, action) => {
      state.aproxAddress = action.payload
      state.loading = false
    })
    builder.addCase(reverseGeocode.rejected, (state) => {
      state.status = 'error'
      state.loading = false
      state.aproxAddress = null
      LanguageDialog({ from: reverseGeocode })
    })
    builder.addCase(registerDirection.pending, (state) => {
      state.loading = true
    })
    builder.addCase(registerDirection.fulfilled, (state, action) => {
      state.direction = action.payload
      state.loading = false
    })
    builder.addCase(registerDirection.rejected, (state) => {
      state.status = 'error'
      state.loading = false
      state.direction = null
      LanguageDialog({ from: registerDirection })
    })
    builder.addCase(deleteDirection.pending, (state) => {
      state.loading = true
    })
    builder.addCase(deleteDirection.fulfilled, (state) => {
      state.loading = false
    })
    builder.addCase(deleteDirection.rejected, (state) => {
      state.status = 'error'
      state.loading = false
      LanguageDialog({ from: deleteDirection })
    })
    builder.addCase(validateDirection.pending, (state) => {
      state.loading = true
    })
    builder.addCase(validateDirection.fulfilled, (state, action) => {
      state.loading = false
      state.validateDirection = action.payload
    })
    builder.addCase(validateDirection.rejected, (state, action) => {
      state.status = 'error'
      state.loading = false
      state.validateDirection = null
      state.error = action.payload
    })
    builder.addCase(selectDirection.pending, (state) => {
      state.loading = true
    })
    builder.addCase(selectDirection.fulfilled, (state, action) => {
      state.loading = false
      state.selectDirection = action.payload
    })
    builder.addCase(selectDirection.rejected, (state, action) => {
      state.status = 'error'
      state.loading = false
      state.selectDirection = null
      state.error = action.payload
      LanguageDialog({ from: selectDirection })
    })
  }
})

export const directionActions = { reverseGeocode, registerDirection, deleteDirection, validateDirection, selectDirection }

export const { resetAproxAddress } = directionSlice.actions

export default directionSlice.reducer
