import { maxButtonWebWidth, maxButtonWidth } from '../../../utils/screen_utils'
import { makeStyles } from '@mui/styles'

export const PaymentProofDialogStyles = makeStyles(theme => ({
  dialog: {
    '& .MuiDialog-container': {
      '& .MuiPaper-root': {
        width: '100%',
        maxWidth: '600px',
      },
    },
    zIndex: 100
  },
  iconButton: {
    position: 'absolute',
    right: 10,
    top: 8,
    marginBottom: 24,
    color: theme.palette.grey[500],
  },
}))

export const PaymentProofDialogStyle = (mobileView) => ({
  container: {
    borderRadius: '.4rem',
    position: 'fixed',
    zIndex: 1,
    bottom: '58px',
    marginLeft: '8px',
    marginRight: '8px',
    width: mobileView ? maxButtonWidth : maxButtonWebWidth,
    fontSize: '12px',
    backgroundColor: 'mediumblue'
  },
  dialog: {
    '& .MuiDialogContainer': {
      '& .MuiPaper-root': {
        width: '100%',
        maxWidth: '600px',
      },
    },
    zIndex: 2
  },
  container2: {
    width: '100%',
    minHeight: mobileView ? '700px' : '500px'
  }
})
