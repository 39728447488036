import { DialogTitle, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import DialogContent from '@mui/material/DialogContent'
import styles from './ScheduleDialog.module.css'
import { BootstrapDialogStyle } from '../../../components/dialogs/BootstrapDialog.style'
import { ScheduleDialogStyle } from './Schedule.style'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { convertTime, getDayName } from '../../../utils/date_utils'
import { useIntl } from 'react-intl'

const ScheduleDialog = ({ open, setOpen, mobileView }) => {
  const classes = ScheduleDialogStyle()
  const restaurantData = JSON.parse(localStorage.getItem('restaurant_data'))
  const intl = useIntl()

  const handleClose = () => {
    setOpen(false)
  }

  const dialogDismiss = () => {
    setOpen(false)
  }

  const schedule = () => {
    return restaurantData?.delivery === true ?
      restaurantData?.restaurant?.delivery_schedule_by_ranges :
      restaurantData?.restaurant?.table_schedule_by_ranges
  }

  return (
    <div>
      <BootstrapDialogStyle
        onClose={dialogDismiss}
        aria-labelledby="customized-dialog-title"
        open={open}
        className={classes.dialog}
        fullScreen={mobileView}
      >
        <DialogTitle className={styles.title}>
          <IconButton aria-label="close" onClick={handleClose} className={classes.iconButton}>
            <CloseIcon/>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container direction={'column'}>
            <Grid item container xs={12} direction={'row'} justifyContent={'center'} className={styles.container}>
              <Typography variant="h6">
                {intl.formatMessage({ id: 'attentionSchedule' })}
              </Typography>
            </Grid>
            {schedule().map((day) => (
              <Grid key={day.day} item container xs={12} direction={'row'} className={styles.grid}>
                <Grid item>
                  <Typography variant="body1" fontWeight={'bold'}>
                    {getDayName(day.day)}
                  </Typography>
                </Grid>
                <Grid>
                  {day?.ranges_hour.map((schedule, index) => (
                    <Grid key={index} item container direction={'column'}>
                      <Grid item container direction={'row'}>
                        <Typography variant="body1">
                          {`${convertTime(schedule.start_time)} - ${convertTime(schedule.end_time)}`}
                        </Typography>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
      </BootstrapDialogStyle>
    </div>
  )
}
export default ScheduleDialog
