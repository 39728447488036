import { maxButtonWebWidth, maxButtonWidth, maxMobileWidth, maxWebWidth } from '../../utils/screen_utils'

export const OrderDetailPageStyle = (mobileView) => ({
  container: {
    justifyContent: 'center',
    display: mobileView ? 'inherit' : 'flex'
  },
  container2: {
    width: mobileView ? maxMobileWidth : maxWebWidth
  },
  button: {
    borderRadius: '.4rem',
    width: mobileView ? maxButtonWidth : maxButtonWebWidth,
    position: 'fixed',
    bottom: 0,
    marginBottom: '8px',
    marginX: '8px'
  }
})