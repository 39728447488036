import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { InfoDialog } from '../../../components/dialogs/InfoDialog'
import getLocalKeyWords from '../../../utils/local_keywords'
import { productActions } from '../../product/product.slice'
import { ListItemText, Typography } from '@mui/material'
import styles from './CategoryTab.module.css'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import {
  bodyBackground,
  premiumBottomBackground,
  premiumCategoryPresentation,
  premiumHeaderBackground, premiumHeaderBackgroundW
} from '../../../components/Background.style'
import { neutralizeBack, revivalBack } from '../../../utils/navigation_util'
import PremiumProductItem from './PremiumProductItem'
import { isBusinessClose } from '../../../utils/schedule_util'

const PremiumCategoryTab = ({ category, mobileView, readOnly, index, setOpenBusinessDialog, setOpen }) => {
  const intl = useIntl()
  const restaurantData = useSelector((store) => store.restaurant.restaurantData)
  const dispatch = useDispatch()

  const navigateToProduct = (product) => {
    if (isBusinessClose(restaurantData) || restaurantData.restaurant.frozen_account === true) {
      InfoDialog({
        text: intl.formatMessage({ id: 'restaurantInactive' }, {
          restaurant: restaurantData.restaurant.name,
          order: (getLocalKeyWords('order_plural') || '').toLowerCase()
        })
      })
    } else {
      if (readOnly() === true) {
        dispatch(productActions.getProductAsync(product._id)).then(() => {
          setOpenBusinessDialog(true)
          neutralizeBack(() => handleCloseBusinessDialog())
        })
      } else {
        if (product?.has_toppings === true || product?.description) {
          dispatch(productActions.getProductAsync(product._id)).then(() => {
            setOpen(true)
            neutralizeBack(() => handleCloseProductDialog())
          })
        } else {
          const params = {
            order_item: {
              amount: product.price,
              product_id: product._id,
              product_image: product.image,
              product_name: product.name,
              quantity: 1,
            }
          }
          dispatch(productActions.submitProductAsync(params)).then(() => {
            InfoDialog({ text: intl.formatMessage({ id: 'productAdded' }, { product: getLocalKeyWords('product') }) })
          })
        }
      }
    }
  }

  const hasCategoryImagePresentation = () => {
    return category?.wallpaper_image !== null && category?.wallpaper_image !== undefined && category?.wallpaper_image !== '/wallpaper_images/original/missing.png'
  }

  const handleCloseProductDialog = () => {
    setOpen(false)
    revivalBack()
  }

  const handleCloseBusinessDialog = () => {
    setOpenBusinessDialog(false)
    revivalBack()
  }

  const hasHeaderImage = () => {
    return category?.header_image !== null && category?.header_image !== undefined && category?.header_image !== '/header_images/original/missing.png'
  }

  const hasFooterImage = () => {
    return category?.footer_image !== null && category?.footer_image !== undefined && category?.footer_image !== '/footer_images/original/missing.png'
  }

  const hasFrontImage = () => {
    return restaurantData.restaurant?.premium_menu_front_page !== null && restaurantData.restaurant?.premium_menu_front_page !== undefined && restaurantData.restaurant?.premium_menu_front_page !== '/premium_menu_front_pages/original/missing.png'
  }

  return (
    <Grid container direction="column" justifyContent="center" alignItems="center">
      {hasFrontImage() === true && index === 0 &&
        <div style={premiumCategoryPresentation(mobileView, restaurantData.restaurant?.premium_menu_front_page)}/>}
      {hasHeaderImage() === true &&
        <ListItemText
          className={styles.header1}
          style={premiumHeaderBackground(mobileView, category?.header_image)}
        />}
      {hasHeaderImage() === false &&
        <ListItemText
          className={styles.header1}
          style={premiumHeaderBackgroundW(mobileView, restaurantData.restaurant?.premium_menu_wallpaper)}
          primary={
            <Typography variant="body2" fontFamily={restaurantData.restaurant?.premium_menu_typography} className={styles.font3}>
              {category.name}
            </Typography>
          }
        />}
      <div style={bodyBackground(mobileView, restaurantData.restaurant?.premium_menu_wallpaper)}>
        <Grid container justifyItems={'center'} justifyContent={'center'} alignItems={'center'}>
          {category.products.map((product) => (
            <Grid key={product._id} item xs={10} sm={10} lg={10} container>
              <PremiumProductItem
                product={product}
                navigateToProduct={(product) => navigateToProduct(product)}
                mobileView={mobileView}
                fontFamily={restaurantData.restaurant?.premium_menu_typography}
              />
              <Divider variant="middle" className={styles.divider}/>
            </Grid>
          ))}
        </Grid>
      </div>
      {hasFooterImage() === true && <div style={premiumBottomBackground(mobileView, category?.footer_image)}/>}
      {hasCategoryImagePresentation() === true &&
        <Grid item style={premiumCategoryPresentation(mobileView, category?.wallpaper_image)}/>
      }
    </Grid>
  )
}

export default PremiumCategoryTab
