import Grid from '@mui/material/Grid'
import { Avatar, Typography } from '@mui/material'
import styles from './Header.module.css'
import CallWaiter from '../../callWaiter/components/CallWaiter'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import Address from '../../../assets/icons/ic_address.png'
import Image from '../../../components/Image'
import { HeaderStyle, ScheduleStyle } from './Header.style'
import { convertTime } from '../../../utils/date_utils'
import { useWindowScrollPositions } from '../../../utils/useWindowScrollPositions'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCartOutlined'
import { StyledMiniBadge } from './SubHeaderMenu.style'
import SearchIcon from '@mui/icons-material/Search'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import Red from '../../../assets/images/red.png'
import Green from '../../../assets/images/green.png'
import { neutralizeBack, revivalBack } from '../../../utils/navigation_util'
import BackButton from '../../../components/buttons/BackButton'

const Header = ({
  restaurant,
  isDelivery,
  mobileView,
  count,
  navigateToShoppingCart,
  setOpenScheduleDialog,
  scrollToSearchBar,
  backAction,
}) => {
  const { scrollY } = useWindowScrollPositions()
  const [isHovering, setIsHovering] = useState(false)
  const intl = useIntl()

  const getNewCurrentDaySchedule = () => {
    const currentDate = new Date()
    if (isDelivery === true) {
      if (restaurant?.delivery_schedule_by_ranges !== null && restaurant?.delivery_schedule_by_ranges !== undefined) {
        if (restaurant?.delivery_schedule_by_ranges[currentDate.getDay()] !== null && restaurant?.delivery_schedule_by_ranges[currentDate.getDay()] !== undefined) {
          if (restaurant?.delivery_schedule_by_ranges[currentDate.getDay()]?.ranges_hour[0]?.start_time?.hour !== null) {
            return true
          }
        }
      }
    } else {
      if (restaurant?.table_schedule_by_ranges !== null && restaurant?.table_schedule_by_ranges !== undefined) {
        if (restaurant?.table_schedule_by_ranges[currentDate.getDay()] !== null && restaurant?.table_schedule_by_ranges[currentDate.getDay()] !== undefined) {
          if (restaurant?.table_schedule_by_ranges[currentDate.getDay()]?.ranges_hour[0]?.start_time?.hour !== null) {
            return true
          }
        }
      }
    }
    return null
  }

  const currentDaySchedules = () => {
    const currentDate = new Date()
    if (isDelivery === true) {
      if (restaurant?.delivery_schedule_by_ranges !== null && restaurant?.delivery_schedule_by_ranges !== undefined) {
        if (restaurant?.delivery_schedule_by_ranges[currentDate.getDay()] !== null && restaurant?.delivery_schedule_by_ranges[currentDate.getDay()] !== undefined) {
          if (restaurant?.delivery_schedule_by_ranges[currentDate.getDay()]?.ranges_hour) {
            return (
              <Grid container>
                <Grid item container direction={mobileView ? 'row' : 'column'}>
                  <Grid item container justifyContent={'flex-end'} direction={mobileView ? 'row' : 'column'}>
                    {restaurant?.delivery_schedule_by_ranges[currentDate.getDay()]?.ranges_hour.map((shedule, index) => (
                      <Typography key={index} variant="body1" className={styles.font3} fontSize={'12px'}>
                        {`${convertTime(shedule.start_time)} - ${convertTime(shedule.end_time)}`}
                      </Typography>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            )
          }
        }
      }
    } else {
      if (restaurant?.table_schedule_by_ranges !== null && restaurant?.table_schedule_by_ranges !== undefined) {
        if (restaurant?.table_schedule_by_ranges[currentDate.getDay()] !== null && restaurant?.table_schedule_by_ranges[currentDate.getDay()] !== undefined) {
          if (restaurant?.table_schedule_by_ranges[currentDate.getDay()]?.ranges_hour) {
            return (
              <Grid container>
                <Grid item container direction={mobileView ? 'row' : 'column'}>
                  <Grid item container justifyContent={'flex-end'} direction={mobileView ? 'row' : 'column'}>
                    {restaurant?.table_schedule_by_ranges[currentDate.getDay()]?.ranges_hour.map((shedule, index) => (
                      <Typography key={index} variant="body1" className={styles.font3} fontSize={'12px'}>
                        {`${convertTime(shedule.start_time)} - ${convertTime(shedule.end_time)}`}
                      </Typography>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            )
          }
        }
      }
    }
  }

  const isBusinessActivated = () => {
    return isDelivery === true ?
      restaurant?.delivery_schedule_by_ranges_activated :
      restaurant?.table_schedule_by_ranges_activated
  }

  const handleMouseOver = () => {
    setIsHovering(true)
  }

  const handleMouseOut = () => {
    setIsHovering(false)
  }

  const handleCloseScheduleDialog = () => {
    setOpenScheduleDialog(false)
    revivalBack()
  }

  return (
    <div>
      <BackButton backAction={backAction}/>
      {isBusinessActivated() === false && isDelivery === true && restaurant?.banner && !restaurant?.banner?.includes('missing') &&
        <Grid container justifyContent={'flex-end'}>
          <Typography variant="body2" className={styles.deliveryOffBanner}>
            {intl.formatMessage({ id: 'inactive' })}
          </Typography>
        </Grid>}
      {restaurant?.banner && !restaurant?.banner?.includes('missing') &&
        <Image src={restaurant?.banner} style={HeaderStyle(mobileView).image}/>}
      <Grid direction="row" container spacing={1} className={styles.grid1}>
        <Grid
          container
          className={styles.grid3}
          direction={'row'}
          item
          xs={isBusinessActivated() === false && isDelivery === true ? 11 : 8} sm={8} lg={8}
        >
          <Avatar style={HeaderStyle(mobileView, restaurant).avatar} alt={restaurant?.name} src={restaurant?.logo}/>
          <Typography variant="body1" style={HeaderStyle(mobileView).font}>
            {restaurant?.name}
          </Typography>
          {(restaurant?.banner === null || restaurant?.banner === undefined || restaurant?.banner?.includes('missing')) &&
            isBusinessActivated() === false && isDelivery === true &&
            <Typography variant="body2" className={styles.deliveryOff}>
              {intl.formatMessage({ id: 'inactive' })}
            </Typography>}
        </Grid>
        <Grid
          item
          xs={isBusinessActivated() === false && isDelivery === true ? 1 : 4} lg={4} sm={4}
          container
          className={styles.container}
        >
          {isDelivery === false && restaurant?.waiter_assistance === true && <div className={styles.div1}>
            <CallWaiter/>
          </div>}
          {mobileView === false && scrollToSearchBar &&
            <SearchIcon fontSize={'large'} onClick={scrollToSearchBar} className={styles.search}/>
          }
          {scrollY < 418 && mobileView === false && navigateToShoppingCart &&
            <StyledMiniBadge overlap="circular" badgeContent={count} color="error" className={styles.div1}>
              <ShoppingCartIcon fontSize={'large'} onClick={() => navigateToShoppingCart()}
                                className={styles.shopping}/>
            </StyledMiniBadge>}
        </Grid>
      </Grid>
      {isDelivery === true && restaurant?.read_only === false &&
        <Grid direction={mobileView ? 'column' : 'row'} container spacing={1} className={styles.grid2}>
          {restaurant?.address &&
            <Grid item xs={mobileView ? 12 : 7} direction="row" container alignItems={'center'}>
              <div className={styles.div2}>
                <img src={Address} alt="address" className={styles.icons}/>
                <Typography variant="body1" className={styles.font2}>
                  {restaurant?.address}
                </Typography>
              </div>
            </Grid>}
          {getNewCurrentDaySchedule() &&
            <Grid
              item xs={mobileView ? 12 : 5}
              direction="row"
              container
              style={HeaderStyle(mobileView).schedule}
              onClick={() => {
                setOpenScheduleDialog(true)
                neutralizeBack(() => handleCloseScheduleDialog())
              }}
            >
              <div onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} style={ScheduleStyle(isHovering).schedule}>
                <img src={isBusinessActivated() === true ? Green : Red} alt="a" className={styles.icons}/>
                {currentDaySchedules()}
                <InfoIcon fontSize={'small'} className={styles.icon}/>
              </div>
            </Grid>}
        </Grid>}
      {isDelivery === false && getNewCurrentDaySchedule() &&
        <Grid
          item xs={12}
          direction="row"
          container
          style={HeaderStyle(mobileView).schedule}
          onClick={() => {
            setOpenScheduleDialog(true)
            neutralizeBack(() => handleCloseScheduleDialog())
          }}
        >
          <div onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} style={ScheduleStyle(isHovering).schedule}>
            <img src={isBusinessActivated() === true ? Green : Red} alt="a" className={styles.icons}/>
            {currentDaySchedules()}
            <InfoIcon fontSize={'small'} className={styles.icon}/>
          </div>
        </Grid>
      }
    </div>)
}

export default Header
