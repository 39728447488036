import React, { useEffect, useState } from 'react'
import { collection, doc, getDoc, onSnapshot, query, where } from 'firebase/firestore'
import { db } from '../../../firebase'
import Loading from '../../../components/Loading'
import {
  deliveriesCollection,
  usersOrdersCollection
} from '../../../utils/collections_names_firestore'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import PaymentMethodUserOrder
  from '../../../components/deliveryOrder/UserOrderDeliveryPayBill/PaymentMethodUserOrder'
import TipsUserOrder from '../../../components/deliveryOrder/UserOrderDeliveryPayBill/TipsUserOrder'
import HeaderUserOrderPay from '../../../components/deliveryOrder/UserOrderDeliveryPayBill/HeaderUserOrderPay'
import SummaryUserOrderPay from '../../../components/deliveryOrder/UserOrderDeliveryPayBill/SummaryUserOrderPay'
import BottomUserOrderPay from '../../../components/deliveryOrder/UserOrderDeliveryPayBill/BottomUserOrderPay'
import { payDeliveryBillActions } from '../../../features/payDeliveryBill/PayDeliveryBill.slice'
import { updateTheme } from '../../../features/theme/Theme.slice'
import { InfoDialog } from '../../../components/dialogs/InfoDialog'
import { useIntl } from 'react-intl'
import Header from '../../../features/menu/components/Header'
import { isPayedDelivery } from '../../../utils/order_state_extension'
import { background, container } from '../../../components/Background.style'
import { getStorage } from '../../../utils/storage'
import ScheduleDialog from '../../../features/menu/components/ScheduleDialog'
import EditOrderItemDialog from '../../../features/menu/components/EditOrderItemDialog'
import { productActions } from '../../../features/product/product.slice'
import { neutralizeBack, revivalBack } from '../../../utils/navigation_util'

const UserOrderDeliveryPayBillPage = () => {
  const [table, setTable] = useState(null)
  const [myOrder, setMyOrder] = useState(null)
  const [loading, setLoading] = useState(false)
  const [payment, setPayment] = useState('empty')
  const loadingPickup = useSelector((store) => store.payDeliveryBill.loading)
  const restaurantData = JSON.parse(localStorage.getItem('restaurant_data'))
  const [tip, setTip] = useState(0)
  const [tipAmount, setTipAmount] = useState(0)
  const navigate = useNavigate()
  const intl = useIntl()
  const dispatch = useDispatch()
  const [mobileView, setMobileView] = useState(false)
  const [openScheduleDialog, setOpenScheduleDialog] = useState(false)
  const [openEditProductDialog, setOpenEditOrderItemDialog] = useState(false)
  const [orderItemToEdit, setOrderItemToEdit] = useState(null)
  const product = useSelector((store) => store.product.value)
  const { state } = useLocation()
  let { fromOrder } = state || {}

  const setResponsiveness = () => {
    return window.innerWidth < 900
      ? setMobileView(true)
      : setMobileView(false)
  }

  useEffect(() => {
    setResponsiveness()
    window.addEventListener('resize', () => setResponsiveness())
    return () => {
      window.removeEventListener('resize', () => setResponsiveness())
    }
  }, [])

  useEffect(() => {
    const getTable = async () => {
      try {
        setLoading(true)
        const document = await getDoc(doc(collection(db, deliveriesCollection), getStorage('table_id')))
        setTable(document.data())

        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }

    const getMyOrder = async () => {
      try {
        setLoading(true)
        const orderQuery = query(collection(db, usersOrdersCollection), where('user_id', '==', getStorage('user_id')), where('table_id', '==', getStorage('table_id')))
        onSnapshot(orderQuery, (querySnapshot) => {
          querySnapshot.docChanges().forEach((change) => {
            if (change.type === 'added' || change.type === 'modified') {
              setMyOrder(change.doc.data())
              if (isPayedDelivery(change.doc.data()['state'])) {
                navigate(`/deliveryOrder/${getStorage('table_uid')}`, { state: { fromOrder: true } })
              }
            }
          })
        })
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }
    if (fromOrder === undefined) {
      navigate(`/${getStorage('table_uid')}`)
    }
    dispatch(updateTheme())
    getMyOrder()
    getTable()
  }, [])

  useEffect(() => {
    if (orderItemToEdit !== null) {
      dispatch(productActions.getProductAsync(orderItemToEdit.product_id)).then(() => {
        setOpenEditOrderItemDialog(true)
        neutralizeBack(() => handleCloseEditOrderItemDialog())
      })
    }
  }, [orderItemToEdit])

  const handleCloseEditOrderItemDialog = () => {
    setOpenEditOrderItemDialog(false)
    setOrderItemToEdit(null)
    revivalBack()
  }

  const resumeClick = () => {
    if (payment !== 'empty') {
      const pickup = getStorage('pickup')
      let params
      const tipPercent = tip > -1 ? tip : 0
      const tiAmount = tip > -1 ? 0 : tipAmount / 100
      if (pickup === 'true') {
        params = {
          user_order: {
            payment_method: payment,
            tip_percentage: tip > tipPercent,
            pickup_at_restaurant: pickup,
            tip_amount: tiAmount,
          }
        }
      } else {
        const direction = JSON.parse(getStorage('direction'))
        params = {
          user_order: {
            payment_method: payment,
            tip_percentage: tipPercent,
            pickup_at_restaurant: pickup,
            direction_id: direction?._id,
            tip_amount: tiAmount,
            delivery_indications: getStorage('delivery_indications')
          }
        }
      }
      dispatch(payDeliveryBillActions.pay(params)).unwrap().then(() => navigate(`/deliveryOrder/${getStorage('table_uid')}`, { state: { fromOrder: true } }))
    } else {
      InfoDialog({ text: intl.formatMessage({ id: 'mustAddPaymentType' }) })
    }
  }

  return (loading || loadingPickup ? <Loading/> :
      <div style={background(mobileView)}>
        <div style={container(mobileView)}>
          <Header
            restaurant={restaurantData?.restaurant}
            isDelivery={restaurantData?.delivery}
            mobileView={mobileView}
            setOpenScheduleDialog={setOpenScheduleDialog}
          />
          {myOrder &&
            <HeaderUserOrderPay
              myOrder={myOrder}
              table={table}
              mobileView={mobileView}
              setOrderItemToEdit={setOrderItemToEdit}
            />
          }
          {table && <PaymentMethodUserOrder table={table} payment={payment} setPayment={setPayment}/>}
          {(table && table?.tip_activated === true && myOrder && table.tip_options && table.tip_options.length > 0) &&
            <TipsUserOrder myOrder={myOrder} table={table} tip={tip} setTip={setTip} setTipAmount={setTipAmount}/>}
          {myOrder &&
            <SummaryUserOrderPay myOrder={myOrder} tip={tip} tipAmount={tipAmount} table={table}/>}
          {openScheduleDialog === true &&
            <ScheduleDialog mobileView={mobileView} open={openScheduleDialog} setOpen={setOpenScheduleDialog}/>
          }
          {openEditProductDialog === true &&
            <EditOrderItemDialog
              mobileView={mobileView}
              orderItem={orderItemToEdit}
              open={openEditProductDialog}
              handleClose={handleCloseEditOrderItemDialog}
              product={product}
            />
          }
          <footer>
            <BottomUserOrderPay resumeClick={resumeClick} mobileView={mobileView}/>
          </footer>
        </div>
      </div>
  )
}

export default UserOrderDeliveryPayBillPage
