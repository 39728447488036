import Grid from '@mui/material/Grid'
import * as React from 'react'
import PremiumCategoryTab from './PremiumCategoryTab'
import BackButton from '../../../components/buttons/BackButton'
import PremiumScrollSpyTabs from './PremiumScrollSpyTabs'
import PremiumSubHeader from './PremiumSubHeader'
import PremiumHeader from './PremiumHeader'
import styles from './PremiumMenu.module.css'

const PremiumMenu = ({
  filteredCategories,
  userOrder,
  mobileView,
  setOpen,
  onBackPressed,
  restaurantData,
  count,
  navigateToShoppingCart,
  filterText,
  setFilterText,
  readOnly,
  setOpenBusinessDialog
}) => {

  return (
    <div>
      <PremiumHeader
        restaurant={restaurantData?.restaurant}
        mobileView={mobileView}
        count={count}
        navigateToShoppingCart={readOnly() ? undefined : navigateToShoppingCart}
      />
      <PremiumSubHeader
        navigateToShoppingCart={readOnly() ? undefined : navigateToShoppingCart}
        mobileView={mobileView}
        count={count}
        filterText={filterText}
        setFilterText={setFilterText}
        restaurant={restaurantData?.restaurant}
        backAction={onBackPressed}
        readOnly={readOnly}
      />
      <Grid container direction="column" justifyContent="center" alignItems="center">
        <BackButton backAction={onBackPressed}/>
        <Grid item container direction="column" justifyContent="center" alignItems="center">
          <div className={styles.div}>
            <PremiumScrollSpyTabs
              tabsInScroll={filteredCategories.map((category, index) => ({
                text: category.name,
                component:
                  <PremiumCategoryTab
                    category={category}
                    mobileView={mobileView}
                    userOrder={userOrder}
                    setOpen={setOpen}
                    readOnly={readOnly}
                    index={index}
                    setOpenBusinessDialog={setOpenBusinessDialog}
                  />,
                hash: category._id,
                index: index
              }))}
              mobileView={mobileView}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default PremiumMenu
