import {
  Card,
  Grid,
  Typography,
  darken, IconButton, List,
} from '@mui/material'
import { blueGrey } from '@mui/material/colors'
import { styled } from '@mui/system'
import { Add, Remove, Delete, Edit } from '@mui/icons-material'
import { formatCost } from '../../../utils'
import styles from './ShoppingListItem.module.css'
import { decrementQuantity, incrementQuantity, removeOrderItem } from '../ShoppingCart.slice'
import { useDispatch } from 'react-redux'
import EmptyImage from '../../../assets/images/image_empty.png'
import Image from '../../../components/Image'
import getLocalKeyWords from '../../../utils/local_keywords'

const CardComponent = styled(Card)(
  ({ theme }) =>
    `
  color: ${theme.palette.primary.main};
  background-color:${blueGrey};
  border-radius:.4rem;
  padding:.5rem;
  :hover {
    color: ${darken(theme.palette.primary.main, 0.2)};
  }
`
)

const ShoppingListItem = ({ orderItem, canEdit, mobileView, setOrderItemToEdit }) => {
  const dispatch = useDispatch()
  return (
    <div className={styles.container}>
      <CardComponent sx={{ width: '100%', display: 'flex' }}>
        <Grid container justifyContent="space-between" alignContent={'center'}>
          {orderItem.product_image && !orderItem.product_image.toString().includes('missing') &&
            <Grid container item xs={4} sm={3} alignItems="center">
              <Image
                style={{ height: 110, borderRadius: '.4rem', width: '100%', objectFit: 'cover' }}
                src={orderItem.product_image}
                placeholderImg={EmptyImage}
              />
            </Grid>}
          <Grid
            container
            item
            alignItems={'flex-end'}
            className={styles.grid}
            xs={orderItem.product_image && !orderItem.product_image.toString().includes('missing') ? 8 : 12}
            sm={orderItem.product_image && !orderItem.product_image.toString().includes('missing') ? 9 : 12}>
            <Grid container justifyContent="space-between" justifyItems="space-between" alignItems={'center'}>
              <Grid container item xs={11} sm={11} justifyContent="flex-start">
                <Typography variant="body1" sx={{ textOverflow: 'ellipsis' }} fontWeight={'bold'}>
                  {orderItem.product_name}
                </Typography>
              </Grid>

              {setOrderItemToEdit !== undefined && <Grid container item xs={1} sm={1} justifyContent="flex-end">
                <IconButton
                  size={'small'}
                  disabled={!canEdit}
                  onClick={() => {
                    setOrderItemToEdit(orderItem)
                  }}
                >
                  <Edit fontSize={mobileView === false ? 'large' : 'medium'}/>
                </IconButton>
              </Grid>}
            </Grid>
            <Grid item container direction={'row'}>
              <Grid item xs={6}>
                <List>
                  {orderItem.topping_groups_array !== undefined && orderItem.topping_groups_array !== null && orderItem.topping_groups_array.map((group, index) => (
                    <div key={index}>
                      <Typography variant="body2" color={'black'} fontWeight={'bold'} fontSize={11}>
                        {group?.group_name}
                      </Typography>
                      {group.toppings !== undefined && group.toppings !== null && group.toppings.map((topping, index) => (
                        <Typography key={index} variant="body1" color={'black'} fontSize={11}>
                          {topping?.quantity} • {topping?.name} {topping.price !== null && topping.price !== undefined ? topping.price.cents > 0 && `+ ${formatCost(topping?.price)}` : ''}
                        </Typography>
                      ))}
                    </div>
                  ))}
                </List>
                {orderItem.client_observations !== '' && orderItem.client_observations !== null &&
                  <div>
                    <Typography variant="body2" fontSize={10} color={'black'} fontWeight={'bold'}>
                      {getLocalKeyWords('comment_title')}
                    </Typography>
                    <Typography variant="body2" fontSize={11} color={'black'}>
                      {orderItem?.client_observations}
                    </Typography>
                  </div>
                }
                <Typography variant={mobileView === true ? 'body1' : 'h6'} className={styles.amount}>
                  {formatCost(orderItem.amount)}
                </Typography>
              </Grid>
              <Grid item xs={6} container alignItems="flex-end" justifyContent="flex-end">
                <div className={styles.row}>
                  <IconButton
                    size={'small'}
                    disabled={!canEdit}
                    onClick={() => {
                      if (orderItem.quantity > 1) {
                        const params = {
                          order_item: {
                            order_item_id: orderItem.id,
                          }
                        }
                        dispatch(decrementQuantity(params))
                      } else {
                        const params = {
                          order_item: {
                            order_item_id: orderItem.id,
                          }
                        }
                        dispatch(removeOrderItem(params))
                      }
                    }}
                  >
                    {orderItem.quantity > 1 ?
                      <Remove fontSize={mobileView === false ? 'large' : 'medium'}/> :
                      <Delete fontSize={mobileView === false ? 'large' : 'medium'}/>
                    }
                  </IconButton>
                  <span className={styles.value}>{orderItem.quantity}</span>
                  <IconButton
                    size={'small'}
                    disabled={!canEdit}
                    onClick={() => {
                      const params = {
                        order_item: {
                          product_id: orderItem.product_id,
                          product_name: orderItem.product_name,
                          quantity: 1,
                          client_observations: orderItem.client_observations,
                          toppings: orderItem.toppings != null
                            ? orderItem.toppings
                            : orderItem.toppings_array
                        }
                      }
                      dispatch(incrementQuantity(params))
                    }}
                  >
                    <Add fontSize={mobileView === false ? 'large' : 'medium'}/>
                  </IconButton>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardComponent>
    </div>
  )
}

export default ShoppingListItem
