import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Axios from 'axios'
import { urlSendOrderToRestaurant } from '../../apis/delivery'
import LanguageDialog from '../../components/dialogs/LanguageDialog'
import { getStorage } from '../../utils/storage'

export const PAY_DELIVERY_BILL_NAME = 'payDeliveryBill'

const initialState = {
  payBillValue: null,
  status: 'idle',
  loading: false,
}

export const pay = createAsyncThunk('pay', async (params, {rejectWithValue}) => {
  try {
    const resp = await Axios({
      headers: {
        Authorization: `Bearer ${getStorage('st')}`,
        'X-PI-TABLE-TOKEN': getStorage('tt'),
        'X-PI-RESTAURANT-ID': getStorage('restaurant_id'),
        lang: navigator.language,
        'If-None-Match': ''
      },
      url: urlSendOrderToRestaurant,
      method: 'PUT',
      data: params
    })
    return resp.data.response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})


export const payDeliveryBillSlice = createSlice({
  name: PAY_DELIVERY_BILL_NAME,
  initialState,

  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(pay.pending, (state) => {
      state.loading = true
    })
    builder.addCase(pay.fulfilled, (state, action) => {
      state.payBillValue = action.payload
      state.loading = false
    })
    builder.addCase(pay.rejected, (state) => {
      state.status = 'error'
      state.loading = false
      state.payBillValue = null
      LanguageDialog({ from: pay })
    })
  }
})

export const payDeliveryBillActions = { pay }

export default payDeliveryBillSlice.reducer
