import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './app/App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import { store } from './app/store'
import { createTheme, ThemeProvider } from '@mui/material'
import Wrapper from './Wrapper'
import { FirebaseNotifications } from './components/FirebaseNotifications'
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { envConfig } from './envConfig'
import GoogleFonts from './components/GoogleFonts'

Sentry.init({
  dsn: envConfig.environment === 'prod' && "https://74c54dc501fa4907a769c29fed20f47e@o1424175.ingest.sentry.io/6771997",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

export const theme = createTheme({
  palette: {

  },
  typography: {
    fontSize: 12,
    fontFamily: '\'Montserrat\', sans-serif',
    fontWeightLight: 200,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
  },
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <Wrapper>
        <FirebaseNotifications>
          <App/>
          <GoogleFonts/>
        </FirebaseNotifications>
      </Wrapper>
    </ThemeProvider>
  </Provider>
)

reportWebVitals()
