import { Fab } from '@mui/material'
import { StyledBadge } from './SubHeaderMenu.style'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCartOutlined'
import Grid from '@mui/material/Grid'
import React from 'react'

const PremiumShoppingCart = ({ count, navigateToShoppingCart }) => {

  return (
    <Grid container item xs={1.8} sm={1.8} md={1.8} lg={1.8} xl={1.8} justifyContent={'center'} alignItems={'center'}>
      <Fab size="small" color="primary" aria-label="add" onClick={navigateToShoppingCart}>
        <StyledBadge overlap="circular" badgeContent={count} color="error">
          <ShoppingCartIcon onClick={() => navigateToShoppingCart()} sx={{ paddingY: '12px' }}/>
        </StyledBadge>
      </Fab>
    </Grid>
  )
}

export default PremiumShoppingCart
