import styles from './Header.module.css'
import { Avatar, Typography } from '@mui/material'
import { HeaderStyle } from './Header.style'
import Grid from '@mui/material/Grid'
import React from 'react'
import PremiumShoppingCart from './PremiumShoppingCart'

const PremiumHeader = ({
  restaurant,
  mobileView,
  count,
  navigateToShoppingCart,
}) => {

  return (
    <div>
      {mobileView === false &&
        <Grid container className={styles.grid5} direction={'row'}>
          <Grid container item xs={10} alignItems='center' wrap="nowrap">
            <Avatar style={HeaderStyle(mobileView, restaurant).avatar2} alt={restaurant?.name} src={restaurant?.logo}/>
            <Typography variant="body1" style={HeaderStyle(mobileView).font2}>
              {restaurant?.name}
            </Typography>
          </Grid>
          {navigateToShoppingCart &&
            <PremiumShoppingCart navigateToShoppingCart={navigateToShoppingCart} count={count}/>}
        </Grid>
      }
    </div>
  )
}

export default PremiumHeader
