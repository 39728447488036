import GoogleFontLoader from 'react-google-font'
import React from 'react'

const GoogleFonts = () => {

  return (
    <GoogleFontLoader
      fonts={[
        {
          font: 'Kablammo',
        },
        {
          font: 'Oswald',
        },
        {
          font: 'Aclonica',
        },
        {
          font: 'Abril Fatface',
        },
        {
          font: 'Alegreya Sans',
        },
        {
          font: 'Architects Daughter',
        },
        {
          font: 'Archivo',
        },
        {
          font: 'Archivo Narrow',
        },
        {
          font: 'Bebas Neue',
        },
        {
          font: 'Bitter',
        },
        {
          font: 'Bree Serif',
        },
        {
          font: 'Bungee',
        },
        {
          font: 'Cabin',
        },
        {
          font: 'Cairo',
        },
        {
          font: 'Coda',
        },
        {
          font: 'Comfortaa',
        },
        {
          font: 'Comic Neue',
        },
        {
          font: 'Cousine',
        },
        {
          font: 'Croissant One',
        },
        {
          font: 'Faster One',
        },
        {
          font: 'Forum',
        },
        {
          font: 'Great Vibes',
        },
        {
          font: 'Heebo',
        },
        {
          font: 'Inconsolata',
        },
        {
          font: 'Josefin Slab',
        },
        {
          font: 'Lato',
        },
        {
          font: 'Lato',
        },
        {
          font: 'Libre Baskerville',
        },
        {
          font: 'Lobster',
        },
        {
          font: 'Lora',
        },
        {
          font: 'Merriweather',
        },
        {
          font: 'Montserrat',
        },
        {
          font: 'Mukta',
        },
        {
          font: 'Nunito',
        },
        {
          font: 'Offside',
        },
        {
          font: 'Open Sans',
        },
        {
          font: 'Overlock',
        },
        {
          font: 'Pacifico',
        },
        {
          font: 'Playfair Display',
        },
        {
          font: 'Poppins',
        },
        {
          font: 'Raleway',
        },
        {
          font: 'Roboto',
        },
        {
          font: 'Roboto Mono',
        },
        {
          font: 'Source Sans Pro',
        },
        {
          font: 'Space Mono',
        },
        {
          font: 'Spicy Rice',
        },
        {
          font: 'Squada One',
        },
        {
          font: 'Sue Ellen Francisco',
        },
        {
          font: 'Trade Winds',
        },
        {
          font: 'Ubuntu',
        },
        {
          font: 'Varela',
        },
        {
          font: 'Vollkorn',
        },
        {
          font: 'Work Sans',
        },
        {
          font: 'Zilla Slab',
        },
      ]}
      subsets={['cyrillic-ext', 'greek']}
    />
  )
}

export default GoogleFonts
