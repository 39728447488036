import { Card, Grid, IconButton, Typography } from '@mui/material'
import AdjustIcon from '@mui/icons-material/Adjust'
import DeleteIcon from '@mui/icons-material/Delete'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { useState } from 'react'

export const AddressesItem = ({ index, selectedIndex, direction, onPressed, onDeletePressed }) => {
  const [state, setstate] = useState({ isOptionsOpen: false })

  return (<Grid item>
    <Card sx={{ p: 1, boxShadow: 'none' }}>
      <Grid container alignItems={'center'} justifyContent="space-between" sx={{ color: 'black' }}>
        <Grid item xs={11.2} onClick={onPressed}>
          <Typography variant="subtitle2">
            {direction.address} {direction.name}
          </Typography>
        </Grid>
        <Grid item xs={0.8}>
          <IconButton
            aria-label="delete"
            color="primary"
            onClick={() => {
              if (state.isOptionsOpen) {
                onDeletePressed()
              }
              setstate({ ...state, isOptionsOpen: !state.isOptionsOpen })
            }}>
            {index === selectedIndex ? (<AdjustIcon/>) : state.isOptionsOpen ? (<DeleteIcon/>) : (<MoreVertIcon/>)}
          </IconButton>
        </Grid>
      </Grid>
    </Card>
  </Grid>)
}