import { maxButtonWebWidth1, maxButtonWidth } from '../../../utils/screen_utils'

export const OrderSomethingElseButtonStyle = (mobileView) => ({
  button: {
    borderRadius: '.4rem',
    paddingTop: '8px',
    paddingBottom: '8px',
    marginBottom: '16px',
    width: mobileView ? maxButtonWidth : maxButtonWebWidth1
  }
})