import * as React from 'react'
import { Box, ListItemText, ListSubheader, Stack, Step, StepLabel, Stepper, Typography } from '@mui/material'
import {
  MyQuantity,
  deliveryOrderStyles,
  HoverOrder
} from '../../../pages/deliveryOrder/DeliveryOrder/DeliveryOrderPage.style'
import Grid from '@mui/material/Grid'
import { formatCost } from '../../../utils'
import Button from '@mui/material/Button'
import {
  stateNameDelivery,
  canCancelOrder,
  stateColorDelivery,
  getAllDeliveriesStateName, getAllDeliveriesStates, empty
} from '../../../utils/order_state_extension'
import EmptyImage from '../../../assets/images/image_empty.svg'
import Image from '../../Image'
import { useIntl } from 'react-intl'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { useNavigate } from 'react-router-dom'
import getLocalKeyWords from '../../../utils/local_keywords'
import { getStorage } from '../../../utils/storage'
import { QontoConnector, QontoStepIcon } from '../../Stepper'
import { useState } from 'react'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import Collapse from '@mui/material/Collapse'

const MyDeliveryOrder = ({ myOrder, onCancelOrder, mobileView }) => {
  const styles = deliveryOrderStyles()
  const intl = useIntl()
  const navigate = useNavigate()
  const steps = getAllDeliveriesStateName()
  const [isHovering, setIsHovering] = useState(false)
  const [isOpenCollapse, setOpenCollapse] = useState(false)

  const openOrderDetail = () => {
    navigate(`/orderDetail/${getStorage('table_uid')}`)
  }

  const getCurrentState = () => {
    const states = getAllDeliveriesStates()
    if (states !== undefined && states !== null && states.length > 0) {
      return states.indexOf(myOrder.state)
    }
    return empty
  }

  const handleMouseOver = () => {
    setIsHovering(true)
  }

  const handleMouseOut = () => {
    setIsHovering(false)
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <ListSubheader className={styles.headerSection}>
        <Grid container alignItems="center" justifyContent={'space-between'}>
          <Grid item xs={mobileView === true ? 6 : 2}>
            <ListItemText
              color="black"
              primary={
                <Typography variant="body1" color="black" fontWeight={'bold'}>
                  {intl.formatMessage({ id: 'myOrder' }, { order: getLocalKeyWords('order') })}
                </Typography>
              }
            />
          </Grid>
          {mobileView === false &&
            <Grid container item xs={10} justifyItems={'flex-end'} justifyContent={'flex-end'}>
              <Stack sx={{ width: '100%' }} spacing={4}>
                <Stepper alternativeLabel activeStep={getCurrentState()} connector={<QontoConnector/>}>
                  {steps.map((label, index) => (
                    <Step key={index}>
                      <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Stack>
            </Grid>}
          {mobileView === true &&
            <Grid
              item xs={6}
              onMouseOver={handleMouseOver}
              onMouseOut={handleMouseOut}
              container
              alignItems={'center'}
              style={HoverOrder(isHovering).hover}
              onClick={() => setOpenCollapse(prev => !prev)}
            >
              <Grid item xs={4} container justifyItems={'center'} justifyContent={'center'} paddingRight={'4px'}>
                <Typography variant="body1" fontWeight={'bold'} color={'black'} fontSize={'12px'}>
                  {intl.formatMessage({ id: 'state' })}:
                </Typography>
              </Grid>
              <Grid item xs={6} container justifyItems={'center'} justifyContent={'flex-end'}>
                <Grid
                  container
                  bgcolor={stateColorDelivery(myOrder.state)}
                  borderRadius={4}
                  justifyContent={'center'}
                  padding={'5px'}
                >
                  <Typography
                    variant="body1"
                    color={'white'}
                    fontWeight={'bold'}
                    alignItems={'center'}
                    fontSize={'12px'}
                    align={'center'}
                  >
                    {stateNameDelivery(myOrder.state)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container xs={1} paddingLeft={'2px'}>
                <InfoIcon fontSize={'medium'} sx={{ color: '#666666' }}/>
              </Grid>
            </Grid>
          }
        </Grid>
      </ListSubheader>
      {mobileView === true &&
        <Collapse in={isOpenCollapse} timeout="auto" unmountOnExit>
          <Grid container direction={'column'}>
            <Grid item container xs={12} direction={'row'} justifyContent={'center'} className={styles.container}>
              <Typography variant="h6" color={'black'}>
                {intl.formatMessage({ id: 'orderStatus' })}
              </Typography>
            </Grid>
            <Grid container item={12} justifyContent={'center'} alignItems={'center'} marginTop={'32px'}>
              <Stepper activeStep={getCurrentState()} orientation="vertical">
                {steps.map((step, index) => (
                  <Step key={index}>
                    <StepLabel StepIconComponent={QontoStepIcon}>{step}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Grid>
          </Grid>
        </Collapse>
      }
      <Grid container direction={'row'} justifyContent={'space-between'} alignItems={'center'} paddingY={'20px'}>
        <Grid item xs={1.4} md={1} lg={0.4}>
          <ListItemText
            color="black"
            primary={
              <MyQuantity sx={{ width: 30, height: 30, backgroundColor: 'primary.main' }}>
                {myOrder.order_items.length}
              </MyQuantity>
            }
          />
        </Grid>
        <Grid item xs={4.6} md={5} lg={5.6}>
          <Grid item container>
            {myOrder.order_items.slice(0, 3).map((product, index) => (
              product.product_image && !product.product_image.toString().includes('missing') &&
              <Image
                key={index}
                style={{ height: 48, borderRadius: '.8rem', width: 48, objectFit: 'cover' }}
                src={product.product_image}
                placeholderImg={EmptyImage}
              />))}
          </Grid>
        </Grid>
        <Grid item xs={4} lg={4.6} container justifyContent={'flex-end'} alignItems={'center'} sx={{ paddingTop: '8px' }}>
          <Typography gutterBottom variant="h6" align="center">
            {formatCost(myOrder.total_amount)}
          </Typography>
        </Grid>
        <Grid item xs={1} lg={0.4} container justifyContent={'flex-end'} alignItems={'center'} sx={{ paddingRight: '16px' }}>
          <ArrowForwardIosIcon color="black" fontSize="small" onClick={openOrderDetail}/>
        </Grid>
      </Grid>
      {canCancelOrder(myOrder.state) &&
        <div style={{
          display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '10px', paddingBottom: '10px'
        }}>
          <Button variant="text" color="error" onClick={onCancelOrder}>
            {intl.formatMessage({ id: 'cancelOrder' }, { order: getLocalKeyWords('order') })}
          </Button></div>}
    </Box>
  )
}

export default MyDeliveryOrder
