import styles from './ProductItem.module.css'
import Grid from '@mui/material/Grid'
import { ListItemText, Typography } from '@mui/material'
import { formatCost } from '../../../utils'
import Image from '../../../components/Image'
import EmptyImage from '../../../assets/images/image_empty.png'
import Button from '@mui/material/Button'
import { useIntl } from 'react-intl'
import { ProductItemStyle } from './ProductItem.style'
import { colorShade } from '../../../utils/colorShade'
import { theme } from '../../../index'
import { useSubscription } from '../../../utils/useSubscription'
import { isBusinessClose } from '../../../utils/schedule_util'

const ProductItem = ({ product, restaurantData, navigateToProduct, mobileView, readOnly }) => {
  const intl = useIntl()
  const isPremium = useSubscription()

  const isDisabled = () => {
    return isBusinessClose(restaurantData) ||
      (restaurantData?.delivery === false && isPremium === false) ||
      (restaurantData?.restaurant?.frozen_account === true)
  }

  const canHideButton = () => {
    return (restaurantData?.delivery === false && isPremium === false)
  }

  const canNavigateToProduct = () => {
    if (!(restaurantData?.delivery === false && isPremium === false)) {
      navigateToProduct(product)
    }
  }

  return (
    <Grid key={product._id} direction="row" container className={styles.item} onClick={() => canNavigateToProduct()}>
      <Grid item xs={7} sm={9}>
        <ListItemText
          primary={
            <Typography variant="body2" className={styles.font1}>
              {product.name}
            </Typography>
          }
          secondary={
            <Typography
              variant="body1"
              dangerouslySetInnerHTML={{ __html: product.description.length > 100 ? `${product.description.toString().substring(0, 100)}...` : product.description.toString() }}
              className={styles.font2}/>
          }
        />
        <Typography variant="body1" className={styles.font3}>
          {formatCost(product.price)}
        </Typography>
      </Grid>
      <Grid container item xs={5} sm={3} className={styles.grid1}>
        <Grid container direction={'column'} className={styles.grid2}>
          <Image style={ProductItemStyle(mobileView).image} src={product.image} placeholderImg={EmptyImage}/>
          {canHideButton() === false &&
            <Button
              fullWidth
              type="submit"
              variant="contained"
              color="info"
              style={ProductItemStyle(mobileView).button}
              sx={{
                '&:hover': {
                  backgroundColor: colorShade(theme.palette.info.main, -60),
                }
              }}
              disabled={isDisabled()}
            >
              {readOnly() === true ? intl.formatMessage({ id: 'details' }) : intl.formatMessage({ id: 'add' })}
            </Button>}
        </Grid>
      </Grid>
    </Grid>
  )
}
export default ProductItem
