import {
  Checkbox,
  DialogTitle, FormControl,
  FormHelperText,
  Grid,
  IconButton, InputLabel, Link,
  ListItemText, MenuItem,
  Radio, Select
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import DialogContent from '@mui/material/DialogContent'
import getLocalKeyWords from '../../../utils/local_keywords'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import { useIntl } from 'react-intl'
import styles from './UpdateUserDataForm.module.css'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import PhoneInput from 'react-phone-input-2'
import { deliveryOrderActions } from '../../deliveryOrder/deliveryOrder.slice'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Loading from '../../../components/Loading'
import { useEffect, useState, useMemo } from 'react'
import { directionsActions } from '../../directions/Directions.slice'
import { AddressesItem } from '../../addresses/components/AddressesItem'
import { ConfirmDialog } from '../../../components/dialogs/ConfirmDialog'
import { directionActions } from '../../direction/Direction.slice'
import { userActions } from '../user.slice'
import { shoppingCartActions } from '../../shoppingCart/ShoppingCart.slice'
import AddIcon from '@mui/icons-material/Add'
import 'react-phone-input-2/lib/bootstrap.css'
import { getStorage, saveStorage } from '../../../utils/storage'
import { BootstrapDialogStyle } from '../../../components/dialogs/BootstrapDialog.style'
import { UserDialogStyle } from './UpdateUserDataDialog.style'
import { UpdateUserDataDialogStyle } from './UpdateUserDataDialog.style'
import { colorShade } from '../../../utils/colorShade'
import { theme } from '../../../index'
import { InfoDialog } from '../../../components/dialogs/InfoDialog'
import { clean } from '../../../utils/util'

const UpdateUserDataDialog = ({ mobileView, open, setOpen, setOpenAddressesDialog }) => {
  const intl = useIntl()
  const [name, setName] = useState(getStorage('user_name'))
  const [deliveryIndication, setDeliveryIndication] = useState(null)
  const [state, setstate] = useState({
    phoneNumber: JSON.parse(getStorage('phone_number'))?.user?.phone_number,
    phoneCode: JSON.parse(getStorage('phone_number'))?.user?.phone_code,
  })
  const [email, setEmail] = useState('')
  const [deliveryOrPickup, setDeliveryOrPickup] = useState('delivery')
  const [errorValidation, setErrorValidation] = useState(false)
  const [helperText, setHelperText] = useState('')
  const dispatch = useDispatch()
  const loading = useSelector((store) => store.user.loading)
  const loadingUserData = useSelector((store) => store.deliveryOrder.loading)
  const navigate = useNavigate()
  const directions = useSelector((store) => store.directions.directions)
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const restaurantData = JSON.parse(localStorage.getItem('restaurant_data'))
  const [ally, setAlly] = useState(null)
  const savedAllie = JSON.parse(getStorage(`${restaurantData.restaurant._id}_allie`))
  const validRegex = /^(([^<>()[\],;:\s@]+(\.[^<>()[\],;:\s@]+)*)|(.+))@(([^<>()[\],;:\s@]+\.)+[^<>()[\],;:\s@]{2,})$/i
  const classes = UserDialogStyle()
  const [termsAndConditions, setTermsAndConditions] = useState(false)

  useEffect(() => {
    if (restaurantData?.delivery === true) {
      getDirections()
    }
  }, [])

  useEffect(() => {
    if (directions !== null && directions !== undefined) {
      setSelectedIndex(directions.length - 1)
    }
  }, [directions])

  useMemo(() => {
    const phoneData = JSON.parse(getStorage('phone_number'))
    const deliveryIndicationFromLocalStorage = getStorage('delivery_indications')
    const emailFromLocalStorage = getStorage('email')
    if (phoneData) {
      setstate({ ...state, phoneNumber: phoneData.user.phone_number, phoneCode: phoneData.user.phone_code })
      const params = {
        phone_code: state.phoneCode,
        phone_number: state.phoneNumber
      }
      dispatch(userActions.checkForSession(params)).unwrap().then((value) => {
        checkDataFormTermsAndConditions(value)
      })
    }
    if (deliveryIndicationFromLocalStorage) {
      setDeliveryIndication(deliveryIndicationFromLocalStorage)
    }
    if (emailFromLocalStorage) {
      setEmail(emailFromLocalStorage)
      const params = {
        contact_email: emailFromLocalStorage
      }
      dispatch(userActions.checkForSession(params)).unwrap().then((value) => {
        checkDataFormTermsAndConditions(value)
      })
    }
  }, [])

  const checkDataFormTermsAndConditions = (data) => {
    if (data !== undefined && data !== null) {
      if (data.response !== undefined && data.response !== null && Object.keys(data.response).length > 0) {
        setTermsAndConditions(data.response.accepted_tyc)
      } else {
        setTermsAndConditions(false)
      }
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  const dialogDismiss = (event, reason) => {
    if (reason && reason === 'backdropClick')
      return
    setOpen(false)
  }

  const handleOnChangePhoneNumber = (value, data) => {
    setstate({ ...state, phoneNumber: value.slice(data.dialCode.length), phoneCode: `+${data.dialCode}` })
    setErrorValidation(false)
    handlePhoneOrEmailChange({ phoneNumber: value.slice(data.dialCode.length), phoneCode: `+${data.dialCode}` })
  }

  const handleRadioChange = (event) => {
    setDeliveryOrPickup(event.target.value)
    setHelperText(' ')
    setErrorValidation(false)
  }

  const navigateToDeliveryPayBill = (data) => {
    dispatch(deliveryOrderActions.updateDeliveryInfo({
      'id': getStorage('user_order_id',),
      'data': data
    })).unwrap().then(() => {
      saveUserData(data)
      saveStorage('pickup', false)
      saveStorage('direction', JSON.stringify(directions[selectedIndex]))
      saveStorage(`${restaurantData.restaurant._id}_allie`, JSON.stringify(ally))
      navigate(`/userOrderDeliveryPayBill/${getStorage('table_uid')}`, { state: { fromOrder: true } })
    })

  }

  const updateUserData = (data, directionSelected) => {
    dispatch(deliveryOrderActions.updateDeliveryInfo({
      'id': getStorage('user_order_id',),
      'data': data
    })).unwrap().then(() => {
      saveUserData(data)
      if (deliveryOrPickup === 'delivery') {
        saveStorage('pickup', false)
        if (selectedIndex !== -1) {
          saveStorage('direction', JSON.stringify(directionSelected))
          navigate(`/userOrderDeliveryPayBill/${getStorage('table_uid')}`, { state: { fromOrder: true } })
        } else {
          setOpenAddressesDialog(true)
        }
      } else if (deliveryOrPickup === 'pickup') {
        saveStorage('pickup', true)
        navigate(`/userOrderDeliveryPayBill/${getStorage('table_uid')}`, { state: { fromOrder: true } })
      }
    })
  }

  const showPickupDialog = (data) => {
    ConfirmDialog({
      text: intl.formatMessage({ id: 'errorValidateDirectionMessage' }, { restaurant: getLocalKeyWords('restaurant').toLowerCase() }),
      confirmButtonText: intl.formatMessage({ id: 'pickupOnSite' }),
      cancelButtonText: intl.formatMessage({ id: 'changeDirection' }),
      onConfirm: () => {
        data.pickup_at_restaurant = true
        dispatch(deliveryOrderActions.updateDeliveryInfo({
          'id': getStorage('user_order_id',),
          'data': data
        })).unwrap().then(() => {
          saveUserData(data)
          saveStorage('pickup', true)
          saveStorage(`${restaurantData.restaurant._id}_allie`, JSON.stringify(ally))
          saveStorage('direction', null)
          navigate(`/userOrderDeliveryPayBill/${getStorage('table_uid')}`, { state: { fromOrder: true } })
        })
      },
      onCancel: () => {}
    })
  }

  const openAddressDialog = () => {
    const tempData = {
      user: {
        phone_number: state.phoneNumber,
        phone_code: state.phoneCode,
        first_name: name?.trim(),
        contact_email: email,
        accepted_tyc: true
      },
      pickup_at_restaurant: deliveryOrPickup === 'pickup',
      direction_id: selectedIndex !== 1 ? directions[selectedIndex]?._id : null,
      partner_id: ally?._id
    }

    const data = clean(tempData)

    dispatch(deliveryOrderActions.updateDeliveryInfo({
      'id': getStorage('user_order_id',),
      'data': clean(data)
    })).unwrap().then(() => {
      saveUserData(data)
      setOpenAddressesDialog(true)
    })
  }

  const saveUserData = (data) => {
    saveStorage('phone_number', JSON.stringify(data))
    saveStorage('user_name', name?.trim())
    saveStorage('email', email)
    saveStorage(`${restaurantData.restaurant._id}_allie`, JSON.stringify(ally))
  }

  const submitHandler = () => {
    if (termsAndConditions === true) {

      setErrorValidation(false)

      if (name === null || name === '' || name === undefined || name.trim() === '') {
        setErrorValidation(true)
        return
      }

      if (email !== '' && !email.match(validRegex)) {
        setErrorValidation(true)
        return
      }

      if (restaurantData?.restaurant?.delivery_activated === true) {
        if (restaurantData?.delivery === true) {

          if (state.phoneNumber === null || state.phoneNumber === '' || state.phoneNumber === undefined) {
            setErrorValidation(true)
            return
          }

          if (errorValidation === false) {
            let directionSelected
            if (directions && directions?.length > 0) {
              directionSelected = directions[selectedIndex]
            }
            const tempData = {
              user: {
                phone_number: state.phoneNumber,
                phone_code: state.phoneCode,
                first_name: name?.trim(),
                contact_email: email,
                accepted_tyc: true
              },
              pickup_at_restaurant: deliveryOrPickup === 'pickup',
              direction_id: directionSelected?._id,
              partner_id: ally?._id
            }

            const data = clean(tempData)

            if (deliveryOrPickup === 'delivery') {
              if (selectedIndex !== -1) {
                const params = {
                  id: directions[selectedIndex]._id, orderId: getStorage('user_order_id')
                }
                if (restaurantData.restaurant.is_pibox_activated === true) {
                  updateUserData(data, directionSelected)
                } else {
                  dispatch(directionActions.validateDirection(params)).unwrap()
                    .then((response) => {
                      if (response !== null && response !== undefined) {
                        if (response?.has_restaurant_coverage_zones === true) {
                          if (response?.is_user_in_zone === true) {
                            updateUserData(data, directionSelected)
                          } else {
                            showPickupDialog(data)
                          }
                        } else {
                          navigateToDeliveryPayBill(data)
                        }
                      } else {
                        navigateToDeliveryPayBill(data)
                      }
                    })
                    .catch(() => {
                      navigateToDeliveryPayBill(data)
                    })
                  saveUserData(data)
                }
              } else {
                openAddressDialog()
              }
            } else if (deliveryOrPickup === 'pickup') {
              updateUserData(data, directionSelected)
            }
          }

        } else {
          if (errorValidation === false) {
            const params = {
              user: {
                user_name: name.trim(),
                contact_email: email,
                accepted_tyc: true
              },
              partner_id: ally?._id
            }
            dispatch(userActions.updateUserName(clean(params))).then(() => {
              dispatch(shoppingCartActions.confirmUserOrder()).then(() => {
                saveStorage('user_name', name)
                saveStorage('email', email)
                saveStorage(`${restaurantData.restaurant._id}_allie`, JSON.stringify(ally))
                navigate(`/tableOrder/${getStorage('table_uid')}`, { state: { fromOrder: true } })
              })
            })
          }
        }
      } else {
        if (state.phoneNumber === null || state.phoneNumber === '' || state.phoneNumber === undefined) {
          setErrorValidation(true)
          return
        }

        if (email === null || email === '' || !email.match(validRegex)) {
          setErrorValidation(true)
          return
        }

        if (deliveryIndication === null || deliveryIndication === '' || deliveryIndication === undefined) {
          setErrorValidation(true)
          return
        }

        if (errorValidation === false) {
          const data = {
            user: {
              phone_number: state.phoneNumber,
              phone_code: state.phoneCode,
              first_name: name?.trim(),
              contact_email: email,
              accepted_tyc: true
            },
            delivery_indications: deliveryIndication,
            partner_id: ally?._id
          }

          dispatch(deliveryOrderActions.updateDeliveryInfo({
            'id': getStorage('user_order_id',),
            'data': clean(data)
          })).unwrap().then(() => {
            saveStorage('delivery_indications', deliveryIndication)
            saveStorage('phone_number', JSON.stringify(data))
            saveStorage('user_name', data.user.first_name)
            saveStorage('email', email)
            saveStorage(`${restaurantData.restaurant._id}_allie`, JSON.stringify(ally))
            navigate(`/userOrderDeliveryPayBill/${getStorage('table_uid')}`, { state: { fromOrder: true } })
          })
        }
      }
    } else {
      InfoDialog({ text: intl.formatMessage({ id: 'youMustAddTermsAndConditions' }) })
    }
  }

  const getDirections = () => {
    dispatch(directionsActions.getDirections())
  }

  const deleteDirection = (direction) => {
    const params = {
      id: direction._id
    }
    ConfirmDialog({
      text: intl.formatMessage({ id: 'wantToDeleteAddress' }),
      confirmButtonText: intl.formatMessage({ id: 'buttonDeleteItems' }),
      cancelButtonText: intl.formatMessage({ id: 'buttonNo' }),
      onConfirm: () => {
        dispatch(directionActions.deleteDirection(params)).unwrap().then(() => getDirections())
      },
      onCancel: () => {}
    })
  }

  const handleAllieChange = (event) => {
    const partner = restaurantData?.restaurant?.partners.find(partner => partner?._id === event.target.value) || null
    setAlly(partner)
  }

  const handlePhoneOrEmailChange = ({ email, phoneNumber, phoneCode }) => {
    if (email !== undefined && email !== null && email !== '') {
      const params = {
        contact_email: email
      }
      dispatch(userActions.checkForSession(params)).unwrap().then((value) => {
        checkDataFormTermsAndConditions(value)
      })
    }
    if (phoneNumber !== undefined && phoneNumber !== null && phoneNumber !== '') {
      const params = {
        phone_code: phoneCode,
        phone_number: phoneNumber
      }
      dispatch(userActions.checkForSession(params)).unwrap().then((value) => {
        checkDataFormTermsAndConditions(value)
      })
    }
  }

  return (
    <BootstrapDialogStyle
      onClose={dialogDismiss}
      aria-labelledby="customized-dialog-title"
      open={open}
      className={classes.dialog}
      fullScreen={mobileView}
    >
      <DialogTitle className={styles.container1}>
        <IconButton aria-label="close" onClick={handleClose} className={classes.iconButton}>
          <CloseIcon/>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div className={styles.container2}>
          <div style={UpdateUserDataDialogStyle(mobileView).container3}>
            {loading || loadingUserData ? <Loading/> :
              <div className={UpdateUserDataDialogStyle(mobileView).container4}>
                <Grid container className={styles.container}>
                  <Grid item xs={12} container>
                    <Typography gutterBottom variant="h6" className={styles.titles}>
                      {intl.formatMessage({ id: 'orderOwner' })}
                    </Typography>
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      label={intl.formatMessage({ id: 'writeHere' })}
                      variant="outlined"
                      color="secondary"
                      value={name}
                      onChange={(event) => setName(event.target.value)}
                      error={errorValidation && (name === '' || name === null || name.trim() === '')}
                      helperText={errorValidation && (name === '' || name === null) ? intl.formatMessage({ id: 'required' }) : ' '}
                      InputProps={{
                        style: {
                          borderRadius: '.4rem'
                        },
                      }}
                    />
                  </Grid>
                  {(restaurantData?.restaurant?.delivery_activated === true || restaurantData?.delivery === false) &&
                    <Grid item xs={12} container>
                      <Typography gutterBottom variant="h6">
                        {intl.formatMessage({ id: 'addEmail' })}
                      </Typography>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label={intl.formatMessage({ id: 'writeHere' })}
                        variant="outlined"
                        value={email}
                        type={'email'}
                        onChange={(event) => {
                          setEmail(event.target.value)
                          handlePhoneOrEmailChange({ email: event.target.value })
                        }}
                        error={(email !== '' && !email.match(validRegex))}
                        InputProps={{
                          style: {
                            borderRadius: '.4rem',
                          },
                        }}
                      />
                    </Grid>
                  }
                  {restaurantData?.delivery === true &&
                    <Grid item xs={12} container>
                      <Typography gutterBottom variant="h6" className={styles.titles2}>
                        {intl.formatMessage({ id: 'addPhoneNumber' })}
                      </Typography>
                      <PhoneInput
                        inputStyle={{ width: '100%', borderRadius: '.4rem' }}
                        fullWidth
                        preferredCountries={['co', 'mx', 'us']}
                        country={'co'}
                        countryCodeEditable={false}
                        value={`${state.phoneCode} ${state.phoneNumber}`}
                        placeholder={intl.formatMessage({ id: 'writeHere' })}
                        onChange={handleOnChangePhoneNumber}
                        defaultErrorMessage={intl.formatMessage({ id: 'required' })}
                        isValid={() => !(errorValidation && (state.phoneNumber === null || state.phoneNumber === '' || state.phoneNumber === undefined))}
                        inputProps={{
                          required: true,
                        }}
                      />
                    </Grid>}
                  {restaurantData?.delivery === true && restaurantData?.restaurant?.delivery_activated === false &&
                    <Grid item xs={12}>
                      <Typography gutterBottom variant="h6" className={styles.titles} paddingTop={'16px'}>
                        {intl.formatMessage({ id: 'addEmail' })}
                      </Typography>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label={intl.formatMessage({ id: 'writeHere' })}
                        variant="outlined"
                        value={email}
                        type={'email'}
                        onChange={(event) => setEmail(event.target.value)}
                        error={errorValidation && (email === null || email === '' || !email.match(validRegex))}
                        helperText={errorValidation ? intl.formatMessage({ id: 'required' }) : ' '}
                        InputProps={{
                          style: {
                            borderRadius: '.4rem',
                          },
                        }}
                      />
                      <Typography gutterBottom variant="h6" className={styles.titles}>
                        {intl.formatMessage({ id: 'deliveryIndications' }, { delivery_indications: getLocalKeyWords('delivery_indications') })}
                      </Typography>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label={intl.formatMessage({ id: 'writeHere' }, { delivery_indications: getLocalKeyWords('delivery_indications') })}
                        variant="outlined"
                        value={deliveryIndication}
                        onChange={(event) => setDeliveryIndication(event.target.value)}
                        error={errorValidation && (deliveryIndication === '' || deliveryIndication === null || deliveryIndication === undefined)}
                        helperText={errorValidation ? intl.formatMessage({ id: 'required' }) : ' '}
                        InputProps={{
                          style: {
                            borderRadius: '.4rem'
                          },
                        }}
                      />
                    </Grid>
                  }
                  {restaurantData?.restaurant?.partners?.length > 0 &&
                    <Grid item xs={12}>
                      <Typography gutterBottom variant="h6" className={styles.titles1}>
                        {getLocalKeyWords('partner')}
                      </Typography>
                      <FormControl fullWidth>
                        <InputLabel id="select-label">{intl.formatMessage({ id: 'alliesHelper' })}</InputLabel>
                        <Select
                          labelId="select-label"
                          id="demo-simple-select"
                          value={ally?._id || ''}
                          label={intl.formatMessage({ id: 'writeHere' })}
                          onChange={handleAllieChange}
                          sx={{ borderRadius: '.4rem' }}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {savedAllie &&
                            <MenuItem
                              key={savedAllie?._id}
                              value={savedAllie?._id}
                              sx={{ fontWeight: 'bold', backgroundColor: 'whiteSmoke' }}
                            >
                              {savedAllie?.name}
                            </MenuItem>
                          }
                          {restaurantData?.restaurant?.partners.map((partner) => (
                            <MenuItem key={partner?._id} value={partner?._id}>
                              {partner?.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>}
                  {restaurantData?.delivery === true && restaurantData?.restaurant?.delivery_activated === true &&
                    <Grid item xs={12} container={true}>
                      <Typography gutterBottom variant="h6" className={styles.titles1}>
                        {intl.formatMessage({ id: 'pickupOrDeliveryQuestion' }, { order: getLocalKeyWords('order')?.toLowerCase() })}
                      </Typography>

                      <Grid item xs={12} container direction={'row'}>
                        <Grid item xs={6} container className={styles.grid}>
                          <Grid container direction={'column'} className={styles.grid1}>
                            <ListItemText
                              primary={intl.formatMessage({ id: 'deliveryMessage' })}
                              onClick={() => setDeliveryOrPickup('delivery')}
                            />
                            <Radio checked={deliveryOrPickup === 'delivery'} onChange={handleRadioChange}
                                   value="delivery"/>
                          </Grid>
                        </Grid>
                        <Grid item xs={6} container className={styles.grid3}>
                          <Grid container direction={'column'} className={styles.grid2}>
                            <ListItemText
                              primary={intl.formatMessage({ id: 'pickupMessage' })}
                              onClick={() => setDeliveryOrPickup('pickup')}
                            />
                            <Radio checked={deliveryOrPickup === 'pickup'} onChange={handleRadioChange} value="pickup"/>
                          </Grid>
                        </Grid>
                      </Grid>
                      <FormHelperText>{helperText}</FormHelperText>
                    </Grid>}
                  {restaurantData?.restaurant?.delivery_activated === true && directions !== null && directions !== undefined && directions?.length > 0 && deliveryOrPickup === 'delivery' &&
                    <Grid item xs={12}>
                      <Grid container direction={'row'}>
                        <Grid item xs={8}>
                          <Typography gutterBottom variant="h6" className={styles.titles1}>
                            {intl.formatMessage({ id: 'myAddresses' })}
                          </Typography>
                        </Grid>
                        <Grid container item xs={4} direction={'row'} className={styles.grid4}>
                          <AddIcon fontSize={'medium'} onClick={() => openAddressDialog()} inheritViewBox={true}/>
                        </Grid>
                      </Grid>
                      {directions.map((direction, index) => (
                        <AddressesItem
                          key={index}
                          index={index}
                          onPressed={() => setSelectedIndex(index)}
                          selectedIndex={selectedIndex}
                          direction={direction}
                          onDeletePressed={() => deleteDirection(direction)}
                        />
                      ))}
                    </Grid>}
                </Grid>
              </div>}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Grid container direction={'column'} alignItems={'center'}>
          <Grid item xs={12} justifyContent={'center'} justifyItems={'center'} alignItems={'center'} container
                direction={'row'}>
            <Checkbox
              checked={termsAndConditions}
              onChange={(event) => {setTermsAndConditions(event.target.checked)}}
            />
            <Link
              href="https://firebasestorage.googleapis.com/v0/b/cocoa-1281e.appspot.com/o/TERMINOS-Y-CONDICIONES-COCOA_USUARIOS-FINALES.pdf?alt=media&token=e5b86db9-9503-406b-aa66-35f2481411dc"
              target="_blank" variant="body2">
              {intl.formatMessage({ id: 'termsAndConditions' })} {intl.formatMessage({ id: 'usePersonalData' })}
            </Link>
          </Grid>
          <Grid item xs={12} justifyContent={'center'} justifyItems={'center'} container>
            <Button
              color="primary"
              variant="contained"
              onClick={submitHandler}
              style={UpdateUserDataDialogStyle(mobileView).button}
              sx={{
                '&:hover': {
                  backgroundColor: colorShade(theme.palette.primary.main, -60),
                }
              }}
            >
              {
                restaurantData?.restaurant?.delivery_activated === true && directions?.length === 0 && deliveryOrPickup === 'delivery' ?
                  intl.formatMessage({ id: 'addDirection' }) :
                  restaurantData.delivery === true ?
                    intl.formatMessage({ id: 'continueWithPayment' }) :
                    intl.formatMessage({ id: 'continueWithOrder' }, { order: getLocalKeyWords('order') })
              }
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </BootstrapDialogStyle>
  )
}

export default UpdateUserDataDialog
