import { createSlice } from '@reduxjs/toolkit'
import { theme } from '../../index'
import { getStorage } from '../../utils/storage'

export const THEME_NAME = 'theme'

const initialState = {
  status: 'idle',
  loading: false,
}

export const themeSlice = createSlice({
  name: THEME_NAME,
  initialState,

  reducers: {
    updateTheme: () => {
      const primaryColor = getStorage('primary_color')
      const secondaryColor = getStorage('secondary_color')
      const tertiaryColor = getStorage('tertiary_color')
      if (primaryColor !== null && primaryColor !== undefined) {
        theme.palette.primary.main = `#${getStorage('primary_color')}`
      }
      if (secondaryColor !== null && secondaryColor !== undefined) {
        theme.palette.secondary.main = `#${getStorage('secondary_color')}`
      }
      if (tertiaryColor !== null && tertiaryColor !== undefined) {
        theme.palette.info.main = `#${getStorage('tertiary_color')}`
      }
    },
  },

  extraReducers: () => {

  }
})

export const { updateTheme } = themeSlice.actions

export default themeSlice.reducer