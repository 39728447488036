import * as React from 'react'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { deliveryOrderStyles } from '../../../pages/deliveryOrder/DeliveryOrder/DeliveryOrderPage.style'
import {
  isPayedDelivery,
  stateMessageDelivery,
} from '../../../utils/order_state_extension'
import { useIntl } from 'react-intl'
import getLocalKeyWords from '../../../utils/local_keywords'

const HeaderDeliveryOrder = ({ myOrder, table }) => {
  const intl = useIntl()
  const styles = deliveryOrderStyles()
  return (<Card className={styles.headerCard} sx={{ backgroundColor: 'primary.main', marginX: '8px' }}>
    <CardContent>
      <Grid container>
        <Grid item xs={8}>
          <Typography gutterBottom variant="h6" className={styles.titleLeft}>
            {intl.formatMessage({ id: 'hi' })}, {myOrder.user_name}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Grid>
            <Typography gutterBottom variant="h6" className={styles.titleRight}>
              # {intl.formatMessage({ id: 'order' }, { order: getLocalKeyWords('order') })} {myOrder.consecutive}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {table?.delivery_activated === true && myOrder.pickup_at_restaurant === true && <Grid item xs={3}>
        <Typography gutterBottom variant="h6" className={styles.titleLeft}>
          {intl.formatMessage({ id: 'pickupAtRestaurant' }, { restaurant: getLocalKeyWords('restaurant') })}
        </Typography>
      </Grid>}
      {table?.delivery_activated === true && myOrder.pickup_at_restaurant === false &&
        <Grid container alignItems={'center'}>
          <Grid item xs={12}>
            <Typography gutterBottom variant="body1" className={styles.titleLeft}>
              {intl.formatMessage({ id: 'weWillSendYourOrderTo' }, { order: getLocalKeyWords('order') })}:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography gutterBottom variant="h6" className={styles.titleLeft}>
              {myOrder.firestore_direction !== null && myOrder.firestore_direction !== undefined && myOrder.firestore_direction.address !== null && myOrder.firestore_direction.address !== undefined ? `${myOrder.firestore_direction.name} ${myOrder.firestore_direction.address}` : ''}
            </Typography>
          </Grid>
        </Grid>}
      {table?.delivery_activated === false && myOrder?.delivery_indications && <Grid container>
        <Grid item xs={10}>
          <Typography gutterBottom variant="body1" className={styles.titleLeft}>
            {intl.formatMessage({ id: 'deliveryIndications' }, { delivery_indications: getLocalKeyWords('delivery_indications') })}:
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography gutterBottom variant="body1" className={styles.titleLeft}>
            {myOrder?.delivery_indications}
          </Typography>
        </Grid>
      </Grid>}
      {isPayedDelivery(myOrder.state) && <Grid container justifyContent={'center'}>
        <Typography variant="body1" color="white" paddingTop={2} textAlign={'center'} fontWeight={'bold'}>
          {stateMessageDelivery(myOrder.state)}
        </Typography>
      </Grid>}
      {myOrder?.delivery_information && <div>
        <Grid container direction={'row'} alignItems={'center'}>
          <Typography variant="body1" color="white" paddingY={1} textAlign={'center'} fontWeight={'bold'}>
            {intl.formatMessage({ id: 'deliveryMan' })}:
          </Typography>
        </Grid>
        <Grid container direction={'column'} className={styles.grid}>
          <Grid container direction={'row'}>
            <Grid item xs={7} container direction={'column'} style={styles.grid3}>
              <Typography variant="subtitle2" textAlign={'center'} fontSize={14}>
                {intl.formatMessage({ id: 'driverData' })}
              </Typography>
              <Typography variant="subtitle2" textAlign={'center'} fontSize={12}>
                {intl.formatMessage({ id: 'name' })}
              </Typography>
              <Typography variant="body1" textAlign={'center'} fontSize={14}>
                {myOrder?.delivery_information.driver.name}
              </Typography>
              <Typography variant="subtitle2" textAlign={'center'} fontSize={12}>
                {intl.formatMessage({ id: 'phone' })}
              </Typography>
              <a
                href={`tel:+${myOrder?.delivery_information.driver.phone}`}> +{myOrder?.delivery_information.driver.phone} </a>
            </Grid>
            <Grid item xs={5} container direction={'column'} style={styles.grid4}>
              <Typography variant="subtitle2" textAlign={'center'} fontSize={14}>
                {intl.formatMessage({ id: 'vehicleData' })}
              </Typography>
              <Typography variant="subtitle2" textAlign={'center'} fontSize={12}>
                {intl.formatMessage({ id: 'brand' })}
              </Typography>
              <Typography variant="body1" textAlign={'center'} fontSize={12}>
                {myOrder?.delivery_information.vehicle.make}
              </Typography>
              <Typography variant="subtitle2" textAlign={'center'} fontSize={12}>
                {intl.formatMessage({ id: 'plate' })}
              </Typography>
              <Typography variant="body1" textAlign={'center'} fontSize={12}>
                {myOrder?.delivery_information.vehicle.plates}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={2} direction={'column'} container className={styles.grid2}>
            <Typography variant="subtitle2" textAlign={'center'} fontSize={12}>
              {intl.formatMessage({ id: 'trackingLink' })}
            </Typography>
            <a
              href={myOrder?.delivery_information.tracking_link}
              style={{ marginRight: '24px', fontSize: '14px' }}> +{myOrder?.delivery_information.tracking_link} </a>
          </Grid>
        </Grid>
      </div>}
    </CardContent>
  </Card>)
}

export default HeaderDeliveryOrder
