import { useIntl } from 'react-intl'
import getLocalKeyWords from './local_keywords'
import { getStorage } from './storage'

export const empty = 'empty'

//For tables
export const tablePending = 'pending'
export const tableConfirmed = 'confirmed'
export const tablePreparing = 'preparing'
export const tablePaying = 'paying'
export const tablePayed = 'payed'
export const tableFinished = 'finished'
export const tableCanceled = 'canceled'

//For Deliveries
export const orderPending = 'pending'
export const orderReceived = 'received'
export const orderConfirmed = 'confirmed'
export const orderPreparing = 'preparing'
export const orderReadyToShip = 'ready_to_ship'
export const orderWaitingForDriver = 'waiting_for_driver'
export const orderDriverOnWay = 'driver_on_way'
export const orderDriverOnRestaurant = 'driver_on_restaurant'
export const orderShipped = 'shipped'
export const orderArrived = 'arrived'
export const orderFinished = 'finished'
export const orderCanceled = 'canceled'


export const stateColor = (state) => {
  switch (state) {
    case tableConfirmed:
      return '#2196F3FF'
    case tablePending:
      return '#FFAB40FF'
    case tablePaying:
      return '#FF5722FF'
    case tablePreparing:
      return '#4CAF50FF'
    case tablePayed:
      return '#3F51B5FF'
    default:
      return 'grey'
  }
}

export const stateColorDelivery = (state) => {
  switch (state) {
    case orderPending:
      return '#FFAB40FF'
    case orderReceived:
      return '#9C27B0FF'
    case orderConfirmed:
      return '#2196F3FF'
    case orderPreparing:
      return '#4CAF50FF'
    case orderReadyToShip:
      return '#FF5722FF'
    case orderShipped:
      return '#3F51B5FF'
    case orderArrived:
      return '#8BC34AFF'
    case orderWaitingForDriver:
      return '#FF5252'
    case orderDriverOnWay:
      return '#4CAF50'
    case orderDriverOnRestaurant:
      return '#009688'
    case orderFinished:
      return '#6CBD72'
    case orderCanceled:
      return '#FF5252'
    default:
      return 'grey'
  }
}

export const stateName = (state) => {
  const intl = useIntl()
  switch (state) {
    case tableConfirmed:
      return intl.formatMessage({ id: 'userConfirmed' }, { confirmed_order: getLocalKeyWords('confirmed_order') })
    case tablePending:
      return intl.formatMessage({ id: 'userPending' })
    case empty:
      return intl.formatMessage({ id: 'userEmpty' })
    case tablePaying:
      return intl.formatMessage({ id: 'userPaying' })
    case tablePreparing:
      return intl.formatMessage({ id: 'inProgress' }, { preparing_order: getLocalKeyWords('preparing_order') })
    case tablePayed:
      return intl.formatMessage({ id: 'userPayed' })
    case tableFinished:
      return getLocalKeyWords('finished_order')
    default:
      return '-'
  }
}

export const stateNameDelivery = (state) => {
  const intl = useIntl()
  switch (state) {
    case orderPending:
      return intl.formatMessage({ id: 'userPending' })
    case orderReceived:
      return intl.formatMessage({ id: 'received' }, { received_order: getLocalKeyWords('received_order') })
    case orderConfirmed:
      return intl.formatMessage({ id: 'userConfirmed' }, { confirmed_order: getLocalKeyWords('confirmed_order') })
    case orderPreparing:
      return intl.formatMessage({ id: 'inProgress' }, { preparing_order: getLocalKeyWords('preparing_order') })
    case orderReadyToShip:
      return intl.formatMessage({ id: 'readyToShip' }, { ready_to_ship: getLocalKeyWords('ready_to_ship_order') })
    case orderShipped:
      return intl.formatMessage({ id: 'shipped' }, { shipped_order: getLocalKeyWords('shipped_order') })
    case orderArrived:
      return intl.formatMessage({ id: 'arrived' }, { arrived_order: getLocalKeyWords('arrived_order') })
    case orderWaitingForDriver:
      return intl.formatMessage({ id: 'waitingForDriver' })
    case orderDriverOnWay:
      return intl.formatMessage({ id: 'driverOnWay' })
    case orderDriverOnRestaurant:
      return intl.formatMessage({ id: 'driverOnRestaurant' }, { restaurant: getLocalKeyWords('restaurant') })
    case orderFinished:
      return getLocalKeyWords('finished_order')
    case orderCanceled:
      return intl.formatMessage({ id: 'orderCanceled' })
    case empty:
      return intl.formatMessage({ id: 'userEmpty' })
    default:
      return '-'
  }
}

export const stateMessage = (state) => {
  const intl = useIntl()
  switch (state) {
    case tableConfirmed:
      return intl.formatMessage({ id: 'orderConfirmedByUserMessage' }, {
        order: getLocalKeyWords('order'),
        restaurant: getLocalKeyWords('restaurant'),
        confirmed_order: getLocalKeyWords('confirmed_order')
      })
    case tablePending:
      return intl.formatMessage({ id: 'userPending' })
    case empty:
      return intl.formatMessage({ id: 'userEmpty' })
    case tablePaying:
      return intl.formatMessage({ id: 'orderPayingMessage' }, { order: getLocalKeyWords('order') })
    case tablePreparing:
      return intl.formatMessage({ id: 'orderConfirmedByRestaurantMessage' }, {
        order: getLocalKeyWords('order'),
        restaurant: getLocalKeyWords('restaurant')
      })
    case tablePayed:
      return intl.formatMessage({ id: 'orderPayedMessage' }, { order: getLocalKeyWords('order') })
    default:
      return '-'
  }
}

export const stateMessageDelivery = (state) => {
  const intl = useIntl()
  switch (state) {
    case orderReceived:
      return intl.formatMessage({ id: 'receivedDeliveryMessage' }, {
        order: getLocalKeyWords('order'),
        restaurant: getLocalKeyWords('restaurant'),
        received_order: getLocalKeyWords('received_order')
      })
    case orderConfirmed:
      return intl.formatMessage({ id: 'confirmedDeliveryMessage' }, {
        order: getLocalKeyWords('order'),
        restaurant: getLocalKeyWords('restaurant'),
      })
    case orderPreparing:
      return intl.formatMessage({ id: 'preparingDeliveryMessage' }, {
        order: getLocalKeyWords('order'),
        preparing_order: getLocalKeyWords('preparing_order')
      })
    case orderReadyToShip:
      return intl.formatMessage({ id: 'readyToShipDeliveryMessage' }, {
        order: getLocalKeyWords('order'),
        restaurant: getLocalKeyWords('restaurant'),
        ready_to_ship: getLocalKeyWords('ready_to_ship_order')
      })
    case orderShipped:
      return intl.formatMessage({ id: 'shipperDeliveryMessage' }, { order: getLocalKeyWords('order') })
    case orderArrived:
      return intl.formatMessage({ id: 'deliveryOrderArrived' }, {
        order: getLocalKeyWords('order'),
        arrived_order: getLocalKeyWords('arrived_order')
      })
    case orderWaitingForDriver:
      return intl.formatMessage({ id: 'waitingForDriverMessage' }, { restaurant: getLocalKeyWords('restaurant') })
    case orderDriverOnWay:
      return intl.formatMessage({ id: 'driverOnWayMessage' }, { restaurant: getLocalKeyWords('restaurant') })
    case orderDriverOnRestaurant:
      return intl.formatMessage({ id: 'driverOnRestaurantMessage' }, { restaurant: getLocalKeyWords('restaurant') })
    case empty:
      return intl.formatMessage({ id: 'userEmpty' })
    default:
      return '-'
  }
}

export const isPayedDelivery = (state) => {
  switch (state) {
    case orderReceived:
    case orderConfirmed:
    case orderPreparing:
    case orderReadyToShip:
    case orderShipped:
    case orderArrived:
    case orderWaitingForDriver:
    case orderDriverOnWay:
    case orderDriverOnRestaurant:
      return true
    case orderCanceled:
    case empty:
    case orderPending:
    case orderFinished:
      return false
    default:
      return false
  }
}

export const canCancelOrder = (state) => {
  switch (state) {
    case orderPreparing:
    case orderReadyToShip:
    case orderShipped:
    case orderArrived:
    case orderFinished:
    case orderWaitingForDriver:
    case orderDriverOnWay:
    case orderDriverOnRestaurant:
      return false
    case orderReceived:
    case orderConfirmed:
      return true
    default:
      return true
  }
}

export const canModifyDeliveryOrder = (state) => {
  switch (state) {
    case orderConfirmed:
    case orderPreparing:
    case orderReadyToShip:
    case orderShipped:
    case orderArrived:
    case orderFinished:
    case orderWaitingForDriver:
    case orderDriverOnWay:
    case orderDriverOnRestaurant:
      return false
    case orderReceived:
    case orderCanceled:
    case empty:
    case orderPending:
      return true
    default:
      return true
  }
}

export const canModifyTableOrder = (state) => {
  switch (state) {
    case tablePaying:
    case tablePreparing:
    case tableCanceled:
    case tableFinished:
    case tablePayed:
      return false
    case tableConfirmed:
    case tablePending:
    case empty:
      return true
    default:
      return true
  }
}

export const isTableOrderActive = (state) => {
  switch (state) {
    case tablePaying:
    case tablePreparing:
    case tablePayed:
    case tableConfirmed:
      return true
    case tablePending:
    case empty:
      return false
    default:
      return false
  }
}

export const getAllDeliveriesStateName = () => {
  const states = JSON.parse(getStorage('delivery_order_states'))
  let stateNames = []
  if (states !== undefined && states !== null) {
    states.forEach((state) => {
      stateNames.push(stateNameDelivery(state))
    })
  }
  return stateNames
}

export const getAllDeliveriesStates = () => {
  return JSON.parse(getStorage('delivery_order_states'))
}


export const getAllTablesStatesName = () => {
  const states = JSON.parse(getStorage('table_order_states'))
  let stateNames = []
  states.forEach((state) => {
    stateNames.push(stateName(state))
  })
  return stateNames
}

export const getAllTableStates = () => {
  return JSON.parse(getStorage('table_order_states'))
}
