import { maxMobileWidth, maxWebWidth } from '../utils/screen_utils'
import { getStorage } from '../utils/storage'

export const background = (mobileView) => ({
  fontFamily: 'roboto, sans-serif',
  fontSize: 15,
  justifyContent: 'center',
  display: mobileView ? 'inherit' : 'flex',
  backgroundImage: `url(${getStorage('wallpaper')})`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundAttachment: 'fixed',
  backgroundSize: 'cover'
})

export const backgroundForHome = (mobileView, backgroundUrl) => ({
  fontFamily: 'roboto, sans-serif',
  fontSize: 15,
  justifyContent: 'center',
  display: mobileView ? 'inherit' : 'flex',
  backgroundImage: `url(${backgroundUrl})`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundAttachment: 'scroll',
  backgroundSize: 'cover'
})

export const bodyBackground = (mobileView, backgroundUrl) => ({
  backgroundImage: `url(${backgroundUrl})`,
  backgroundSize: '100% auto',
  width: mobileView ? '100%' : '60%',
  backgroundRepeat: 'repeat',
  backgroundPosition: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  alignContent: 'center',
  justifyItems: 'center',
  display: 'flex',
  backgroundAttachment: 'scroll',
  backgroundColor: 'white'
})

export const premiumHeaderBackground = (mobileView, backgroundUrl) => ({
  backgroundImage: `url(${backgroundUrl})`,
  backgroundSize: '100% 100%',
  height: '160px',
  width: mobileView ? '100%' : '60%',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  alignContent: 'center',
  justifyItems: 'center',
  display: 'flex',
  backgroundAttachment: 'scroll',
  marginBottom: 0,
  marginTop:0,
})

export const premiumBottomBackground = (mobileView, backgroundUrl) => ({
  backgroundImage: `url(${backgroundUrl})`,
  width: mobileView ? '100%' : '60%',
  marginBottom: 0,
  backgroundSize: '100% 100%',
  height: '80px',
  backgroundRepeat: 'no-repeat'
})

export const premiumCategoryPresentation = (mobileView, backgroundUrl) => ({
  backgroundImage: `url(${backgroundUrl})`,
  backgroundSize: '100% 100%',
  height: '700px',
  width: mobileView ? '100%' : '60%',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  alignContent: 'center',
  justifyItems: 'center',
  display: 'flex',
  backgroundAttachment: 'scroll',
  marginBottom: 0,
})

export const premiumHeaderBackgroundW = (mobileView, backgroundUrl) => ({
  backgroundImage: `url(${backgroundUrl})`,
  backgroundSize: 'cover',
  height: '160px',
  width: mobileView ? '100%' : '60%',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  alignContent: 'center',
  justifyItems: 'center',
  display: 'flex',
  backgroundAttachment: 'scroll',
  marginBottom: 0,
  marginTop:0,
})

export const container = (mobileView) => ({
  width: mobileView ? maxMobileWidth : maxWebWidth,
  backgroundColor: '#fff'
})

export const container1 = (mobileView) => ({
  justifyContent: 'center', display: mobileView ? 'inherit' : 'flex'
})

export const container2 = (mobileView) => ({
  width: mobileView ? maxMobileWidth : maxWebWidth
})

export const container3 = (mobileView, filteredCategories, isPremium) => ({
  width: mobileView ? maxMobileWidth : isPremium ? '90%' : maxWebWidth,
  backgroundColor: isPremium ? 'transparent' : '#fff',
  height: filteredCategories && filteredCategories.length > 0 ? '100%' : '100vh',
  justifyContent: 'center',
  minHeight: '150px',
})

export const div = () => ({
  paddingLeft: '8px'
})
