import Grid from '@mui/material/Grid'
import { Typography } from '@mui/material'
import styles from './ProductsEmpty.module.css'
import Button from '@mui/material/Button'
import { useIntl } from 'react-intl'

const ProductsEmpty = ({ resetSearch }) => {
  const intl = useIntl()
  return (
    <Grid container className={styles.grid} direction={'column'}>
      <Typography gutterBottom variant="subtitle2" fontSize={'20px'}>
        {intl.formatMessage({ id: 'sorry' })}
      </Typography>
      <Typography gutterBottom variant="body" fontSize={'14px'}>
        {intl.formatMessage({ id: 'noResultsFound' })}
      </Typography>
      <Button color="primary" variant="contained" onClick={resetSearch} className={styles.button}>
        {intl.formatMessage({ id: 'resetSearch' })}
      </Button>
    </Grid>
  )
}

export default ProductsEmpty