import { getStorage } from './storage'

const fontColor = 'ffffff'
const buttonColor = getStorage('tertiary_color')

export const getColorForButton = (buttonAction) => {
  if (buttonAction.background_color !== null && buttonAction.background_color !== '') {
    return buttonAction?.background_color?.substring(4)
  }
  return buttonColor;
}

export const getFontColorForButton = (buttonAction) => {
  if (buttonAction.background_color !== null && buttonAction.background_color !== '') {
    return buttonAction?.font_color?.substring(4)
  }
  return fontColor;
}
