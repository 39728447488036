import { Navigate, Outlet, useParams } from 'react-router-dom'
import { getStorage, removeStorageByKey, saveStorage } from '../utils/storage'

const ProtectedRoutes = () => {
  const sessionToken = getStorage('st')
  const tableToken = getStorage('tt')
  const tableUid = getStorage('table_uid')

  let params = useParams()
  const path = window.location.pathname

  const checkTableUidStorage = () => {
    if (tableUid !== params.id) {
      removeStorageByKey('tt')
    }
    saveStorage('table_uid', params.id)
  }

  if (params.id !== undefined && params.id !== null && params.id !== '') {
    checkTableUidStorage()
    return (<Outlet/>)
  } else if (path === null || path === undefined || path === '/') {
    return (<Outlet/>)
  } else {
    if (sessionToken !== null && sessionToken !== undefined && sessionToken !== '') {
      if (tableToken === null || tableToken === undefined || tableToken === '') {
        return (<Navigate to="/exit"/>)
      } else {
        return (<Outlet/>)
      }
    } else {
      return (<Outlet/>)
    }
  }
}

export default ProtectedRoutes
