import React from 'react'
import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom'
import MenuPage from '../pages/menu/Menu.page'
import TableOrderPage from '../pages/table/TableOrder/TableOrderPage'
import ExitPage from '../pages/exit/Exit.page'
import UserOrderDeliveryPayBillPage from '../pages/deliveryOrder/UserOrderDeliveryPayBill/UserOrderDeliveryPayBillPage'
import DeliveryOrderPage from '../pages/deliveryOrder/DeliveryOrder/DeliveryOrderPage'
import ConditionalRoute from './conditional.routes'
import ProtectedRoutes from './protected.routes'
import OrderDetailPage from '../pages/orderDetail/OrderDetail.page'
import TableOrderDetailPage from '../pages/orderDetail/TableOrderDetail.page'
import HomePage from '../pages/home/Home/Home.page'
import BranchesPage from '../pages/home/Branches/Branches.page'
import OpenBrowserPage from '../pages/openBrowser/OpenBrowser.page'
import BookTablePage from '../pages/bookTable/BookTable.page'

const RoutesApp = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<ProtectedRoutes/>}>
          <Route path="/" element={<HomePage/>}/>
          <Route path="/branches/:id" element={<BranchesPage/>}/>
          <Route path="/#/:id" element={<HomePage/>}/>
          <Route path="/:id" element={<HomePage/>}/>
          <Route path="/products/:id" element={
            <ConditionalRoute>
              <MenuPage/>
            </ConditionalRoute>
          }/>
          <Route path="/tableOrder" element={<ExitPage/>}/>
          <Route path="/tableOrder/:id" element={
            <ConditionalRoute>
              <TableOrderPage/>
            </ConditionalRoute>
          }/>
          <Route path="/userOrderDeliveryPayBill" element={<ExitPage/>}/>
          <Route path="/userOrderDeliveryPayBill/:id" element={
            <ConditionalRoute>
              <UserOrderDeliveryPayBillPage/>
            </ConditionalRoute>
          }/>
          <Route path="/deliveryOrder" element={<ExitPage/>}/>
          <Route path="/deliveryOrder/:id" element={
            <ConditionalRoute>
              <DeliveryOrderPage/>
            </ConditionalRoute>
          }/>
          <Route path="/orderDetail/:id" element={
            <ConditionalRoute>
              <OrderDetailPage/>
            </ConditionalRoute>
          }/>
          <Route path="/tableDetail/:id" element={
            <ConditionalRoute>
              <TableOrderDetailPage/>
            </ConditionalRoute>
          }/>
        </Route>
        <Route path="/exit" element={<ExitPage/>}/>
        <Route path="/book-table" element={<BookTablePage/>}/>
        <Route path="/open-browser" element={<OpenBrowserPage/>}/>
      </Routes>
    </BrowserRouter>
  )
}

export default RoutesApp
