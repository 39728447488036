import Money from './money'

export const formatCost = (cost, quantity = 1) => {
  if (!cost) {
    return ''
  }
  const { cents, currency_iso, subunits } = cost
  const formatted_cost = new Money({
    subunits: (cents || 0) * quantity || (subunits || 0) * quantity,
    iso: currency_iso
  })
  return formatted_cost.format()
}
