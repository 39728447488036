import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Axios from 'axios'
import { urlCloseDeliveryOrder, urlUpdateDeliveryInfo, urlUploadWireTransferReceipt } from '../../apis/delivery'
import { getStorage } from '../../utils/storage'
import LanguageDialog from '../../components/dialogs/LanguageDialog'

export const DELIVERY_ORDER_NAME = 'deliveryOrder'

const initialState = {
  value: null,
  status: 'idle',
  loading: false
}

export const closeOrder = createAsyncThunk(
  'closeOrder',
  async (orderId) => {

    const resp = await Axios({
      headers: {
        Authorization: `Bearer ${getStorage('st')}`,
        'X-PI-TABLE-TOKEN': getStorage('tt'),
        'X-PI-RESTAURANT-ID': getStorage('restaurant_id'),
        lang: navigator.language,
        'If-None-Match': ''
      },
      url: urlCloseDeliveryOrder(orderId),
      method: 'PUT',
    })

    return resp.data.response
  }
)

export const uploadReceipt = createAsyncThunk(
  'uploadReceipt',
  async (params) => {
    const formData = new FormData()
    formData.append('file', params['payload'])
    const resp = await Axios({
      headers: {
        Authorization: `Bearer ${getStorage('st')}`,
        'X-PI-TABLE-TOKEN': getStorage('tt'),
        'X-PI-RESTAURANT-ID': getStorage('restaurant_id'),
        lang: navigator.language,
        'If-None-Match': '',
        'content-type': 'multipart/form-data'
      },
      data: formData,
      url: urlUploadWireTransferReceipt(params['id']),
      method: 'POST',
    })

    return resp.data.response
  }
)

export const updateDeliveryInfo = createAsyncThunk('updateDeliveryInfo', async (arg, { rejectWithValue }) => {
  try {
    const resp = await Axios({
      headers: {
        Authorization: `Bearer ${getStorage('st')}`,
        'X-PI-TABLE-TOKEN': getStorage('tt'),
        'X-PI-RESTAURANT-ID': getStorage('restaurant_id'),
        lang: navigator.language,
        'If-None-Match': ''
      },
      url: urlUpdateDeliveryInfo,
      method: 'PUT',
      data: arg['data']
    })
    return resp.data.response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const deliveryOrderSlice = createSlice({
  name: DELIVERY_ORDER_NAME,
  initialState,

  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(closeOrder.pending, (state) => {
      state.loading = true
    })
    builder.addCase(closeOrder.fulfilled, (state) => {
      state.status = 'idle'
      state.loading = false
    })
    builder.addCase(closeOrder.rejected, (state) => {
      state.loading = false
      LanguageDialog({ from: closeOrder })
    })
    builder.addCase(uploadReceipt.pending, (state) => {
      state.loading = true
    })
    builder.addCase(uploadReceipt.fulfilled, (state) => {
      state.status = 'idle'
      state.loading = false
    })
    builder.addCase(uploadReceipt.rejected, (state) => {
      state.loading = false
      LanguageDialog({ from: uploadReceipt })
    })
    builder.addCase(updateDeliveryInfo.pending, (state) => {
      state.loading = true
    })
    builder.addCase(updateDeliveryInfo.fulfilled, (state) => {
      state.status = 'idle'
      state.loading = false
    })
    builder.addCase(updateDeliveryInfo.rejected, (state) => {
      state.loading = false
      LanguageDialog({ from: updateDeliveryInfo })
    })
  }
})

export const deliveryOrderActions = { closeOrder, uploadReceipt, updateDeliveryInfo }

export default deliveryOrderSlice.reducer
