import {
  Card,
  CardContent,
  Grid,
  ListItem,
  Typography,
  darken, List,
} from '@mui/material'
import { blueGrey } from '@mui/material/colors'
import { styled } from '@mui/system'
import { formatCost } from '../../../utils'
import styles from './TableDetailItem.module.css'
import EmptyImage from '../../../assets/images/image_empty.png'
import Image from '../../../components/Image'

const CardComponent = styled(Card)(
  ({ theme }) =>
    `
  color: ${theme.palette.primary.main};
  background-color:${blueGrey};
  border-radius:1rem;
  padding:.5rem;
  :hover {
    color: ${darken(theme.palette.primary.main, 0.2)};
  }
`
)

const TableDetailItem = ({ orderItem }) => {
  return (
    <ListItem>
      <CardComponent sx={{ width: '100%', display: 'flex' }}>
        <Grid
          container
          justifyContent="space-between"
          alignContent={'center'}
        >
          {orderItem.product_image && !orderItem.product_image.toString().includes('missing') &&
            <Grid container item xs={4} sm={3} alignItems="center">
              <Image
                style={{ height: 110, borderRadius: '.8rem', width: '100%', objectFit: 'cover' }}
                src={orderItem.product_image}
                placeholderImg={EmptyImage}
              />
            </Grid>}
          <Grid container item
                xs={orderItem.product_image && !orderItem.product_image.toString().includes('missing') ? 8 : 12}
                sm={orderItem.product_image && !orderItem.product_image.toString().includes('missing') ? 9 : 12}>
            <CardContent
              sx={{ overflow: 'hidden', p: 1, width: '100%' }}
            >
              <Grid
                container
                justifyContent="space-between"
                justifyItems="space-between"
                alignItems={'center'}
              >
                <Grid
                  container
                  item
                  xs={8}
                  sm={10}
                  justifyContent="flex-start"
                >
                  <Typography
                    gutterBottom
                    variant="subtitle2"
                    fontWeight={'bold'}
                    noWrap
                    sx={{ textOverflow: 'ellipsis' }}
                  >
                    {orderItem.product_name}
                  </Typography>
                </Grid>
                <Grid container item xs={4} sm={2} justifyContent="flex-end">
                  <Typography
                    gutterBottom
                    variant="subtitle2"
                    fontWeight={'bold'}
                  >
                    {formatCost(orderItem.amount)}
                  </Typography>
                </Grid>
              </Grid>
              <List>
                {orderItem.toppings_array !== undefined ? orderItem.toppings_array.map((topping) => (
                  <Typography key={topping._id} variant="body2" fontSize={10}>
                    {topping.name}
                  </Typography>
                )) : ''}
              </List>
              <Grid container spacing={2} alignItems="flex-end" justifyContent="flex-end">
                <Grid item xs={6}>
                  <div className={styles.row}>
                    <span className={styles.value}>{orderItem.quantity}</span>
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Grid>
        </Grid>
      </CardComponent>
    </ListItem>
  )
}

export default TableDetailItem