import { collection, doc, onSnapshot } from 'firebase/firestore'
import { db } from '../firebase'
import { restaurantsCollection } from './collections_names_firestore'
import { getStorage, saveStorage } from './storage'

const getKeywordsFromFirestore = async () => {
  const document = await doc(collection(db, restaurantsCollection), getStorage('restaurant_id'))
  await onSnapshot(document, (snapshot) => {
    if (snapshot && snapshot?.data() && snapshot?.data()?.keywords) {
      const value = JSON.stringify(snapshot.data().keywords)
      saveStorage('keywords', value)
    }
  })
}

export default getKeywordsFromFirestore
