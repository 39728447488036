import Grid from '@mui/material/Grid'
import styles from './ProductItem.module.css'
import { ListItemText, Typography } from '@mui/material'
import { formatCost } from '../../../utils'
import { theme } from '../../../index'

const PremiumProductItem = ({ product, navigateToProduct, fontFamily }) => {


  return (
    <Grid
      key={product._id}
      direction="row"
      container
      className={styles.item}
      onClick={() => navigateToProduct(product)}
      alignItems="flex-start"
      justifyContent="center"
    >
      <Grid item xs={12} sm={12}>
        <ListItemText
          primary={
            <Typography variant="body2" fontFamily={fontFamily} sx={{ color: theme.palette.info.main, fontSize: '18px' }}>
              {product.name}
            </Typography>
          }
          secondary={
            <Typography
              variant="body1"
              fontFamily={fontFamily}
              dangerouslySetInnerHTML={{ __html: product.description.length > 100 ? `${product.description.toString().substring(0, 100)}...` : product.description.toString() }}
              className={styles.font4}
            />
          }
        />
        <Typography variant="body1" className={styles.font3} fontFamily={fontFamily}>
          {formatCost(product.price)}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default PremiumProductItem
