import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Axios from 'axios'
import { urlAskPayBill, urlCancelUserOrder, urlConfirmTableOrder } from '../../apis/tableOrder'
import LanguageDialog from '../../components/dialogs/LanguageDialog'
import { getStorage } from '../../utils/storage'

export const TABLE_ORDER_NAME = 'table_order'

const initialState = {
  value: null,
  status: 'idle',
  loading: false
}

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const confirmTableOrder = createAsyncThunk('confirmTableOrder', async () => {
  const resp = await Axios({
    headers: {
      Authorization: `Bearer ${getStorage('st')}`,
      'X-PI-TABLE-TOKEN': getStorage('tt'),
      'X-PI-RESTAURANT-ID': getStorage('restaurant_id'),
      lang: navigator.language,
      'If-None-Match': ''
    },
    url: urlConfirmTableOrder,
    method: 'PUT'
  })
  return resp.data.response
})

export const askPayBill = createAsyncThunk('askPayBill', async () => {
  const resp = await Axios({
    headers: {
      Authorization: `Bearer ${getStorage('st')}`,
      'X-PI-TABLE-TOKEN': getStorage('tt'),
      'X-PI-RESTAURANT-ID': getStorage('restaurant_id'),
      lang: navigator.language,
      'If-None-Match': ''
    },
    url: urlAskPayBill,
    method: 'PUT'
  })
  return resp.data.response
})

export const cancelUserOrder = createAsyncThunk('cancelUserOrder', async () => {
  const resp = await Axios({
    headers: {
      Authorization: `Bearer ${getStorage('st')}`,
      'X-PI-TABLE-TOKEN': getStorage('tt'),
      'X-PI-RESTAURANT-ID': getStorage('restaurant_id'),
      lang: navigator.language,
      'If-None-Match': ''
    },
    url: urlCancelUserOrder,
    method: 'PUT'
  })
  return resp.data.response
})

export const tableOrderSlice = createSlice({
  name: TABLE_ORDER_NAME,
  initialState,

  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(confirmTableOrder.pending, (state) => {
      state.loading = true
    })
    builder.addCase(confirmTableOrder.fulfilled, (state, action) => {
      state.value = action.payload
      state.loading = false
    })
    builder.addCase(confirmTableOrder.rejected, (state) => {
      state.status = 'error'
      state.loading = false
      LanguageDialog({ from: confirmTableOrder })
    })
    builder.addCase(askPayBill.pending, (state) => {
      state.loading = true
    })
    builder.addCase(askPayBill.fulfilled, (state, action) => {
      state.value = action.payload
      state.loading = false
    })
    builder.addCase(askPayBill.rejected, (state) => {
      state.status = 'error'
      state.loading = false
      LanguageDialog({ from: askPayBill })
    })
    builder.addCase(cancelUserOrder.pending, (state) => {
      state.loading = true
    })
    builder.addCase(cancelUserOrder.fulfilled, (state) => {
      state.loading = false
    })
    builder.addCase(cancelUserOrder.rejected, (state) => {
      state.status = 'error'
      state.loading = false
      LanguageDialog({ from: cancelUserOrder })
    })
  }
})

export const tableOrderActions = { confirmTableOrder, askPayBill, cancelUserOrder }

export default tableOrderSlice.reducer
