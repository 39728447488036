import { fetchToken, onMessageListener } from '../firebase'
import { useEffect, useRef } from 'react'
import NotificationSound from '../assets/sound/sound.mp3'

export const FirebaseNotifications = (props) => {
  const audioPlayer = useRef(null)

  useEffect(() => {
    initData()
  }, [])

  const initData = async() => {
    await fetchToken()
    await onMessageListener({ audioPlayer })
  }

  return (
    <div>
      {props.children}
      <audio ref={audioPlayer} src={NotificationSound}/>
    </div>

  )
}
