import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Axios from 'axios'
import { urlRestaurantData, urlCategories, urlRemoveUser } from '../../apis/restaurant'
import LanguageDialog from '../../components/dialogs/LanguageDialog'
import { getStorage } from '../../utils/storage'
import { saveRestaurantData } from '../../utils/saveRestaurantData'

export const RESTAURANT_NAME = 'restaurant'

const initialState = {
  restaurantData: null,
  restaurantStatus: 'idle',
  loading: false,
  categories: null,
  categoriesStatus: 'idle',
  restaurantError: false,
  error: null
}

export const restaurantData = createAsyncThunk('restaurantData', async (arg,{ rejectWithValue }) => {
  try {
    const resp = await Axios({
      headers: {
        Authorization: `Bearer ${getStorage('st')}`,
        lang: navigator.language,
        'If-None-Match': ''
      },
      url: urlRestaurantData(getStorage('table_uid')),
      method: 'GET'
    })
    return resp.data.response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getCategories = createAsyncThunk('getCategories', async () => {
  const resp = await Axios({
    headers: {
      Authorization: `Bearer ${getStorage('st')}`,
      'X-PI-TABLE-TOKEN': getStorage('tt'),
      'X-PI-RESTAURANT-ID': getStorage('restaurant_id'),
      lang: navigator.language,
      'If-None-Match': ''
    },
    url: urlCategories,
    method: 'GET'
  })
  return resp.data.response
})

export const removeUserOrder = createAsyncThunk('removeUserOrder', async () => {
  const resp = await Axios({
    headers: {
      Authorization: `Bearer ${getStorage('st')}`,
      'X-PI-TABLE-TOKEN': getStorage('tt'),
      'X-PI-RESTAURANT-ID': getStorage('restaurant_id'),
      lang: navigator.language,
      'If-None-Match': ''
    },
    url: urlRemoveUser,
    method: 'PUT'
  })
  return resp.data.response
})

export const restaurantSlice = createSlice({
  name: RESTAURANT_NAME,
  initialState,

  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(restaurantData.pending, (state) => {
      state.loading = true
    })
    builder.addCase(restaurantData.fulfilled, (state, action) => {
      state.restaurantData = action.payload
      state.loading = false
      if (state.restaurantData != null) {
        saveRestaurantData(state.restaurantData)
      }
    })
    builder.addCase(restaurantData.rejected, (state, action) => {
      state.restaurantStatus = 'error'
      state.loading = false
      state.restaurantError = true
      state.restaurantData = null
      state.error = action.payload
      if (state.error.message.table) {
        LanguageDialog({from: restaurantData, text: state.error.message.table})
      }
      if (state.error.message.token) {
        LanguageDialog({from: restaurantData, text: state.error.message.token})
      }
    })
    builder.addCase(getCategories.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getCategories.fulfilled, (state, action) => {
      state.categories = action.payload
      state.loading = false
    })
    builder.addCase(getCategories.rejected, (state) => {
      state.categoriesStatus = 'error'
      state.categories = null
      //LanguageDialog({from: getCategories})
    })
    builder.addCase(removeUserOrder.pending, (state) => {
      state.loading = true
    })
    builder.addCase(removeUserOrder.fulfilled, (state) => {
      state.loading = false
    })
    builder.addCase(removeUserOrder.rejected, (state) => {
      state.categoriesStatus = 'error'
      state.loading = false
      state.categories = null
      LanguageDialog({from: removeUserOrder})
    })
  }
})

export const restaurantActions = { restaurantData, getCategories, removeUserOrder }

export default restaurantSlice.reducer
