import { makeStyles, styled } from '@mui/styles'
import { Typography, Avatar } from '@mui/material'
import { colorShade } from '../../../utils/colorShade'

export const deliveryOrderStyles = makeStyles(theme => ({
  titleLeft: {
    color: 'white'
  },
  titleRight: {
    color: 'white',
    textAlign: 'right'
  },
  headerCard: {
    marginTop: 10,
    marginBottom: 10
  },
  buttonHeader: {
    color: 'white !important'
  },
  summaryCard: {
    marginTop: 10,
    marginBottom: 10
  },
  circleMe: {
    display: 'grid',
    placeItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#57a496',
    borderRadius: '50%',
    width: 30,
    height: 30,
    marginRight: 10
  },
  titleCircleMe: {},
  divider: {
    color: 'white',
    marginLeft: '10 !important',
    marginRight: '10 !important',
  },
  myOrderState: {
    background: '#57a496 !important',
    borderRadius: '5px !important',
    color: 'white',
    fontWeight: 'bold',
    paddingTop: 5,
    paddingBottom: 5,
  },
  headerSection: {
    background: '#F0F0F0',
    paddingTop: 5,
    paddingBottom: 5,
  },
  photo: {
    borderRadius: '5px',
    width: '100%',
    height: '400px',
    marginBottom: '32px',
    objectFit: 'contain',
    marginTop: 10
  },
  buttonUpload: {
    width: '95%',
    outline: '0px',
    border: '0px',
    //fontWeight: 600,
    //fontSize: ' 0.90rem',
    //lineHeight: '1.75',
    height: '20px',
    minWidth: '64px',
    padding: '6px 16px',
    borderRadius: '4px',
    color: 'white',
    textAlign: 'center',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: colorShade(theme.palette.primary.main, -60),
    }
  },
  driverSubDetailText: {
    color: 'gray',
    textAlign: 'center',
    fontWeight: 'bold'
  },
  driverCard: {
    padding: '16px',
    alignItems: 'center',
    borderRadius: '.4rem',
    backgroundColor: 'white',
    direction: 'row'
  },
  driverDetailFirstColumn: {
    direction: 'column',
    alignItems: 'flex-start',
    paddingLeft: '24px'
  },
  driverDetailSecondColumn: {
    direction: 'column',
    alignItems: 'flex-end'
  },
  grid: {
    padding: '16px',
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: '.4rem'
  },
  grid2: {
    paddingLeft: '24px',
    paddingRight: '24px',
    paddingTop: '24px',
    paddingBottom: '12px',
    alignItems: 'flex-start',
    alignContent: 'flex-start'
  },
  grid3: {
    paddingLeft: '24px',
    alignItems: 'flex-start'
  },
  grid4: {
    alignItems: 'flex-start',
    alignContent: 'flex-start'
  },
  container: {
    p: 2,
    marginTop: '16px',
    marginBottom: '8px'
  },
  check: {
    width: '48px'
  }
}))

export const HoverOrder = (isHovering) => ({
  hover: {
    backgroundColor: isHovering ? 'lightgray' : '#F0F0F0',
      display: 'flex',
      borderRadius: '.2rem',
      padding: '2px',
      justifyContent: 'center',
      justifyItems: 'center',
      alignItems: 'center'

  }
})

export const ProductItem = styled(Avatar)({
  textAlign: 'left',
  borderRadius: 8,
  marginRight: 10
})

export const UserItem = styled(Typography)({
  textAlign: 'center',
  color: 'white',
  marginBottom: '0 !important',
  margin: '0 1rem !important'
})

export const MyQuantity = styled(Typography)({
  textAlign: 'center',
  color: 'white',
  borderRadius: '50%',
  marginBottom: '0 !important',
  margin: '0 1rem !important',
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center'
})
