import { collection, doc, onSnapshot, query, where } from 'firebase/firestore'
import { db } from '../../firebase'
import { tablesOrdersCollection, usersOrdersCollection } from '../../utils/collections_names_firestore'
import { useEffect, useState } from 'react'
import Loading from '../../components/Loading'
import Typography from '@mui/material/Typography'
import CallWaiter from '../../features/callWaiter/components/CallWaiter'
import { List } from '@mui/material'
import Button from '@mui/material/Button'
import TableDetailItem from '../../components/table/TableOrder/TableDetailItem'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Grid from '@mui/material/Grid'
import { MyQuantity } from '../table/TableOrder/TableOrderPage.style'
import getLocalKeyWords from '../../utils/local_keywords'
import { maxButtonWidth, maxMobileWidth, maxWebWidth } from '../../utils/screen_utils'
import { getStorage } from '../../utils/storage'

const TableOrderDetailPage = () => {
  const restaurant = useSelector((store) => store.restaurant.restaurantData)
  const [otherUsersOrders, setOtherUsersOrders] = useState({})
  const [keys, setKeys] = useState([])
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const [mobileView, setMobileView] = useState(false)

  const setResponsiveness = () => {
    return window.innerWidth < 900
      ? setMobileView(true)
      : setMobileView(false)
  }

  useEffect(() => {
    setResponsiveness()
    window.addEventListener('resize', () => setResponsiveness())
    return () => {
      window.removeEventListener('resize', () => setResponsiveness())
    }
  }, [])

  const intl = useIntl()

  useEffect(() => {
    getOtherUsersOrders()
  }, [])

  const getOtherUsersOrders = async () => {
    try {
      setLoading(true)
      const document = await query(collection(doc(collection(db, tablesOrdersCollection), getStorage('table_id')), usersOrdersCollection), where('user_id', '!=', getStorage('user_id')))
      await onSnapshot(document, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === 'added' || change.type === 'modified') {
            otherUsersOrders[change.doc.id] = change.doc.data()
            setKeys([...keys, change.doc.id])
          }
          if (change.type === 'removed') {
            Object.keys(otherUsersOrders).forEach(function (key) {
              if (key.match('^' + change.doc.id)) delete otherUsersOrders[key]
            })
          }
        })

        setOtherUsersOrders(otherUsersOrders)
        setLoading(false)
      })
    } catch (error) {
      setLoading(false)
    }
  }

  const navigateBack = () => {
    navigate(-1)
  }

  return (loading ? <Loading/> : otherUsersOrders ?
    <div style={{ justifyContent: 'center', display: mobileView ? 'inherit' : 'flex' }}>
      <div style={{ width: mobileView ? maxMobileWidth : maxWebWidth }}>
        {restaurant?.delivery ? <div/> :
          <div
            style={{ display: 'flex', justifyContent: 'space-between', padding: 16, alignItems: 'center' }}>
            <Typography variant="h5" style={{ paddingRight: 8, paddingTop: 8 }}>
              {intl.formatMessage({ id: 'tableOrder' }, {
                order_plural: getLocalKeyWords('order_plural'),
                order: getLocalKeyWords('order'),
                table: getLocalKeyWords('table')
              })}
            </Typography>
            <CallWaiter/>
          </div>
        }
        <List>
          {keys.map((key) => (
            <Grid key={key} container>
              <Grid item container direction={'row'} paddingTop={'16px'}>
                <MyQuantity sx={{
                  width: 30, height: 30, backgroundColor: 'primary.main'
                }}>{otherUsersOrders[key]?.user_name.charAt(0)}</MyQuantity>
                <Typography key={key} variant="h5">
                  {otherUsersOrders[key].user_name}
                </Typography>
              </Grid>
              {otherUsersOrders[key].order_items.map((orderItem) => (
                <TableDetailItem key={orderItem._id} orderItem={orderItem}/>
              ))}
            </Grid>
          ))
          }
        </List>
        <footer>
          <Button
            fullWidth={true}
            color="primary"
            variant="contained"
            onClick={navigateBack}
            sx={{
              borderRadius: '8rem',
              width: mobileView ? maxButtonWidth : maxWebWidth,
              position: 'fixed',
              bottom: 0,
              marginBottom: '8px',
              marginX: '8px'
            }}
          >
            {intl.formatMessage({ id: 'buttonBack' })}
          </Button>
        </footer>
      </div>
    </div> : <div/>)
}

export default TableOrderDetailPage
