import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import styles from './HomeButtons.module.css'
import { homeStyle } from './Home.style'

const BranchButton = ({ buttonName, onClick, restaurant_location }) => {
  const classes = homeStyle()

  return (
    <Grid container direction={'column'} className={styles.grid2}>
      <Button
        variant={'contained'}
        size={'large'}
        color={'info'}
        onClick={() => {onClick(restaurant_location)}}
        className={classes.button2}
      >
        {buttonName}
      </Button>
    </Grid>
  )
}
export default BranchButton
