import { useEffect, useState } from 'react'
import { collection, getDocs, query, where } from 'firebase/firestore'
import { db } from '../firebase'
import { usersOrdersCollection } from './collections_names_firestore'
import { getStorage } from './storage'

export const useShoppingCartItem = () => {
  const [hasItemInCart, setItemInCart] = useState({ hasItemInCart: false })

  useEffect(() => {
    checkForActiveOrder()
  }, [])

  const checkForActiveOrder = () => {
    const ordersQuery = query(collection(db, usersOrdersCollection), where('user_id', '==', getStorage('user_id')))
    getDocs(ordersQuery).then((querySnapshot) => {
      if (querySnapshot.docs.length > 0) {
        querySnapshot.forEach((doc) => {
          if (doc.data() !== null) {
            if (doc.data()) {
              if (doc.data().order_items !== undefined) {
                if (doc.data().order_items.length > 0) {
                  setItemInCart({ hasItemInCart: true })
                }
              }
            }
          }
        })
      }
    })
  }

  return hasItemInCart
}
