import { useDispatch, useSelector } from 'react-redux'
import { restaurantActions } from '../restaurant.slice'
import React, { useEffect, useState } from 'react'
import Loading from '../../../components/Loading'
import { db, fetchToken } from '../../../firebase'
import { collection, query, where, onSnapshot } from 'firebase/firestore'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { usersOrdersCollection } from '../../../utils/collections_names_firestore'
import { updateTheme } from '../../theme/Theme.slice'
import { InfoDialog } from '../../../components/dialogs/InfoDialog'
import { useIntl } from 'react-intl'
import getLocalKeyWords from '../../../utils/local_keywords'
import FooterMenu from './FooterMenu'
import ShoppingCartDialog from '../../shoppingCart/components/ShoppingCartDialog'
import UpdateUserDataDialog from '../../userdata/components/UpdateUserDataDialog'
import { shoppingCartActions } from '../../shoppingCart/ShoppingCart.slice'
import AddressesDialog from '../../addresses/components/AddressesDialog'
import { getStorage, saveStorage } from '../../../utils/storage'
import { background, container3 } from '../../../components/Background.style'
import CookieNotice from '../../../components/CookieNotice'
import ProductsEmpty from './ProductsEmpty'
import ScheduleDialog from './ScheduleDialog'
import { neutralizeBack, revivalBack } from '../../../utils/navigation_util'
import EditOrderItemDialog from './EditOrderItemDialog'
import { productActions } from '../../product/product.slice'
import ActiveOrder from '../../../components/ActiveOrder'
import { useActiveOrder } from '../../../utils/useActiveOrder'
import ProductDialog from '../../product/components/ProductDialog'
import MenuDrawer from '../../../components/drawer/MenuDrawer'
import PremiumMenu from './PremiumMenu'
import StandardMenu from './StandardMenu'
import { useSubscription } from '../../../utils/useSubscription'
import { paramIdFromHash } from '../../../utils/paramIdFromHash'
import BusinessDialog from '../../product/components/BusinessDialog'
import { browserName, browserVersion } from 'react-device-detect'
import packageJson from '../../../../package.json'
import { userActions } from '../../userdata/user.slice'
import { isBusinessClose } from '../../../utils/schedule_util'

const Menu = () => {
  const intl = useIntl()
  const loading = useSelector((store) => store.restaurant.loading)
  const categories = useSelector((store) => store.restaurant.categories)
  const [filteredCategories, setFilteredCategories] = useState(null)
  const [filterText, setFilterText] = useState('')
  const restaurantData = useSelector((store) => store.restaurant.restaurantData)
  const dispatch = useDispatch()
  const [count, setCount] = useState(0)
  const [showMenu, setShowMenu] = useState(false)
  const navigate = useNavigate()
  const loadingLogin = useSelector((store) => store.user.loading)
  const loadingProduct = useSelector((store) => store.product.loading)
  const [mobileView, setMobileView] = useState(false)
  const [open, setOpen] = useState(false)
  const [openUpdateUserData, setOpenUpdateUserData] = useState(false)
  const [openAddressDialog, setOpenAddressDialog] = useState(false)
  const [openScheduleDialog, setOpenScheduleDialog] = useState(false)
  const [openEditProductDialog, setOpenEditOrderItemDialog] = useState(false)
  const [orderItemToEdit, setOrderItemToEdit] = useState(null)
  const [openProductDialog, setOpenProductDialog] = useState(false)
  const [openBusinessDialog, setOpenBusinessDialog] = useState(false)
  const [orderItems, setOrderItems] = useState(null)
  const [userOrder, setUserOrder] = useState(null)
  const product = useSelector((store) => store.product.value)
  const { hasActiveOrder } = useActiveOrder()
  const { state } = useLocation()
  const { fromActiveOrder } = state || {}
  const isPremium = useSubscription()
  const params = useParams()
  const { hash } = useLocation()

  const setResponsiveness = () => {
    return window.innerWidth < 900
      ? setMobileView(true)
      : setMobileView(false)
  }

  useEffect(() => {
    setShowMenu(true)
    saveStorage('temp_table_uid', getStorage('table_uid'))
    setResponsiveness()
    window.addEventListener('resize', () => setResponsiveness())
    return () => {
      window.removeEventListener('resize', () => setResponsiveness())
    }
  }, [])

  useEffect(() => {
    if (showMenu === true)
      if (getStorage('read_only') === 'true') {
        dispatch(restaurantActions.restaurantData()).unwrap().catch(() => {
          navigate('/exit')
        }).then(() => {
          dispatch(restaurantActions.getCategories()).unwrap().catch(() => {
            const tableId = params.id ? params.id : paramIdFromHash(hash)
            navigate(`/${tableId}`)
          })
          dispatch(getOrder)
          dispatch(updateTheme())
        })
      } else {
        dispatch(restaurantActions.getCategories()).unwrap().catch(() => {
          const tableId = params.id ? params.id : paramIdFromHash(hash)
          navigate(`/${tableId}`)
        })
        dispatch(getOrder)
        dispatch(updateTheme())
        if (restaurantData == null) {
          getRestaurantData()
        }
        sendFcmToken()
      }
  }, [showMenu])

  useEffect(() => {
    setFilteredCategories(categories)
  }, [categories])

  useEffect(() => {
    if (filterText !== null && filterText !== undefined && filterText !== '') {
      let dummyListData = []
      if (categories) {
        const categoriesFiltered = categories.filter((category) => category.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(filterText.toLowerCase()))
        dummyListData.push(...categoriesFiltered)
        for (const item of categories) {
          const products = item.products
          const productFiltered = products.filter((product) => product.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(filterText.toLowerCase()))
          const categoryIndex = (dummyListData.findIndex((i) => item._id === i._id))
          if (productFiltered !== null && productFiltered !== undefined && productFiltered.length > 0 && categoryIndex === -1) {
            const category = {
              name: item.name, _id: item._id, products: productFiltered,
            }
            dummyListData.push(category)
          }
        }
        setFilteredCategories(dummyListData)
      }
    } else {
      setFilteredCategories(categories)
    }
  }, [filterText])

  useEffect(() => {
    if (orderItemToEdit !== null) {
      dispatch(productActions.getProductAsync(orderItemToEdit.product_id)).then(() => {
        setOpenEditOrderItemDialog(true)
        neutralizeBack(() => handleCloseEditOrderItemDialog())
      })
    }
  }, [orderItemToEdit])

  const handleCloseEditOrderItemDialog = () => {
    setOpenEditOrderItemDialog(false)
    setOrderItemToEdit(null)
    revivalBack()
  }

  const sendFcmToken = () => {
    fetchToken().then(() => {
      const params = {
        device: {
          'model': browserName,
          'brand': 'Web',
          'os': 'Web',
          'os_version': browserVersion,
          'push_token': getStorage('fcm_token'),
          'app_version': packageJson.version,
          'lang': window.language
        }
      }
      dispatch(userActions.updatedDeviceData(params))
    })
  }

  const getRestaurantData = () => {
    dispatch(restaurantActions.restaurantData()).unwrap().catch(() => {
      navigate('/exit')
    })
  }

  const getOrder = () => {
    const orderQuery = query(collection(db, usersOrdersCollection), where('user_id', '==', getStorage('user_id')), where('table_id', '==', getStorage('table_id')))
    onSnapshot(orderQuery, (querySnapshot) => {
      querySnapshot.docChanges().forEach((change) => {
        if (change.type === 'added' || change.type === 'modified') {
          setUserOrder(change.doc.data())
          setOrderItems(change.doc.data().order_items)
          setCount(change.doc.data().order_items.length)
          saveStorage('user_order_id', change.doc.data().user_order_id)
        }
        if (change.type === 'removed') {
          setCount(0)
          navigate('/exit')
          setShowMenu(false)
        }
      })
    })
  }

  const navigateToShoppingCart = () => {
    if (count > 0) {
      setOpen(true)
      neutralizeBack(() => handleCloseShoppingCartDialog())
    } else {
      InfoDialog({
        text: intl.formatMessage({ id: 'mustAddProductsToYourOrder' }, {
          order: getLocalKeyWords('order'),
          products: getLocalKeyWords('products')
        })
      })
    }
  }

  const handleCloseShoppingCartDialog = () => {
    setOpen(false)
    revivalBack()
  }

  const confirmUserOrder = () => {
    if (hasActiveOrder === false) {

      if (userOrder.user_name === '' || userOrder.user_name === 'Anónimo') {
        setOpenUpdateUserData(true)
        setOpen(false)
        neutralizeBack(() => handleCloseUpdateUserDialog())
      } else {
        if (restaurantData?.delivery === true) {
          setOpenUpdateUserData(true)
          setOpen(false)
          neutralizeBack(() => handleCloseUpdateUserDialog())
        } else {
          dispatch(shoppingCartActions.confirmUserOrder()).then(() => navigate(`/tableOrder/${getStorage('table_uid')}`, { state: { fromOrder: true } }))
        }
      }
    } else {
      if (restaurantData?.delivery === true) {
        navigate(`/deliveryOrder/${getStorage('table_uid')}`, { state: { fromOrder: true } })
      } else {
        navigate(`/tableOrder/${getStorage('table_uid')}`, { state: { fromOrder: true } })
      }

    }
  }

  const handleCloseUpdateUserDialog = () => {
    setOpenUpdateUserData(false)
    revivalBack()
  }

  const setOpenAddressesDialog = () => {
    setOpenAddressDialog(true)
    neutralizeBack(() => handleCloseAddressDialog())
    setOpenUpdateUserData(false)
    setOpen(false)
  }

  const handleCloseAddressDialog = () => {
    setOpenAddressDialog(false)
    revivalBack()
  }

  const resetSearch = () => {
    setFilterText('')
  }

  const scrollToSearchBar = () => {
    let scrollValue
    if (restaurantData?.delivery === true && restaurantData.restaurant?.address) {
      scrollValue = 460
    } else {
      scrollValue = 420
    }
    window.scrollTo({ top: scrollValue, behavior: 'smooth' })
  }

  const onBackPressed = () => {
    if (fromActiveOrder === true && openUpdateUserData === false && open === false && openUpdateUserData === false && openScheduleDialog === false && openEditProductDialog === false && openAddressDialog === false && openProductDialog === false && openBusinessDialog === false) {
      navigate(`/${getStorage('table_uid')}`)
    } else {
      navigate(-1)
    }
  }

  const readOnly = () => {
    return !!(restaurantData !== null && restaurantData.restaurant?.read_only)
  }

  const isPremiumMenu = () => {
    return restaurantData?.restaurant?.has_premium_menu_active === true && isPremium === true
  }

  const isShoppingCartDisabled = () => {
    return readOnly() || isBusinessClose(restaurantData) || (restaurantData?.delivery === false && isPremium === false)
  }

  return (

    loading ? <Loading/> : showMenu ?
      <div style={background(mobileView)}>
        <div style={container3(mobileView, filteredCategories, isPremiumMenu())}>
          <ActiveOrder/>
          <MenuDrawer showAtBottom={false} mobileView={mobileView}/>
          {isPremium === true && isPremiumMenu() === true && filteredCategories && filteredCategories.length > 0 &&
            <PremiumMenu
              filteredCategories={filteredCategories}
              userOrder={userOrder}
              mobileView={mobileView}
              setOpen={setOpenProductDialog}
              onBackPressed={onBackPressed}
              restaurantData={restaurantData}
              count={count}
              filterText={filterText}
              setFilterText={setFilterText}
              navigateToShoppingCart={readOnly() ? undefined : navigateToShoppingCart}
              readOnly={readOnly}
              setOpenBusinessDialog={setOpenBusinessDialog}
            />}
          {(isPremium === false || isPremiumMenu() === false) && filteredCategories && filteredCategories.length > 0 &&
            <StandardMenu
              mobileView={mobileView}
              setOpen={setOpenProductDialog}
              userOrder={userOrder}
              filteredCategories={filteredCategories}
              onBackPressed={onBackPressed}
              restaurantData={restaurantData}
              count={count}
              filterText={filterText}
              setFilterText={setFilterText}
              navigateToShoppingCart={isShoppingCartDisabled() ? undefined : navigateToShoppingCart}
              scrollToSearchBar={scrollToSearchBar}
              setOpenScheduleDialog={setOpenScheduleDialog}
              readOnly={readOnly}
              setOpenBusinessDialog={setOpenBusinessDialog}
            />
          }
          {open === true &&
            <ShoppingCartDialog
              mobileView={mobileView}
              open={open}
              setOpen={handleCloseShoppingCartDialog}
              orderItems={orderItems}
              confirmUserOrder={confirmUserOrder}
              setOrderItemToEdit={setOrderItemToEdit}
              userOrder={userOrder}
            />}
          {openProductDialog === true &&
            <ProductDialog
              mobileView={mobileView}
              product={product}
              open={openProductDialog}
              setOpen={setOpenProductDialog}
              userOrder={userOrder}
            />}
          {openBusinessDialog === true &&
            <BusinessDialog product={product} open={openBusinessDialog} setOpen={setOpenBusinessDialog}/>
          }
          {openEditProductDialog === true &&
            <EditOrderItemDialog
              mobileView={mobileView}
              orderItem={orderItemToEdit}
              open={openEditProductDialog}
              handleClose={handleCloseEditOrderItemDialog}
              product={product}
            />
          }
          {openUpdateUserData === true &&
            <UpdateUserDataDialog
              open={openUpdateUserData}
              setOpen={setOpenUpdateUserData}
              mobileView={mobileView}
              setOpenAddressesDialog={setOpenAddressesDialog}
            />}
          {openAddressDialog === true &&
            <AddressesDialog mobileView={mobileView} setOpen={setOpenAddressDialog} open={openAddressDialog}/>
          }
          {openScheduleDialog === true &&
            <ScheduleDialog mobileView={mobileView} open={openScheduleDialog} setOpen={setOpenScheduleDialog}/>
          }
          {categories && categories.length > 0 && filteredCategories && filteredCategories.length === 0 &&
            <ProductsEmpty resetSearch={resetSearch}/>
          }
          <FooterMenu categories={filteredCategories?.length}/>
        </div>
        <CookieNotice/>
      </div> : loading || loadingLogin || loadingProduct ? <Loading/> : <div/>
  )
}

export default Menu
