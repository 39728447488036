import Grid from '@mui/material/Grid'
import styles from './SubHeaderMenu.module.css'
import ProductSearch from './ProductSearch'
import React from 'react'
import { useSubscription } from '../../../utils/useSubscription'
import PremiumShoppingCart from './PremiumShoppingCart'
import BackButton from '../../../components/buttons/BackButton'

const PremiumSubHeader = ({
  filterText,
  setFilterText,
  mobileView,
  count,
  navigateToShoppingCart,
  onBackPressed
}) => {
  const isPremium = useSubscription()

  return (
    (mobileView === true || isPremium === true) &&
    <Grid container direction={'row'} className={mobileView === true ? styles.grid : styles.grid1}>
      <Grid item xs={12} container direction={'row'} className={styles.container}>
        {mobileView === true &&
          <Grid item xs={1.2}>
            <BackButton backAction={onBackPressed} />
          </Grid>}
        <Grid
          container
          item
          xs={mobileView === true ? 8 : 12}
          sm={mobileView === true ? 8 : 12}
          md={mobileView === true ? 8 : 12}
          lg={mobileView === true ? 8 : 12}
          xl={mobileView === true ? 8 : 12}
        >
          <ProductSearch text={filterText} setText={(text) => setFilterText(text)}/>
        </Grid>
        {mobileView === true && navigateToShoppingCart &&
          <PremiumShoppingCart navigateToShoppingCart={navigateToShoppingCart} count={count}/>
        }
      </Grid>
    </Grid>
  )
}

export default PremiumSubHeader
