import { styled } from '@mui/styles'
import Badge from '@mui/material/Badge'

export const StyledBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    right: -2,
    top: 10,
    padding: '0 2px',
    fontSize: '12px'
  },
}))

export const StyledMiniBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    right: 6,
    top: 2,
    padding: '0 2px',
    fontSize: '12px'
  },
}))

export const SubHeaderMenuStyle = () => ({
  avatar: {
    width: '50px',
    height: '50px',
    marginLeft: '8px',
    marginRight: '8px',
    zIndex: 1
  },
})
