import * as React from 'react'
import {
  Avatar, Checkbox, ListItem, ListItemAvatar, ListItemText, ListSubheader, Typography
} from '@mui/material'
import Grid from '@mui/material/Grid'
import styles from '../UserOrderDeliveryPayBill/PaymentMethodUserOrder.module.css'
import { grey } from '@mui/material/colors'
import icBank from '../../../assets/images/ic_bank.png'
import icCash from '../../../assets/images/ic_cash.png'
//import { urlIfy } from '../../../utils/util'
import { useIntl } from 'react-intl'
import ImagePaymentMethodDialog from '../../dialogs/ImagePaymentMethodDialog'
import { urlIfy } from '../../../utils/util'

const PaymentMethodUserOrder = ({ table, payment, setPayment }) => {
  const intl = useIntl()

  return (<div>
    <ListSubheader className={styles.headerSection}>
      <Grid container alignItems="center">
        <Typography variant="body1" color="black" fontWeight={'bold'}>
          {intl.formatMessage({ id: 'paymentMethod' })}
        </Typography>
      </Grid>
    </ListSubheader>
    <div>
      {table.payment_method_options && table.payment_method_options.map((paymentItem) => (
        <div key={paymentItem.method} className={styles.item}>
          <ListItem
            className={styles.item}
            key={`${paymentItem.method}`}
            secondaryAction={<Checkbox
              edge="end"
              sx={{
                color: grey, '&.Mui-checked': {
                  color: '#57a496'
                }
              }}
              onChange={() => setPayment(paymentItem.method)}
              checked={payment === paymentItem.method}
            />}
          >
            <ListItem className={styles.itemText}>
              <ListItemAvatar className={styles.itemPhoto}>
                <Avatar
                  alt={paymentItem.method}
                  className={styles.avatar}
                  variant="square"
                  src={paymentItem.method === 'wire_transfer' ? icBank : icCash}
                />
              </ListItemAvatar>
              <div>
                <ListItemText
                  id={paymentItem.method}
                  primary={paymentItem.label}
                />
                {paymentItem.data !== null && paymentItem.data !== '' && paymentItem.data !== undefined ? <Typography
                  fontWeight={'bold'}>
                  {intl.formatMessage({ id: 'paymentInformation' })}
                </Typography> : <div/>}
                {paymentItem.data !== null && paymentItem.data !== '' && paymentItem.data !== undefined ? <div>
                  <div dangerouslySetInnerHTML={{ __html: urlIfy(paymentItem.data) }}/>
                </div> : <div/>}
                {paymentItem.image !== null && paymentItem.image !== '' && paymentItem.image !== undefined &&
                  <ImagePaymentMethodDialog paymentData={paymentItem}/>}
              </div>
            </ListItem>
          </ListItem>
        </div>))}
    </div>
  </div>)
}

export default PaymentMethodUserOrder