import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import { useState } from 'react'
import List from '@mui/material/List'
import ProductHeader from '../../../components/product/DetailProduct/ProductHeader'
import ToppingsProduct from '../../../components/product/DetailProduct/ToppingsProduct'
import { useSelector } from 'react-redux'
import { Button, DialogTitle, Grid, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import styles from './ProductDialog.module.css'
import Loading from '../../../components/Loading'
import { BootstrapDialogStyle } from '../../../components/dialogs/BootstrapDialog.style'
import { revivalBack } from '../../../utils/navigation_util'
import { colorShade } from '../../../utils/colorShade'
import { theme } from '../../../index'
import { handleUrl } from '../../../utils/urlUtils'

const BusinessDialog = ({ open, setOpen, product }) => {
  const [selectedToppings, setSelectedToppings] = useState([])
  const loading = useSelector((store) => store.product.loading)
  const [toppingGroupRequired, setToppingGroupRequired] = useState(null)
  const restaurantData = JSON.parse(localStorage.getItem('restaurant_data'))

  const handleClose = () => {
    setOpen(false)
    revivalBack()
  }

  const dialogDismiss = (event, reason) => {
    if (reason && reason === 'backdropClick')
      return
    setOpen(false)
    revivalBack()
  }

  const openPage = (url) => {
    handleClose()
    window.open(url ? handleUrl(url) : 'https://google.com', '_self')
  }

  return (
    <div>
      <BootstrapDialogStyle
        onClose={dialogDismiss}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '550px',
            },
          },
          zIndex: 100
        }}
        fullScreen={true}
      >
        <DialogTitle sx={{ m: 0, p: 2, marginBottom: '8px' }}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 10,
              top: 8,
              marginBottom: 24,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon/>
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ overflow: 'auto' }}>
          <div style={{ justifyContent: 'center', display: 'flex' }}>
            <div style={{ width: '100%' }}>
              {loading && <Loading/>}
              <Grid container direction={'vertical'} maxHeight={'500px'}>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  sx={{
                    marginLeft: product.image !== null ? '22px' : '12px',
                    paddingRight: product.image !== null ? '8px' : '12px',
                  }}
                  className={styles.verticalView}>
                  <ProductHeader {...product} mobileView={true}/>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  sx={{
                    marginLeft: '18px',
                    paddingRight: '12px',
                  }}
                  className={styles.verticalView1}>
                  <List>
                    <ToppingsProduct
                      {...product}
                      selectedToppings={selectedToppings}
                      setSelectedToppings={(selectedToppings) => {
                        setSelectedToppings(selectedToppings)
                      }}
                      product={product}
                      toppingGroupRequired={toppingGroupRequired}
                      setToppingGroupRequired={setToppingGroupRequired}
                    />
                  </List>
                </Grid>
              </Grid>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            className={styles.button}
            onClick={() => openPage(product?.action_url)}
            sx={{
              '&:hover': {
                backgroundColor: colorShade(theme.palette.primary.main, -60),
              },
            }}
          >
            {restaurantData?.restaurant?.read_only_products_label}
          </Button>
        </DialogActions>
      </BootstrapDialogStyle>
    </div>
  )
}
export default BusinessDialog
