import Grid from '@mui/material/Grid'
import ProductSearch from './ProductSearch'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCartOutlined'
import React from 'react'
import styles from './SubHeaderMenu.module.css'
import { StyledBadge, SubHeaderMenuStyle } from './SubHeaderMenu.style'
import { Avatar, Typography } from '@mui/material'
import { useWindowScrollPositions } from '../../../utils/useWindowScrollPositions'

const SubHeaderMenu = ({
  filterText,
  setFilterText,
  mobileView,
  count,
  navigateToShoppingCart,
  restaurant,
}) => {
  const { scrollY } = useWindowScrollPositions()
  const classes = SubHeaderMenuStyle()

  const getSize = () => {
    let value = 12
    if (mobileView === false) {
      value = 6
    }
    return value
  }

  return (
    (scrollY > 190 || mobileView === true) && <Grid container direction={'row'} className={styles.grid}>
      <Grid item xs={6} container direction={'row'}>
        {(mobileView === false && scrollY > 190) &&
          <Grid item container direction={'row'} alignItems={'center'}>
            <Avatar style={classes.avatar} alt={restaurant?.name} src={restaurant?.logo}/>
            <Typography variant="h5" color="black" fontWeight={'bold'}>
              {restaurant?.name}
            </Typography>
          </Grid>
        }
      </Grid>
      <Grid item xs={getSize()} container direction={'row'} className={styles.container}>
        <Grid
          container item
          xs={navigateToShoppingCart ? 10.2 : 12}
          sm={navigateToShoppingCart ? 10.2 : 12}
          md={navigateToShoppingCart ? 10.2 : 12}
          lg={navigateToShoppingCart ? 10.2 : 12}
          xl={navigateToShoppingCart ? 10.2 : 12}
          className={styles.grid2}
        >
          <ProductSearch text={filterText} setText={(text) => setFilterText(text)} mobileView={mobileView}/>
        </Grid>
        {navigateToShoppingCart &&
          <Grid container item xs={1.8} sm={1.8} md={1.8} lg={1.8} xl={1.8} justifyContent={'center'} alignItems={'center'}>
            <StyledBadge overlap="circular" badgeContent={count} color="error">
              <ShoppingCartIcon fontSize={'large'} onClick={() => navigateToShoppingCart()} className={styles.icon}/>
            </StyledBadge>
          </Grid>}
      </Grid>
    </Grid>
  )
}

export default SubHeaderMenu
