import React, { useEffect, useState } from 'react'
import { collection, doc, onSnapshot, where, query, getDoc } from 'firebase/firestore'
import { db } from '../../../firebase'
import Loading from '../../../components/Loading'
import {
  deliveriesCollection, usersOrdersCollection
} from '../../../utils/collections_names_firestore'
import { useLocation, useNavigate } from 'react-router-dom'
import HeaderDeliveryOrder from '../../../components/deliveryOrder/DeliveryOrder/HeaderDeliveryOrder'
import MyDeliveryOrder from '../../../components/deliveryOrder/DeliveryOrder/MyDeliveryOrder'
import SummaryDeliveryOrder from '../../../components/deliveryOrder/DeliveryOrder/SummaryDeliveryOrder'
import BottomDeliveryOrder from '../../../components/deliveryOrder/DeliveryOrder/BottomDeliveryOrder'
import { useDispatch, useSelector } from 'react-redux'
import { deliveryOrderActions } from '../../../features/deliveryOrder/deliveryOrder.slice'
import { tableOrderActions } from '../../../features/table/table.slice'
import { updateTheme } from '../../../features/theme/Theme.slice'
import PaymentSummary from '../../../features/paymentSummary/components/PaymentSummary'
import { ConfirmDialog } from '../../../components/dialogs/ConfirmDialog'
import { useIntl } from 'react-intl'
import getLocalKeyWords from '../../../utils/local_keywords'
import { orderArrived } from '../../../utils/order_state_extension'
import Header from '../../../features/menu/components/Header'
import PaymentProofDialog from '../../../features/payDeliveryBill/components/PaymentProofDialog'
import { background, container } from '../../../components/Background.style'
import { getStorage } from '../../../utils/storage'
import ScheduleDialog from '../../../features/menu/components/ScheduleDialog'
import { neutralizeBack, revivalBack } from '../../../utils/navigation_util'
import { InfoDialog } from '../../../components/dialogs/InfoDialog'
import MenuDrawer from '../../../components/drawer/MenuDrawer'

const DeliveryOrderPage = () => {
  const [myOrder, setMyOrder] = useState(null)
  const [loading, setLoading] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const intl = useIntl()
  const loadingCancel = useSelector((store) => store.table_order.loading)
  const { state } = useLocation()
  let { fromOrder } = state || {}
  const [mobileView, setMobileView] = useState(false)
  const [table, setTable] = useState(null)
  const restaurantData = JSON.parse(localStorage.getItem('restaurant_data'))
  const [openScheduleDialog, setOpenScheduleDialog] = useState(false)

  const setResponsiveness = () => {
    return window.innerWidth < 900
      ? setMobileView(true)
      : setMobileView(false)
  }

  useEffect(() => {
    setResponsiveness()
    window.addEventListener('resize', () => setResponsiveness())
    return () => {
      window.removeEventListener('resize', () => setResponsiveness())
    }
  }, [])

  useEffect(() => {
    const getMyOrder = async () => {
      try {
        setLoading(true)
        const document = await query(collection(doc(collection(db, deliveriesCollection), getStorage('table_id')), usersOrdersCollection), where('user_id', '==', getStorage('user_id')))
        await onSnapshot(document, (snapshot) => {
          snapshot.docChanges().forEach((change) => {
            if (change.type === 'added' || change.type === 'modified') {
              setMyOrder(change.doc.data())
            }
            if (change.type === 'removed') {
              setMyOrder(null)
              navigate('/exit')
            }
          })
          setLoading(false)

        })
      } catch (error) {
        setLoading(false)
      }
    }

    if (fromOrder === undefined) {
      navigate(`/${getStorage('table_uid')}`)
    }
    dispatch(updateTheme())
    getMyOrder()
    getTable()
  }, [])

  useEffect(() => {
    if (myOrder && myOrder?.payment_method.method.includes('wire_transfer') && (myOrder?.wire_transfer_receipt_url === null || myOrder?.wire_transfer_receipt_url === undefined)) {
      if (myOrder.pickup_at_restaurant === false && (myOrder.fixed_delivery_amount !== null && myOrder.fixed_delivery_amount !== undefined && !myOrder.fixed_delivery_amount) && (myOrder.delivery_amount === null || myOrder.delivery_amount === undefined)) {
        InfoDialog({text: intl.formatMessage({ id: 'youNeedToWaitForDeliveryAmount' }, {order: getLocalKeyWords('order'), restaurant: getStorage('restaurant_name')})})
      } else {
        setOpenDialog(true)
        neutralizeBack(() => handleClosePaymentDialog())
      }
    }
  }, [myOrder])

  const handleClosePaymentDialog = () => {
    setOpenDialog(false)
    revivalBack()
  }

  const getTable = async () => {
    try {
      setLoading(true)
      const document = await getDoc(doc(collection(db, deliveriesCollection), getStorage('table_id')))
      setTable(document.data())

      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const resumeClick = () => {
    dispatch(deliveryOrderActions.closeOrder(myOrder.user_order_id))
  }

  const cancelOrder = () => {
    ConfirmDialog({
      text: intl.formatMessage({ id: 'wantToCancelQuestion' }, { order: getLocalKeyWords('order') }),
      confirmButtonText: intl.formatMessage({ id: 'buttonCancelOrder' }),
      cancelButtonText: intl.formatMessage({ id: 'buttonNo' }), //
      onConfirm: () => {
        dispatch(tableOrderActions.cancelUserOrder())
      },
      onCancel: () => {}
    })
  }

  const onBackPressed = () => {
    navigate(`/products/${getStorage('table_uid')}`,{ state: { fromActiveOrder: true } })
  }

  return (loadingCancel || loading ? <Loading/> :
      <div style={background(mobileView)}>
        <div style={container(mobileView)}>
          <MenuDrawer showAtBottom={!mobileView} mobileView={mobileView}/>
          <Header
            restaurant={restaurantData?.restaurant}
            isDelivery={restaurantData?.delivery}
            mobileView={mobileView}
            setOpenScheduleDialog={setOpenScheduleDialog}
            backAction={onBackPressed}
          />
          {myOrder && <HeaderDeliveryOrder myOrder={myOrder} table={table}/>}
          {myOrder &&
            <MyDeliveryOrder myOrder={myOrder} onCancelOrder={cancelOrder} mobileView={mobileView}/>
          }
          {myOrder && <SummaryDeliveryOrder myOrder={myOrder} table={table}/>}
          {openScheduleDialog === true &&
            <ScheduleDialog mobileView={mobileView} open={openScheduleDialog} setOpen={setOpenScheduleDialog}/>
          }
          <footer>
            {myOrder && myOrder?.payment_method.method.includes('wire_transfer') && myOrder.state !== orderArrived &&
              <PaymentProofDialog myOrder={myOrder} mobileView={mobileView} openDialog={openDialog} setOpenDialog={setOpenDialog}/>}
            {myOrder && <PaymentSummary isDelivery={true} userOrders={[myOrder]} mobileView={mobileView}/>}
            {(myOrder && myOrder.state === orderArrived) && <BottomDeliveryOrder resumeClick={resumeClick} mobileView={mobileView}/>}
          </footer>
        </div>
      </div>
  )
}

export default DeliveryOrderPage
