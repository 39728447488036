import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import {
  DialogTitle,
  IconButton,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Loading from '../../../components/Loading'
import styles from './OrdersHistory.module.css'
import { BootstrapDialogStyle } from '../../../components/dialogs/BootstrapDialog.style'
import Grid from '@mui/material/Grid'
import { colorShade } from '../../../utils/colorShade'
import { theme } from '../../../index'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { OrdersHistoryDialogStyle } from './OrdersHistoryDialog.style'
import { ordersHistoryActions } from '../OrdersHistory.slice'
import { formatCost } from '../../../utils'
import { formatDate } from '../../../utils/date_utils'
import Typography from '@mui/material/Typography'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

const OrdersHistoryDialog = ({ open, setOpen, mobileView, setOpenOrderDetailDialog, setOrderHistoryDetail }) => {
  const intl = useIntl()
  const loading = useSelector((store) => store.ordersHistory.loading)
  const classes = OrdersHistoryDialogStyle()
  const dispatch = useDispatch()
  const orders = useSelector((store) => store.ordersHistory.ordersHistoryValue)
  const meta = useSelector((store) => store.ordersHistory.metaValue)
  const [page, setPage] = useState(1)
  const perPage = 10

  useEffect(() => {
    getOrdersHistory()
  }, [])

  useEffect(() => {
    getOrdersHistory()
  }, [page])

  const getParams = () => {
    return {
      page: page,
      per_page: perPage
    }
  }

  const getOrdersHistory = () => {
    dispatch(ordersHistoryActions.getOrdersHistory(getParams()))
  }

  const handleChange = (event, value) => {
    setPage(value)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const dialogDismiss = (event, reason) => {
    if (reason && reason === 'backdropClick')
      return
    setOpen(false)
  }

  const openOrderHistoryDetail = (order) => {
    setOpenOrderDetailDialog(true)
    setOrderHistoryDetail(order)
  }

  const getProductsName = (order) => {
    let products = []
    let count = 0
    const maxLimit = 3
    for (const orderItem of order.order_items) {
      if (count < maxLimit) {
        products.push(orderItem.product_name)
        count++
      }
    }
    if (products !== []) {
      return products.join(', ')
    }
    return []
  }

  function Row (props) {
    const { row } = props

    return (
      <React.Fragment>
        <TableRow
          key={row._id}
          sx={{
            '&:last-child td, &:last-child th': { border: 0 },
            '& > *': { borderBottom: 'unset !important;' },
            'borderBottom': 'none !important;',
            'borderCollapse': "collapse !important;",
          }}
        >
          <TableCell align="left" component="th">
            {row.consecutive}
          </TableCell>
          <TableCell align="center">{formatDate(row.updated_at)}</TableCell>
          <TableCell align="center">{formatCost(row.total_amount)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" >
              <Grid item container xs={4} justifyContent="flex-start">
                <Typography variant="body2" gutterBottom component="div">
                  {intl.formatMessage({ id: 'items' })}
                </Typography>
              </Grid>
              <Grid item container xs={4} justifyContent="center">
                <Typography variant="body2" gutterBottom component="div">
                  {getProductsName(row)}
                </Typography>
              </Grid>
              <Grid item container xs={4} justifyContent="flex-end">
                <Button size="small" endIcon={<ArrowForwardIosIcon/>} onClick={() => openOrderHistoryDetail(row)}>
                  {intl.formatMessage({ id: 'details' })}
                </Button>
              </Grid>
            </Grid>
          </TableCell>
        </TableRow>
      </React.Fragment>
    )
  }

  return (
    <div>
      <BootstrapDialogStyle
        onClose={dialogDismiss}
        aria-labelledby="customized-dialog-title"
        open={open}
        className={classes.dialog}
        fullScreen={mobileView}
      >
        <DialogTitle className={styles.title}>
          <IconButton aria-label="close" onClick={handleClose} className={classes.iconButton}>
            <CloseIcon/>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div className={styles.container}>
            {loading && <Loading/>}
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: '100px' }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">{intl.formatMessage({ id: 'consecutive' })}</TableCell>
                    <TableCell align="center">{intl.formatMessage({ id: 'date' })}</TableCell>
                    <TableCell align="center">{intl.formatMessage({ id: 'total' })}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orders && orders.map((order) => (
                    <Row key={order._id} row={order}/>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </DialogContent>
        <DialogActions>
          <Grid container direction={'column'}>
            <Grid item xs={12} justifyContent={'center'} justifyItems={'center'} container>
              {meta !== null &&
                <Pagination
                  count={meta.total_pages}
                  page={page}
                  size="large"
                  showFirstButton
                  showLastButton
                  onChange={handleChange}
                />
              }
              <Button
                color="primary"
                variant="contained"
                onClick={handleClose}
                className={styles.button}
                sx={{
                  '&:hover': {
                    backgroundColor: colorShade(theme.palette.primary.main, -60),
                  }
                }}
              >
                {intl.formatMessage({ id: 'buttonClose' })}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </BootstrapDialogStyle>
    </div>
  )
}
export default OrdersHistoryDialog
