import { collection, doc, onSnapshot } from 'firebase/firestore'
import { db } from '../firebase'
import { restaurantsCollection } from './collections_names_firestore'
import { getStorage, saveStorage } from './storage'

const orderStatesFromFirestore = async () => {
  const document = await doc(collection(db, restaurantsCollection), getStorage('restaurant_id'))
  await onSnapshot(document, (snapshot) => {
    saveStorage('delivery_order_states', JSON.stringify(snapshot.data()?.delivery_order_states))
    saveStorage('table_order_states', JSON.stringify(snapshot.data()?.table_order_states))
  })
}
export default orderStatesFromFirestore

