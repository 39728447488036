import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Axios from 'axios'
import { urlCheckForHome, urlGetDeliveryURL, urlGetHomeUrl } from '../../apis/home'
import { getStorage, removeThemeStorage, saveStorage } from '../../utils/storage'
import { saveRestaurantData } from '../../utils/saveRestaurantData'

export const HOME_NAME = 'home'

const initialState = {
  value: null,
  status: 'idle',
  loading: false,
  homeInfo: null,
  restaurantData: null
}

export const getDeliveryURL = createAsyncThunk('getDeliveryURL', async (params) => {
  const resp = await Axios({
    headers: {
      lang: navigator.language,
      'If-None-Match': ''
    },
    url: urlGetDeliveryURL,
    method: 'GET',
    params: params
  })
  return resp.data.response
})

export const getHomeInfo = createAsyncThunk('getHomeInfo', async () => {
  const resp = await Axios({
    headers: {
      Authorization: `Bearer ${getStorage('st')}`,
      'X-PI-TABLE-TOKEN': getStorage('tt'),
      'X-PI-RESTAURANT-ID': getStorage('restaurant_id'),
      lang: navigator.language,
      'If-None-Match': ''
    },
    url: urlGetHomeUrl,
    method: 'GET',
  })
  return resp.data.response
})

export const checkForHome = createAsyncThunk('checkForHome', async (params, { rejectWithValue }) => {
  try {
    const resp = await Axios({
      headers: {
        Authorization: `Bearer ${getStorage('st')}`,
        lang: navigator.language,
        'If-None-Match': ''
      },
      url: urlCheckForHome,
      method: 'GET',
      params: params
    })
    return resp.data.response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const homeSlice = createSlice({
  name: HOME_NAME,
  initialState,//472 257 + 230

  reducers: {
    resetDeliveryResponse: (state) => {
      state.value = null
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getHomeInfo.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getHomeInfo.fulfilled, (state, action) => {
      state.homeInfo = action.payload
      //state.loading = false
    })
    builder.addCase(getHomeInfo.rejected, (state) => {
      state.status = 'error'
      state.loading = false
      state.homeInfo = null
    })
    builder.addCase(getDeliveryURL.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getDeliveryURL.fulfilled, (state, action) => {
      state.value = action.payload
      //state.loading = false
    })
    builder.addCase(getDeliveryURL.rejected, (state) => {
      state.status = 'error'
      state.loading = false
      state.value = null
    })
    builder.addCase(checkForHome.pending, (state) => {
      state.loading = true
    })
    builder.addCase(checkForHome.fulfilled, (state, action) => {
      if (action.payload !== undefined && Object.keys(action.payload).length > 0) {
        const key = Object.keys(action.payload)
        removeThemeStorage()
        if (key[0] === 'home') {
          state.homeInfo = action.payload['home']
          saveStorage('primary_color', state.homeInfo.primary_color.substring(4))
          saveStorage('secondary_color', state.homeInfo.secondary_color.substring(4))
          saveStorage('tertiary_color', state.homeInfo.tertiary_color.substring(4))
          if (state.homeInfo?.title_color !== null) {
            saveStorage('title_color', state.homeInfo?.title_color.substring(4))
          }
          if (state.homeInfo?.restaurant_locations?.length === 1) {
            saveStorage('table_uid', state.homeInfo.restaurant_locations[0].table_uid)
          }
        } else if (key[0] === 'restaurant_info') {
          state.restaurantData = action.payload['restaurant_info']
          saveStorage('table_uid', state.restaurantData.table.uid)
          saveRestaurantData(state.restaurantData)
        }
      }
      state.loading = false
    })
    builder.addCase(checkForHome.rejected, (state) => {
      state.status = 'error'
      state.loading = false
      state.value = null
    })
  }
})

export const homeActions = { getDeliveryURL, getHomeInfo, checkForHome }

export const { resetDeliveryResponse } = homeSlice.actions

export default homeSlice.reducer
