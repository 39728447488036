import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Box, DialogTitle, IconButton, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import DialogContent from '@mui/material/DialogContent'
import List from '@mui/material/List'
import DialogActions from '@mui/material/DialogActions'
import { shoppingCartActions } from '../ShoppingCart.slice'
import { ConfirmDialog } from '../../../components/dialogs/ConfirmDialog'
import getLocalKeyWords from '../../../utils/local_keywords'
import Button from '@mui/material/Button'
import ShoppingListItem from './ShoppingListItem'
import { useMemo } from 'react'
import Loading from '../../../components/Loading'
import styles from './ShoppingCartDialog.module.css'
import { ShoppingCartDialogStyle } from './ShoppingCartDialog.style'
import { BootstrapDialogStyle } from '../../../components/dialogs/BootstrapDialog.style'
import Grid from '@mui/material/Grid'
import { formatCost } from '../../../utils'
import { getStorage } from '../../../utils/storage'
import { colorShade } from '../../../utils/colorShade'
import { theme } from '../../../index'
import { useActiveOrder } from '../../../utils/useActiveOrder'
import { canModifyDeliveryOrder } from '../../../utils/order_state_extension'

const ShoppingCartDialog = ({
  open,
  setOpen,
  mobileView,
  orderItems,
  confirmUserOrder,
  setOrderItemToEdit,
  userOrder
}) => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const loading = useSelector((store) => store.shoppingCart.loading)
  const restaurantData = JSON.parse(localStorage.getItem('restaurant_data'))
  const classes = ShoppingCartDialogStyle()
  const { hasActiveOrder } = useActiveOrder()

  useMemo(() => {
    if (orderItems) {
      if (orderItems.length === 0) {
        setOpen(false)
      }
    }
  }, [orderItems])

  const deleteAllItems = () => {
    ConfirmDialog({
      text: intl.formatMessage({ id: 'wantToDeleteAllItems' }, {
        order: getLocalKeyWords('order'),
        products: getLocalKeyWords('products')
      }),
      confirmButtonText: intl.formatMessage({ id: 'buttonDeleteItems' }),
      cancelButtonText: intl.formatMessage({ id: 'buttonNo' }),
      onConfirm: () => {
        dispatch(shoppingCartActions.deleteAllItems())
        handleClose()
      },
      onCancel: () => {}
    })
  }

  const handleClose = () => {
    setOpen(false)
  }

  const dialogDismiss = (event, reason) => {
    if (reason && reason === 'backdropClick')
      return
    setOpen(false)
  }

  const getSubTotal = () => {
    let subTotal = {
      'subunits': 0,
      'currency_iso': getStorage('currency')
    }
    orderItems.forEach((orderItem) => {
      if (orderItem?.amount?.subunits) {
        subTotal.subunits += orderItem?.amount?.subunits
      }
    })
    return subTotal
  }

  return (
    <div>
      <BootstrapDialogStyle
        onClose={dialogDismiss}
        aria-labelledby="customized-dialog-title"
        open={open}
        className={classes.dialog}
        fullScreen={mobileView}
      >
        <DialogTitle className={styles.title}>
          <IconButton aria-label="close" onClick={handleClose} className={classes.iconButton}>
            <CloseIcon/>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div className={styles.container}>
            {loading && <Loading/>}
            <List>
              {orderItems.map((orderItem, index) => (
                <ShoppingListItem
                  key={index}
                  orderItem={orderItem}
                  canEdit={canModifyDeliveryOrder(userOrder?.state)}
                  mobileView={mobileView}
                  setOrderItemToEdit={setOrderItemToEdit}
                />
              ))}
              {canModifyDeliveryOrder(userOrder?.state) &&
                <Box className={styles.box}>
                  <Button variant="outlined" color="error" size="large" onClick={deleteAllItems}>
                    {intl.formatMessage({ id: 'removeProduct' }, { products: getLocalKeyWords('products') })}
                  </Button>
                </Box>}
            </List>
          </div>
        </DialogContent>
        <DialogActions>
          <Grid container direction={'column'}>
            {getSubTotal().subunits > 0 &&
              <Grid item xs={12} justifyContent={'center'} justifyItems={'center'} container className={styles.price}>
                <Typography gutterBottom variant="subtitle2" sx={{ textOverflow: 'ellipsis' }} fontSize={'13px'}>
                  {intl.formatMessage({ id: 'products' }, { products: getLocalKeyWords('products') })}: {formatCost(getSubTotal())}
                </Typography>
              </Grid>}
            <Grid item xs={12} justifyContent={'center'} justifyItems={'center'} container>
              <Button
                color="primary"
                variant="contained"
                onClick={confirmUserOrder}
                className={styles.button}
                sx={{
                  '&:hover': {
                    backgroundColor: colorShade(theme.palette.primary.main, -60),
                  }
                }}
              >
                {hasActiveOrder === true ? intl.formatMessage({ id: 'goToActiveOrder' }) : restaurantData.delivery === true ? intl.formatMessage({ id: 'continueWithMyData' }) : intl.formatMessage({ id: 'confirmOrder' }, { order: getLocalKeyWords('order') })}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </BootstrapDialogStyle>
    </div>
  )
}
export default ShoppingCartDialog
