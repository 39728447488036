export const HeaderStyle = (mobileView, restaurant) => ({
  avatar: {
    width: restaurant?.banner && !restaurant?.banner?.includes('missing') ? mobileView ? '70px' : '100px' : '50px',
    height: restaurant?.banner && !restaurant?.banner?.includes('missing') ? mobileView ? '70px' : '100px' : '50px',
    marginTop: restaurant?.banner && !restaurant?.banner?.includes('missing') ? '-60px' : '8px',
    zIndex: 1
  },
  avatar2: {
    width: '40px',
    height: '40px',
    marginTop: '8px',
    zIndex: 1
  },
  font: {
    fontWeight: 'Bold',
    color: 'black',
    paddingLeft: '8px',
    fontSize: mobileView === true ? '18px' : '20px'
  },
  font2: {
    fontWeight: 'Bold',
    color: 'black',
    paddingLeft: '8px',
    fontSize: '14px',
    overflow: 'auto',
    maxLines: 2
  },
  image: {
    objectFit: 'cover',
    width: '100%',
    height: '110px'
  },
  schedule: {
    alignItems: 'center',
    justifyContent: mobileView ? 'flex-start' : 'flex-end',
    paddingRight: '8px'
  }
})

export const ScheduleStyle = (isHovering) => ({
  schedule: {
    backgroundColor: isHovering ? 'lightgray' : 'white',
    display: 'flex',
    borderRadius: '.2rem',
    padding: '2px',
    justifyContent: 'center',
    justifyItems: 'center',
    alignItems: 'center'
  }
})
