import { makeStyles, styled } from '@mui/styles'
import { Typography } from '@mui/material'

export const userOrderStyles = makeStyles(theme =>({
  titleLeft: {
    color: 'white'
  },
  titleRight: {
    color: 'white',
    textAlign: 'right'
  },
  headerCard: {
    margin: '8px',
    backgroundColor: theme.palette.primary.main
  },
  summaryCard: {
    margin: '8px',
    backgroundColor: theme.palette.primary.main
  },
  buttonHeader: {
    color: 'white !important'
  },
  circleMe: {
    display: 'grid',
    placeItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#57a496',
    borderRadius: '50%',
    width: 30,
    height: 30,
    marginRight: 10
  },
  titleCircleMe: {},
  divider: {
    color: 'white',
    marginLeft: '10 !important',
    marginRight: '10 !important',
  },
  myOrderState: {
    background: '#57a496 !important',
    borderRadius: '5px !important',
    color: 'white',
    fontWeight: 'bold',
    paddingTop: 5,
    paddingBottom: 5,
  },
  headerSection: {
    background: '#F0F0F0',
    paddingTop: 5,
    paddingBottom: 5,
  }
}))

export const MyQuantity = styled(Typography)({
  textAlign: 'center',
  color: 'white',
  borderRadius: '50%',
  marginBottom: '0 !important',
  margin: '0 1rem !important',
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center'
})
