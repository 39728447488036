import * as React from 'react'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import { useIntl } from 'react-intl'
import getLocalKeyWords from '../../../utils/local_keywords'
import { maxButtonWebWidth, maxButtonWidth } from '../../../utils/screen_utils'
import { colorShade } from '../../../utils/colorShade'
import { theme } from '../../../index'

const PayButtonTableOrder = ({ resumeClick, mobileView, orderDelivered }) => {
  const intl = useIntl()
  return (
    <Grid container
          alignItems="center"
          justifyContent="center">
      <Button
        variant={'contained'}
        size={'large'}
        color={'info'}
        onClick={resumeClick}
        fullWidth
        sx={{
          borderRadius: '.4rem',
          position: 'fixed',
          zIndex: 1,
          bottom: orderDelivered === true ? '58px' : '8px',
          marginLeft: '8px',
          marginRight: '8px',
          width: mobileView ? maxButtonWidth : maxButtonWebWidth,
          '&:hover': {
            backgroundColor: colorShade(theme.palette.info.main, -60),
          }
        }}
      >
        {intl.formatMessage({ id: 'seeBill' }, { order: getLocalKeyWords('order') })}
      </Button>
    </Grid>
  )
}

export default PayButtonTableOrder
