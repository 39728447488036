import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Axios from 'axios'
import LanguageDialog from '../../components/dialogs/LanguageDialog'
import { getStorage } from '../../utils/storage'
import { urlCloneOrder, urlGetOrdersHistory } from '../../apis/ordersHistory'

export const ORDERS_HISTORY_NAME = 'ordersHistory'

const initialState = {
  ordersHistoryValue: null,
  status: 'idle',
  loading: false,
  metaValue: null
}

export const getOrdersHistory = createAsyncThunk('getOrdersHistory', async (params, { rejectWithValue }) => {
  try {
    const resp = await Axios({
      headers: {
        Authorization: `Bearer ${getStorage('st')}`,
        'X-PI-TABLE-TOKEN': getStorage('tt'),
        'X-PI-RESTAURANT-ID': getStorage('restaurant_id'),
        lang: navigator.language,
        'If-None-Match': ''
      },
      url: urlGetOrdersHistory,
      method: 'GET',
      params: params
    })
    return resp.data
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const cloneOrder = createAsyncThunk('cloneOrder', async (params, { rejectWithValue }) => {
  try {
    const resp = await Axios({
      headers: {
        Authorization: `Bearer ${getStorage('st')}`,
        'X-PI-TABLE-TOKEN': getStorage('tt'),
        'X-PI-RESTAURANT-ID': getStorage('restaurant_id'),
        lang: navigator.language,
        'If-None-Match': ''
      },
      url: urlCloneOrder,
      method: 'PUT',
      data: params
    })
    return resp.data
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const ordersHistorySlice = createSlice({
  name: ORDERS_HISTORY_NAME,
  initialState,

  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getOrdersHistory.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getOrdersHistory.fulfilled, (state, action) => {
      state.ordersHistoryValue = action.payload.response
      state.metaValue = action.payload.meta
      state.loading = false
    })
    builder.addCase(getOrdersHistory.rejected, (state) => {
      state.status = 'error'
      state.loading = false
      state.ordersHistoryValue = null
      LanguageDialog({ from: getOrdersHistory })
    })
    builder.addCase(cloneOrder.pending, (state) => {
      state.loading = true
    })
    builder.addCase(cloneOrder.fulfilled, (state) => {
      state.loading = false
    })
    builder.addCase(cloneOrder.rejected, (state) => {
      state.status = 'error'
      state.loading = false
      LanguageDialog({ from: cloneOrder })
    })
  }
})

export const ordersHistoryActions = { getOrdersHistory, cloneOrder }

export default ordersHistorySlice.reducer
