import { Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import styles from './HomeInformation.module.css'
import { getStorage } from '../../utils/storage'

const HomeInformation = ({ homeInfo }) => {
  const textColor = getStorage('title_color') || 'black'

  return (
    <Grid container className={styles.container} direction={'column'}>
      <Typography variant="body1" className={styles.text} color={`#${textColor}`}>
        {homeInfo?.description}
      </Typography>
    </Grid>
  )
}

export default HomeInformation
