import {
  DialogTitle,
  Grid,
  IconButton, TextField, Typography,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import DialogContent from '@mui/material/DialogContent'
import styles from './AddressesDialog.module.css'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import { useIntl } from 'react-intl'
import InputAdornment from '@mui/material/InputAdornment'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import { SuggestionsItem } from './SuggestionsItem'
import NavigationIcon from '@mui/icons-material/Navigation'
import { AddressesItem } from './AddressesItem'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { directionActions, resetAproxAddress } from '../../direction/Direction.slice'
import { ConfirmDialog } from '../../../components/dialogs/ConfirmDialog'
import { directionsActions } from '../../directions/Directions.slice'
import { suggestionsActions } from '../../suggestions/Suggestions.slice'
import GetLocationByMap from '../../../components/maps/GetLocationByMap'
import { InfoDialog } from '../../../components/dialogs/InfoDialog'
import getLocalKeyWords from '../../../utils/local_keywords'
import Loading from '../../../components/Loading'
import { getStorage, removeStorageByKey, saveStorage } from '../../../utils/storage'
import { BootstrapDialogStyle } from '../../../components/dialogs/BootstrapDialog.style'
import {
  AddressesDialogStyles,
  AddressesDialogStyle,
} from './AddressesDialog.style'
import { colorShade } from '../../../utils/colorShade'
import { theme } from '../../../index'

let options = {
  enableHighAccuracy: true, timeout: 5000, maximumAge: 0,
}

const AddressesDialog = ({ open, setOpen, mobileView }) => {
  const [state, setstate] = useState({
    showMap: false, showCurrentLocation: false, isInputFocus: false, suggestion: null,
  })
  const navigate = useNavigate()
  const intl = useIntl()

  const [address, setAddress] = useState('')
  const [description, setDescription] = useState('')
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const [errorValidation, setErrorValidation] = useState(false)
  const [isPinDragged, setPinDragged] = useState(false)

  const loadingDirections = useSelector((store) => store.directions.loading)
  const loadingDirection = useSelector((store) => store.direction.loading)
  const restaurantData = JSON.parse(localStorage.getItem('restaurant_data'))
  const directions = useSelector((store) => store.directions.directions)
  const suggestions = useSelector((store) => store.suggestions.suggestions)
  let aproxAddress = useSelector((store) => store.direction.aproxAddress)
  const [currentLocation, setCurrentLocation] = useState({ lat: 37.421757, lon: -122.083910 })
  const [locationByGps, setLocationByGps] = useState(null)
  const dispatch = useDispatch()
  const classes = AddressesDialogStyle()

  useEffect(() => {
    getCurrentLocation(true)
    getDirections()
  }, [])

  useEffect(() => {
    if (isPinDragged === true) {
      handleMapGeocodePosition()
    }
  }, [isPinDragged])

  useEffect(() => {
    if (aproxAddress !== null && aproxAddress !== undefined) {
      setAddress(aproxAddress.aprox_address)
      setstate({
        ...state, showMap: true, suggestion: {
          address: aproxAddress.aprox_address,
          lat: currentLocation.lat,
          lon: currentLocation.lon
        }
      })
    }
  }, [aproxAddress])

  useEffect(() => {
    if (directions !== null && directions !== undefined) {
      setSelectedIndex(directions.length - 1)
    }
  }, [directions])

  const handleClose = () => {
    setOpen(false)
  }

  const dialogDismiss = (event, reason) => {
    if (reason && reason === 'backdropClick')
      return
    setOpen(false)
  }

  const getCurrentLocation = (isFromStart) => {
    navigator.geolocation.getCurrentPosition(onSuccess, onError, options)
    if (isFromStart === false) {
      setPinDragged(true)
    }
  }

  const onSuccess = ({ coords }) => {
    setCurrentLocation({ lat: coords.latitude, lon: coords.longitude })
    setLocationByGps({ lat: coords.latitude, lon: coords.longitude })
  }

  const onError = (err) => {
    console.warn('ERROR(' + err.code + '): ' + err.message)
  }

  const handleChange = (value) => {
    setAddress(value)
    const params = {
      query: value, lat: currentLocation.lat, lon: currentLocation.lon
    }
    dispatch(suggestionsActions.getSuggestions(params))
  }

  const getDirections = () => {
    dispatch(directionsActions.getDirections())
  }

  const registerDirection = () => {
    let params
    if (description === '' || description === undefined || description === null) {
      setErrorValidation(true)
    } else {
      setErrorValidation(false)
      if (state.showCurrentLocation) {
        params = {
          lat: currentLocation.lat, lon: currentLocation.lon, name: description, address: address,
        }
      } else {
        if (isPinDragged) {
          params = {
            lat: currentLocation.lat, lon: currentLocation.lon, name: description, address: address,
          }
        } else {
          params = {
            name: description, address: address, lat: state.suggestion.lat, lon: state.suggestion.lon,
          }
        }
      }
      dispatch(directionActions.registerDirection(params)).then(() => {
        getDirections()
        setstate({ ...state, showMap: false, isInputFocus: false })
        setAddress('')
        setDescription(null)
        dispatch(resetAproxAddress())
      })
    }
    setPinDragged(false)
  }

  const deleteDirection = (direction) => {
    const params = {
      id: direction._id
    }
    ConfirmDialog({
      text: intl.formatMessage({ id: 'wantToDeleteAddress' }),
      confirmButtonText: intl.formatMessage({ id: 'buttonDeleteItems' }),
      cancelButtonText: intl.formatMessage({ id: 'buttonNo' }),
      onConfirm: () => {
        dispatch(directionActions.deleteDirection(params)).unwrap().then(() => getDirections())
      },
      onCancel: () => {}
    })
  }

  const onButtonPressed = () => {
    if (state.showMap === true) {
      registerDirection()
    } else {
      selectDirectionAndContinue()
    }
  }

  const showPickupDialog = () => {
    ConfirmDialog({
      text: intl.formatMessage({ id: 'errorValidateDirectionMessage' }, { restaurant: getLocalKeyWords('restaurant').toLowerCase() }),
      confirmButtonText: intl.formatMessage({ id: 'pickupOnSite' }),
      cancelButtonText: intl.formatMessage({ id: 'changeDirection' }),
      onConfirm: () => {
        saveStorage('pickup', true)
        removeStorageByKey('direction')
        navigate(`/userOrderDeliveryPayBill/${getStorage('table_uid')}`, { state: { fromOrder: true } })
      },
      onCancel: () => {}
    })
  }

  const selectDirectionAndContinue = () => {
    if (selectedIndex !== -1) {
      const params = {
        id: directions[selectedIndex]._id, orderId: getStorage('user_order_id')
      }
      if (restaurantData.restaurant.is_pibox_activated === true) {
        dispatch(directionActions.selectDirection(params)).unwrap().then(() => {
          navigateToDeliveryPayBill()
        })
      } else {
        dispatch(directionActions.validateDirection(params)).unwrap()
          .then((response) => {
            if (response !== null && response !== undefined) {
              if (response.has_restaurant_coverage_zones) {
                if (response.is_user_in_zone === true) {
                  dispatch(directionActions.selectDirection(params)).unwrap().then(() => {
                    navigateToDeliveryPayBill()
                  }).catch(() => {
                    navigateToDeliveryPayBill()
                  })
                } else {
                  showPickupDialog()
                }
              } else {
                navigateToDeliveryPayBill()
              }
            } else {
              navigateToDeliveryPayBill()
            }
          })
          .catch(() => {
            navigateToDeliveryPayBill()
          })
      }
    } else {
      InfoDialog({ text: intl.formatMessage({ id: 'mustSelectAddress' }) })
    }
  }

  const navigateToDeliveryPayBill = () => {
    saveStorage('pickup', false)
    saveStorage('direction', JSON.stringify(directions[selectedIndex]))
    navigate(`/userOrderDeliveryPayBill/${getStorage('table_uid')}`, { state: { fromOrder: true } })
  }

  const handleMapGeocodePosition = () => {
    if (state.showCurrentLocation) {
      const params = {
        lat: currentLocation.lat, lon: currentLocation.lon
      }
      dispatch(directionActions.reverseGeocode(params))
    } else {
      if (isPinDragged) {
        const params = {
          lat: currentLocation.lat, lon: currentLocation.lon
        }
        dispatch(directionActions.reverseGeocode(params))
      } else {
        aproxAddress = {
          aprox_address: `${state.suggestion.name} ${state.suggestion.address}`
        }
        setAddress(aproxAddress.aprox_address)
      }
    }
    setPinDragged(false)
  }

  const handleOnDragEnd = (location) => {
    setCurrentLocation({ lat: location.lat, lon: location.lng })
    setPinDragged(true)
  }

  const renderMap = (showCurrentLocation) => (
    <div style={AddressesDialogStyles(mobileView).mapContainer}>
      <Grid container className={styles.container9}>
        <Grid item className={styles.container7}>
          <GetLocationByMap
            direction={state.suggestion}
            onDragEnd={handleOnDragEnd}
            index={1}
            style={{ zIndex: 0 }}
            showCurrentLocation={showCurrentLocation}
            showDragablePin={true}
          />
        </Grid>
        <Grid item xs={12}>
          <footer>
            {/*<Box sx={{ width: mobileView ? maxMobileWidth : maxWebWidth, position: 'fixed', bottom: 50 }}>
                  <Collapse in={open}>
                    <Alert
                      severity="info"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setOpen(false)
                          }}
                        >
                          <CloseIcon fontSize="inherit"/>
                        </IconButton>}
                      sx={{ mb: 2 }}
                    >
                      {intl.formatMessage({ id: 'canMovesPin' })}
                    </Alert>
                  </Collapse>
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    style={{ bottom: 20 }}
                    onClick={() => {
                      setOpen(true)
                    }}
                  >
                    <InfoIcon fontSize="inherit"/>
                  </IconButton>
                </Box>*/}
          </footer>
        </Grid>
      </Grid>
    </div>
  )

  return (
    <BootstrapDialogStyle
      onClose={dialogDismiss}
      aria-labelledby="customized-dialog-title"
      open={open}
      className={classes.dialog}
      fullScreen={mobileView}
    >
      <DialogTitle className={styles.container}>
        <IconButton aria-label="close" onClick={handleClose} className={classes.iconButton}>
          <CloseIcon/>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div className={styles.container2}>
          <div style={AddressesDialogStyles(mobileView).container3}>
            {loadingDirection || loadingDirections ? <Loading/> :
              <div style={AddressesDialogStyles(mobileView).container4}>
                <Grid container className={styles.container5}>
                  <Grid container className={styles.container6}>
                    <TextField
                      value={address}
                      placeholder={intl.formatMessage({ id: 'insertNewAddress' })}
                      fullWidth
                      variant="outlined"
                      color="secondary"
                      onChange={({ target }) => {
                        target.value.length === 0 ? setstate({ ...state, isInputFocus: false }) : setstate({
                          ...state,
                          isInputFocus: true,
                          showMap: false
                        })
                        handleChange(target.value)
                      }}
                      onFocus={() => setstate({ ...state, isInputFocus: true })}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LocationOnIcon/>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            {state.isInputFocus ? (
                              <CloseIcon onClick={() => {
                                setstate({
                                  ...state, showMap: false, isInputFocus: false,
                                })
                                setAddress('')
                              }}
                              />
                            ) : <div/>}
                          </InputAdornment>),
                        style: { borderRadius: '.4rem' },
                      }}
                    >
                    </TextField>
                  </Grid>
                  {state.showMap ? (<>
                    <Grid container className={styles.container6}>
                      <TextField
                        fullWidth
                        label={intl.formatMessage({ id: 'addressDetail' })}
                        placeholder={intl.formatMessage({ id: 'addressDetailExample' })}
                        color="secondary"
                        error={errorValidation}
                        helperText={errorValidation ? intl.formatMessage({ id: 'required' }) : ' '}
                        onChange={(event) => setDescription(event.target.value)}
                        InputProps={{
                          style: { borderRadius: '.4rem', },
                        }}
                      />
                    </Grid>
                  </>) : state.isInputFocus ? (<>
                      {state.showMap === false &&
                        <Grid container direction={'column'} className={styles.container7} gap={1}>
                          {suggestions ? suggestions.map((suggestion) =>
                            <SuggestionsItem
                              suggestion={suggestion}
                              key={suggestion._id}
                              onPressed={() => {
                                setAddress(`${suggestion.name} ${suggestion.address}`)
                                setstate({
                                  ...state,
                                  showMap: true,
                                  showCurrentLocation: false,
                                  suggestion: suggestion
                                })
                              }}
                            />
                          ) : <div/>}
                        </Grid>}
                    </>
                  ) : (
                    <>
                      <Grid container className={styles.container8}>
                        <Typography variant="subtitle1" fontWeight={'bold'}>
                          {intl.formatMessage({ id: 'myAddresses' })}
                        </Typography>
                      </Grid>
                      <Grid container direction={'column'} className={styles.container7} gap={1}>
                        {locationByGps ? <Grid className={styles.container7}>
                          <Button
                            startIcon={<NavigationIcon/>}
                            onClick={() => {
                              getCurrentLocation(false)
                              setstate({
                                ...state,
                                showMap: true,
                                showCurrentLocation: true,
                              })
                            }}
                          >
                            {intl.formatMessage({ id: 'currentLocation' })}
                          </Button>
                        </Grid> : <div/>}
                        {directions ? directions.map((direction, index) => (
                          <AddressesItem
                            key={index}
                            index={index}
                            onPressed={() => setSelectedIndex(index)}
                            selectedIndex={selectedIndex}
                            direction={direction}
                            onDeletePressed={() => deleteDirection(direction)}
                          />
                        )) : <div/>}
                      </Grid>
                    </>)}
                  {state.showMap === true &&
                    renderMap(state.showCurrentLocation)
                  }
                </Grid>
              </div>}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          color="info"
          variant="contained"
          onClick={onButtonPressed}
          style={AddressesDialogStyles(mobileView).button}
          sx={{
            '&:hover': {
              backgroundColor: colorShade(theme.palette.info.main, -60),
            }
          }}
        >
          {intl.formatMessage({ id: 'confirmAddress' })}
        </Button>
      </DialogActions>
    </BootstrapDialogStyle>
  )
}

export default AddressesDialog
