import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: 1,
  status: 'idle'
}

export const COUNTER_PRODUCT_NAME = 'counter_product'

// A mock function to mimic making an async request for data
export function fetchCount (amount = 1) {
  return new Promise((resolve) =>
    setTimeout(() => resolve({ data: amount }), 500)
  )
}

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const incrementAsync = createAsyncThunk(
  'counter/fetchCount',
  async (amount) => {
    const response = await fetchCount(amount)
    // The value we return becomes the `fulfilled` action payload
    return response.data
  }
)

export const setValue = createAsyncThunk('setValue', async (arg) => {
    return arg
})

export const counterSlice = createSlice({
  name: COUNTER_PRODUCT_NAME,
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    increment: (state, value) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      if (value.payload !== undefined && value.payload !== '') {
        state.value = parseInt(value.payload)
      } else {
        state.value += 1
      }
    },
    decrement: (state) => {
      state.value -= 1
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    incrementByAmount: (state, action) => {
      state.value += action.payload
    },
    resetValue: (state) => {
      state.value = 1
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(incrementAsync.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(incrementAsync.fulfilled, (state, action) => {
        state.status = 'idle'
        state.value += action.payload
      })
    builder.addCase(setValue.fulfilled, (state, action) => {
      state.value = action.payload
      state.loading = false
    })
  }
})

export const { increment, decrement, resetValue } = counterSlice.actions

export const counterActions = { setValue }

export default counterSlice.reducer
