import styles from './CategoryTab.module.css'
import { ListItemText, Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import { InfoDialog } from '../../../components/dialogs/InfoDialog'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import getLocalKeyWords from '../../../utils/local_keywords'
import { productActions } from '../../product/product.slice'
import ProductItem from './ProductItem'
import Divider from '@mui/material/Divider'
import { neutralizeBack, revivalBack } from '../../../utils/navigation_util'
import { isBusinessClose } from '../../../utils/schedule_util'

const CategoryTab = ({ category, mobileView, readOnly, setOpen, setOpenBusinessDialog }) => {
  const intl = useIntl()
  const restaurantData = useSelector((store) => store.restaurant.restaurantData)
  const dispatch = useDispatch()

  const navigateToProduct = (product) => {
    if (isBusinessClose(restaurantData) || restaurantData.restaurant.frozen_account === true) {
      InfoDialog({
        text: intl.formatMessage({ id: 'restaurantInactive' }, {
          restaurant: restaurantData.restaurant.name,
          order: (getLocalKeyWords('order_plural') || '').toLowerCase()
        })
      })
    } else {
      if (readOnly() === true) {
        dispatch(productActions.getProductAsync(product._id)).then(() => {
          setOpenBusinessDialog(true)
          neutralizeBack(() => handleCloseBusinessDialog())
        })
      } else {
        if (product?.has_toppings === true || product?.description) {
          dispatch(productActions.getProductAsync(product._id)).then(() => {
            setOpen(true)
            neutralizeBack(() => handleCloseProductDialog())
          })
        } else {
          const params = {
            order_item: {
              amount: product.price,
              product_id: product._id,
              product_image: product.image,
              product_name: product.name,
              quantity: 1,
            }
          }
          dispatch(productActions.submitProductAsync(params)).then(() => {
            InfoDialog({ text: intl.formatMessage({ id: 'productAdded' }, { product: getLocalKeyWords('product') }) })
          })
        }
      }
    }
  }

  const handleCloseProductDialog = () => {
    setOpen(false)
    revivalBack()
  }

  const handleCloseBusinessDialog = () => {
    setOpenBusinessDialog(false)
    revivalBack()
  }

  return (
    <div>
      <ListItemText
        className={styles.header}
        primary={
          <Typography variant="h6" className={styles.font1}>
            {category.name}
          </Typography>
        }
        secondary={
          <Typography variant="body1" className={styles.font2}>
            {category.description}
          </Typography>
        }
      />
      <Grid container>
        {category.products.map((product) => (
          <Grid key={product._id} item xs={12} sm={6} lg={6}>
            <ProductItem
              product={product}
              restaurantData={restaurantData}
              navigateToProduct={(product) => navigateToProduct(product)}
              mobileView={mobileView}
              readOnly={readOnly}
            />
            <Divider variant="middle"/>
          </Grid>
        ))}
      </Grid>
    </div>)
}

export default CategoryTab
