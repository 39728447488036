import { makeStyles, styled } from '@mui/styles'
import { Typography, Avatar } from '@mui/material'

export const tableOrderStyles = makeStyles({
  titleLeft: {
    color: 'white'
  },
  titleRight: {
    color: 'white',
    textAlign: 'right'
  },
  headerCard: {
    marginTop: 10,
    marginBottom: 10
  },
  buttonHeader: {
    color: 'white !important'
  },
  summaryCard: {
    backgroundColor: '#563424 !important',
    margin: 10
  },
  circleMe: {
    display: 'grid',
    placeItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#57a496',
    borderRadius: '50%',
    width: 30,
    height: 30,
    marginRight: 10,
    marginLeft: 5
  },
  titleCircleMe: {},
  divider: {
    color: 'white',
    marginLeft: '10 !important',
    marginRight: '10 !important',
  },
  myOrderState: {
    background: '#57a496 !important',
    borderRadius: '5px !important',
    color: 'white',
    fontWeight: 'bold',
    paddingTop: 5,
    paddingBottom: 5,
  },
  headerSection: {
    background: '#F0F0F0',
    paddingTop: 5,
    paddingBottom: 5,
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  container2: {
    p: 2,
    marginTop: '16px',
    marginBottom: '8px'
  },
  image: {
    height: 48,
    borderRadius: '.8rem',
    width: 48,
    objectFit: 'cover'
  },
  grid: {
    paddingRight: '16px',
    justifyContent:'flex-end',
    alignItems:'center'
  },
  grid1: {
    justifyContent:'flex-end',
    alignItems:'center',
    paddingTop: '8px'
  },
  image2: {
    height: 48,
    borderRadius: '.8rem',
    width: 48,
    objectFit: 'cover'
  }
})

export const UserList = styled('ul')({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-evenly',
  overflowX: 'scroll',
  flexWrap: 'nowrap',
  alignContent: 'start'
})

export const UserOrderList = styled('ul')({
  display: 'flex',
  justifyContent: 'space-evenly',
  overflowX: 'scroll',
  flexWrap: 'nowrap',
  alignContent: 'start',
})

export const ProductItem = styled(Avatar)({
  textAlign: 'left',
  borderRadius: 8,
  marginRight: 10
})

export const UserItem = styled(Typography)({
  textAlign: 'center',
  color: 'white',
  marginBottom: '0 !important',
  margin: '0 1rem !important',
  fontWeight: 'bold'
})

export const MyQuantity = styled(Typography)({
  textAlign: 'center',
  color: 'white',
  borderRadius: '50%',
  marginBottom: '0 !important',
  margin: '0 1rem !important',
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center'
})

export const OrderQuantity = styled(Typography)({
  textAlign: 'center',
  color: 'white',
  borderRadius: '.4rem',
  marginBottom: '0 !important',
  margin: '0 1rem !important',
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center'
})