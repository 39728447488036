import * as React from 'react'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import { useIntl } from 'react-intl'
import getLocalKeyWords from '../../../utils/local_keywords'
import { BottomDeliveryOrderStyle } from './BottomDeliveryOrder.style'
import { colorShade } from '../../../utils/colorShade'
import { theme } from '../../../index'

const BottomDeliveryOrder = ({ resumeClick, mobileView }) => {
  const intl = useIntl()
  const classes = BottomDeliveryOrderStyle(mobileView)
  return (
    <Grid container alignItems="center" justifyContent="center">
      <Button
        variant={'contained'}
        size={'large'}
        color={'info'}
        onClick={resumeClick}
        style={classes.button}
        sx={{
          '&:hover': {
            backgroundColor: colorShade(theme.palette.info.main, -60),
          }
        }}
      >
        {intl.formatMessage({ id: 'closeUserOrder' }, { order: getLocalKeyWords('order') })}
      </Button>
    </Grid>
  )
}

export default BottomDeliveryOrder
